import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
// import { useUpdatePayrollSettings } from '@/services/payroll/mutations'
import { useEffect, useState } from 'react'
// import { biWeekly, weekdayIndex } from '../../../utils'
import DatePicker from 'react-datepicker'
import { format, subDays } from 'date-fns'
import { capitalizeText } from '@/utils/string'
// import { toastHandler } from '@/components/utils/Toast'
import { Minus } from 'lucide-react'
import { Plus } from 'lucide-react'
import { dayFromNumber, numberDay } from '@/modules/dashboard/Payroll/partials/Settings/partials/utils'
import { biWeekly, weekdayIndex } from '@/modules/dashboard/Payroll/utils'
import { formatDate } from '@/modules/dashboard/Payroll/helper'
import { PayrollSettingsFullType } from './PayrollSettingsPrompt'
// import { dayFromNumber, numberDay } from './utils'

type Props = {
    setFormState: React.Dispatch<React.SetStateAction<PayrollSettingsFullType>>
    setStep: React.Dispatch<React.SetStateAction<number>>
    formState: PayrollSettingsFullType
}
export const StepOne = ({ setFormState, setStep, formState }: Props) => {
    const [booleanPay, setBooleanPay] = useState({
        monthly: formState.booleanPay.monthly,
        weekly: formState.booleanPay.weekly,
        biWeekly: formState.booleanPay.biWeekly,
    })

    const [payDaySettings, setPayDaySettings] = useState({
        weekly: formState.weekly_payment_day,
        monthly: formState.monthly_payment_day,
        biWeekly: formState.biweekly_payment_day,
    })
    const [biweeklyActualDates, setBiweeklyActualDates] = useState({
        biweekly_payment_start_date: formState.biweekly_payment_start_date,
        biweekly_payment_day: '',
        calendar_date: formState.biweekly_payment_start_date,
    })

    const [payrollWeekDay, setPayrollWeekDay] = useState(numberDay(payDaySettings.weekly!))

    const filterDays = (date: Date) => {
        return date.getDay() === weekdayIndex(payDaySettings?.biWeekly as string)
    }
    const disableBtn = !(booleanPay.biWeekly || booleanPay.monthly || booleanPay.weekly)

    useEffect(() => {
        setBiweeklyActualDates(prev => ({
            ...prev,
            biweekly_payment_start_date: subDays(new Date(biweeklyActualDates?.calendar_date), 14).toString(),
        }))
    }, [biweeklyActualDates?.calendar_date])

    return (
        <div className='mt-5'>
            <div className='h-[71vh] overflow-scroll'>
                <p className='font-medium'>How often do you pay your users?</p>
                <div className='flex gap-6 items-center'>
                    <div className={`flex gap-2 item-center text-gray7 mt-4`}>
                        <input
                            type='checkbox'
                            id='monthly'
                            className='accent-[#454ADE] cursor-pointer h-4 w-4'
                            onChange={e => setBooleanPay(prev => ({ ...prev, monthly: e.target.checked }))}
                            checked={booleanPay.monthly}
                        />
                        <label htmlFor='monthly' className='text-sm font-medium cursor-pointer'>
                            Monthly
                        </label>
                    </div>
                    <div className={`flex gap-2 item-center text-gray7 mt-4`}>
                        <input
                            type='checkbox'
                            id='weekly'
                            className='accent-[#454ADE] cursor-pointer h-4 w-4'
                            onChange={e => setBooleanPay(prev => ({ ...prev, weekly: e.target.checked }))}
                            checked={booleanPay.weekly}
                        />
                        <label htmlFor='weekly' className='text-sm font-medium cursor-pointer'>
                            Weekly
                        </label>
                    </div>
                    <div className={`flex gap-2 item-center text-gray7 mt-4`}>
                        <input
                            type='checkbox'
                            id='biWeekly'
                            className='accent-[#454ADE] cursor-pointer h-4 w-4'
                            onChange={e => setBooleanPay(prev => ({ ...prev, biWeekly: e.target.checked }))}
                            checked={booleanPay.biWeekly}
                        />
                        <label htmlFor='biWeekly' className='text-sm font-medium cursor-pointer'>
                            Biweekly
                        </label>
                    </div>
                </div>
                <div className='mb-11 flex-wrap mt-4 grid grid-cols-2 gap-y-5 gap-x-8'>
                    {booleanPay.monthly && (
                        <div className=''>
                            <h2 className='text-[#838383] text-base mb-4'>Set monthly pay day</h2>
                            <div className='flex items-baseline gap-3'>
                                <div className='flex border border-[#DADCE0] rounded-lg gap-3 w-[99px] h-10 items-center px-2 justify-center'>
                                    <button
                                        disabled={Number(payDaySettings.monthly) == 1}
                                        className='disabled:opacity-30'
                                    >
                                        <Minus
                                            color='#5E5E5E'
                                            className='cursor-pointer'
                                            onClick={() =>
                                                setPayDaySettings(prev => ({
                                                    ...prev,
                                                    monthly: (Number(payDaySettings.monthly) - 1).toString(),
                                                }))
                                            }
                                            size={20}
                                        />
                                    </button>
                                    {payDaySettings.monthly}
                                    <button
                                        disabled={Number(payDaySettings.monthly) == 30}
                                        className='disabled:opacity-30'
                                    >
                                        <Plus
                                            color='#5E5E5E'
                                            className='cursor-pointer'
                                            onClick={() =>
                                                setPayDaySettings(prev => ({
                                                    ...prev,
                                                    monthly: (Number(payDaySettings.monthly) + 1).toString(),
                                                }))
                                            }
                                            size={20}
                                        />
                                    </button>
                                </div>
                                <p className='whitespace-nowrap'>
                                    {formatDate(Number(payDaySettings.monthly))} day of the month
                                </p>
                            </div>
                        </div>
                    )}
                    {booleanPay.weekly && (
                        <div className=''>
                            <h2 className='text-[#838383] text-base mb-4'>Set weekly pay day</h2>
                            <div className='flex items-baseline gap-3'>
                                <div className='flex border border-[#DADCE0] rounded-lg gap-3 w-[90px] h-10 items-center px-2'>
                                    <button disabled={payrollWeekDay == 1} className='disabled:opacity-30'>
                                        <Minus
                                            color='#5E5E5E'
                                            className='cursor-pointer'
                                            onClick={() => setPayrollWeekDay(payrollWeekDay - 1)}
                                            size={20}
                                        />
                                    </button>
                                    {payrollWeekDay}
                                    <button disabled={payrollWeekDay == 7} className='disabled:opacity-30'>
                                        <Plus
                                            color='#5E5E5E'
                                            className='cursor-pointer'
                                            onClick={() => setPayrollWeekDay(payrollWeekDay + 1)}
                                            size={20}
                                        />
                                    </button>
                                </div>
                                <p className='whitespace-nowrap'>{formatDate(payrollWeekDay)} day of the week</p>
                            </div>
                        </div>
                    )}
                    {booleanPay.biWeekly && (
                        <div className=''>
                            <h2 className='text-[#838383] text-base mb-4'>Set bi-weekly pay day</h2>
                            <Select
                                name='pay_day'
                                onValueChange={data => {
                                    setPayDaySettings(prev => ({
                                        ...prev,
                                        biWeekly: data,
                                    }))
                                    setBiweeklyActualDates(prev => ({
                                        ...prev,
                                        calendar_date: '',
                                    }))
                                }}
                                defaultValue={''}
                                value={payDaySettings?.biWeekly}
                            >
                                <SelectTrigger
                                    className={`h-[2.5rem] border-[#DADCE0] bg-base_gray_100  text-base w-full font-medium `}
                                >
                                    <SelectValue placeholder={'Select an option'} />
                                </SelectTrigger>
                                <SelectContent className='text-sm w-[18rem]'>
                                    {biWeekly.map(option => (
                                        <SelectItem value={option.desc} className='cursor-pointer'>
                                            {option.name}s
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                            {biweeklyActualDates.biweekly_payment_start_date !== 'Invalid Date' && (
                                <p className='text-sm font-medium mt-1'>
                                    Pay starts -{' '}
                                    {format(new Date(biweeklyActualDates.biweekly_payment_start_date), 'dd/MM/yy')}
                                </p>
                            )}
                            {(payDaySettings.biWeekly?.length as number) > 5 && (
                                <div className='bg-white py-4 w-[19rem] mt-3 rounded-2xl'>
                                    <h3 className='font-bold'>Set first payment date</h3>
                                    <p className='text-sm'>
                                        Select the first{' '}
                                        <span className='text-brand font-semibold'>
                                            {capitalizeText(payDaySettings.biWeekly)}
                                        </span>{' '}
                                        to pay
                                        <br /> bi-weekly salaries{' '}
                                    </p>

                                    <div className='mt-4'>
                                        <DatePicker
                                            onChange={date => {
                                                setBiweeklyActualDates(prev => ({
                                                    ...prev,
                                                    calendar_date: date! as unknown as string,
                                                }))
                                            }}
                                            selected={
                                                biweeklyActualDates?.calendar_date
                                                    ? new Date(biweeklyActualDates?.calendar_date)
                                                    : undefined
                                            }
                                            filterDate={filterDays}
                                            inline
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div className='sticky bg-white bottom-0 w-full flex items-center justify-end gap-4 py-2 rounded-b-2xl'>
                <button
                    className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px] disabled:opacity-30'
                    disabled={
                        (booleanPay.biWeekly && biweeklyActualDates.biweekly_payment_start_date === 'Invalid Date') ||
                        disableBtn
                    }
                    onClick={() => {
                        setFormState(prev => ({
                            ...prev,
                            booleanPay: booleanPay,
                            monthly_payment_day: payDaySettings?.monthly,
                            weekly_payment_day: dayFromNumber(payrollWeekDay).toLowerCase(),
                            biweekly_payment_day: payDaySettings?.biWeekly,
                            biweekly_payment_start_date: booleanPay.biWeekly
                                ? biweeklyActualDates?.biweekly_payment_start_date
                                : '',
                        }))
                        setStep(2)
                    }}
                >
                    Next
                </button>
            </div>
        </div>
    )
}
