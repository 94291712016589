import { CiSearch } from 'react-icons/ci'
import React, { useEffect, useMemo, useState } from 'react'
import notFound from '@/assets/not-found.svg'
import ProgressBar from '@/components/ProgressBar'
import { useAuthContext, useTransactionContext } from '@/context'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useGetTransactions } from '@/services/transactions/queries'

import { debounce } from 'lodash'
import FilterTransactionsChip from '../FilterTransactionsChip'
import TransactionHistoryTable from '../components/TransactionHistoryTable'
import filter from '@/assets/filter.svg'
import FilterTransactions from '@/components/Modals/Transactions/AdvancedFilter'

const TransactionsHistory = () => {
    const [searchQuery, setSearchQuery] = useState('')
    const [backspaceClicked, setBackSpaceClicked] = useState(false)
    const [filterTransactionsModal, setFilterTransactionsModal] = useState(false)

    const viewOrgTransaction = location.pathname.includes('company')

    const { selectedAccount } = useAuthContext()
    const {
        filterTransactions,
        setFilterTransactions,
        setPageNumberClicked,
        pageNumber,
        pageNumberClicked,
        setPaginationDetails,
    } = useTransactionContext()

    const handleKeyDown = (event: any) => {
        if (event.key === 'Backspace') {
            setBackSpaceClicked(true)
        }
    }

    const {
        data: trasactiondata,
        isLoading: isTransactionsLoading,

        isRefetching,
        refetch: refetchAllOrgTransactions,
    } = useGetTransactions({
        queryParams: {
            page: pageNumber.toString(),
            limit: '12',
            account_type: viewOrgTransaction ? 'Org' : 'UserOrgAccount',
            id: viewOrgTransaction ? (selectedAccount?.org?.id as string) : (selectedAccount?.id as string),
            search: searchQuery,
            status: filterTransactions?.status,
            mode: filterTransactions?.mode,
            start_date: (filterTransactions?.period?.from ??
                filterTransactions?.period?.time_in_words) as unknown as string,
            end_date: (filterTransactions?.period?.to ?? new Date()) as unknown as string,
            transaction_type: filterTransactions?.transaction_type,
            min_amount: filterTransactions?.amount?.min?.replaceAll(',', ''),
            max_amount: filterTransactions?.amount?.max?.replaceAll(',', ''),
            initiated_by: filterTransactions?.initiated_by,
            flagged: (filterTransactions?.flagged === 'flagged' ? true : false) as unknown as string,
        },
        onSuccess: data => {
            if (data?.pagination) {
                setPaginationDetails(data?.pagination)
            }
            setFilterTransactions(prev => ({ ...prev, filter_now: false }))
        },
    })

    const { data: org_members } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
    })
    const orgMembers = useMemo(() => org_members ?? [], [org_members])

    useEffect(() => {
        if (pageNumber && pageNumberClicked) {
            refetchAllOrgTransactions()
            setPageNumberClicked(false)
        }
    }, [pageNumber, pageNumberClicked])
    useEffect(() => {
        refetchAllOrgTransactions()
    }, [viewOrgTransaction])

    const debouncedSearch = React.useRef(
        debounce(() => {
            refetchAllOrgTransactions()
        }, 300)
    ).current

    React.useEffect(() => {
        if (backspaceClicked) {
            refetchAllOrgTransactions()
            setBackSpaceClicked(false)
        }
        if (filterTransactions.filter_now) {
            refetchAllOrgTransactions()
        }
        if (searchQuery) {
            debouncedSearch()
        }
        return () => {
            debouncedSearch.cancel()
        }
    }, [filterTransactions.filter_now, refetchAllOrgTransactions, backspaceClicked, searchQuery])

    const transactionsData = useMemo(
        () => (trasactiondata && trasactiondata?.results?.length > 0 ? [...trasactiondata.results] : []),
        [trasactiondata]
    )

    const advanceFilterTriggered = Object.values(filterTransactions).some(value => {
        if (typeof value === 'string') {
            return value.length > 0
        }
        if (typeof value === 'object') {
            return Object.values(value).some(el => typeof el === 'string' && el.length > 0)
        }
    })

    if (isTransactionsLoading) return <ProgressBar />

    return (
        <section className={`h-screen overflow-scroll`}>
            <div className='flex items-center justify-between w-full mb-6'>
                <div className='flex-1'>
                    <h1 className='text-dark font-medium text-lg '>Transaction history</h1>
                    <p className='text-gray7 text-sm '>See users transactions in real time</p>
                </div>
            </div>
            <div className='w-full py-4 block bg-white rounded-2xl p-4 '>
                <div className='flex items-center justify-between'>
                    <div className='border border-[#DADCE0] h-[45px] w-[60%] md:w-[30%] rounded-lg flex items-center overflow-hidden'>
                        <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full mr-1'>
                            <CiSearch fontSize={25} />
                        </div>
                        <input
                            type='text'
                            placeholder='Search by Initiator'
                            className='h-full w-[80%] md:w-[60%] outline-none placeholder:text-xs'
                            onChange={e => setSearchQuery(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    <div className='flex items-center gap-2'>
                        <div
                            className='h-[35px] w-[35px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-1.5 cursor-pointer'
                            onClick={() => setFilterTransactionsModal(true)}
                        >
                            <img src={filter} alt='button' />
                        </div>
                        {/* <div className='h-[35px] w-[35px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-1.5 cursor-pointer'>
                            <img src={downArrow} alt='button' />
                        </div> */}
                    </div>
                </div>
                <section className='my-4'>
                    <FilterTransactionsChip orgMembers={orgMembers} />
                </section>
                {/* Checks if items exist  */}
                {transactionsData.length === 0 && isRefetching ? (
                    <ProgressBar />
                ) : (transactionsData.length >= 0 && searchQuery?.length < 1 && !advanceFilterTriggered) ||
                  (transactionsData.length > 0 && searchQuery?.length >= 0) ? (
                    <div
                        className={`overflow-y-scroll transition-all rounded-lg ${isRefetching ? 'opacity-30' : 'opacity-100'} `}
                    >
                        <TransactionHistoryTable transactions={transactionsData} orgMembers={orgMembers} />
                    </div>
                ) : (
                    <div className='h-450px] flex flex-col text-center justify-center items-center pb-8'>
                        {searchQuery || filterTransactions.filter_now ? (
                            <>
                                <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                                <span className='text-xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                                <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                    {searchQuery && transactionsData
                                        ? 'Transaction not found in this record. Please try a different filter or contact support for assistance.'
                                        : "Oops! It seems we couldn't find any matching results for your search. Please try again with different keywords or refine your filter criteria for better results."}
                                </span>
                            </>
                        ) : (
                            transactionsData.length === 0 &&
                            advanceFilterTriggered && (
                                <>
                                    <img src={notFound} alt='Not found' className='mt-6 h-[200px]' />
                                    <span className='text-2xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                                    <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                        {transactionsData &&
                                            'Transaction not found in this record. Please try a different filter or contact support for assistance.'}
                                    </span>
                                </>
                            )
                        )}
                    </div>
                )}
            </div>
            {filterTransactionsModal && (
                <FilterTransactions closeModal={setFilterTransactionsModal} orgMembers={orgMembers} />
            )}
        </section>
    )
}

export default TransactionsHistory
