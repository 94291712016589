import KeyInsights from './components/partials/KeyInsights'
import NewInflowSection from './components/partials/NewInflowSection'
import RecentExpenses from './components/partials/RecentExpenses'
import RecentTransactions from './components/partials/RecentTransactions'
import TransactionsGraph from './components/partials/TransactionsGraph'
// import BillRequests from './components/partials/BillRequests'
import KYCInfoContainer from '@/components/Modals/Employee/KYCForInvitedEmployees/KYCInfoContainer'
import { useAuthContext } from '@/context'
import { IsAdminWithWalletAccess } from '@/utils/permissions'
// import UpcomingEvents from './components/UpcomingEvents'

export default function OverviewDashboard() {
    const { showKYCModalOnce, setShowKYCModalOnce } = useAuthContext()
    const { isLoadingPrivilegedAdmin, isPriviledgedAdmin } = IsAdminWithWalletAccess()
    // console.log({ walletAccessUsers })

    return (
        <section className='md:m-2 lg:m-4 xl:m-6 overflow-scroll'>
            <KeyInsights isPriviledgedAdmin={isPriviledgedAdmin} isLoadingPrivilegedAdmin={isLoadingPrivilegedAdmin} />
            <RecentTransactions isPriviledgedAdmin={isPriviledgedAdmin} />
            <TransactionsGraph />
            <RecentExpenses />
            {/* <BillRequests /> */}
            <NewInflowSection />
            {/* <UpcomingEvents /> */}
            {showKYCModalOnce && <KYCInfoContainer setShowKYCModalOnce={setShowKYCModalOnce} />}
        </section>
    )
}
