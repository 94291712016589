import { Button } from '@/components'
import React, { ChangeEvent, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ErrorText } from './ErrorText'
import { useCreateSignedUrl } from '@/services/file-upload/mutation'
import { ModuleNameEnum, generateFileName } from '@/hooks/useSingleFileUpload'
import { useAuthContext } from '@/context'
import { BizCompanyComplianceType } from '../utils'

type Props = {
    title: string
    name: keyof BizCompanyComplianceType
}

export const UploadKYBDocument = ({ title, name }: Props) => {
    const { control, setValue } = useFormContext<BizCompanyComplianceType>()
    const { selectedAccount } = useAuthContext()

    const [fileData, setFileData] = useState<File>()

    const { mutate, isLoading } = useCreateSignedUrl({
        onSuccess: async dataFromSer => {
            const formdata = new FormData()
            formdata.append('file', fileData as Blob)

            await fetch(dataFromSer?.[0]?.signedUrl as string, {
                method: 'PUT',
                body: formdata,
            })

            setValue(name as keyof BizCompanyComplianceType, dataFromSer?.[0]?.url)
        },
    })

    const handleGetSignedURL = (e: ChangeEvent<HTMLInputElement>) => {
        mutate({
            files: [
                {
                    fileName: generateFileName({
                        module: ModuleNameEnum.KYB,
                        unique_identifier: name,
                        org_id: selectedAccount?.org?.id ?? '',
                        selectedFileName: e.target.files?.[0]?.name as string,
                    }),
                    contentType: e.target.files?.[0]?.type,
                },
            ],
            module: ModuleNameEnum.KYB,
        })

        setFileData(e.target.files?.[0])
    }

    const ref = useRef<HTMLInputElement>(null)

    return (
        <section className={`${isLoading ? 'opacity-60' : ''}`}>
            <h5 className='text-gray7 font-normal text-sm mb-2'>{title}</h5>
            <div className='border border-[#DADCE0] rounded-xl p-4'>
                <label className='cursor-pointer flex flex-col md:flex-row items-center justify-between gap-4 md:gap-16'>
                    <div className=''>
                        {!fileData?.name && (
                            <span className='text-gray7/80 text-sm text-medium'>
                                Click to upload file. Max 2MB | Accepts JPEG, PNG, and PDF formats
                            </span>
                        )}
                        {fileData?.name && (
                            <span className='font-medium break-all'>{fileData?.name ?? 'File uploaded'}</span>
                        )}
                    </div>
                    <Controller
                        name={name}
                        rules={{
                            required: true,
                        }}
                        disabled={isLoading}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <>
                                <input
                                    disabled={isLoading}
                                    name={field.name}
                                    type='file'
                                    hidden
                                    ref={ref}
                                    accept='.pdf, .jpeg, .jpg, .png'
                                    onChange={handleGetSignedURL}
                                />
                                <div className='w-full md:w-auto'>
                                    <Button
                                        disabled={isLoading}
                                        className='min-w-full md:min-w-auto bg-transparent text-brand border-brand border whitespace-nowrap hover:text-white'
                                        onClick={() => ref.current?.click()}
                                    >
                                        {fileData?.name ? 'Change document' : 'Upload document'}
                                    </Button>
                                </div>
                                {error && <ErrorText />}
                            </>
                        )}
                    />
                </label>
            </div>
        </section>
    )
}
