import { Button } from '@/components/Button'
import { PayloadType } from '@/services/api/mutation'
import { UseMutateFunction } from '@tanstack/react-query'
import { Minus, Plus } from 'lucide-react'
import React, { useState } from 'react'
import { PayrollSettingsFullType } from './PayrollSettingsPrompt'
import { useAuthContext } from '@/context'
import { useGetLeaveSettings } from '@/services/payroll/queries'
import { useUpdateOrgLeaveSettings } from '@/services/payroll/mutations'
import { toastHandler } from '@/components/utils/Toast'

type Props = {
    setStep: React.Dispatch<React.SetStateAction<number>>
    setFormState: React.Dispatch<React.SetStateAction<PayrollSettingsFullType>>
    formState: PayrollSettingsFullType
    updateFn: UseMutateFunction<unknown, unknown, PayloadType, unknown>
    isLoading: boolean
}
export const StepFour = ({ setStep, setFormState, isLoading, formState, updateFn }: Props) => {
    const { selectedAccount } = useAuthContext()
    const [leaveSettings, setLeaveSettings] = useState({
        sick_days: formState.leaveSettings.sick_days,
        vacation: formState.leaveSettings.vacation,
        personal: formState.leaveSettings.personal,
        maternity: formState.leaveSettings.maternity,
    })

    const { data: orgLeaveSettings } = useGetLeaveSettings({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
    })
    const { mutate: updateOrgLeaveSettings, isLoading: isLoadingLeaveSettings } = useUpdateOrgLeaveSettings({
        queryParams: {
            leave_setting_id: orgLeaveSettings?.[0]?.id as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'Leave settings saved', state: 'success' })
        },
    })
    const handleUpdate = () => {
        const data_to_server = {
            biweekly_payment_day: formState.biweekly_payment_day,
            biweekly_payment_start_date: formState.biweekly_payment_start_date,
            monthly_payment_day: formState.monthly_payment_day,
            weekly_payment_day: formState.weekly_payment_day,
            default_bonuses: formState.default_bonuses.map(data => ({
                name: data?.name as string,
                type: data.type,
                amount: data.amount,
                org: selectedAccount?.org.id as string,
            })),
            default_deductions: formState.default_deductions.map(data => ({
                name: data?.name as string,
                type: data.type,
                amount: data.amount,
                org: selectedAccount?.org.id as string,
            })),
            // leaveSettings: formState.leaveSettings,
            lead_days: formState.isGenAutomated ? formState.lead_days : undefined,
        }
        updateFn({ ...data_to_server })
    }
    return (
        <div className='mt-7'>
            <div className='h-[71vh] overflow-scroll'>
                <div className='grid grid-cols-2 gap-5'>
                    {leaveArr.map(type => (
                        <div>
                            <h2 className='text-[#838383] text-base mb-3'>{type.name}</h2>
                            <div className='flex items-baseline gap-3'>
                                <div className='flex border border-[#DADCE0] rounded-lg gap-3 w-[99px] h-10 items-center px-2 justify-center'>
                                    <button
                                        disabled={Number(leaveSettings[type.value as keyof typeof leaveSettings]) == 1}
                                        className='disabled:opacity-30'
                                    >
                                        <Minus
                                            color='#5E5E5E'
                                            className='cursor-pointer'
                                            onClick={() =>
                                                setLeaveSettings(prev => ({
                                                    ...prev,
                                                    [type.value]: (
                                                        Number(
                                                            leaveSettings[type.value as keyof typeof leaveSettings]
                                                        ) - 1
                                                    ).toString(),
                                                }))
                                            }
                                            size={20}
                                        />
                                    </button>
                                    {leaveSettings[type.value as keyof typeof leaveSettings]}
                                    <button
                                        disabled={Number(leaveSettings[type.value as keyof typeof leaveSettings]) == 30}
                                        className='disabled:opacity-30'
                                    >
                                        <Plus
                                            color='#5E5E5E'
                                            className='cursor-pointer'
                                            onClick={() =>
                                                setLeaveSettings(prev => ({
                                                    ...prev,
                                                    [type.value]: (
                                                        Number(
                                                            leaveSettings[type.value as keyof typeof leaveSettings]
                                                        ) + 1
                                                    ).toString(),
                                                }))
                                            }
                                            size={20}
                                        />
                                    </button>
                                </div>
                                <p className='whitespace-nowrap'>
                                    day{leaveSettings[type.value as keyof typeof leaveSettings] !== '1' && 's'} in a
                                    year
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='sticky bg-white bottom-0 w-full flex items-center justify-end gap-4 py-2 rounded-b-2xl border-t border-[#DADCE0]'>
                <button
                    className='bg-white text-black px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px] disabled:opacity-30'
                    onClick={() => {
                        setFormState(prev => ({
                            ...prev,
                            leaveSettings: leaveSettings,
                            miscellaneous: leaveSettings?.personal,
                            parental: leaveSettings?.maternity,
                        }))
                        setStep(3)
                    }}
                >
                    back
                </button>
                <Button
                    className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px] disabled:opacity-30'
                    loading={isLoading}
                    disabled={isLoading || isLoadingLeaveSettings}
                    onClick={() => {
                        setFormState(prev => ({
                            ...prev,
                            leaveSettings: leaveSettings,
                            // miscellaneous: leaveSettings?.personal,
                            // parental: leaveSettings?.maternity,
                        }))
                        updateOrgLeaveSettings({
                            ...leaveSettings,
                            miscellaneous: leaveSettings?.personal,
                        })
                        handleUpdate()
                    }}
                >
                    Finish setup
                </Button>
            </div>
        </div>
    )
}

const leaveArr = [
    {
        name: 'Sick days',
        value: 'sick_days',
    },
    {
        name: 'Vacation',
        value: 'vacation',
    },
    {
        name: 'Personal',
        value: 'personal',
    },
    {
        name: 'Maternity / Paternity',
        value: 'maternity',
    },
]
