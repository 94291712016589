import React, { useState } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { AiOutlineClose } from 'react-icons/ai'
import { Button, Input, Textarea } from '@/components'
import { useMutationCreateCustomer, useMutationUpdateCustomer } from '@/services/customers/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { Customers } from '@/types/customers'
import { emailRegex } from '@/utils/string'

type Props = {
    setCloseModal: React.Dispatch<React.SetStateAction<boolean>>
    customerDetails?: Customers
    update?: boolean
}

export default function CreateNewCustomr({ setCloseModal, customerDetails, update }: Props) {
    const defaultState = {
        name: customerDetails?.name ?? '',
        contact: {
            first_name: customerDetails?.contact?.first_name ?? '',
            last_name: customerDetails?.contact?.last_name ?? '',
            phone: customerDetails?.contact?.phone ?? '',
        },
        address: customerDetails?.address ?? '',
        city: customerDetails?.city ?? '',
        state: customerDetails?.state ?? '',
        email: customerDetails?.email ?? '',
        description: customerDetails?.description ?? '',
        country: customerDetails?.country ?? '',
        zipcode: customerDetails?.zipcode ?? '',
    }
    const [createCustomerProps, setCreateCustomerProps] = useState(defaultState)

    const { selectedAccount } = useAuthContext()
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target

        setCreateCustomerProps(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }

    const { mutate: createCustomer, isLoading } = useMutationCreateCustomer({
        onSuccess: () => {
            toastHandler({ message: 'Customer Created', state: 'success' })
            setCloseModal(false)
        },
        queryParams: {
            org: selectedAccount?.org?.id as string,
        },
    })
    const { mutate: updateCustomer, isLoading: isUpdating } = useMutationUpdateCustomer({
        onSuccess: () => {
            toastHandler({ message: `Customer Updated`, state: 'success' })
            setCloseModal(false)
        },
        queryParams: {
            org: selectedAccount?.org?.id as string,
        },
    })
    const handleCustomer = () => {
        const { name, email } = createCustomerProps
        // mutate({ ...createCustomerProps })

        if (!name || !email) {
            toastHandler({ message: 'Fill in the compulsory details', state: 'error' })
            return
        }
        if (!update) {
            createCustomer({ ...createCustomerProps, org_id: selectedAccount?.org?.id })
            return
        }
        updateCustomer({
            id: customerDetails?.id,
            update: { ...createCustomerProps, org_id: selectedAccount?.org?.id },
        })
        // console.log({ ...createCustomerProps, ...bankDetails, org_id: selectedAccount?.org?.id })
    }
    const disableButton = () => {
        const { name, email } = createCustomerProps
        const res = !name || !email

        return res || !emailRegex.test(createCustomerProps.email)
    }

    return (
        <ModalWrapper variants={undefined}>
            <section className='w-screen lg:w-[44rem] max-h-[85vh] pb-8'>
                <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-end '>
                    <div className='flex items-center w-[65%] justify-between h-full'>
                        <h2 className='font-semibold text-[#31254B]'>{update ? 'Update' : 'Add New'} Customer</h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={() => setCloseModal(false)}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <div className=' max-h-[64vh] overflow-scroll'>
                    <section className='flex p-6 mt-5 gap-[1rem] lg:gap-[2rem]'>
                        <div className='flex flex-col flex-1 gap-3'>
                            <label className='flex items-center gap-1'>
                                Customer name
                                <span className='text-[red]'>*</span>
                            </label>
                            <Input
                                type='text'
                                placeholder='Enter name of customer'
                                name='name'
                                value={createCustomerProps.name}
                                onChange={handleInputChange}
                                className='border-[#DADCE0]  h-[3rem]'
                            />
                        </div>
                        <div className='flex flex-col flex-1 gap-3'>
                            <label className='flex items-center gap-1'>
                                Email address
                                <span className='text-[red]'>*</span>
                            </label>
                            <Input
                                type='email'
                                placeholder='Enter email
'
                                name='email'
                                value={createCustomerProps.email}
                                onChange={handleInputChange}
                                className='border-[#DADCE0] h-[3rem]'
                            />
                        </div>
                    </section>
                    <section className='flex px-6 gap-[1rem] lg:gap-[2rem]'>
                        <div className='flex flex-col flex-1 gap-3'>
                            <label className='flex items-center gap-1'>First name</label>
                            <Input
                                type='text'
                                placeholder='Enter first name
'
                                name='first_name'
                                value={createCustomerProps.contact.first_name}
                                onChange={e => {
                                    setCreateCustomerProps(prevState => ({
                                        ...prevState,
                                        contact: { ...prevState.contact, first_name: e.target.value },
                                    }))
                                }}
                                className='border-[#DADCE0]  h-[3rem]'
                            />
                        </div>
                        <div className='flex flex-col flex-1 gap-3'>
                            <label className='flex items-center gap-1'>Last name</label>
                            <div>
                                <Input
                                    type='text'
                                    placeholder='Enter last name'
                                    name='last_name'
                                    value={createCustomerProps.contact.last_name}
                                    onChange={e => {
                                        setCreateCustomerProps(prevState => ({
                                            ...prevState,
                                            contact: { ...prevState.contact, last_name: e.target.value },
                                        }))
                                    }}
                                    className='border-[#DADCE0]  h-[3rem]'
                                />
                            </div>
                        </div>
                    </section>
                    <section className='flex px-6 gap-[1rem] lg:gap-[2rem] mt-5'>
                        <div className='flex flex-col flex-1 gap-3'>
                            <label className='flex items-center gap-1'>Address</label>
                            <Input
                                type='text'
                                placeholder='Enter address
'
                                name='address'
                                value={createCustomerProps.address}
                                onChange={handleInputChange}
                                className='border-[#DADCE0]  h-[3rem]'
                            />
                        </div>
                        <div className='flex flex-col flex-1 gap-3'>
                            <label className='flex items-center gap-1'>City</label>
                            <div>
                                <Input
                                    type='text'
                                    placeholder='Enter city'
                                    name='city'
                                    value={createCustomerProps.city}
                                    onChange={handleInputChange}
                                    className='border-[#DADCE0]  h-[3rem]'
                                />
                            </div>
                        </div>
                    </section>
                    <section className='flex px-6 gap-[1rem] lg:gap-[2rem] mt-5'>
                        <div className='flex flex-col flex-1 gap-3'>
                            <label className='flex items-center gap-1'>State</label>
                            <Input
                                type='text'
                                placeholder='Enter state'
                                name='state'
                                value={createCustomerProps.state}
                                onChange={handleInputChange}
                                className='border-[#DADCE0]  h-[3rem]'
                            />
                        </div>

                        <div className='flex flex-col flex-1 gap-3'>
                            <label className='flex items-center gap-1'>Zipcode</label>
                            <div>
                                <Input
                                    type='text'
                                    placeholder='Enter zipcode'
                                    name='zipcode'
                                    value={createCustomerProps.zipcode}
                                    onChange={handleInputChange}
                                    className='border-[#DADCE0]  h-[3rem]'
                                />
                            </div>
                        </div>
                    </section>
                    <section className='flex px-6 gap-[1rem] lg:gap-[2rem] mt-5'>
                        <div className='flex flex-col flex-1 gap-3'>
                            <label className='flex items-center gap-1'>Phone number</label>
                            <Input
                                type='number'
                                placeholder='Enter phone number
'
                                name='phone'
                                value={createCustomerProps.contact.phone}
                                onChange={e => {
                                    if (e.target.value.length === 12) return
                                    setCreateCustomerProps(prevState => ({
                                        ...prevState,
                                        contact: { ...prevState.contact, phone: e.target.value },
                                    }))
                                }}
                                className='border-[#DADCE0]  h-[3rem]'
                            />
                        </div>
                        <div className='flex flex-col flex-1 gap-3'>
                            <label className='flex items-center gap-1'>Country</label>
                            <div>
                                <Input
                                    type='text'
                                    placeholder='Enter country'
                                    name='country'
                                    value={createCustomerProps.country}
                                    onChange={handleInputChange}
                                    className='border-[#DADCE0]  h-[3rem]'
                                />
                            </div>
                        </div>
                    </section>
                    <section className='mb-16'>
                        <div className='flex flex-col flex-1 gap-3 px-6 mt-5'>
                            <label className='flex items-center justify-between'>
                                <span className='flex items-center gap-1'>Description</span>
                                <span className='text-xs'>{createCustomerProps.description.length} / 120</span>
                            </label>
                            <Textarea
                                name='description'
                                placeholder='Customer description'
                                className='border border-[#DADCE0] pt-4 min-h-[5.6rem] text-18px resize-none'
                                value={createCustomerProps.description}
                                onChange={e => handleInputChange(e)}
                                maxLength={120}
                            />
                        </div>
                    </section>
                </div>
                <section className='bg-white fixed bottom-0 border-t border-gray8 w-full flex items-center justify-end gap-4  px-6 py-2 rounded-b-md'>
                    <Button
                        className='ml-auto'
                        onClick={handleCustomer}
                        loading={isLoading || isUpdating}
                        disabled={disableButton()}
                    >
                        {update ? 'Update' : 'Add'} customer
                    </Button>
                </section>
            </section>
        </ModalWrapper>
    )
}
