import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import PageContent from '@/layout/PageContent'
import { useNavigate, useParams } from 'react-router-dom'
import InvoicePartial from './partials/Invoice/InvoicePartial'
import { motion } from 'framer-motion'
import InvoiceAnalytics from './partials/InvoiceAnalytics/InvoiceAnalytics'
import InvoiceItemPartial from './partials/InvoiceItems/InvoiceItems'
import InvoiceSettings from './partials/InvoiceSettings'

const invoice_routes_const = {
    list: 'invoice_list',
    analytics: 'invoice_analytics',
    items: 'items',
    settings: 'settings',
}
export default function Invoices() {
    const { invoice_subRoute } = useParams()
    const navigate = useNavigate()

    return (
        <section className='h-full'>
            <section className='mt-7'>
                <Tabs defaultValue={invoice_subRoute} value={invoice_subRoute}>
                    <TabsList className='border-none'>
                        <TabTriggersContainer>
                            <TabsTrigger
                                value={invoice_routes_const.list}
                                onClick={() => navigate(`/admin/dashboard/invoice/${invoice_routes_const.list}`)}
                                className={`relative border-none ${
                                    invoice_routes_const.list === invoice_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {invoice_routes_const.list === invoice_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}

                                <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                    Invoices
                                </motion.span>
                            </TabsTrigger>
                            <TabsTrigger
                                value={invoice_routes_const.analytics}
                                onClick={() => navigate(`/admin/dashboard/invoice/${invoice_routes_const.analytics}`)}
                                className={`relative border-none ${
                                    invoice_routes_const.analytics === invoice_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {invoice_routes_const.analytics === invoice_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                    Analytics
                                </motion.span>{' '}
                            </TabsTrigger>
                            <TabsTrigger
                                value={invoice_routes_const.items}
                                onClick={() => navigate(`/admin/dashboard/invoice/${invoice_routes_const.items}`)}
                                className={`relative border-none ${
                                    invoice_routes_const.items === invoice_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {invoice_routes_const.items === invoice_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                    Items
                                </motion.span>{' '}
                            </TabsTrigger>
                            <TabsTrigger
                                value={invoice_routes_const.settings}
                                onClick={() => navigate(`/admin/dashboard/invoice/${invoice_routes_const.settings}`)}
                                className={`relative border-none ${
                                    invoice_routes_const.settings === invoice_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {invoice_routes_const.settings === invoice_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                    Settings
                                </motion.span>{' '}
                            </TabsTrigger>
                        </TabTriggersContainer>
                    </TabsList>
                    <TabsContent value={invoice_routes_const.list}>
                        <PageContent>
                            <InvoicePartial />
                        </PageContent>
                    </TabsContent>

                    <TabsContent value={invoice_routes_const.analytics}>
                        <PageContent>
                            <InvoiceAnalytics />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={invoice_routes_const.items}>
                        <PageContent>
                            <InvoiceItemPartial />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={invoice_routes_const.settings}>
                        <PageContent>
                            <InvoiceSettings />
                        </PageContent>
                    </TabsContent>
                </Tabs>
            </section>
        </section>
    )
}
