import { Button } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { useMutationDeleteBill } from '@/services/bill-payment/mutations'
import ModalWrapper from '../ModalWrapper'
import { useNavigate } from 'react-router-dom'

interface DeleteBillPromptProps {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    billId?: string
}

export default function DeleteBillPrompt({ closeModal, billId }: DeleteBillPromptProps) {
    const navigate = useNavigate()

    const { mutate: deleteBill, isLoading: isBillDeleted } = useMutationDeleteBill({
        queryParams: {
            id: billId as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'Bill deleted successfully', state: 'success' })
            navigate('/admin/dashboard/bill_payment/lists')
            closeModal(false)
        },
        onError: () => {
            toastHandler({ message: 'Please try again', state: 'error' })
            closeModal(false)
        },
    })

    return (
        <ModalWrapper variants={undefined}>
            <div className='border border-[#DADCE0] w-screen lg:w-[540px] px-5 lg:px-8 py-8 rounded-[.5rem]'>
                <h1 className='text-[#CF491E] text-2xl font-semibold mb-3'>Attention</h1>
                <p>
                    Deleting this bill means it will no longer be traced. Are you sure you still want to delete this
                    bill
                </p>
                {/* Buttons */}
                <div className='mt-10 flex justify-end'>
                    <button
                        type='button'
                        disabled={isBillDeleted}
                        className='min-h-[50px] px-5 mx-3'
                        onClick={() => closeModal(false)}
                    >
                        Cancel
                    </button>
                    <Button
                        className='bg-[#CF491E]/80 hover:bg-[#CF491E]'
                        onClick={() => deleteBill({})}
                        loading={isBillDeleted}
                    >
                        Yes, delete
                    </Button>
                </div>
            </div>
        </ModalWrapper>
    )
}
