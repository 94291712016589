import { PlusSquare } from 'lucide-react'
import { useMemo, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import { DocumentsNav } from '../../components/DocumentsNav/DocumentsNav'
import { AddNewFileModal } from '@/components/Modals/Vault/AddNewFileModal'
import { CreateNewFolder } from '@/components/Modals/Vault/CreateNewFolder'
import { UploadingPopup } from '@/components/Modals/Vault/UploadingPopup'
import { useGetAllVaults, useGetVaultItems } from '@/services/vault/queries'
import ProgressBar from '@/components/ProgressBar'
import { NoVaultComponent } from '../../components/NoVaultComponent'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ItemsView } from '../../components/FolderNavigation/ItemsView'
import FilterVault from '../../components/AdvancedFilter/VaultsAdvanceFilter'
import { VaultItem } from '@/services/vault/types'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useAuthContext } from '@/context'
import { SlidersHorizontal } from 'lucide-react'

export const VaultDocuments = () => {
    const [searchQuery, setSearchQuery] = useState('')
    const [createFolder, setCreateFolder] = useState(false)
    const [showAddNewFile, setShowAddNewFile] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const { selectedAccount } = useAuthContext()
    const [searchParams] = useSearchParams()

    console.log(searchQuery)
    const { data: vaults, isLoading: isLoadingVaults } = useGetAllVaults({})
    const vaultsData = useMemo(() => vaults ?? [], [vaults])
    const singleVault = vaultsData?.[0]

    const { data: vaultItems, isLoading: isLoadingVaultItems } = useGetVaultItems({
        queryParams: {
            vault: singleVault?.id,
            parent: searchParams.get('folder') ?? singleVault?.id,
        },
        enabled: !!vaults && !searchParams.get('folder'),
    })

    const { data: employees } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: `ACTIVE`,
        },
    })
    const orgMembers = useMemo(() => employees ?? [], [employees])
    const navigate = useNavigate()

    const folders = vaultItems?.filter(item => item.type === 'folder')

    if (isLoadingVaults || isLoadingVaultItems) return <ProgressBar />
    // h-screen overflow-scroll
    return (
        <section className={` pb-6`}>
            <div className='block lg:flex justify-between mb-6 lg:mb-0 relative'>
                <div className='mb-2 lg:mb-6'>
                    <h1 className='text-dark font-medium text-lg'>Welcome to vault</h1>
                    <p className='text-gray7 text-sm'>
                        Easily upload, share, and manage all your business documents in one place.
                    </p>
                </div>
                <div className='border border-[#DADCE0] h-[45px] px-2 w-full lg:w-[30%] rounded-lg flex items-center justify-between overflow-hidden bg-white'>
                    <div className='flex gap-1 items-center h-full'>
                        <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full mx-1 lg:mx-0'>
                            <CiSearch fontSize={25} />
                        </div>
                        <input
                            type='text'
                            className='h-full w-[60%] outline-none placeholder:text-xs'
                            onChange={e => setSearchQuery(e.target.value)}
                            // onKeyDown={handleKeyDown}
                            placeholder='Search by name'
                        />
                    </div>
                    <SlidersHorizontal color='#5E5E5E' onClick={() => setShowFilter(true)} className='cursor-pointer' />
                </div>
                <UploadingPopup />
                {showFilter && (
                    <FilterVault
                        closeModal={setShowFilter}
                        vaults={vaultItems as VaultItem[]}
                        orgMembers={orgMembers}
                    />
                )}
            </div>
            <div className='flex flex-col lg:flex-row gap-5 justify-between items-center lg:items-start '>
                {vaultsData?.length > 0 && (
                    <div className='w-full lg:w-[23%] bg-white p-2 lg:p-5 flex flex-row items-center lg:flex-col gap-3 rounded-xl lg:min-h-[598px]'>
                        <button
                            onClick={() => {
                                setShowAddNewFile(true)
                            }}
                            className='bg-[#EFEFF9] text-sm font-semibold py-2 flex items-center justify-center gap-2 w-[85px] lg:w-full rounded-lg px-3 text-brand border border-transparent hover:border-brand h-fit'
                        >
                            <PlusSquare size={20} /> New
                        </button>
                        <div className='flex flex-row w-full lg:flex-col gap-3 justify-between'>
                            <p
                                className={`cursor-pointer text-sm font-semibold ${!searchParams.get('folder') && 'text-brand'} border-l border-gray8 pl-3 lg:pl-0 lg:border-none`}
                                onClick={() => navigate('')}
                            >
                                Home
                            </p>
                            <p className='text-sm font-medium text-gray7 hidden lg:block'>Personal Vault</p>
                            <DocumentsNav folders={folders ?? []} />
                        </div>
                    </div>
                )}
                <div
                    className={`${vaultsData?.length === 0 ? 'w-full mt-8' : 'w-full lg:w-[74%]'} bg-white rounded-2xl pt-5 pb-8 px-6 lg:min-h-[598px]`}
                >
                    {vaultsData?.length === 0 ? (
                        <>
                            <NoVaultComponent />
                        </>
                    ) : (
                        <>
                            <ItemsView setCreateFolder={setCreateFolder} />
                        </>
                    )}
                </div>
            </div>
            {showAddNewFile &&
                (createFolder ? (
                    <CreateNewFolder closeModal={setShowAddNewFile} vault={vaultsData?.[0]} />
                ) : (
                    <AddNewFileModal closeModal={setShowAddNewFile} vault={singleVault} />
                ))}
        </section>
    )
}
