import { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import Header from './LayoutComponents/Header'
import SideBar from './LayoutComponents/SideBar'
import useFeatureFlag from '@/lib/feature-flags/useFeatureFlag'
import { isAdmin, isAdminRoute } from '@/utils/permissions'
import { useAuthContext } from '@/context'
import UpgradePlanModal from '@/components/Modals/Upgrade-plan'
import UserViewUpgradeModal from '@/components/Modals/Upgrade-plan/UserViewUpgradeModal'

export const route_const_substri = ['dashboard/payroll', 'dashboard/contract']

const DashboardLayout = () => {
    const location = useLocation()

    const [headerText, setHeaderText] = useState<string>('Dashboard')

    const payrollFlagEnabled = useFeatureFlag('show_payroll')
    const contractFlagEnabled = useFeatureFlag('show_contract')

    const { showUpgradeModal, setShowUpgradeModal, currentModule, selectedAccount } = useAuthContext()

    const [showSidebar, setShowSidebar] = useState(true)

    const handleRouteChange = (newHeaderText: string) => {
        setHeaderText(newHeaderText)
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1024) setShowSidebar(true)
        }
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    if (location.pathname === '/dashboard') {
        return <Navigate to='/dashboard/overview' />
    }
    if (
        route_const_substri.some(route => location.pathname.includes(route)) &&
        !payrollFlagEnabled &&
        !contractFlagEnabled
    ) {
        return <Navigate to={`${isAdminRoute() ? '/admin' : ''}/dashboard/overview`} />
    }

    return (
        <div className='w-screen h-screen flex flex-row overflow-hidden bg-[#FAFAFD]'>
            <div className={`absolute lg:relative z-[100] transition-all  ${showSidebar ? 'left-0 ' : '-left-full'}`}>
                <SideBar onRouteChange={handleRouteChange} setShowSidebar={setShowSidebar} />
            </div>
            <div className='w-full lg:w-[calc(100vw-240px)] bg-white px-1 md:px-4 lg:px-0'>
                <Header headerText={headerText} setShowSidebar={setShowSidebar} />

                <div className='bg-[#FAFAFD] overflow-scroll h-[calc(100vh-82px)]'>
                    <div className='scroll-smooth h-full'>{<Outlet />}</div>
                </div>
            </div>
            {showUpgradeModal &&
                (isAdmin(selectedAccount!) ? (
                    <UpgradePlanModal module={currentModule} setShowModal={setShowUpgradeModal} />
                ) : (
                    <UserViewUpgradeModal module={currentModule!} closeModal={() => setShowUpgradeModal(false)} />
                ))}
        </div>
    )
}

export default DashboardLayout
