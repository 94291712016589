import React from 'react'
import { capitalizeText } from '@/utils/string'
import { cn } from '@/lib/styling'

interface StatusColorProps {
    status: string
    disable?: boolean
    disableBgColor?: boolean
    className?: string
}

const StatusColor: React.FC<StatusColorProps> = ({ status, disable = false, disableBgColor = false, className }) => {
    let badgeColor: string

    switch (status) {
        case 'approved':
        case 'ACTIVE':
        case 'VERIFIED':
        case 'OWNER':
        case 'USER':
        case 'ADMIN':
            badgeColor = 'text-[#19C37D] bg-[#EFFFED]'
            break
        case 'PAID':
            badgeColor = 'text-[#19C37D] bg-[#EFFFED]'
            break
        case 'active':
            badgeColor = 'text-[#19C37D] bg-[#EFFFED]'
            break
        case 'completed':
            badgeColor = 'text-[#19C37D] bg-[#EFFFED]'
            break
        case 'in-progress':
            badgeColor = 'text-brand bg-[#EFEFF9]'
            break
        case 'pending':
        case 'PENDING':
        case 'IN_PROGRESS':
            badgeColor = 'text-[#DEB145] bg-[#FFFEEC]'
            break
        case 'UNPAID':
            badgeColor = 'text-[#DEB145] bg-[#FFFEEC]'
            break
        case 'denied':
        case 'expired':
        case 'INACTIVE':
        case 'UNVERIFIED':
        case 'overdue':
            badgeColor = 'text-[#CF491E]/90 bg-[#CF491E]/10'
            break

        case 'OVERDUE':
            badgeColor = 'text-[#CF491E]/90 bg-[#CF491E]/10'
            break
        case 'cancelled':
        case 'incomplete':
        case 'DECLINED':
            badgeColor = 'text-[#CF491E]/90 bg-[#CF491E]/10'
            break
        case 'partialApproval':
            badgeColor = 'text-[#FF9152]'
            break
        case 'reimbursed':
            badgeColor = 'text-[#5C9BFF] bg-[#EBF5FF]'
            break
        case 'disabled':
            badgeColor = 'bg-[#F9F9F9] text-[#DADCE0]'
            break
        case 'in_progress':
            badgeColor = 'bg-[#EFEFF9] text-[#454ADE]'
            break
        case 'in progress':
            badgeColor = 'bg-[#EFEFF9] text-[#454ADE]'
            break
        case 'closed':
            badgeColor = 'text-[#5C9BFF] bg-[#EBF5FF]'
            break
        case 'due':
            badgeColor = 'text-[#DEB145] bg-[#FDFCDB]'
            break

        default:
            badgeColor = 'text-[purple] bg-[purple]/10'
            break
    }
    return (
        <div
            className={cn(
                `inline-flex items-center justify-center  p-1 px-4 rounded-2xl ${
                    disable ? 'bg-[#F9F9F9] text-[#DADCE0]' : badgeColor
                } ${disableBgColor ? 'bg-transparent px-0' : ''}`
            )}
        >
            <span className={`text-center ${className}`}>
                {capitalizeText(
                    status === 'denied'
                        ? 'Declined'
                        : status === 'IN_PROGRESS'
                          ? 'In Progress'
                          : status === 'cancelled'
                            ? 'Terminated'
                            : status
                )}
            </span>
        </div>
    )
}

export default StatusColor
