import { ApprovalPolicyCondition, ApprovalPolicyArrow, TrashIcon } from '@/assets/assets.export'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../Select'
import { Input } from '../Input'
import { TbCurrencyNaira } from 'react-icons/tb'
import { Popover } from '@headlessui/react'
import { EmployeeType } from '@/types/org'
import { ChevronDown, X } from 'lucide-react'
import { Check } from 'lucide-react'
import { Controller, useFormContext } from 'react-hook-form'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { ApporvalConditionOperatorEnum, ApprovalPolicyConsts, approversCount } from '@/types/approval-policy'
import { capitalizeText } from '@/utils/string'

type Props = {
    index?: number
    orgMembers: EmployeeType[]
    deactivatedUsers?: EmployeeType[]
}

export default function ApprovalPolicyBox({ index, orgMembers, deactivatedUsers }: Props) {
    const { control, watch } = useFormContext()
    const watchComparison = watch(`policy.${index}.comparison`)

    return (
        <section className='flex gap-3 w-full  items-start'>
            <div className=''>
                <div className='flex flex-col items-center h-full'>
                    <button type='button'>
                        <ApprovalPolicyCondition />
                    </button>
                    <div className='w-[1px] bg-gray5  h-[14rem] mt-2'></div>
                </div>
            </div>
            <div className='flex gap-4 border border-gray5 rounded-2xl px-2 p-5 lg:px-5 w-full  '>
                <h5 className='text-brand font-semibold text-lg  relative -top-0.5'>IF</h5>
                <div className='flex flex-col gap-1'>
                    <div className='flex items-center gap-2'>
                        <span className='bg-brand/10 text-brand text-xs p-1 px-2 rounded-full'>
                            {Number(index) + 1}
                            {approversCount[(Number(index) + 1) as keyof typeof approversCount] ?? 'th'} condition
                        </span>
                    </div>

                    <div className='w-[1px] bg-gray5 h-10 ml-1.5 '></div>

                    <div className='flex lg:items-center gap-3 flex-col lg:flex-row'>
                        <div className='flex items-center gap-3'>
                            <span className='text-sm whitespace-nowrap'>Amount is:</span>
                            <Controller
                                name={`policy.${index}.comparison`}
                                rules={{
                                    required: true,
                                    minLength: 2,
                                }}
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        name='type'
                                        defaultValue={ApporvalConditionOperatorEnum.BETWEEN}
                                        onValueChange={data => field.onChange(data)}
                                    >
                                        <SelectTrigger className=' border-none text-sm h-[2.3rem] w-fit bg-[#F9F9F9] text-[#5E5E5E] whitespace-nowrap'>
                                            <SelectValue placeholder={''} />
                                        </SelectTrigger>
                                        <SelectContent className='w-[10rem]'>
                                            <SelectItem value={ApporvalConditionOperatorEnum.BETWEEN}>
                                                Between
                                            </SelectItem>
                                            <SelectItem value={ApporvalConditionOperatorEnum.EQUAL}>Equal</SelectItem>
                                            <SelectItem value={ApporvalConditionOperatorEnum.GREATER_THAN}>
                                                Greater than
                                            </SelectItem>
                                            <SelectItem value={ApporvalConditionOperatorEnum.LESS_THAN}>
                                                Less than
                                            </SelectItem>
                                            <SelectItem value={ApporvalConditionOperatorEnum.NOT_EQUAL}>
                                                Not equal
                                            </SelectItem>
                                        </SelectContent>
                                    </Select>
                                )}
                            />
                        </div>

                        <Controller
                            name={`policy.${index}.initialAmt`}
                            rules={{
                                required: true,
                                minLength: 0,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    min={0}
                                    value={formatAmount(field.value).replaceAll('₦', '')}
                                    onChange={e => {
                                        const value_entered = formatAmountWithoutSymbols(e.target.value)
                                        field.onChange(value_entered)
                                    }}
                                    bodyClassName='xl:min-w-[120px] max-w-[150px] lg:ml-3 lg:mb-0'
                                    className='h-[2.5rem] border-gray4/60  px-2'
                                    leftIcon={<TbCurrencyNaira fontSize={25} className='text-gray7' />}
                                />
                            )}
                        />

                        {watchComparison === ApporvalConditionOperatorEnum.BETWEEN && (
                            <div className='flex gap-4 items-center'>
                                <span className='text-gray7 p-1 px-2 bg-gray6 rounded-lg h-fit text-sm'>and</span>
                                <Controller
                                    name={`policy.${index}.finalAmt`}
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            min={0}
                                            value={formatAmount(field.value).replaceAll('₦', '')}
                                            onChange={e => {
                                                const value_entered = formatAmountWithoutSymbols(e.target.value)
                                                field.onChange(value_entered)
                                            }}
                                            bodyClassName='xl:min-w-[120px] max-w-[150px]'
                                            className='h-[2.5rem] border-gray4/60  px-2'
                                            leftIcon={<TbCurrencyNaira fontSize={25} className='text-gray7' />}
                                        />
                                    )}
                                />
                            </div>
                        )}
                    </div>
                    <div className='flex items-end gap-3 mb-5'>
                        <ApprovalPolicyArrow className='ml-1.5' />

                        <div className='flex lg:items-center gap-3 relative -bottom-5 flex-col lg:flex-row'>
                            <span className='text-errorRed font-medium text-sm'>Require</span>
                            <ApproversSelectBox
                                deactivatedUsers={deactivatedUsers}
                                orgMembers={orgMembers}
                                index={index}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export const ApproversSelectBox = ({ index, orgMembers, deactivatedUsers }: Props) => {
    const { control, watch } = useFormContext()

    const checkIfAdminIsDeactivated = (id: string) => {
        return deactivatedUsers?.some(user => (user?._id ?? user?.id) === id) ?? false
    }
    const watchSelectedAdmins = (watch(typeof index === 'number' ? `policy.${index}.admins` : 'defaultPolicy') ??
        []) as EmployeeType[]

    return (
        <Controller
            name={typeof index === 'number' ? `policy.${index}.admins` : 'defaultPolicy'}
            rules={{
                required: true,
                maxLength: 3,
            }}
            control={control}
            render={({ field }) => (
                <div className='flex gap-5 items-center'>
                    <Popover className='relative'>
                        <Popover.Button className=' text-sm text-gray7 border-gray4 border rounded-lg py-3 px-4 min-w-[12rem] lg:min-w-[20rem] text-left flex items-center justify-between focus:outline-brand'>
                            {watchSelectedAdmins?.length > 0 ? (
                                <div className='flex gap-3 flex-1 flex-col lg:flex-row'>
                                    {watchSelectedAdmins?.map((admin, idx) =>
                                        typeof admin !== 'string' ? (
                                            <button
                                                type='button'
                                                onClick={e => e.stopPropagation()}
                                                className={`cursor-default flex-1 justify-between flex gap-3 items-center bg-gray4/30 rounded-md p-1 px-2 whitespace-nowrap ${checkIfAdminIsDeactivated(admin?._id ?? admin?.id) ? 'animate-bounce hover:animate-none' : ''}`}
                                            >
                                                <span className='flex flex-col'>
                                                    <span className='text-xs font-medium text-left'>
                                                        {admin?.user?.first_name} {admin?.user?.last_name?.[0]}.
                                                    </span>
                                                    <span className='text-xs text-errorRed/60'>
                                                        {idx + 1}
                                                        {approversCount[(idx + 1) as keyof typeof approversCount] ??
                                                            'th'}{' '}
                                                        approval
                                                    </span>
                                                </span>

                                                <X
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        field.onChange(
                                                            (field.value as EmployeeType[]).filter(
                                                                selectedAdmin =>
                                                                    (selectedAdmin?.id ?? selectedAdmin?._id) !==
                                                                    (admin.id ?? admin?._id)
                                                            )
                                                        )
                                                    }}
                                                    className='stroke-errorRed w-4 h-4 cursor-pointer'
                                                />
                                            </button>
                                        ) : (
                                            // basically for the auto approval field
                                            <button
                                                type='button'
                                                onClick={e => e.stopPropagation()}
                                                className='flex-1 justify-between flex gap-3 items-center bg-gray4/30 rounded-md py-2 px-2 whitespace-nowrap'
                                            >
                                                {capitalizeText(String(admin).replaceAll('-', ' '))}
                                                <X
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        field.onChange(
                                                            (field.value as EmployeeType[]).filter(
                                                                selectedAdmin => selectedAdmin !== admin
                                                            )
                                                        )
                                                    }}
                                                    className='stroke-errorRed w-5 h-5 cursor-pointer'
                                                />
                                            </button>
                                        )
                                    )}
                                </div>
                            ) : (
                                <span className='font-medium opacity-60'>Select maximum of 3 admins</span>
                            )}
                            <ChevronDown className='text-gray w-4 h-4' />
                        </Popover.Button>
                        <Popover.Panel className='absolute bottom-full z-[10000] bg-white shadow-md rounded-lg  px-4 p-3  max-h-[15rem] overflow-y-scroll mt-1 border border-gray5 w-[18rem]'>
                            <ul className='text-sm font-medium space-y-3'>
                                <li
                                    className='flex items-center justify-between'
                                    onClick={() =>
                                        watchSelectedAdmins.length < 1 &&
                                        field.onChange([ApprovalPolicyConsts.AUTOAPPROVE])
                                    }
                                >
                                    <button type='button' className='whitespace-nowrap'>
                                        Auto approve
                                    </button>

                                    {field.value?.length > 0 &&
                                        field?.value?.some(
                                            (admin: EmployeeType) =>
                                                (typeof admin === 'string' ? admin : admin?.id) ===
                                                ApprovalPolicyConsts.AUTOAPPROVE
                                        ) && (
                                            <Check
                                                className='w-4 h-4 stroke-brand bg-brand/10 p-1 rounded-full'
                                                strokeWidth={3}
                                            />
                                        )}
                                </li>
                                {orgMembers?.map(member => (
                                    <li
                                        className='flex items-center justify-between'
                                        onClick={() => {
                                            const hasAdminId = field.value?.some(
                                                (admin: EmployeeType) =>
                                                    (admin?.id ?? admin?._id) === (member?.id ?? member?._id)
                                            )

                                            watchSelectedAdmins.length < 3 &&
                                                !hasAdminId &&
                                                field.value?.[0] !== ApprovalPolicyConsts.AUTOAPPROVE &&
                                                field.onChange(
                                                    watchSelectedAdmins?.length > 0
                                                        ? [...watchSelectedAdmins, member]
                                                        : [member]
                                                )
                                        }}
                                    >
                                        <button type='button' className='whitespace-nowrap'>
                                            {member?.user?.first_name} {member?.user?.last_name}
                                        </button>

                                        {field.value?.length > 0 &&
                                            field?.value?.some(
                                                (admin: EmployeeType) =>
                                                    (admin?.id ?? admin?._id) === (member?.id ?? member?._id)
                                            ) && (
                                                <Check
                                                    className='w-4 h-4 stroke-brand bg-brand/10 p-1 rounded-full'
                                                    strokeWidth={3}
                                                />
                                            )}
                                    </li>
                                ))}
                            </ul>
                        </Popover.Panel>
                    </Popover>

                    {watchSelectedAdmins?.length > 0 && (
                        <button type='button' className='text-errorRed bg-errorRed/10 p-1.5 rounded-full'>
                            <TrashIcon className='w-4 h-4 ' onClick={() => field.onChange([])} />
                        </button>
                    )}
                </div>
            )}
        />
    )
}
