export type AddItemProps = {
    quantity: string | number
    description: string
    unit_cost: string | number
    total_cost?: string | number
    uniq_id?: string
    _id?: string
    id?: string
    name: string
    account?: string
}

export const itemDefault: AddItemProps = {
    description: '',
    name: '',
    quantity: 1,
    unit_cost: 0,
    total_cost: 0,
}
export const formEmptyState = {
    customer: '',
    issue_date: '',
    due_date: '',
    item: [] as AddItemProps[],
    tax: 0,
    message: '',
    copy: false,
    asPDF: false,
    logo: '',
    payment_method: '',
    part_payment: false,
    part_payment_percent: 0,
    footer: '',
    account: '',
}
export type FormStateType = typeof formEmptyState
export enum InvoiceStatus {
    PAID = 'PAID',
    UNPAID = 'UNPAID',
    OVERDUE = 'OVERDUE',
    PENDING = 'PENDING',
    DRAFT = 'DRAFT',
}
export enum PaymentMethod {
    OFFLINE = 'OFFLINE',
    ONLINE = 'ONLINE',
    BOTH = 'BOTH',
}

export const paymentOptions = [
    {
        name: 'Accept online payment only',
        value: PaymentMethod.ONLINE,
    },
    {
        name: 'Accept offline payment only',
        value: PaymentMethod.OFFLINE,
    },
    {
        name: 'Accept both online and offline payment',
        value: PaymentMethod.BOTH,
    },
]
