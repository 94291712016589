import { VaultItem } from '@/services/vault/types'
import { FolderLists } from '../Folders/FolderLists'
import { FileLists } from '../Files/FileLists'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGetVaultItem } from '@/services/vault/queries'
import { capitalizeText } from '@/utils/string'
import { useVaultContext } from '@/context'
import { ChevronRight } from 'lucide-react'

type FullFolderProps = {
    items: VaultItem[]
}

export const SingleFolderFullView = ({ items }: FullFolderProps) => {
    const folders = items?.filter(item => item.type === 'folder')
    const files = items?.filter(item => item.type === 'file')
    const [category, setCategory] = useState<string>('')
    const [type, setType] = useState<string>('')
    const [searchParams] = useSearchParams()
    const itemId = searchParams.get('folder')
    const navigate = useNavigate()

    const { selectedFolder } = useVaultContext()
    const { data: vaultItem } = useGetVaultItem({
        queryParams: {
            id: itemId as string,
        },
    })
    const notInRootVault = vaultItem?.parent !== vaultItem?.vault

    return (
        <div>
            <p className='flex'>
                <span onClick={() => navigate(-1)} className='text-black cursor-pointer'>
                    {notInRootVault ? selectedFolder?.name : capitalizeText(vaultItem?.name)}
                </span>
                {notInRootVault && (
                    <>
                        <ChevronRight color='#5e5e5e' />
                        <span className='text-gray7'>{capitalizeText(vaultItem?.name)}</span>
                    </>
                )}
            </p>
            <div className='flex gap-3 items-center my-4'>
                <Select onValueChange={setCategory} value={category}>
                    <SelectTrigger className='w-[168px] border-gray8 text-[#5E5E5E]' id='transactionYear'>
                        {category ? <SelectValue /> : <span>Category</span>}
                    </SelectTrigger>
                    <SelectContent className='h-fit max-h-[10rem] w-[10rem]'>
                        {['Contracts', 'Receipts', 'SLAs', 'Invoices', 'Bills', 'Others']?.map(category => (
                            <SelectItem value={category} className='pl-3' key={category}>
                                <h6 className='text-sm'>{category}</h6>
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
                <Select onValueChange={setType} value={type}>
                    <SelectTrigger className='w-[168px] border-gray8 text-[#5E5E5E]' id='transactionYear'>
                        {type ? <SelectValue /> : <span>Type</span>}
                    </SelectTrigger>
                    <SelectContent className='h-fit max-h-[10rem] w-[10rem]'>
                        <SelectItem value={'file'} className='pl-3'>
                            <h6 className='text-sm'>File</h6>
                        </SelectItem>
                        <SelectItem value={'folder'} className='pl-3'>
                            <h6 className='text-sm'>Folder</h6>
                        </SelectItem>
                    </SelectContent>
                </Select>
            </div>
            <div className='mt-10'>
                {folders?.length > 0 && (type === '' || type === 'folder') && (
                    <div className='mb-4'>
                        <h1 className='font-semibold'>FOLDERS</h1>
                        <div className='mt-3 grid grid-cols-1 lg:grid-cols-4 gap-4'>
                            <FolderLists folders={folders as VaultItem[]} />
                        </div>
                    </div>
                )}
                {files?.length > 0 && (type === '' || type === 'file') && (
                    <div className=''>
                        <h1 className='font-semibold'>FILES</h1>
                        <div className='mt-3 grid grid-cols-1 lg:grid-cols-4 gap-4'>
                            <FileLists files={files as VaultItem[]} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
