import { useState } from 'react'
import blueTick from '@/assets/blue-tick.svg'
import greyTick from '@/assets/grey-tick.svg'
import FPImage from '@/assets/FPPimage.svg'
import ResetStep1 from './ResetStep1'
import ResetStep2 from './ResetStep2'
import ResetStep3 from './ResetStep3'
import { useForm } from 'react-hook-form'
import Logo from '@/assets/bizpendlogo.png'
import { useNavigate } from 'react-router-dom'

const ForgotPasswordRoot = () => {
    const [step, setStep] = useState(1)

    const { handleSubmit, getValues, control, watch } = useForm()

    const onSubmit = async () => {
        // console.log(control.getFieldState())
    }
    const navigate = useNavigate()

    return (
        <section>
            <div className='w-full h-screen grid grid-cols-4 relative'>
                <div className='overflow-auto overscroll-y-contain fixed w-1/4 lg:col-span-1 border-r border-[#DADCE0] h-full hidden lg:block bg-[#EFEFF9]'>
                    <div className='p-8 py-4 border-b border-[#DADCE0]'>
                        <div className='cursor-pointer' onClick={() => navigate('/login')}>
                            <img src={Logo} alt='logo' className='w-[137px]' />
                        </div>
                    </div>

                    <div className='py-12 h-[89vh] flex flex-col justify-between'>
                        <div className='px-8 pb-12'>
                            <div className='flex gap-3 items-start mb-8'>
                                <img src={step === 1 ? `${blueTick}` : `${greyTick}`} alt='tick' />
                                <div className='flex flex-col gap-2'>
                                    <span
                                        className={
                                            step === 1
                                                ? `text-base font-bold text-[#202020]`
                                                : `text-base font-bold text-[#838383]`
                                        }
                                    >
                                        Enter email address
                                    </span>
                                    <p
                                        className={
                                            step === 1
                                                ? `text-[#5E5E5E] pr-12 text-sm font-medium`
                                                : `text-[#5E5E5E] pr-12 text-sm`
                                        }
                                    >
                                        Provide email you used to sign up for our service
                                    </p>
                                </div>
                            </div>

                            <div className='flex gap-3 items-start mb-8'>
                                <img src={step === 2 ? `${blueTick}` : `${greyTick}`} alt='tick' />
                                <div className='flex flex-col gap-2'>
                                    <span
                                        className={
                                            step === 2
                                                ? `text-base font-bold text-[#202020]`
                                                : `text-base font-bold text-[#838383]`
                                        }
                                    >
                                        Verify your email
                                    </span>
                                    <p
                                        className={
                                            step === 2
                                                ? `text-[#5E5E5E] pr-12 text-sm font-medium`
                                                : `text-[#5E5E5E] pr-12 text-sm`
                                        }
                                    >
                                        Enter OTP sent to your email address
                                    </p>
                                </div>
                            </div>

                            <div className='flex gap-3 items-start mb-8'>
                                <img src={step === 3 ? `${blueTick}` : `${greyTick}`} alt='tick' />
                                <div className='flex flex-col gap-2'>
                                    <span
                                        className={
                                            step === 3
                                                ? `text-base font-bold text-[#202020]`
                                                : `text-base font-bold text-[#838383]`
                                        }
                                    >
                                        Create new password
                                    </span>
                                    <p
                                        className={
                                            step === 3
                                                ? `text-[#5E5E5E] pr-12 text-sm font-medium`
                                                : `text-[#5E5E5E] pr-12 text-sm`
                                        }
                                    >
                                        Create new password for your account
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='px-4'>
                            <img src={FPImage} alt='sign-up image' className='w-full' />
                        </div>
                    </div>
                </div>

                <form
                    className='col-span-4 lg:col-start-2 lg:col-span-3 bg-[#FAFAFD]'
                    onSubmit={handleSubmit(onSubmit)}
                >
                    {step === 1 && (
                        <ResetStep1 toggleStep={setStep} control={control} values={getValues} watch={watch} />
                    )}
                    {step === 2 && (
                        <ResetStep2 toggleStep={setStep} control={control} values={getValues} watch={watch} />
                    )}
                    {step === 3 && (
                        <ResetStep3 toggleStep={setStep} control={control} values={getValues} watch={watch} />
                    )}
                </form>
            </div>
        </section>
    )
}

export default ForgotPasswordRoot
