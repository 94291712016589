import React from 'react'
import { formatAmount } from '@/utils/money'
import { Button } from '@/components/Button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { Input } from '@/components/Input'
import { TbCurrencyNaira } from 'react-icons/tb'
import { Link } from 'lucide-react'
import { useMatchInvoiceToTransaction } from '@/services/invoice/mutations'
import { useParams } from 'react-router-dom'
import { TransactionType } from '@/types/transactions'
import { Invoice } from '@/types/invoice'
import { toastHandler } from '@/components/utils/Toast'

type Props = {
    transaction?: TransactionType
    invoice?: Invoice
    goBack: () => void
}
const ConfirmMatching = ({ transaction, goBack, invoice }: Props) => {
    const { mutate, isLoading } = useMatchInvoiceToTransaction({
        onSuccess: () => {
            toastHandler({ message: 'Invoice matched!', state: 'success' })
            goBack()
        },
    })
    const { invoice_id } = useParams()

    return (
        <main className='h-[40rem] lg:h-fit w-screen lg:w-[28rem] p-3'>
            <div>
                <h5 className='opacity-90 text-lg font-medium'>Confirm matching</h5>
                <p className='text-sm text-gray7'>Confirm payment type to proceed with matching</p>
            </div>
            <section className='mt-7'>
                <div className='bg-gray4/40 rounded-lg p-3 flex justify-between mt-3'>
                    <div>
                        <h6 className='text-xs font-medium'>Invoice amount</h6>
                        <p className='text-lg font-medium text-gray7 tracking-tighter'>
                            {' '}
                            {formatAmount(invoice?.total_amount)}
                        </p>
                    </div>
                    <div>
                        <h6 className='text-xs font-medium'>Payment recorded</h6>
                        <p className='text-lg font-medium text-gray7 tracking-tighter'>
                            {formatAmount(invoice?.paid?.$numberDecimal)}
                        </p>
                    </div>
                    <div>
                        <h6 className='text-xs font-medium'>Outstanding</h6>
                        <p className='text-lg font-medium text-gray7 tracking-tighter'>
                            {' '}
                            {formatAmount(Number(invoice?.total_amount) - Number(invoice?.paid?.$numberDecimal))}
                        </p>
                    </div>
                </div>
                <div className=' flex flex-col gap-2 mt-4 '>
                    <label htmlFor='sorting' className='text-sm font-medium'>
                        Payment option
                    </label>
                    <Select name='payment option'>
                        <SelectTrigger className='text-xs h-11 border-gray4'>
                            <SelectValue placeholder={'Full or part payment'} />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value='full_payment'>Full payment </SelectItem>
                            <SelectItem value='part_paryment'>Part payment </SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div className='flex flex-col gap-2 mt-4 '>
                    <label htmlFor='sorting' className='text-sm font-medium '>
                        Amount paid*
                    </label>

                    <Input
                        leftIcon={<TbCurrencyNaira size={20} className='text-gray7' />}
                        className='text-xs h-11 border-green disabled:bg-green/10 disabled:cursor-not-allowed text-gray7'
                        min={0}
                        disabled
                        value={formatAmount(transaction?.credit?.amount).replaceAll('₦', '')}
                    />
                </div>
            </section>
            <div className='flex justify-end items-center mt-8 gap-3 mb-1 border-gray4 border-t pt-4'>
                <Button
                    onClick={goBack}
                    variant={'outline'}
                    className='border-none text-xs min-w-[100px] px-2.5 min-h-[20px] py-0 h-9'
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        mutate({
                            invoice_id: invoice_id as string,
                            trans_id: transaction?._id ?? transaction?.id,
                        })
                    }}
                    loading={isLoading}
                    className='text-xs min-w-[100px] px-2 min-h-[20px] py-0 h-9 gap-1'
                >
                    <Link size={16} />
                    Yes, match
                </Button>
            </div>
        </main>
    )
}

export default ConfirmMatching
