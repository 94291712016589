import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { TransactionMetricIcon } from '@/assets/assets.export'
import { formatAmount } from '@/utils/money'
import { ArrowDownLeft, ArrowUpRight } from 'lucide-react'
import { useEffect, useState } from 'react'
import { PieChart } from 'react-minimal-pie-chart'

import { useGetSpecificMonthTransact, useGetTransactionsSummary } from '@/services/transactions/queries'
import { useAuthContext } from '@/context'
import ProgressBar from '@/components/ProgressBar'

import emptyTransaction from '@/assets/empty-state-transactions.svg'
import EmptyStates from '@/components/EmptyStates'
import TransactionsGraph from '../../Overview/components/partials/TransactionsGraph'

const TransactionAnalytics = () => {
    const [isSummaryYearClicked, setIsSummaryYearClicked] = useState(false)
    const [isExpenseYearClicked, setIsExpenseYearClicked] = useState(false)

    const viewOrgTransactionAnalytics = location.pathname.includes('company')

    const thisYear = new Date().getFullYear()
    const [expenseYear, setExpenseYear] = useState(thisYear + '')
    const [summaryYear, setSummaryYear] = useState(thisYear + '')
    const COLORS = ['#19C37D', '#CF491E', '#5C9BFF']

    const { selectedAccount } = useAuthContext()
    const {
        data: transactionSummary,
        refetch,
        isLoading,
        isRefetching,
    } = useGetTransactionsSummary({
        queryParams: {
            owner: viewOrgTransactionAnalytics ? (selectedAccount?.org?.id as string) : (selectedAccount?.id as string),
            year: summaryYear as string,
        },
    })
    const {
        isLoading: isMonthLoading,
        refetch: refetchMonthTransact,
        isRefetching: isRefetchingMonths,
    } = useGetSpecificMonthTransact({
        queryParams: {
            owner: viewOrgTransactionAnalytics ? (selectedAccount?.org?.id as string) : (selectedAccount?.id as string),
            year: expenseYear as string,
        },
    })
    // const chartData = useMemo(() => data ?? [], [data])
    const data01 = [
        {
            name: 'Successful',
            value: transactionSummary?.successful as number,
            color: '#19C37D',
        },
        {
            name: 'Failed',
            value: transactionSummary?.failed as number,
            color: '#CF491E',
        },
    ]
    const total = data01.reduce((accumulator, currentValue) => accumulator + (currentValue?.value ?? 0), 0)

    useEffect(() => {
        refetchMonthTransact()
        refetch()
    }, [viewOrgTransactionAnalytics])

    useEffect(() => {
        if (isExpenseYearClicked) {
            refetchMonthTransact()
        }
    }, [expenseYear])
    useEffect(() => {
        if (isSummaryYearClicked) {
            refetch()
        }
    }, [summaryYear])

    if (isLoading || isMonthLoading) return <ProgressBar />

    return (
        <div className='pb-28'>
            {/* dummy */}
            <span
                className='hidden'
                onClick={() => {
                    setIsExpenseYearClicked(false)
                    setExpenseYear('')
                }}
            >
                {}
            </span>
            <div className='bg-white w-full p-3 md:p-6 relative rounded-xl mt-8'>
                <div className='flex justify-between'>
                    <span className='font-semibold block text-xl'>Transaction graph</span>

                    <div className=''>
                        <Select
                            name='expenseYear'
                            onValueChange={data => {
                                setSummaryYear(data)
                                setIsSummaryYearClicked(true)
                            }}
                            value={summaryYear}
                        >
                            <SelectTrigger className='w-full'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className='w-[8rem]'>
                                <SelectItem value={`${thisYear - 1}`}>Last year</SelectItem>
                                <SelectItem value={`${thisYear}`}>This year</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <div className={`grid grid-cols-1 md:grid-cols-2  ${isRefetching ? 'opacity-30' : ''}`}>
                    <div className='border-b md:border-r border-[#DADCE0] p-3 pr-4 md:pr-9 mt-4 md:mt-0'>
                        <p className='text-[#5E5E5E] font-semibold mt-4'>
                            So far, you’ve spent{' '}
                            <span className='text-[#CF491E]'>
                                {' '}
                                {transactionSummary?.insight ? transactionSummary?.insight.toFixed(2) : '0'}%{' '}
                            </span>
                            more than you have added money
                        </p>
                        <div className='border-b border-[#DADCE0] py-6'>
                            <div className='flex items-center gap-4'>
                                <div className='bg-[#EFFFED] rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                    <ArrowDownLeft color='#19C37D' width={40} height={40} />
                                </div>
                                <div className=''>
                                    <p className='text-base lg:text-lg font-semibold text-[#5E5E5E] '>Incoming</p>
                                    <p className='text-2xl lg:text-[32px] font-extrabold text-[#202020]'>
                                        {formatAmount(transactionSummary?.incomingTotal) ?? 'N/A'}
                                    </p>
                                </div>
                            </div>
                            <div className='py-4 px-3 bg-[#F9F9F9]  w-3/4 md:w-1/2 rounded-xl mt-5'>
                                <p className='text-base lg:text-lg font-medium text-[#5E5E5E]'>Incoming transactions</p>
                                <p className='text-2xl lg:text-[32px] font-bold text-[#202020] '>
                                    {transactionSummary?.numberOfIncoming}
                                </p>
                            </div>
                        </div>
                        <div className='py-6'>
                            <div className='flex items-center gap-4'>
                                <div className='bg-[#FFEDED] rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                    <ArrowUpRight color='#CF491E' width={40} height={40} />
                                </div>
                                <div className=''>
                                    <p className='text-base lg:text-lg font-semibold text-[#5E5E5E] '>Outgoing</p>
                                    <p className='text-2xl lg:text-[32px] font-extrabold text-[#202020]'>
                                        {formatAmount(transactionSummary?.outgoingTotal) ?? 'N/A'}
                                    </p>
                                </div>
                            </div>
                            <div className='py-4 px-3 bg-[#F9F9F9] w-3/4 md:w-1/2 rounded-xl mt-5'>
                                <p className='text-base lg:text-lg font-medium text-[#5E5E5E]'>Outgoing transactions</p>
                                <p className='text-2xl lg:text-[32px] font-bold text-[#202020] '>
                                    {transactionSummary?.numberOfOutgoing}
                                </p>
                            </div>
                        </div>
                    </div>
                    <section className='rounded-lg flex flex-col justify-center bg-white p-0 md:p-4 mt-6 md:mt-1'>
                        {data01?.length > 0 ? (
                            <>
                                <div className={`relative min-h-[408px] mx-auto`}>
                                    <PieChart
                                        data={data01}
                                        lineWidth={32}
                                        className='py-7'
                                        animate
                                        animationDuration={500}
                                        animationEasing='ease-in'
                                        reveal={100}
                                        segmentsStyle={{ strokeDashoffset: '1.5px' }}
                                    />
                                    {/* <PieChart width={370} height={370}>
                                        <Pie
                                            data={data01}
                                            dataKey='value'
                                            nameKey='name'
                                            // cx='50%'
                                            cy='50%'
                                            outerRadius={180}
                                            fill='#8884d8'
                                            innerRadius={120}
                                            height={300}
                                        />
                                        {data01.map((_, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                        <Tooltip
                                            offset={1}
                                            content={<CustomPieTooltipNum />}
                                            wrapperStyle={{ zIndex: '10' }}
                                        />
                                    </PieChart> */}
                                    {data01.length > 0 && (
                                        <h1 className='absolute top-[200px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center font-medium'>
                                            Transaction count
                                            <br />{' '}
                                            <span className='font-extrabold text-3xl lg:text-[40px]'>
                                                {total.toLocaleString()}
                                            </span>
                                        </h1>
                                    )}
                                </div>
                                <div className='flex w-[360px] md:w-[400px] mx-auto gap-8'>
                                    {data01.map((data, i) => (
                                        <div className='w-1/2'>
                                            <span
                                                className=' h-1 block mr-2 rounded'
                                                style={{ backgroundColor: `${COLORS[i]}` }}
                                            >
                                                &nbsp;
                                            </span>
                                            <h2 className='text-base font-medium mt-1'>{data.name}</h2>
                                            <p className='font-extrabold text-2xl lg:text-[32px]'>{data.value}</p>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <div className='flex items-center justify-center flex-col text-gray7 h-full'>
                                <TransactionMetricIcon />
                                <h2 className='text-gray7 font-medium mt-5'>No transaction metrics yet</h2>
                                <p className='text-sm'>No transaction has been made yet</p>
                            </div>
                        )}
                    </section>
                </div>
            </div>
            <section className='pt-6 my-6'>
                {/*==== Bar chart ====*/}
                <div className={` ${isRefetchingMonths ? 'opacity-30' : ''}`}>
                    {transactionSummary && transactionSummary?.totalTransactions < 0 ? (
                        <>
                            <EmptyStates
                                title='No Transactions Available'
                                description="You didn't record any transactions for this time period. Start exploring our services and make your first transaction to see it listed here."
                                image={emptyTransaction}
                            />
                        </>
                    ) : (
                        <>
                            <TransactionsGraph />
                        </>
                    )}
                </div>
            </section>
            {/* <section className='p-4 border border-[#DADCE0] rounded mb-6'>
                <div className='flex items-center justify-between mb-6'>
                    <span className='font-semibold'>Outgoing transaction type distribution</span>

                    <div>
                        <Select
                            name='expenseCategoryDate'
                            onValueChange={setTransactionTypeDate}
                            value={transactionTypeDate}
                        >
                            <SelectTrigger className='w-full'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value={`${thisYear}-0${thisMonth - 1}-01`}>Last month</SelectItem>
                                <SelectItem value={`${thisYear}-0${thisMonth - 3}-01`}>Last 3 months</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                <div className='flex gap-[20px] min-h-[100px]'>
                    <div
                        className={`relative border border-[#DADCE0] rounded-lg col-span-2 p-4 h-[480px] w-1/2 `}
                        // style={{ height: `${min_height}` }}
                    >
                        <ResponsiveContainer height='72%'>
                            <PieChart width={800} height={700}>
                                <Pie
                                    data={newData}
                                    cy={170}
                                    innerRadius={100}
                                    outerRadius={147}
                                    fill='#8884d8'
                                    // paddingAngle={5}
                                    dataKey='amountTransacted'
                                    nameKey='type'
                                >
                                    {newData.map((_, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Legend
                                    layout='horizontal'
                                    formatter={renderColorfulLegendText}
                                    iconType='square'
                                    wrapperStyle={{
                                        position: 'relative',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '50px',
                                        // gap : 3
                                    }}
                                />
                                <Tooltip offset={1} content={<CustomPieTooltip />} wrapperStyle={{ zIndex: '10' }} />
                            </PieChart>
                        </ResponsiveContainer>
                        <h1 className='absolute top-[200px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center'>
                            You transacted most via
                            <br /> <span className='font-semibold'>Bill & Invoice payment</span>
                        </h1>
                    </div>

                    <div className={` rounded-lg overflow-hidden w-1/2 h-full`}>
                        <TransactionTypeTable data={newChartData} />
                    </div>
                </div>
            </section> */}
            {/* <section className='p-6 pt-6 border border-[#DADCE0] rounded-lg my-6'>
                <div className='flex items-center justify-between mb-6'>
                    <span className='font-semibold'>Transaction Types Over Time</span>
                    <div>
                        <Select name='expenseYear' onValueChange={setExpenseYear} value={expenseYear}>
                            <SelectTrigger className='w-full'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value={`${thisYear - 1}`}>Last year</SelectItem>
                                <SelectItem value={`${thisYear}`}>This year</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <div className='sort flex items-center mb-6'>
                    <p className='mr-4'>Sort by</p>
                    <Select name='expenseYear' onValueChange={setTransactionType} value={transactionType}>
                        <SelectTrigger className='w-[18%] border-none bg-[#F9F9F9]'>
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value='Bill & Invoice payment'>Bill & Invoice payment</SelectItem>
                            <SelectItem value='Card'>Card</SelectItem>
                            <SelectItem value='Reimbursement'>Reimbursement</SelectItem>
                        </SelectContent>
                    </Select>
                </div>


                <div className={`bg-white p-4 rounded-md`}>
                    <ResponsiveContainer height={350} width={'100%'} className={'mx-auto'}>
                        <BarChart data={chartData} margin={{ left: 60 }}>
                            <CartesianGrid stroke='#DADCE0' vertical={false} strokeDasharray='5 5' fill='white' />
                            <XAxis dataKey='name' stroke='#888888' fontSize={12} tickLine={false} axisLine={false} />
                            <YAxis
                                dataKey='total'
                                stroke='#888888'
                                fontSize={12}
                                tickLine={false}
                                axisLine={false}
                                tickFormatter={value => formatAmount(value)}
                                hide={false}
                                label={{
                                    value: 'Transactions in Naira',
                                    angle: -90,
                                    position: 'insideLeft',
                                    offset: -50,
                                }}
                                offset={10}
                            />
                            <Bar dataKey='total' fill='#57E1FF' radius={[4, 4, 0, 0]} barSize={50} />
                            <Tooltip
                                filterNull={true}
                                cursor={false}
                                wrapperStyle={{ borderRadius: '15px', zIndex: '10' }}
                                content={<CustomBarTooltip />}
                            />
                            <ReferenceLine y={0} stroke='#DADCE0' type='linear' />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                <div className='flex gap-4 text-[#5E5E5E]'>
                    <div className='bg-[#F9F6FF] px-4 py-3 rounded-lg w-1/4'>
                        Bill & invoice payments were at their all-time high in the month of{' '}
                        <span className='text-[#454ADE]'>June</span>
                    </div>
                    <div className='bg-[#FDFCDB] px-4 py-3 rounded-lg w-1/4 flex items-center'>
                        Your average Bill & Invoice payment transaction amount is ₦ 40,680.90
                    </div>
                </div>
            </section> */}
        </div>
    )
}

export default TransactionAnalytics
