import React, { useState } from 'react'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'
import { Minus, Plus } from 'lucide-react'
import { PayrollSettingsFullType } from './PayrollSettingsPrompt'

type Props = {
    setStep: React.Dispatch<React.SetStateAction<number>>
    setFormState: React.Dispatch<React.SetStateAction<PayrollSettingsFullType>>
    formState: PayrollSettingsFullType
}
export const StepThree = ({ setStep, setFormState, formState }: Props) => {
    const [isAutomated, setIsAutomated] = useState(formState.isGenAutomated)
    const [payrollGenSettings, setPayrollGenSettings] = useState({
        weekly: `${formState.lead_days}`,
        monthly: `${formState.lead_days}`,
        biWeekly: `${formState.lead_days}`,
    })

    return (
        <div className='mt-7'>
            <div className='h-[71vh] overflow-scroll'>
                <p className='font-medium'>Would you like to automate payroll generation?</p>
                <RadioGroup
                    // {...field}
                    value={isAutomated}
                    className='flex gap-6 mt-4'
                    onValueChange={value => {
                        setIsAutomated(value)
                        if (value === 'manual') {
                            setPayrollGenSettings({
                                weekly: '1',
                                monthly: '1',
                                biWeekly: '1',
                            })
                        }
                    }}
                >
                    {automation.map((options, i) => (
                        <label htmlFor={options.value} className={`flex gap-2 items-center cursor-pointer`} key={i}>
                            <RadioGroupItem type='button' value={options.value} id={options.value} />
                            <p className='text-base text-[#5E5E5E]'>{options.name}</p>
                        </label>
                    ))}
                </RadioGroup>
                <section>
                    {isAutomated === 'auto' && (
                        <div className=''>
                            <p className='text-lg font-semibold text-gray7 mb-4 mt-8'>
                                When should automated payroll be generated?
                            </p>
                            <div className='grid grid-cols-2 gap-5'>
                                {formState.booleanPay.monthly && (
                                    <div>
                                        <h2 className='text-[#838383] text-base mb-3'>Monthly</h2>
                                        <div className='flex items-baseline gap-3'>
                                            <div className='flex border border-[#DADCE0] rounded-lg gap-3 w-[99px] h-10 items-center px-2 justify-center'>
                                                <button
                                                    disabled={Number(payrollGenSettings.monthly) == 1}
                                                    className='disabled:opacity-30'
                                                >
                                                    <Minus
                                                        color='#5E5E5E'
                                                        className='cursor-pointer'
                                                        onClick={() =>
                                                            setPayrollGenSettings(prev => ({
                                                                ...prev,
                                                                monthly: (
                                                                    Number(payrollGenSettings.monthly) - 1
                                                                ).toString(),
                                                            }))
                                                        }
                                                        size={20}
                                                    />
                                                </button>
                                                {payrollGenSettings.monthly}
                                                <button
                                                    disabled={Number(payrollGenSettings.monthly) == 30}
                                                    className='disabled:opacity-30'
                                                >
                                                    <Plus
                                                        color='#5E5E5E'
                                                        className='cursor-pointer'
                                                        onClick={() =>
                                                            setPayrollGenSettings(prev => ({
                                                                ...prev,
                                                                monthly: (
                                                                    Number(payrollGenSettings.monthly) + 1
                                                                ).toString(),
                                                            }))
                                                        }
                                                        size={20}
                                                    />
                                                </button>
                                            </div>
                                            <p className='whitespace-nowrap'>
                                                day{payrollGenSettings.monthly !== '1' && 's'} before pay day
                                            </p>
                                        </div>
                                    </div>
                                )}
                                {formState.booleanPay.weekly && (
                                    <div>
                                        <h2 className='text-[#838383] text-base mb-3'>Weekly</h2>
                                        <div className='flex items-baseline gap-3'>
                                            <div className='flex border border-[#DADCE0] rounded-lg gap-3 w-[99px] h-10 items-center px-2 justify-center'>
                                                <button
                                                    disabled={Number(payrollGenSettings.weekly) == 1}
                                                    className='disabled:opacity-30'
                                                >
                                                    <Minus
                                                        color='#5E5E5E'
                                                        className='cursor-pointer'
                                                        onClick={() =>
                                                            setPayrollGenSettings(prev => ({
                                                                ...prev,
                                                                weekly: (
                                                                    Number(payrollGenSettings.weekly) - 1
                                                                ).toString(),
                                                            }))
                                                        }
                                                        size={20}
                                                    />
                                                </button>
                                                {payrollGenSettings.weekly}
                                                <button
                                                    disabled={Number(payrollGenSettings.weekly) == 30}
                                                    className='disabled:opacity-30'
                                                >
                                                    <Plus
                                                        color='#5E5E5E'
                                                        className='cursor-pointer'
                                                        onClick={() =>
                                                            setPayrollGenSettings(prev => ({
                                                                ...prev,
                                                                weekly: (
                                                                    Number(payrollGenSettings.weekly) + 1
                                                                ).toString(),
                                                            }))
                                                        }
                                                        size={20}
                                                    />
                                                </button>
                                            </div>
                                            <p className='whitespace-nowrap'>
                                                day{payrollGenSettings.weekly !== '1' && 's'} before pay day
                                            </p>
                                        </div>
                                    </div>
                                )}
                                {formState.booleanPay.biWeekly && (
                                    <div>
                                        <h2 className='text-[#838383] text-base mb-3'>Bi-weekly</h2>
                                        <div className='flex items-baseline gap-3'>
                                            <div className='flex border border-[#DADCE0] rounded-lg gap-3 w-[99px] h-10 items-center px-2 justify-center'>
                                                <button
                                                    disabled={Number(payrollGenSettings.biWeekly) == 1}
                                                    className='disabled:opacity-30'
                                                >
                                                    <Minus
                                                        color='#5E5E5E'
                                                        className='cursor-pointer'
                                                        onClick={() =>
                                                            setPayrollGenSettings(prev => ({
                                                                ...prev,
                                                                biWeekly: (
                                                                    Number(payrollGenSettings.biWeekly) - 1
                                                                ).toString(),
                                                            }))
                                                        }
                                                        size={20}
                                                    />
                                                </button>
                                                {payrollGenSettings.biWeekly}
                                                <button
                                                    disabled={Number(payrollGenSettings.biWeekly) == 30}
                                                    className='disabled:opacity-30'
                                                >
                                                    <Plus
                                                        color='#5E5E5E'
                                                        className='cursor-pointer'
                                                        onClick={() =>
                                                            setPayrollGenSettings(prev => ({
                                                                ...prev,
                                                                biWeekly: (
                                                                    Number(payrollGenSettings.biWeekly) + 1
                                                                ).toString(),
                                                            }))
                                                        }
                                                        size={20}
                                                    />
                                                </button>
                                            </div>
                                            <p className='whitespace-nowrap'>
                                                day{payrollGenSettings.biWeekly !== '1' && 's'} before pay day
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </section>
            </div>
            <div className='sticky bg-white bottom-0 w-full flex items-center justify-end gap-4 py-2 rounded-b-2xl border-t border-[#DADCE0]'>
                <button
                    className='bg-white text-black px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px] disabled:opacity-30'
                    onClick={() => {
                        setFormState(prev => ({
                            ...prev,
                            isGenAutomated: isAutomated,
                            lead_days: parseInt(payrollGenSettings.weekly as string),
                        }))
                        setStep(2)
                    }}
                >
                    back
                </button>
                <button
                    className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px] disabled:opacity-30'
                    disabled={isAutomated.length < 1}
                    onClick={() => {
                        setFormState(prev => ({
                            ...prev,
                            isGenAutomated: isAutomated,
                            lead_days: parseInt(payrollGenSettings.weekly as string),
                        }))
                        setStep(4)
                    }}
                >
                    Next
                </button>
            </div>
        </div>
    )
}

const automation = [
    {
        name: 'Yes',
        value: 'auto',
    },
    {
        name: 'No',
        value: 'manual',
    },
]
