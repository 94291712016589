import { AiOutlineClose } from 'react-icons/ai'
import ModalWrapper from '../../ModalWrapper'
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Textarea } from '@/components'
import { useMutateFlagTransaction } from '@/services/transactions/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { capitalizeText } from '@/utils/string'
import { useState } from 'react'

type FlagTransactionProps = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    transactionId: string
    flagDetails: 'FLAG' | 'UNFLAG' | undefined
}

const FlagTransactionModal = ({ closeModal, transactionId, flagDetails }: FlagTransactionProps) => {
    const [flagReason, setFlagReason] = useState<string>('')
    const [note, setNote] = useState<string>(flagDetails === 'UNFLAG' ? 'Issue Resolved' : '')
    const { mutate: flagTransactionFn, isLoading: isFlaggingTransaction } = useMutateFlagTransaction({
        queryParams: {
            transaction_id: transactionId,
        },
        onSuccess: () => {
            closeModal(false)
            toastHandler({
                message: flagDetails === 'FLAG' ? 'Transaction Flagged' : 'Transaction Unflagged',
                state: 'success',
            })
        },
    })

    const handleFlagTransaction = () => {
        flagTransactionFn({
            flag: flagDetails === 'FLAG' ? true : false,
            flag_reason: flagReason,
        })
    }

    return (
        <ModalWrapper variants={undefined}>
            <div className='w-screen lg:w-[37rem] max-h-[90vh] overflow-scroll '>
                <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-end'>
                    <div className='flex items-center w-[65%] justify-between h-full'>
                        <h2 className='font-semibold text-[#31254B] text-2xl'>
                            {capitalizeText(flagDetails)} transaction
                        </h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={() => closeModal(false)}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <div className='px-[1rem] lg:px-[1.8rem] mt-7'>
                    <h1 className='text-xl'>
                        Why are you {flagDetails === 'FLAG' ? 'flagging' : 'unflagging'} this transaction?
                    </h1>
                    <p className='text-sm text-[#5E5E5E]'>
                        Are you sure you want to {flagDetails === 'FLAG' ? 'flag' : 'unflag'} this transaction? Please
                        provide a reason below.
                    </p>
                </div>
                <form onSubmit={(e: React.FormEvent) => e.preventDefault()} className='mt-2 pb-6'>
                    <div className='px-[1rem] lg:p-[1.8rem] pt-3 pb-0 max-h-[400px] lg:max-h-[80vh] overflow-scroll'>
                        <div className={`flex flex-col gap-[1.6rem] mb-16`}>
                            <div className='mt-5 flex flex-col gap-2 mb-5'>
                                <label htmlFor='flag' className='text-[#202020] text-[14px] font-medium'>
                                    Reason <span className='text-[red]'>*</span>
                                </label>
                                <Select name='flag' onValueChange={e => setFlagReason(e)}>
                                    <SelectTrigger className='w-full h-[3.5rem] border-[#DADCE0] bg-base_gray_100 text-[13px]'>
                                        <SelectValue
                                            placeholder={`Why are you ${
                                                flagDetails === 'FLAG' ? 'flagging' : 'unflagging'
                                            } this transaction`}
                                        />
                                    </SelectTrigger>
                                    <SelectContent className='w-[33.3rem] max-h-[200px] overflow-scroll'>
                                        {flagDetails === 'FLAG' ? (
                                            <>
                                                <SelectItem value='charges'>Suspicious Charges</SelectItem>
                                                <SelectItem value='fraud'>Fraudulent Activity</SelectItem>
                                                <SelectItem value='other'>Others</SelectItem>
                                            </>
                                        ) : (
                                            <>
                                                <SelectItem value='resolved'>Resolution Confirmed</SelectItem>
                                            </>
                                        )}
                                    </SelectContent>
                                </Select>
                            </div>

                            <div className='flex flex-col flex-1 gap-3'>
                                <label className='flex items-center gap-1'>Additional note (Optional)</label>
                                <Textarea
                                    name='note'
                                    placeholder='Explain further if you wish to.'
                                    className='border border-[#DADCE0] pt-4 min-h-[7.5rem] text-base resize-none'
                                    value={note}
                                    onChange={e => setNote(e.target.value)}
                                />
                            </div>
                        </div>
                        <Button
                            className='w-full bg-errorRed text-base font-normal mb-3 hover:bg-errorRed disabled:bg-errorRed disabled:opacity-30'
                            onClick={handleFlagTransaction}
                            loading={isFlaggingTransaction}
                            disabled={!(flagReason.length > 0)}
                        >
                            Yes, {flagDetails?.toLowerCase()} this transaction
                        </Button>
                    </div>
                </form>
            </div>
        </ModalWrapper>
    )
}

export default FlagTransactionModal
