import BilPaymentIcon from '@/assets/recent-transactions-icon/bill_paymennt.svg'
import DirectTransferIcon from '@/assets/recent-transactions-icon/direct_transfer.svg'
import OnlineIcon from '@/assets/recent-transactions-icon/online_payment.svg'
import WalletFundingIcon from '@/assets/recent-transactions-icon/wallet_funding.svg'
import { ArrowRight, Loader2 } from 'lucide-react'
import SubscribeIcon from '@/assets/subscribe-outline.svg'
import { RecentTransactionIcon, TransactionMetricIcon } from '@/assets/assets.export'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { useAuthContext } from '@/context'
import { useGetTransactions, useGetTransactionsSummary } from '@/services/transactions/queries'
import { TransactionType, TransactionTypeType } from '@/types/transactions'
import { formatAmount } from '@/utils/money'
import { format } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { PieChart } from 'react-minimal-pie-chart'
import { useNavigate } from 'react-router-dom'
import { capitalizeText } from '../../../../../utils/string'

const TransactionIconType = {
    [TransactionTypeType.TRANSFER]: OnlineIcon,
    [TransactionTypeType.INVOICE]: OnlineIcon,
    [TransactionTypeType.WITHDRAWAL]: BilPaymentIcon,
    [TransactionTypeType.WALLET_FUNDING]: WalletFundingIcon,
    [TransactionTypeType.ACCOUNT_FUNDING]: WalletFundingIcon,
    [TransactionTypeType.REIMBURSEMENT]: DirectTransferIcon,
    [TransactionTypeType.BILL_PAYMENT]: DirectTransferIcon,
    [TransactionTypeType.PAYROLL]: BilPaymentIcon,
    [TransactionTypeType.CONTRACT_PAYMENT]: BilPaymentIcon,
    [TransactionTypeType.SUBSCRIPTION]: SubscribeIcon,
}

type Props = { isPriviledgedAdmin: boolean }

const adminTransactionURL = '/admin/dashboard/transactions/transactions_history'
const userTransactionURL = '/dashboard/transactions/transactions_history'
export default function RecentTransactions({ isPriviledgedAdmin }: Props) {
    const thisYear = new Date().getFullYear()

    const { selectedAccount } = useAuthContext()

    const [yearMetrics, setYearMetrics] = useState(thisYear + '')

    const navigate = useNavigate()
    const {
        data: overview_data,
        isLoading,
        isRefetching,
    } = useGetTransactions({
        enabled: isPriviledgedAdmin !== undefined,
        queryParams: {
            account_type: isPriviledgedAdmin ? 'Org' : 'UserOrgAccount',
            id: isPriviledgedAdmin ? (selectedAccount?.org?.id as string) : (selectedAccount?.id as string),
            limit: '5',
        },
    })
    const overviewTransaction = useMemo(() => overview_data?.results ?? ([] as TransactionType[]), [overview_data])

    const {
        data: transactionSummary,
        isLoading: isLoadingSummary,
        refetch,
    } = useGetTransactionsSummary({
        queryParams: {
            owner: selectedAccount?.org?.id as string,
            year: yearMetrics,
        },
    })

    useEffect(() => {
        refetch()
    }, [yearMetrics])

    const data01 = [
        {
            title: 'Successful',
            value: transactionSummary?.successful as number,
            color: '#19C37D',
        },
        {
            title: 'Failed',
            value: transactionSummary?.failed as number,
            color: '#CF491E',
        },
    ]

    const COLORS = ['#19C37D', '#CF491E']
    const isSkeleton = isLoadingSummary || isLoading
    const total = data01.reduce((accumulator, currentValue) => accumulator + (currentValue?.value ?? 0), 0)

    return (
        <section className='flex flex-col md:flex-row justify-between my-8 gap-4 lg:gap-9'>
            <section className='bg-white rounded-xl p-2 md:p-4 md:w-1/2'>
                <div className='flex items-center justify-between rounded text-dark'>
                    <div className=''>
                        <span className=' font-semibold text-base'>
                            {isPriviledgedAdmin ? 'Recent Transactions' : 'My Recent Transactions'}
                        </span>
                        <p className='text-sm text-gray7 mt-1'>
                            {' '}
                            {isPriviledgedAdmin
                                ? 'See latest transactions in real time'
                                : 'See your most recent transactions'}
                        </p>
                    </div>
                    {!isSkeleton && (
                        <button
                            onClick={() => navigate(isPriviledgedAdmin ? adminTransactionURL : userTransactionURL)}
                            className='bg-transparent hover:bg-transparent flex items-center text-sm gap-2'
                        >
                            View all
                            <ArrowRight size={15} />
                        </button>
                    )}
                </div>
                <div
                    id='recent-transactions'
                    className={`flex flex-col gap-3 mt-5 h-full ${isRefetching ? 'opacity-30' : 'opacity-100'}`}
                >
                    {isSkeleton ? (
                        <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={100}>
                            <Skeleton />
                        </SkeletonTheme>
                    ) : (
                        <>
                            {isLoading ? (
                                <div className='h-full'>
                                    <Loader2
                                        size={50}
                                        className='animate-spin mx-auto grid place-items-center h-full opacity-50'
                                    />
                                </div>
                            ) : overviewTransaction?.length > 0 ? (
                                overviewTransaction?.slice(0, 5)?.map(transaction => (
                                    <div
                                        onClick={() =>
                                            navigate(
                                                isPriviledgedAdmin
                                                    ? `${adminTransactionURL}/${transaction?._id ?? transaction?.id}`
                                                    : `${userTransactionURL}/${transaction?._id ?? transaction?.id}`
                                            )
                                        }
                                        className='cursor-pointer border border-gray5 p-4 rounded-xl flex items-center justify-between'
                                    >
                                        <div className='flex items-center gap-3'>
                                            <div>
                                                <img
                                                    src={
                                                        TransactionIconType[
                                                            transaction.type as keyof typeof TransactionIconType
                                                        ]
                                                    }
                                                    alt='Icon'
                                                />
                                            </div>
                                            <div>
                                                <h5 className='text-sm font-semibold'>
                                                    {[
                                                        TransactionTypeType.ACCOUNT_FUNDING,
                                                        TransactionTypeType.WALLET_FUNDING,
                                                        TransactionTypeType.TRANSFER,
                                                    ].includes(transaction?.type) ? (
                                                        <>
                                                            {capitalizeText(transaction?.initiated_by?.first_name)}{' '}
                                                            {capitalizeText(transaction?.initiated_by?.last_name)}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {capitalizeText(
                                                                transaction?.credit?.account?.user?.first_name
                                                            )}{' '}
                                                            {capitalizeText(
                                                                transaction?.credit?.account?.user?.last_name
                                                            )}
                                                        </>
                                                    )}
                                                </h5>
                                                <p className='text-xs text-gray7'>{transaction?.description}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p
                                                className={`text-sm font-medium ${
                                                    transaction.type === TransactionTypeType.WALLET_FUNDING
                                                        ? 'text-green'
                                                        : 'text-errorRed'
                                                }`}
                                            >
                                                {formatAmount(transaction?.debit?.amount)}
                                            </p>
                                            <p className='text-gray7 text-xs'>
                                                {transaction?.createdAt &&
                                                    format(new Date(transaction?.createdAt), 'dd/MM/yyyy')}
                                            </p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='flex items-center lg:justify-center flex-col text-gray7'>
                                    <RecentTransactionIcon />
                                    <h2 className='text-gray7 font-medium mt-5'>No transactions</h2>
                                    <p className='text-sm'>No transaction has been made yet</p>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </section>
            <section
                id='recent-transaction-chart'
                className={`rounded-lg md:w-1/2 flex flex-col ${
                    isSkeleton ? 'justify-between' : 'justify-center'
                } bg-white p-4`}
            >
                <div className='flex justify-between items-start mb-6'>
                    <h1 className='text-center font-semibold text-base text-gray7'>Metrics for {yearMetrics}</h1>
                    <div>
                        <Select onValueChange={setYearMetrics} value={yearMetrics}>
                            <SelectTrigger className='w-full' id='transactionYear'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className='w-[8rem]'>
                                <SelectItem value={`${thisYear}`}>This year</SelectItem>
                                <SelectItem value={`${thisYear - 1}`}>Last year</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                {isSkeleton ? (
                    <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={100}>
                        <Skeleton />
                    </SkeletonTheme>
                ) : (
                    <>
                        {data01[0].value + data01[1].value > 0 ? (
                            <>
                                <div className={`relative min-h-[408px] mx-auto`}>
                                    {/* <PieChart width={400} height={400}>
                                <Pie
                                    data={data01}
                                    dataKey='value'
                                    nameKey='name'
                                    // cx='50%'
                                    cy='50%'
                                    outerRadius={180}
                                    fill='#8884d8'
                                    innerRadius={120}
                                    height={300}
                                />
                                {data01.map((_, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                                <Tooltip offset={1} content={<CustomPieTooltipNum />} wrapperStyle={{ zIndex: '10' }} />
                            </PieChart> */}
                                    <PieChart
                                        data={data01}
                                        lineWidth={32}
                                        className='py-7'
                                        animate
                                        animationDuration={500}
                                        animationEasing='ease-in'
                                        reveal={100}
                                        segmentsStyle={{ strokeDashoffset: '1.5px' }}
                                    />
                                    {data01.length > 0 && (
                                        <h1 className='absolute top-[210px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center font-medium'>
                                            Transaction count
                                            <br />{' '}
                                            <span className='font-extrabold text-[40px]'>{total.toLocaleString()}</span>
                                        </h1>
                                    )}
                                </div>
                                <div className='flex w-full max-w-[400px] mx-auto gap-8'>
                                    {data01.map((data, i) => (
                                        <div className='w-1/2'>
                                            <span
                                                className=' h-1 block mr-2 rounded'
                                                style={{ backgroundColor: `${COLORS[i]}` }}
                                            >
                                                &nbsp;
                                            </span>
                                            <h2 className='text-base font-medium mt-1'>{data.title}</h2>
                                            <p className='font-extrabold text-[32px]'>{data.value}</p>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <div className='flex items-center justify-center flex-col text-gray7 h-full'>
                                <TransactionMetricIcon />
                                <h2 className='text-gray7 font-medium mt-5'>No transaction metrics yet</h2>
                                <p className='text-sm'>No transaction has been made yet</p>
                            </div>
                        )}
                    </>
                )}
            </section>
        </section>
    )
}
