import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { useAddNewBusiness } from '@/services/auth/mutations'
import { emailRegex } from '@/utils/string'
import { Info } from 'lucide-react'
import { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import ModalWrapper from '../ModalWrapper'
import { QueryCache } from '@tanstack/react-query'

type AddNewBusinessModalProps = {
    closeNewBusinessModal: React.Dispatch<React.SetStateAction<boolean>>
}
const formEmptyState = {
    first_name: '',
    last_name: '',
    org_name: '',
    org_type: '',
    email: '',
    phone: '',
}
enum org_type {
    'Sole proprietorship' = 'SOLE_PROPRIETORSHIP',
    'Partnership' = 'PARTNERSHIP',
    'Corporation' = 'CORPORATION',
    'Limited Liability company' = 'LIMITED_LIABILITY_COMPANY',
    'Franchise' = 'FRANCHISE',
    'Joint venture' = 'JOINT_VENTURE',
    'Public traded companies' = 'PUBLIC_TRADED_COMPANIES',
    'Private equity funds' = 'PRIVATE_EQUITY_FUNDS',
    'Government owned' = 'GOVERNMENT_OWNED',
}
const AddNewBusinessModal = ({ closeNewBusinessModal }: AddNewBusinessModalProps) => {
    const { user } = useAuthContext()
    const queryCache = new QueryCache({})

    const [errInEmail, setErrInEmail] = useState(false)
    const [sameEmail, setSameEmail] = useState(false)

    const [formState, setFormState] = useState(formEmptyState)
    const [selectedOrgType, setSelectedOrgType] = useState('')
    const orgTypes = [
        'Sole proprietorship',
        'Partnership',
        'Corporation',
        'Limited Liability company',
        'Franchise',
        'Joint venture',
        'Public traded companies',
        'Private equity funds',
        'Government owned',
    ]
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target

        // if (name === 'email' && user?.email === formState.email) return

        setFormState(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }
    const {
        mutate: AddNewBusiness,
        isLoading,
        isError,
    } = useAddNewBusiness({
        onSuccess: () => {
            toastHandler({ message: 'New business successfully added', state: 'success' })
            // Close the modal
            // setSelectedAccount(data)
            closeNewBusinessModal(false)
            // navigate('/admin/dashboard/overview')

            //Reset the form state
            setFormState(formEmptyState)
        },
        onError: () => {
            toastHandler({ message: 'Please try again', state: 'error' })
            // Close the modal
        },
    })

    const handleFormSubmit = () => {
        const formData = {
            org_name: formState.org_name,
            org_type: org_type[formState.org_type as keyof typeof org_type],
            phone: formState?.phone,
            email: formState?.email,
        }
        if (!formState.org_name || !formState.org_type || !formState.email || !formState.phone)
            return toastHandler({ message: 'Please fill all fields', state: 'error' })
        AddNewBusiness(formData)
        queryCache.clear()
    }

    useEffect(() => {
        if (user) {
            setFormState(prevState => ({
                ...prevState,
                first_name: user?.first_name,
                last_name: user?.last_name,
                org_type: selectedOrgType,
                phone: user?.phone as string,
            }))
        }
    }, [user, selectedOrgType])

    useEffect(() => {
        setErrInEmail(() => user?.email.includes(formState.email) ?? false)
        setSameEmail(() => user?.email === formState.email)
    }, [formState.email, user?.email])

    const disableButton = () => {
        const res = Object.values({ ...formState })
            .map(value => !!value)
            .some(data => data === false)
        return res || !emailRegex.test(formState.email)
    }
    const SettingUp = () => {
        return (
            <div className='flex flex-col justify-center items-center h-full px-8 lg:px-32 bg-[#FAFAFD] rounded-xl'>
                <h1 className='text-xl font-semibold text-[#202020]'>Setting up your account</h1>
                <p className='text-base font-normal mx-auto text-center'>
                    We're putting things together. This won’t take long.
                </p>
                <div className='border-t border-[#A8A8A8] w-[400px] h-[184px] flex justify-center  mt-[3.7rem] rotate-180'>
                    <div
                        className='w-16 h-[184px] bg-[#9FDA96] rounded-b-lg animate-animate-up-down'
                        style={{ animationDelay: '600ms' }}
                    ></div>
                    <div
                        className='w-16 h-[184px] bg-[#FFAB90] rounded-b-lg animate-animate-up-down'
                        style={{ animationDelay: '400ms' }}
                    ></div>
                    <div
                        className='w-16 h-[184px] bg-[#7E82FB] rounded-b-lg animate-animate-up-down delay-[400ms]'
                        style={{ animationDelay: '200ms' }}
                    ></div>
                    <div className='w-16 h-[184px] bg-[#F5D484] rounded-b-lg animate-animate-up-down delay-[600ms]'></div>
                </div>
            </div>
        )
    }

    return (
        <ModalWrapper>
            <div className='w-screen lg:w-[650px] h-[505px] relative overflow-scroll'>
                {isLoading && !isError ? (
                    <SettingUp />
                ) : (
                    <>
                        <div className='border-b border-[#DADCE0] p-5 pt-3  flex justify-end fixed top-0 w-full bg-white rounded-t-lg  z-20'>
                            <div className='flex items-center w-[63%] justify-between h-full'>
                                <h2 className='font-semibold text-[#31254B] text-lg'>Add new business</h2>
                                <div
                                    className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                                    onClick={() => closeNewBusinessModal(false)}
                                >
                                    <AiOutlineClose fontSize={25} />
                                </div>
                            </div>
                        </div>
                        <form onSubmit={(e: React.FormEvent) => e.preventDefault()} className='mt-14 mb-8 text-sm'>
                            <div className='p-6 pb-0'>
                                <div className={`flex flex-col md:flex-row gap-[1.8rem] mb-6 `}>
                                    <div className='flex flex-col flex-1 gap-3'>
                                        <label className='flex items-center gap-1'>
                                            First name
                                            <span className=''>*</span>
                                        </label>
                                        <Input
                                            type='text'
                                            name='first_name'
                                            value={user?.first_name}
                                            className=' border-[#DADCE0]  h-[3rem] bg-[#F9F9F9] text-[#A8A8A8]'
                                            onChange={handleInputChange}
                                            disabled
                                        />
                                    </div>

                                    <div className='flex flex-col flex-1 gap-3'>
                                        <label className='flex items-center gap-1'>
                                            Last name
                                            <span className=''>*</span>
                                        </label>
                                        <Input
                                            type='text'
                                            name='last_name'
                                            value={user?.last_name}
                                            className=' border-[#DADCE0]  h-[3rem] bg-[#F9F9F9] text-[#A8A8A8]'
                                            onChange={handleInputChange}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col flex-1 gap-3 mb-6'>
                                    <label className='flex items-center gap-1'>
                                        Organization name
                                        <span className=''>
                                            <Info color='#A8A8A8' size={15} />
                                        </span>
                                    </label>
                                    <Input
                                        type='text'
                                        name='org_name'
                                        value={formState.org_name}
                                        placeholder='Enter organization name'
                                        className=' border-[#DADCE0] h-[3rem]'
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div className='flex flex-col md:flex-row gap-[1.8rem] mb-2'>
                                    <div className='flex flex-col flex-1 gap-3 w-full lg:w-1/2'>
                                        <label className='flex items-center gap-1'>
                                            Organization type
                                            <span>*</span>
                                        </label>
                                        <div>
                                            <Select
                                                name='org_type'
                                                value={selectedOrgType}
                                                onValueChange={setSelectedOrgType}
                                            >
                                                <SelectTrigger className='w-full h-[3rem] border-[#DADCE0]'>
                                                    <SelectValue placeholder='Select organization type' />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {orgTypes.map(orgType => (
                                                        <SelectItem value={orgType}>{orgType}</SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                        </div>
                                    </div>

                                    <div className='flex flex-col flex-1 gap-3 w-full lg:w-1/2'>
                                        <label className='flex items-center gap-1'>
                                            Work email
                                            <span>*</span>
                                        </label>
                                        <Input
                                            type='email'
                                            value={formState?.email}
                                            name='email'
                                            onChange={handleInputChange}
                                            className='border border-[#DADCE0] h-[3rem] '
                                        />
                                        <span className='text-xs text-[#CF491E] whitespace-nowrap '>
                                            {errInEmail && `An email different from ${user?.email}`}
                                        </span>
                                    </div>
                                </div>
                                <div className='w-1/2 pr-4'>
                                    <div className='flex flex-col flex-1 gap-3'>
                                        <label className='flex items-center gap-1'>
                                            Phone
                                            <span>*</span>
                                        </label>
                                        <Input
                                            type='number'
                                            value={user?.phone}
                                            name='phone'
                                            onChange={handleInputChange}
                                            placeholder='e.g 09012345678'
                                            className={`'border border-[#DADCE0] h-[3rem]  ${(user?.phone?.length as number) > 1 ? 'bg-[#F9F9F9] text-[#A8A8A8]' : 'text-[#000]'}`}
                                            disabled={(user?.phone?.length as number) > 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className='sticky bg-white bottom-0 w-full flex items-center justify-end gap-4 px-6 py-2 rounded-b-2xl'>
                            <Button
                                className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px]'
                                onClick={handleFormSubmit}
                                disabled={sameEmail || disableButton()}
                            >
                                Add new business
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </ModalWrapper>
    )
}

export default AddNewBusinessModal
