import ModalWrapper from '../../ModalWrapper'
import { AiOutlineClose } from 'react-icons/ai'
import SingleInvite from './SingleInvite'
import { modalVariants } from '../../Expense/utils'
import { EmployeeType } from '@/types/org'

interface PendingInvitesProps {
    setPendingInvitesModal: React.Dispatch<React.SetStateAction<boolean>>
    pendingInvitesData: EmployeeType[]
}

const PendingInvitesModal = ({ setPendingInvitesModal, pendingInvitesData }: PendingInvitesProps) => {
    return (
        <ModalWrapper
            className='justify-end right-8 top-0'
            variants={modalVariants}
            modalClassName='rounded-none bg-[red]'
        >
            <div className='pl-4 h-[100vh] w-screen lg:w-auto'>
                <div className='border-b border-[#DADCE0] py-4 px-2 flex'>
                    <div className='flex items-center w-full pt-10 lg:pt-2'>
                        <h2 className='font-semibold text-[#31254B] text-[22px] flex-1 text-center'>Pending Invites</h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={() => setPendingInvitesModal(false)}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>

                <div className='flex flex-col mb-6 items-end pt-8'>
                    {Array.isArray(pendingInvitesData) && pendingInvitesData.length > 0 ? (
                        pendingInvitesData?.map(invite => (
                            <SingleInvite InactiveEmp={pendingInvitesData} inviteDetails={invite} />
                        ))
                    ) : (
                        <section className='w-auto mx-auto lg:w-[30rem]  flex justify-center items-center h-[80vh]'>
                            <div>There are no pending invites currently</div>
                        </section>
                    )}
                </div>
            </div>
        </ModalWrapper>
    )
}

export default PendingInvitesModal
