import StatusColor from '@/components/utils/StatusColor'
import { useAuthContext } from '@/context'
import SwitchOrgHelper from '@/layout/SwitchOrgHelper'
import { useLogoutUser, useSwitchAccount } from '@/services/auth/mutations'
import { UserOrgAccount } from '@/types/org'
import { isAdmin, isAdminRoute } from '@/utils/permissions'
import { capitalizeText } from '@/utils/string'
import { Menu } from '@headlessui/react'
import { LogOut, PlusSquare } from 'lucide-react'
import { usePostHog } from 'posthog-js/react'
import { useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { TfiAngleDown } from 'react-icons/tfi'
import { useNavigate } from 'react-router-dom'

type DropdownSwitchProps = {
    addNewBusinessModal: React.Dispatch<React.SetStateAction<boolean>>
}

const DropdownSwitchAccount = ({ addNewBusinessModal }: DropdownSwitchProps) => {
    const { user, selectedAccount, accounts, logoutUser } = useAuthContext()
    const posthog = usePostHog()
    const [accountToBeSwitched, setAccountToBeSwitched] = useState<UserOrgAccount>()

    const [isSwitchingToNewOrg, setIsSwitchingToNewOrg] = useState(false)
    const navigate = useNavigate()

    const { mutate: switchAccountFn, isLoading: isSwitching } = useSwitchAccount({
        onSuccess(data: any) {
            if (data.account_data) {
                posthog.identify(data.account_data.id, {
                    first_name: data.account_data.user.first_name,
                    last_name: data.account_data.user.last_name,
                    email: data.account_data.user.email,
                    account_id: data.account_data.id,
                    org_id: data.account_data.org?.id,
                    org_name: data.account_data.org?.org_name,
                })
            }
            if (['ADMIN', 'OWNER', 'FINANCE_ADMIN'].includes(selectedAccount?.role?.name ?? 'USER')) {
                navigate('/admin/dashboard/overview')
            } else {
                navigate('/dashboard/overview')
            }
        },
    })

    // const [route, _] = useState(() => (isAdminRoute() ? 'admin' : 'user'))
    const route = isAdminRoute() ? 'admin' : 'user'

    const handleRouteChange = () => {
        const isUser = route === 'user'
        const isAdmin = route === 'admin'

        // setRoute(path)

        if (isUser) {
            navigate('/admin/dashboard/overview')
        }
        if (isAdmin) {
            navigate('/dashboard/overview')
        }
    }

    const { mutate: logoutFn } = useLogoutUser({
        onSuccess: () => {
            logoutUser()
            navigate('/')
        },
    })

    if (isSwitchingToNewOrg) return <SwitchOrgHelper account={accountToBeSwitched!} />
    return (
        <section className='relative sm:border-l border-[#DADEC0] pl-3'>
            <Menu>
                <Menu.Button>
                    <div className='flex justify-start items-center gap-1 sm:gap-2 lg:gap-4 mx-1 sm:mx-0'>
                        <div
                            className={` rounded-full w-fit text-white ${
                                user?.profile_photo ? 'overflow-hidden' : 'bg-[#75AD74] p-2'
                            }`}
                        >
                            {user?.profile_photo ? (
                                <img
                                    src={user.profile_photo}
                                    alt='user'
                                    className='w-[30px] lg:w-[40px] h-[30px] lg:h-[40px]'
                                />
                            ) : (
                                // ''
                                <p>
                                    {user?.first_name?.[0]?.toUpperCase()} {user?.last_name?.[0]?.toUpperCase()}
                                </p>
                            )}
                        </div>

                        <div className='justify-end flex items-center gap-2 lg:gap-4 cursor-pointer sm:min-w-[10rem] lg:min-w-[13rem]'>
                            <div className='hidden sm:flex flex-col'>
                                <div className='flex gap-1 lg:gap-3 items-center'>
                                    <p className='text-sm lg:text-[16px] font-semibold text-left'>
                                        {user?.first_name} {user?.last_name?.[0]}.
                                    </p>
                                    <div className='scale-75 lg:scale-[.85]'>
                                        {selectedAccount?.role.name ? (
                                            <StatusColor status={selectedAccount?.role.name} />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>

                                <p className='text-[14px] text-[#838383] text-left'>{selectedAccount?.org?.org_name}</p>
                            </div>
                            <div className={`transition-all duration-200 w-2 h-2 lg:w-4 lg:h-4`}>
                                <TfiAngleDown fontSize={15} className='w-full' />
                            </div>
                        </div>
                    </div>
                </Menu.Button>
                <Menu.Items className='absolute right-0 top-[4.75rem] bg-white shadow-2xl px-5 pb-5 rounded-md z-[100] pt-6  md:min-w-max max-w-2xl '>
                    <div className='border border-[#DADCE0] p-2 rounded-xl mb-4 flex items-center'>
                        <div
                            className={` rounded-full w-fit text-white mr-4 ${
                                user?.profile_photo ? 'overflow-hidden' : 'bg-[#75AD74] p-2'
                            }`}
                        >
                            {user?.profile_photo ? (
                                <img src={user.profile_photo} alt='user' className='w-[40px] h-[40px]' />
                            ) : (
                                // ''
                                <p>
                                    {user?.first_name?.[0]?.toUpperCase()} {user?.last_name?.[0]?.toUpperCase()}
                                </p>
                            )}
                        </div>
                        <div className='w-[14rem]'>
                            <h1 className='text-[#202020] font-semibold lg:font-bold text-base lg:text-xl truncate '>
                                {capitalizeText(user?.first_name)} {capitalizeText(user?.last_name)}
                            </h1>
                            <p className='text-[#838383] text-xs md:text-sm truncate'>
                                {selectedAccount?.org?.org_name}
                            </p>
                        </div>
                        {isAdmin(selectedAccount!) && (
                            <button
                                className='bg-brand text-xs text-white p-3 rounded-md md:rounded-xl ml-auto whitespace-nowrap'
                                onClick={handleRouteChange}
                            >
                                <span className='hidden md:inline-block '>Switch to</span>{' '}
                                {isAdminRoute() ? 'User' : 'Company'} view
                            </button>
                        )}
                    </div>
                    <div className='flex flex-col gap-4 my-4 mb-7 max-h-[51vh] overflow-scroll pb-2'>
                        {accounts
                            ?.filter(account => account.id !== selectedAccount?.id && account.org)
                            ?.map(account => (
                                <Menu.Item key={account.id}>
                                    <div
                                        key={account.id}
                                        className='bg-[#F9F9F9] flex justify-between items-center border border-[#DADCE0] rounded-xl p-2 px-4'
                                    >
                                        <h1 className='text-[#202020] text-base font-semibold'>
                                            {account?.org?.org_name}
                                        </h1>

                                        {isSwitching && account?.id === accountToBeSwitched?.id ? (
                                            <CgSpinner className='animate-spin' />
                                        ) : (
                                            <button
                                                type='button'
                                                className='text-sm font-medium text-brand'
                                                onClick={() => {
                                                    posthog.reset()
                                                    setIsSwitchingToNewOrg(true)
                                                    setAccountToBeSwitched(account)
                                                    switchAccountFn({ account: account?.id })
                                                }}
                                            >
                                                Switch
                                            </button>
                                        )}
                                    </div>
                                </Menu.Item>
                            ))}
                    </div>
                    <Menu.Item>
                        <div className='rounded-2xl px-5 pt-5 bg- bg-white'>
                            <div className='flex text-base justify-between items-center'>
                                <div
                                    className='text-errorRed flex items-center  gap-2 cursor-pointer'
                                    onClick={() => logoutFn({})}
                                >
                                    <LogOut className='rotate-180' /> <p>Log out</p>
                                </div>
                                <div
                                    className='text-brand flex items-center gap-2 cursor-pointer'
                                    onClick={() => {
                                        addNewBusinessModal(true)
                                    }}
                                >
                                    <PlusSquare />
                                    <p>New business</p>
                                </div>
                            </div>
                        </div>
                    </Menu.Item>
                </Menu.Items>
            </Menu>
        </section>
    )
}

export default DropdownSwitchAccount
