import { AiOutlineClose } from 'react-icons/ai'
import downArrow from '@/assets/blueDownload.svg'
import ModalWrapper from '../../ModalWrapper'
import { Button } from '@/components'
import { TbReload } from 'react-icons/tb'

import { useRef, useState } from 'react'
import { XLSX_SVG } from '@/assets/assets.export'
import useSingleFileUpload, { FileUploadFormatProps, fileEmptyState } from '@/hooks/useSingleFileUpload'
import { useCreatePayrollFromDoc } from '@/services/payroll/mutations'
import { useAuthContext } from '@/context'
import { LoaderIcon } from 'react-hot-toast'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}
const BulkPayroll = ({ closeModal }: Props) => {
    const fileRef = useRef<HTMLInputElement>(null)

    const [payrollFile, setPayrollFile] = useState<FileUploadFormatProps | undefined>(fileEmptyState)
    const { selectedAccount } = useAuthContext()

    const { mutate: createPayrollFromDocFn, isLoading } = useCreatePayrollFromDoc({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
    })
    const { handleFile: uploadPayrollFn } = useSingleFileUpload({
        fileUpload: payrollFile,
        setFileUpload: setPayrollFile,
    })

    const handleUploadPayroll = (e: React.ChangeEvent<HTMLInputElement>) => {
        uploadPayrollFn(e)
    }

    const handleCreatePayroll = () => {
        const formdata = new FormData()
        formdata.append('file', payrollFile?.fileBeforeFormData as File)

        createPayrollFromDocFn(formdata)
    }

    return (
        <ModalWrapper variants={undefined}>
            <div className='w-[51rem] max-h-[90vh]'>
                <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-end'>
                    <div className='flex items-center w-[66%] justify-between h-full'>
                        <h2 className='font-semibold text-[#31254B] text-xl'>Import payroll list in bulk</h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer mr-8'
                            onClick={() => closeModal(false)}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <div className='p-6 px-10 pb-3 max-h-[400px] lg:max-h-[70vh] overflow-scroll mt-2'>
                    <h1 className='text-lg'>Step 1: Download the Bulk Payroll Template</h1>
                    <p className='mt-1 text-[#5E5E5E] text-base font-medium'>
                        Follow the steps below to effortlessly import multiple users to your payroll at once.
                    </p>
                    <div className='my-10 flex flex-col gap-2 text-[#5E5E5E]'>
                        <p className='font-medium flex gap-2'>
                            <span>1. </span>
                            <span>
                                Click on the "Download CSV Template" button below to obtain the pre-formatted CSV file.
                            </span>
                        </p>
                        <p className='font-medium flex gap-2'>
                            <span>2. </span>
                            <span>
                                Save the template to your preferred location on your computer, ensuring easy access when
                                you're ready to upload the completed file.
                            </span>
                        </p>
                        <p className='font-medium flex gap-2'>
                            <span>3. </span>
                            Open and populate the downloaded template with your payroll details, using a spreadsheet
                            software like Microsoft Excel or Google Sheets.
                        </p>
                    </div>
                    <a
                        className='cursor-pointer w-max border border-brand text-brand py-3 px-5 text-lg font-medium flex items-center gap-3 rounded-lg mx-auto mb-10 hover:scale-95 transition-all duration-150 active:scale-100'
                        href='/payroll_template.xlsx'
                        download='Template.xlsx'
                        target='_blank'
                        rel='noreferrer'
                    >
                        Download CSV Template
                        <img src={downArrow} alt='button' />
                    </a>
                    <h1 className='text-lg'>Step 2: Upload the Completed CSV File</h1>
                    <p className='mt-1 text-[#5E5E5E] text-base font-medium'>
                        Follow the steps below to upload your bulk payroll list
                    </p>
                    <div className='my-10 flex flex-col gap-2 text-[#5E5E5E]'>
                        <p className='font-medium flex gap-2'>
                            <span>1. </span>
                            <span>
                                Click on the "Click here to upload CSV file" tab below to select the completed CSV file
                                from your computer.
                            </span>
                        </p>
                        <p className='font-medium flex gap-2'>
                            <span>2. </span>
                            <span>
                                Locate and select the file you saved earlier, ensuring it has the ".csv or .xlsx" file
                                extension.
                            </span>
                        </p>
                        <p className='font-medium flex gap-2'>
                            <span>3. </span>
                            After selecting the file, click on the "Create bulk expense" button to initiate the process.
                        </p>
                    </div>
                    <>
                        <div className='border border-[#DADCE0] rounded-lg px-5 py-3 max-w-max mx-auto mb-8'>
                            {/* !overallKYCForrmDetails?.profile_file?.file */}
                            <input
                                type='file'
                                name='csv_file'
                                id='csv_file'
                                aria-label='Upload your csv file here'
                                hidden
                                ref={fileRef}
                                accept='.csv, .xls, .xlsx'
                                onChange={handleUploadPayroll}
                            />

                            {!payrollFile?.file ? (
                                <label
                                    htmlFor='profile_img'
                                    className={`${'bg-[#DADCE0'} cursor-pointer text-lg font-medium text-[#A8A8A8] flex justify-between gap-[5rem] items-center`}
                                >
                                    <>
                                        <div className=''>
                                            <span className='block text-[15px] font-normal '>
                                                Click here to upload CSV file
                                            </span>
                                            <span className='block text-[15px] font-normal '>
                                                only .csv, .xls files. Max size: 2MB
                                            </span>
                                        </div>

                                        <Button
                                            className='px-2'
                                            onClick={() => {
                                                fileRef?.current?.click()
                                            }}
                                        >
                                            Import payroll list
                                        </Button>
                                    </>
                                </label>
                            ) : (
                                <div className='w-[10rem] border border-[#DADCE0] rounded-xl '>
                                    <XLSX_SVG className=' w-[70%] mx-auto  p-2 opacity-80' />
                                    <span className='text-center w-full text-xs block mb-5'>
                                        {payrollFile?.file_name}
                                    </span>
                                    <span className='flex gap-2 mx-2 items-center mb-3 mt-1 text-[#5E5E5E] justify-center'>
                                        <button
                                            type='button'
                                            className='text-sm text-[#CF491E] flex items-center justify-center gap-1 cursor-pointer'
                                            onClick={() => {
                                                // setPayrollFile(fileEmptyState)
                                                fileRef?.current?.click()
                                            }}
                                        >
                                            <TbReload /> Change
                                        </button>
                                    </span>
                                </div>
                            )}
                        </div>
                        {payrollFile?.file && (
                            <Button className='mx-auto' onClick={handleCreatePayroll}>
                                Save Payroll
                            </Button>
                        )}
                    </>
                </div>
                {isLoading && (
                    <div className='gap-7 fixed top-0 h-full w-full flex justify-center items-center shadow-expenses bg-black/50 text-white font-semibold text-2xl backdrop-blur-[.7px]'>
                        Generating Payroll
                        <LoaderIcon className='animate-spin w-[20px] h-[20px]' />
                    </div>
                )}
            </div>
        </ModalWrapper>
    )
}

export default BulkPayroll
