import emptyEmployees from '@/assets/employees-empty-state.svg'
import filter from '@/assets/filter.svg'
import notFound from '@/assets/not-found.svg'
import { Button } from '@/components'
import EmptyStates from '@/components/EmptyStates'
import FilterEmployee from '@/components/Modals/Employee/FilterEmployees'
import InviteMemberSuccesful from '@/components/Modals/Employee/InviteMembersModal/InviteMemberSuccesful'
import InviteMembersModal from '@/components/Modals/Employee/InviteMembersModal/InviteMembersModal'
import InviteModalContainer from '@/components/Modals/Employee/InviteMembersModal/InviteModalContainer'
import PendingInvitesModal from '@/components/Modals/Employee/PendingInvitesModal/PendingInvitesModal'
import ProgressBar from '@/components/ProgressBar'
import { useAuthContext, useEmployeesContext } from '@/context'
import { useEffect, useMemo, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import EmployeesListTable from '../../components/EmployeesListTable'
import FilterEmployeeChip from './FilterEmployeesChip'
import { useSearchParams } from 'react-router-dom'
import { ModuleNameEnum } from '@/types/billing'
import useAccessToModule from '@/hooks/useAccessToModule'

type Props = {
    onKeyDown: (e: any) => void
    isLoading: boolean
    isRefetching: boolean
}
export default function EmployeeMembers({ onKeyDown, isLoading, isRefetching }: Props) {
    const { orgMembers, pendingOrgMembers, setFilterOrgMembersBy, filterEmployee, filterOrgMembersBy } =
        useEmployeesContext()
    const [searchedInput, setSearchedInput] = useState<string | undefined>()
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
    const [isPendingInviteModalOpen, setIsPendingInviteModalOpen] = useState(false)
    const [filterEmployeeModal, setFilterEmployeeModal] = useState(false)
    const [searchParams] = useSearchParams()

    const { setShowUpgradeModal, setCurrentModule } = useAuthContext()
    const { enabled: isPeopleEnabled } = useAccessToModule({ module: ModuleNameEnum.BUDGETS })

    const orgMembersData = useMemo(() => orgMembers ?? [], [orgMembers])
    const advanceFilterTriggered = Object.values(filterEmployee).some(
        value =>
            (typeof value === 'string' && value.length > 0) ||
            !!filterEmployee?.period?.to ||
            !!filterEmployee?.period?.from ||
            !!filterEmployee?.period?.time_in_words
    )
    useEffect(() => {
        if (searchParams.get('from') === 'get_started') {
            setIsInviteModalOpen(true)
        }
    }, [])
    if (isLoading) return <ProgressBar />
    return (
        <main className=''>
            <div className='block lg:flex justify-between mb-6 lg:mb-0'>
                <div className='mb-6'>
                    <h1 className='text-dark font-medium text-lg'>People</h1>
                    <p className='text-gray7 text-sm'>View and manage people in your business.</p>
                </div>
                <div className='flex gap-3 lg:gap-6 text-sm'>
                    <Button
                        className='min-h-[45px] w-1/2 lg:min-w-[190px] border border-[#DADCE0] bg-transparent text-sm text-[#202020] hover:opacity-80 hover:bg-transparent'
                        onClick={() => setIsPendingInviteModalOpen(true)}
                    >
                        Pending invites{' '}
                        {pendingOrgMembers && pendingOrgMembers?.length > 0 && (
                            <span className='bg-[#454ADE] rounded-full p-1 px-2 text-[white] inline-block text-xs  font-normal'>
                                {pendingOrgMembers.length}
                            </span>
                        )}
                    </Button>
                    <Button
                        className='min-h-[45px] w-1/2 lg:min-w-[120px] text-sm'
                        onClick={() => {
                            if (!isPeopleEnabled) {
                                setShowUpgradeModal(true)
                                setCurrentModule(ModuleNameEnum.PEOPLE)

                                return
                            }
                            setIsInviteModalOpen(true)
                        }}
                    >
                        Invite people
                    </Button>
                </div>
            </div>

            <section className='w-full py-4 block bg-white rounded-2xl p-4 '>
                <div className='flex justify-between'>
                    <div className='border border-[#DADCE0] h-[45px] w-[60%] lg:w-[30%] rounded-lg flex items-center overflow-hidden'>
                        <div className='text-[#A8A8A8] flex items-center justify-center h-full px-3'>
                            <CiSearch fontSize={25} />
                        </div>
                        <input
                            type='text'
                            placeholder='Search by name or email'
                            className='flex-1 outline-none placeholder:text-xs'
                            value={filterOrgMembersBy?.bySearch}
                            onChange={e => {
                                setSearchedInput(e.target.value)
                                setFilterOrgMembersBy(prev => ({ ...prev, bySearch: e.target.value }))
                            }}
                            onKeyDown={onKeyDown}
                        />
                    </div>
                    <div className='flex items-center gap-2'>
                        <div
                            className='h-[35px] w-[35px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-1.5 cursor-pointer'
                            onClick={() => setFilterEmployeeModal(true)}
                        >
                            <img src={filter} alt='button' />
                        </div>
                    </div>
                </div>
                <div className='my-4'>
                    <FilterEmployeeChip />
                </div>
                {orgMembersData?.length > 0 ? (
                    <section className={`mt-4  ${isRefetching ? 'opacity-50' : 'opacity-100'} overflow-x-scroll`}>
                        <EmployeesListTable employees={orgMembersData} />
                    </section>
                ) : orgMembersData?.length === 0 && isRefetching ? (
                    <ProgressBar />
                ) : (
                    <div>
                        {(searchedInput !== undefined && orgMembersData?.length === 0) || advanceFilterTriggered ? (
                            <div className='flex flex-col w-[30rem] mx-auto items-center mt-5'>
                                <img src={notFound} alt='Not found' className='mt-6 h-[200px]' />
                                <span className='text-2xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                                <span className='text-[#202020] text-sm w-full mt-3 font-medium text-center'>
                                    Oops! It seems we couldn't find any matching results for your search. Please try
                                    again with different keywords or refine your filter criteria for better results.
                                </span>
                            </div>
                        ) : (
                            <section className='grid place-items-center'>
                                <EmptyStates
                                    title='No users yet'
                                    description='Kindly click the “invite users button by the top right to empower
your users in monitoring and managing company expenses together!'
                                    image={emptyEmployees}
                                />
                            </section>
                        )}
                    </div>
                )}

                {isInviteModalOpen && (
                    <InviteModalContainer closeModal={setIsInviteModalOpen}>
                        <InviteMembersModal />
                        <InviteMemberSuccesful />
                    </InviteModalContainer>
                )}
                {isPendingInviteModalOpen && (
                    <PendingInvitesModal
                        setPendingInvitesModal={setIsPendingInviteModalOpen}
                        pendingInvitesData={pendingOrgMembers!}
                    />
                )}
            </section>
            {filterEmployeeModal && <FilterEmployee closeModal={setFilterEmployeeModal} />}
        </main>
    )
}
