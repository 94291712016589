import { Menu } from '@headlessui/react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { TfiAngleDown } from 'react-icons/tfi'
// import downArrow from '@/assets/down-arrow.svg'
import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import { useMemo, useState } from 'react'
import { cn } from '@/lib/styling'
import ActiveBenefits from '../../components/ActiveBenefits'
import { useNavigate } from 'react-router-dom'
import { useGetOrgBenefits } from '@/services/benefits/queries'
import { useAuthContext } from '@/context'
import ProgressBar from '@/components/ProgressBar'
import { formatAmount } from '@/utils/money'
import EmptyStates from '@/components/EmptyStates'
import { BenefitsSvg } from '@/assets/assets.export'

const Benefits = () => {
    const { selectedAccount } = useAuthContext()

    const [tabValue, setTabValue] = useState('active')
    const navigate = useNavigate()

    const {
        data: org_benefits,
        isLoading,
        isRefetching,
    } = useGetOrgBenefits({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
    })

    const orgBenefits = useMemo(() => org_benefits ?? [], [org_benefits])

    return (
        <section className='p-2 lg:p-4 py-2 overflow-scroll bg-white rounded-xl pb-32'>
            <div className='block lg:flex items-center justify-between my-2 lg:my-7'>
                <div className='mb-4 lg:mb-0'>
                    <h1 className='text-lg lg:text-xl font-semibold'>User benefits</h1>
                    <p className='text-sm lg:text-base text-[#5E5E5E] font-medium mt-2'>
                        Assign and manage benefits for your users with ease.
                    </p>
                </div>
                <div className='flex gap-6 w-full lg:w-auto'>
                    {/* <Button className='border py-7 border-[#DADCE0] bg-transparent text-[#202020] text-[1rem] hover:opacity-80 hover:bg-transparent'>
                        Benefit requests{' '}
                        <span className='bg-[#454ADE] rounded-full p-1 px-3 text-[white] inline-block text-[1rem]  font-normal'>
                            5
                        </span>
                    </Button> */}
                    <Menu as='div'>
                        <Menu.Button
                            className='bg-[#454ADE] text-white px-3 lg:px-6 h-[45px] lg:h-[56px] rounded-lg text-[14px] flex items-center gap-2 min-w-full lg:w-auto'
                            onClick={() => navigate('/admin/dashboard/payroll/benefits/new_benefit')}
                        >
                            Acquire benefit
                            <TfiAngleDown />
                        </Menu.Button>
                    </Menu>
                </div>
            </div>
            <div className='bg-[#F9F9F9] rounded-2xl py-3 px-6 pr-4 lg:pr-6 flex flex-row justify-between items-end lg:items-center'>
                <div className='flex gap-5 lg:gap-12 items-center flex-wrap'>
                    <div className=''>
                        <h1 className='font-medium text-sm flex gap-1 items-center text-[#5E5E5E]'>Enrolled users</h1>
                        <p className='mt-2 font-semibold'>N/A</p>
                    </div>
                    <div className=''>
                        <h1 className='font-medium text-sm flex gap-1 items-center text-[#5E5E5E]'>
                            Active benefit providers
                        </h1>
                        <p className='mt-2 font-semibold'>N/A</p>
                    </div>
                    <div className=''>
                        <h1 className='font-medium text-sm flex gap-1 items-center text-[#5E5E5E]'>
                            Total Benefit Costs
                            <AiOutlineInfoCircle color='#838383' />
                        </h1>
                        <p className='mt-2 font-semibold'>{formatAmount()} </p>
                    </div>
                </div>
                {/* <div className='h-[40px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-2 cursor-pointer'>
                    <img src={downArrow} alt='button' />
                </div> */}
            </div>
            <div className=''>
                <Tabs defaultValue={tabValue}>
                    <TabsList>
                        <TabTriggersContainer className='lg:w-[100%]'>
                            <TabsTrigger
                                value={'active'}
                                onClick={() => setTabValue('active')}
                                className={cn(
                                    'underline_animation_normal',
                                    tabValue === 'active'
                                        ? 'underline_animation_selected'
                                        : 'underline_animation_notSelected'
                                )}
                            >
                                Active
                                <span className={`inline-block ml-2  rounded-full px-3 bg-[#D5D5F5]`}>
                                    {orgBenefits?.length}
                                </span>
                            </TabsTrigger>
                            <TabsTrigger
                                value={'inactive'}
                                onClick={() => setTabValue('inactive')}
                                className={cn(
                                    'underline_animation_normal',
                                    tabValue === 'inactive'
                                        ? 'underline_animation_selected'
                                        : 'underline_animation_notSelected'
                                )}
                            >
                                Inactive
                                <span className={`inline-block ml-2  rounded-full px-3 bg-[#D5D5F5]`}>0</span>
                            </TabsTrigger>
                        </TabTriggersContainer>
                    </TabsList>
                    <TabsContent value={'active'} className={`${isRefetching ? 'opacity-50' : 'opacity-100'}`}>
                        {isLoading ? <ProgressBar /> : <ActiveBenefits orgBenefits={orgBenefits} />}
                    </TabsContent>
                    <TabsContent value={'inactive'}>
                        <section className='grid place-items-center'>
                            <EmptyStates
                                title=''
                                description='You don’t have any inactive benefits yet. Click the “Acquire benefit” button at the top-right to acquire your first benefit.'
                                SvgComponent={BenefitsSvg}
                            />
                        </section>
                    </TabsContent>
                </Tabs>
            </div>
        </section>
    )
}

export default Benefits
