import { Invoice } from '@/types/invoice'
import { formatAmount } from '@/utils/money'

export default function InvoiceAmountTable({ invoiceDetails }: { invoiceDetails?: Invoice }) {
    const sub_total = invoiceDetails?.items?.reduce(
        (acc, cur) => acc + Number(cur?.quantity) * Number(cur?.unit_cost),
        0
    )
    return (
        <div className='rounded-lg overflow-y-scroll border border-[#DADCE0] mb-3'>
            <table className='rounded-lg   w-full'>
                <thead className='bg-[#f9f9f9] text-left text-[16px]  text-[#838383] rounded'>
                    <tr>
                        <th className='px-5 py-2 font-normal rounded-t'>Item </th>
                        <th className='px-5 py-2 font-normal '>Description </th>
                        <th className='px-5 py-2 font-normal '>Quantity </th>
                        <th className='px-5 py-2 font-normal '>Rate</th>
                        <th className='px-5 py-2 font-normal rounded-t'>Line Total</th>
                    </tr>
                </thead>
                <tbody className='text-[#5E5E5E]'>
                    {invoiceDetails?.items?.map(item => (
                        <>
                            <tr className='border-b border-[#DADCE0]'>
                                <td className='px-5 py-5'>{item?.name}</td>
                                <td className='px-5 py-5'>{item?.description ?? 'No description'}</td>
                                <td className='px-5 py-5'>{item?.quantity}</td>
                                <td className='px-5 py-5'>{formatAmount(item?.unit_cost)}</td>
                                <td className='px-5 py-5'>
                                    {formatAmount(Number(item?.unit_cost) * Number(item?.quantity))}
                                </td>
                            </tr>
                        </>
                    ))}
                    <tr className='border-b border-[#DADCE0]'>
                        <td></td>
                        <td> </td>
                        <td> </td>
                        <td className='px-5 py-2  font-semibold'> Sub total</td>
                        <td className='px-5 py-2 '>{formatAmount(sub_total)}</td>
                    </tr>
                    <tr className='border-b border-[#DADCE0]'>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                        <td className='px-5 py-2 font-semibold'>Tax (₦) </td>
                        <td className='px-5 py-2'> {formatAmount(invoiceDetails?.tax)}</td>
                    </tr>
                    <tr className=''>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className='px-5 py-2 font-semibold'>Total amount due</td>
                        <td className='px-5 py-2'>{formatAmount(sub_total! + Number(invoiceDetails?.tax))}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
