import React, { useState } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { AiOutlineClose } from 'react-icons/ai'
import { modalVariants } from '../../Expense/utils'
import { StepOne } from './StepOne'
import { useGetPayrollSettingsForOrg } from '@/services/payroll/queries'
import { useAuthContext } from '@/context'
import { toastHandler } from '@/components/utils/Toast'
import { useUpdatePayrollSettings } from '@/services/payroll/mutations'
import { StepThree } from './StepThree'
import { StepTwo } from './StepTwo'
import { StepFour } from './StepFour'

interface EditSettingsProps {
    setSettings: React.Dispatch<React.SetStateAction<boolean>>
}

export type PayrollSettingsFullType = {
    biweekly_payment_day: string
    biweekly_payment_start_date: string
    monthly_payment_day: string
    weekly_payment_day: string
    lead_days: number
    default_deductions: {
        name: string
        type: 'percentage' | 'amount'
        amount: number
        org: string
    }[]
    default_bonuses: {
        name: string
        type: 'percentage' | 'amount'
        amount: number
        org: string
    }[]
    booleanPay: {
        monthly: boolean
        weekly: boolean
        biWeekly: boolean
    }
    isGenAutomated: string
    leaveSettings: {
        sick_days: string
        vacation: string
        personal: string
        maternity: string
    }
}

export const PayrollSettingsPrompt = ({ setSettings }: EditSettingsProps) => {
    const formEmptyState: PayrollSettingsFullType = {
        biweekly_payment_day: '',
        biweekly_payment_start_date: '',
        monthly_payment_day: '1',
        weekly_payment_day: 'monday',
        lead_days: 1,
        default_deductions: [],
        default_bonuses: [],
        booleanPay: {
            monthly: false,
            weekly: false,
            biWeekly: false,
        },
        isGenAutomated: '',
        leaveSettings: {
            sick_days: '1',
            vacation: '1',
            personal: '1',
            maternity: '1',
        },
    }
    const [step, setStep] = useState(1)
    const { selectedAccount } = useAuthContext()
    const [formState, setFormState] = useState(formEmptyState)

    const { data: orgPayrollSettings } = useGetPayrollSettingsForOrg({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
    })
    const { mutate: updatePayrollSettingsFn, isLoading: isUpdating } = useUpdatePayrollSettings({
        queryParams: {
            setting_id: orgPayrollSettings?.id as string,
        },
        onSuccess() {
            setSettings(false)
            toastHandler({ message: 'Settings saved', state: 'success' })
        },
    })

    return (
        <ModalWrapper className='justify-end right-8' variants={modalVariants} modalClassName='rounded-none bg-[red]'>
            <div className='h-[100vh] w-[36rem] overflow-scroll'>
                <div className='border-b border-[#DADCE0] py-4 px-2 flex'>
                    <div className='flex items-center w-full'>
                        <h2 className='font-semibold text-[#31254B] text-[22px] flex-1 ml-7'>Set your payroll</h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={() => setSettings(false)}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <div className='px-7 py-6 h-[80vh]'>
                    <div className='flex justify-between items-center mb-4'>
                        <h2 className='text-lg font-semibold relative'>
                            {step === 1
                                ? 'Set pay frequency'
                                : step === 2
                                  ? 'Add and set deduction and bonus types'
                                  : step === 3
                                    ? 'Set payroll generation.'
                                    : 'Set leave allowances for all your users'}
                            {step === 3 && (
                                <span className='absolute -bottom-5 left-0 text-green text-base font-medium'>
                                    You're almost done!
                                </span>
                            )}
                        </h2>
                        <div>
                            <span className='py-2 px-4 bg-[#EFEFF9] rounded-2xl flex items-center gap-2'>
                                {step}/ 4 <span className='font-semibold'> steps</span>
                            </span>
                        </div>
                    </div>
                    <div className=''>
                        {step === 1 && <StepOne setFormState={setFormState} setStep={setStep} formState={formState} />}
                        {step === 2 && <StepTwo setFormState={setFormState} setStep={setStep} formState={formState} />}
                        {step === 3 && (
                            <StepThree setStep={setStep} setFormState={setFormState} formState={formState} />
                        )}
                        {step === 4 && (
                            <StepFour
                                setStep={setStep}
                                setFormState={setFormState}
                                isLoading={isUpdating}
                                updateFn={updatePayrollSettingsFn}
                                formState={formState}
                            />
                        )}
                    </div>
                </div>
                {/* <div className='px-7'>
                    <Button
                        className='mt-[2rem]'
                        // loading={isTransferring}
                        type={'submit'}
                        onClick={() => {
                            setStep(step + 1)
                        }}
                        // disabled={!isValid}
                    >
                        Next
                    </Button>
                </div> */}
            </div>
        </ModalWrapper>
    )
}
