import { CancelIcon, LoaderIcon, PDFIcon } from '@/assets/assets.export'
import { Button } from '@/components'
import useSingleFileUpload, { FileUploadFormatProps, fileEmptyState } from '@/hooks/useSingleFileUpload'
import { useMutationScanReceipt } from '@/services/expenses/mutations'
import ModalWrapper from 'COMPONENTS/Modals/ModalWrapper'
import { useState } from 'react'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { useNavigate } from 'react-router-dom'

type CreateScanExpenseModalProps = {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    hasAPrimaryBank: boolean
}

const file_types = {
    pdf: 'pdf',
    image: 'image',
}

export default function CreateScanExpenseModal({ setShowModal, hasAPrimaryBank }: CreateScanExpenseModalProps) {
    const [receiptFile, setReceiptFile] = useState<FileUploadFormatProps>()

    const navigate = useNavigate()

    const { selectedAccount } = useAuthContext()

    const { mutate: scanReceipt, isLoading: isExpenseScanned } = useMutationScanReceipt({
        onSuccess: () => {
            toastHandler({ message: 'Expense submitted', state: 'success' })
            setShowModal(false)
        },
        onError: () => {
            toastHandler({ message: 'Please use the manual creation', state: 'error' })
            setShowModal(false)
        },
    })

    //async operation to upload the scanned receipt to the server
    const handleUploadReceipt = () => {
        const formdata = new FormData()
        formdata.append('file', receiptFile?.fileBeforeFormData as File)
        formdata.append('org_id', selectedAccount?.org?.id as string)

        if (receiptFile?.file) {
            scanReceipt(formdata)
        }
        return
    }

    //reusable hook that helps with uploading file
    const { handleFile: uploadReceiptFn } = useSingleFileUpload({
        fileUpload: receiptFile,
        setFileUpload: setReceiptFile,
    })
    return (
        <ModalWrapper closeModal={() => setShowModal(false)}>
            <section className='w-screen lg:w-[50rem] text-[#202020] my-6 overflow-y-scroll relative -z-20 h-fit max-h-[60vh] lg:max-h-[53vh]'>
                <div className='flex mx-8'>
                    <h1 className='flex-1 text-center text-lg lg:text-2xl font-semibold'>
                        Create expense (Scan a receipt)
                    </h1>
                    <CancelIcon
                        className='rounded-full bg-gray/20 p-1 cursor-pointer w-9 h-9'
                        onClick={() => setShowModal(false)}
                    />
                </div>
                <hr className='bg-[#DADCE0] w-full h-[1px] mt-4 opacity-10' />
                <div className='mx-4 lg:mx-8 mt-8'>
                    {hasAPrimaryBank && (
                        <div className=' p-3 mb-6 flex justify-between items-center bg-[#F8F1DE] text-[#5E5E5E] text-xs gap-9 rounded-lg'>
                            <p>
                                Please note that you won’t be able to submit a reimbursable expense if you have not
                                linked your bank account.
                            </p>
                            <Button
                                className='px-2 min-w-[120px] text-xs'
                                onClick={() => {
                                    navigate('/dashboard/settings/user/payment')
                                }}
                            >
                                Link bank account
                            </Button>
                        </div>
                    )}
                    <h2 className='text-[1.2rem]  font-semibold'>Generate expense with receipt</h2>
                    <p className='mt-3 text-[#5E5E5E]'>
                        Upload your receipt to generate an expense request for reimbursement or accounting purposes.
                    </p>
                    <label
                        htmlFor='receipt'
                        className={`${
                            receiptFile && 'bg-[#DADCE0]/10'
                        } cursor-pointer w-full h-[12rem] border border-[#DADCE0] border-dashed mt-4 lg:mt-10 rounded-md text-xl font-semibold text-[#A8A8A8] flex flex-col justify-center items-center p-[1rem]`}
                    >
                        {/* conditionally  render between the input:file or the included file */}
                        {!receiptFile?.file ? (
                            <>
                                <input
                                    type='file'
                                    name='upload_receipt'
                                    id='receipt'
                                    aria-label='Upload your receipt here'
                                    hidden
                                    onChange={e => uploadReceiptFn(e)}
                                />
                                <span className='block'>Upload receipt</span>
                                <span className='block text-center'>Max 2MB | Accepts JPEG, PNG, and PDF formats</span>
                            </>
                        ) : (
                            <div className='bg-white flex justify-between items-center h-full rounded-lg px-4 w-full'>
                                <div className='flex items-center w-full gap-4 lg:gap-6 mx-0 lg:mx-6'>
                                    {receiptFile.fileType === file_types.pdf ? (
                                        <PDFIcon className='w-28 h-28 inline-block rounded-lg' />
                                    ) : (
                                        <img
                                            src={receiptFile.fileUrl}
                                            alt='Img  description of the scanned receipt'
                                            className='w-[4.5rem] h-28 inline-block rounded-lg'
                                        />
                                    )}
                                    <div className='flex w-full items-center'>
                                        <span className=''>
                                            <span className='block max-w-xs text-base lg:text-[20px] lg:w-auto truncate break-all w-[150px]'>
                                                {receiptFile.file_name}
                                            </span>
                                            <span className='block text-[#000] mt-1'>{receiptFile.size}</span>
                                        </span>
                                        <span className='ml-auto ' onClick={() => setReceiptFile(fileEmptyState)}>
                                            <CancelIcon className='rounded-full bg-[#F9F0EF]  text-red p-[0.35rem] h-10 w-10 !stroke-[#872D10]' />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </label>
                </div>
                <div className='w-full mt-10 lg:mt-14'>
                    <Button
                        disabled={!receiptFile?.file}
                        size={'default'}
                        className='mx-auto px-[6rem]'
                        onClick={handleUploadReceipt}
                    >
                        Scan and generate expense
                    </Button>
                </div>
                {/* success modal for scanned receipts*/}
                {isExpenseScanned && (
                    <div className='gap-3 fixed top-0 h-full w-full flex justify-center items-center shadow-expenses bg-black/50 text-white font-semibold text-2xl backdrop-blur-[.7px]'>
                        Generating expense
                        <LoaderIcon className='animate-spin' />
                    </div>
                )}
            </section>
        </ModalWrapper>
    )
}
