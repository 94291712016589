import { Button, Textarea } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { FileUploadFormatProps } from '@/hooks/useSingleFileUpload'
import { FormStateType } from '@/modules/dashboard/Invoices/components/utils'
import { CreateInvoiceActions } from '@/modules/dashboard/Invoices/partials/SaveInvoiceTypes/SaveInvoice'
import { useCreateInvoice, useUpdateInvoice } from '@/services/invoice/mutations'
import { Customers } from '@/types/customers'
import { formatAmount } from '@/utils/money'
import { format, formatDistance } from 'date-fns'
import moment from 'moment'
import React, { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { useNavigate, useParams } from 'react-router-dom'
import ModalWrapper from '../ModalWrapper'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    logoFile?: FileUploadFormatProps
    parentState: FormStateType
    orgCustomers: Customers[]
}
export enum PaymentMethod {
    OFFLINE = 'OFFLINE',
    ONLINE = 'ONLINE',
    BOTH = 'BOTH',
}
export default function SaveSendModal({ closeModal, parentState, orgCustomers }: Props) {
    const { selectedAccount } = useAuthContext()
    const navigate = useNavigate()
    const { invoice_id } = useParams()

    const { mutate: createInvoiceFn, isLoading: isCreating } = useCreateInvoice({
        onSuccess() {
            navigate(-1)
            toastHandler({ message: 'Invoice created', state: 'success' })
        },
    })
    const { mutate: updateInvoiceFn, isLoading: isUpdating } = useUpdateInvoice({
        onSuccess() {
            navigate(-1)
            toastHandler({ message: 'Invoice updated', state: 'success' })
        },
        queryParams: {
            invoice_id: invoice_id as string,
        },
    })

    const selectedCustomer = orgCustomers?.find(customer => parentState?.customer === customer?.id)
    const sub_total = parentState?.item?.reduce((acc, cur) => acc + Number(cur?.quantity) * Number(cur?.unit_cost), 0)
    const total = Number(sub_total) + Number(parentState?.tax ?? 0)

    const save_data = [
        { name: 'From', desc: selectedAccount?.user?.email },
        { name: 'To', desc: selectedCustomer?.email },

        {
            name: 'Customer',
            desc: selectedCustomer?.name,
        },
        {
            name: 'Issue date',
            desc: parentState?.issue_date && format(new Date(parentState?.issue_date), 'MMM dd, yyyy'),
        },
        {
            name: 'Due date',
            desc: (
                <span>
                    <span>{parentState?.due_date && format(new Date(parentState?.due_date), 'MMM dd, yyyy')}</span>
                    <span className='text-[#5E5E5E] text-sm'>
                        ({formatDistance(new Date(parentState?.issue_date), new Date(parentState?.due_date))})
                    </span>
                </span>
            ),
        },
        { name: 'Sub-total', desc: formatAmount(sub_total) },
        { name: 'Tax(N)', desc: formatAmount(parentState?.tax) },
        { name: 'Total amount due', desc: formatAmount(total) },
    ]

    const [formState, setFormState] = useState({
        message: parentState.message ?? '',
        asPDF: false,
        copy: false,
    })
    const disableButton = () => {
        const res = Object.values({ ...formState })
            .map((value, key) => (key !== 3 ? true : !!value))
            .some(data => data === false)
        return res
    }

    const handleSubmit = () => {
        const { customer, due_date, issue_date, item, tax, payment_method, footer } = parentState
        const { asPDF, message } = formState

        const payload = {
            customer,
            due_date: moment(due_date).utc(),
            issue_date: moment(issue_date).utc(),
            message,
            tax: tax ?? '0',
            items: item.map(item_detials => ({
                name: item_detials?.name,
                description: item_detials?.description,
                quantity: item_detials?.quantity,
                unit_cost: item_detials?.unit_cost,
            })),
            payment_method,
            action: CreateInvoiceActions.SAVE_AND_SEND,
            send_admin_email: formState?.copy,
            attached: asPDF as unknown as Blob,
            accept_part_payment: parentState?.part_payment,
            payout_bank_account: parentState?.account,
            footer: footer,
        }

        if (invoice_id) {
            updateInvoiceFn(payload)
            return
        }
        createInvoiceFn(payload)
    }
    return (
        <ModalWrapper variants={undefined}>
            <section className='w-screen lg:w-[30rem]  overflow-scroll pb-8'>
                <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-end '>
                    <div className='flex items-center w-[65%] justify-between h-full'>
                        <h2 className='font-semibold text-[#31254B]'>Send invoice</h2>
                        <div className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'>
                            <AiOutlineClose fontSize={25} onClick={() => closeModal(false)} />
                        </div>
                    </div>
                </div>
                <div
                    className={`m-6 mt-0 pt-6 overflow-y-scroll h-[35rem] ${isCreating || isUpdating ? 'pointer-events-none' : ''} `}
                >
                    <div className='bg-[#EFEFF9] rounded-xl p-5'>
                        {save_data.map(data => (
                            <p className='mb-1'>
                                {' '}
                                <span className='font-medium text-base'>{data.name}</span>
                                {': '}
                                <span>{data.desc}</span>
                            </p>
                        ))}
                    </div>
                    <div className='my-5'>
                        <label
                            htmlFor='message'
                            className='text-[#202020] text-sm mb-3 font-medium flex items-center gap-1'
                        >
                            Message <span className='text-[#5E5E5E]'>(optional)</span>
                            <span className='ml-auto text-xs'>{formState.message?.length ?? '0'}/120</span>
                        </label>
                        <Textarea
                            className='border border-[#DADCE0] pt-4 min-h-[5.6rem] text-18px resize-none'
                            maxLength={120}
                            value={formState.message}
                            placeholder='Enter message to customer'
                            onChange={e =>
                                setFormState(prev => ({
                                    ...prev,
                                    message: e.target.value! as unknown as string,
                                }))
                            }
                        />
                    </div>
                    <div className=''>
                        <>
                            <div className={`flex gap-2 item-center text-[#5E5E5E]`}>
                                <input
                                    type='checkbox'
                                    name='copy'
                                    id='copy'
                                    className='accent-[#454ADE] cursor-pointer'
                                    onChange={e =>
                                        setFormState(prev => ({
                                            ...prev,
                                            copy: e.target.checked,
                                        }))
                                    }
                                    checked={formState.copy}
                                />
                                <label htmlFor='copy' className='text-sm font-medium cursor-pointer'>
                                    Send a copy to myself at {selectedAccount?.user?.email}
                                </label>
                            </div>

                            <div className={`flex gap-2 item-center text-[#5E5E5E] mt-3`}>
                                <input
                                    type='checkbox'
                                    name='asPDF'
                                    id='asPDF'
                                    className='accent-[#454ADE] cursor-pointer'
                                    onChange={e =>
                                        setFormState(prev => ({
                                            ...prev,
                                            asPDF: e.target.checked,
                                        }))
                                    }
                                    checked={formState.asPDF}
                                />
                                <label htmlFor='asPDF' className='text-sm font-medium cursor-pointer'>
                                    Attach invoice as PDF
                                </label>
                            </div>
                        </>
                    </div>
                </div>

                <div className='flex pt-5 items-center justify-end gap-4 px-6 py-2  absolute bottom-0 right-0 left-0 bg-white w-full rounded-b-lg'>
                    <Button
                        className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px]'
                        loading={isCreating || isUpdating}
                        onClick={handleSubmit}
                        disabled={disableButton()}
                    >
                        Send invoice
                    </Button>
                </div>
            </section>
        </ModalWrapper>
    )
}
