import { createMutation } from '../api/mutation'
import { folderItemsKey, orgVaultsCategoryKey, orgVaultsKey, vaultItemsKey } from './queries'
import { signedUrlData } from './types'

export const useCreateVault = createMutation({
    url: '/vault',
    keysToRefetch: [orgVaultsKey],
    method: 'POST',
})

export const useUpdateVault = createMutation({
    url: '/vault',
    keysToRefetch: [orgVaultsKey, ['/single-vault', 'id']],
    method: 'PATCH',
})

export const useDeleteVault = createMutation({
    url: '/vault',
    keysToRefetch: [orgVaultsKey],
    method: 'DELETE',
})

export const useCreateVaultCategory = createMutation({
    url: '/vault/category',
    keysToRefetch: [orgVaultsCategoryKey],
    method: 'POST',
})
export const useUpdateVaultCategory = createMutation({
    url: '/vault/category',
    keysToRefetch: [orgVaultsCategoryKey, ['single-catgeory', 'id']],
    method: 'PATCH',
})
export const useDeleteVaultCategory = createMutation({
    url: '/vault/category',
    keysToRefetch: [orgVaultsCategoryKey],
    method: 'DELETE',
})
export const useCreateVaultItem = createMutation({
    url: '/vault/item',
    keysToRefetch: [orgVaultsKey, vaultItemsKey],
    method: 'POST',
})

export const useUpdateVaultItem = createMutation({
    url: '/vault/item',
    keysToRefetch: [vaultItemsKey, ['single-item', 'id'], folderItemsKey],
    method: 'PATCH',
})
export const useDeleteVaultItem = createMutation({
    url: '/vault/item',
    keysToRefetch: [vaultItemsKey, folderItemsKey],
    method: 'DELETE',
})

export const useGenerateSignedUrl = createMutation<signedUrlData[]>({
    url: '/s3/signed-url',
    method: 'POST',
})

export const useMoveVaultItem = createMutation({
    url: '/vault/item/move',
    method: 'POST',
})
