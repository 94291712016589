import { Tabs, TabsList, TabsTrigger, TabTriggersContainer } from '@/components/Tabs/Tabs'
import { useImpersonationContext } from '@/context'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import { TabsContent } from '@radix-ui/react-tabs'
import PageContent from '@/layout/PageContent'
import BusinessDirectorySummary from './BusinessDirectorySummary'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useMemo, useState } from 'react'
import { ChevronRight } from 'lucide-react'

import BusinessDirectoryCompliance from './BusinessDirectoryCompliance'
import BusinessSubscription from './BusinessSubscription'
import DirectoryOrgBusinessPlan from './DirectoryOrgBusinessPlan'
import { Button } from '@/components'
import CreateBusinessPlan from '../../../../components/Modals/Business-directory/CreateBusinessPlan'

const route_const = {
    summary: 'summary',
    kyb: 'kyb',
    subscription: 'subscription',
    busniness_plan: 'busniness_plan',
}

export default function BusinessOrgDirectory() {
    const { orgDetails } = useImpersonationContext()
    const [createBusinessPlan, setCreateBusinessPlan] = useState(false)

    const { user_id: org_id } = useParams()
    const {
        data,
        isRefetching: isOrgMembersReFetching,
        isLoading: isOrgMembersLoading,
    } = useGetOrgMembers({
        queryParams: {
            org_id: `${org_id as string}`,
            status: `ACTIVE`,
        },
    })
    const orgMembersData = useMemo(() => data ?? [], [data])

    const currentRoute = location.pathname.split('/').slice(0, -1).join('/')

    const navigate = useNavigate()
    const { directory_subRoute } = useParams()
    const isBusinessPlanMode = location.pathname.includes(route_const.busniness_plan)

    return (
        <>
            <section className='text-sm  text-[#202020] py-5 pl-[3.1%] flex gap-2 items-center'>
                <span
                    className='font-semibold  cursor-pointer text-base '
                    onClick={() => {
                        navigate('/admin/dashboard/business-directory/manage_business')
                    }}
                >
                    Business directory
                </span>
                <ChevronRight className='text-gray7 w-4 h-4' />{' '}
                {orgDetails?.org_name ?? orgMembersData?.[0]?.org?.org_name}
            </section>
            <section>
                <Tabs defaultValue={directory_subRoute} value={directory_subRoute}>
                    <TabsList className='border-none flex justify-between w-[93%] mx-auto overflow-scroll items-center'>
                        <TabTriggersContainer>
                            <TabsTrigger
                                value={route_const.summary}
                                onClick={() => {
                                    navigate(`${currentRoute}/${route_const.summary}`)
                                }}
                                className={`relative border-none px-3 ${
                                    route_const.summary === directory_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {route_const.summary === directory_subRoute && (
                                    <motion.div
                                        layoutId='directory_btn'
                                        className={
                                            'bg-tabBg rounded  text-sm  w-full left-[1px] top-1  bottom-1 absolute'
                                        }
                                    />
                                )}
                                <motion.span layout='position' className='z-20 relative'>
                                    Summary
                                </motion.span>
                            </TabsTrigger>
                            <TabsTrigger
                                value={route_const.kyb}
                                onClick={() => {
                                    navigate(`${currentRoute}/${route_const.kyb}`)
                                }}
                                className={`relative border-none px-3 ${
                                    route_const.kyb === directory_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {route_const.kyb === directory_subRoute && (
                                    <motion.div
                                        layoutId='directory_btn'
                                        className={
                                            'bg-tabBg rounded  text-sm  w-full left-[1px] top-1  bottom-1 absolute'
                                        }
                                    />
                                )}
                                <motion.span layout='position' className='z-20 relative'>
                                    Compliance
                                </motion.span>
                            </TabsTrigger>
                            <TabsTrigger
                                value={route_const.subscription}
                                onClick={() => {
                                    navigate(`${currentRoute}/${route_const.subscription}`)
                                }}
                                className={`relative border-none px-3 ${
                                    route_const.subscription === directory_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {route_const.subscription === directory_subRoute && (
                                    <motion.div
                                        layoutId='directory_btn'
                                        className={
                                            'bg-tabBg rounded  text-sm  w-full left-[1px] top-1  bottom-1 absolute'
                                        }
                                    />
                                )}
                                <motion.span layout='position' className='z-20 relative'>
                                    Subscription
                                </motion.span>
                            </TabsTrigger>
                            <TabsTrigger
                                value={route_const.busniness_plan}
                                onClick={() => {
                                    navigate(`${currentRoute}/${route_const.busniness_plan}`)
                                }}
                                className={`relative whitespace-nowrap border-none px-3 ${
                                    route_const.busniness_plan === directory_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {route_const.busniness_plan === directory_subRoute && (
                                    <motion.div
                                        layoutId='directory_btn'
                                        className={
                                            'bg-tabBg rounded  text-sm  w-full left-[1px] top-1  bottom-1 absolute'
                                        }
                                    />
                                )}
                                <motion.span layout='position' className='z-20 relative'>
                                    Business plan
                                </motion.span>
                            </TabsTrigger>
                        </TabTriggersContainer>
                        {isBusinessPlanMode && (
                            <Button
                                onClick={() => setCreateBusinessPlan(true)}
                                className='text-xs min-w-[150px] p-2 min-h-fit ml-8'
                            >
                                Create Business Plan
                            </Button>
                        )}
                    </TabsList>
                    <TabsContent value={route_const.summary}>
                        <PageContent>
                            <BusinessDirectorySummary
                                loading={isOrgMembersReFetching || isOrgMembersLoading}
                                orgMembersData={orgMembersData}
                            />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={route_const.kyb}>
                        <PageContent>
                            <BusinessDirectoryCompliance org={orgMembersData?.[0]?.org} />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={route_const.subscription}>
                        <PageContent>
                            <BusinessSubscription />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={route_const.busniness_plan}>
                        <PageContent>
                            <DirectoryOrgBusinessPlan />
                        </PageContent>
                    </TabsContent>
                </Tabs>
            </section>
            {createBusinessPlan && <CreateBusinessPlan closeModal={() => setCreateBusinessPlan(false)} />}
        </>
    )
}
