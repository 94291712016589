import React from 'react'
import { ExpenseStatus } from '../const'
import { capitalizeText } from '@/utils/string'

interface ExpenseStatusBadgeProps {
    status: ExpenseStatus
    disable?: boolean
}

const ExpenseStatusBadge: React.FC<ExpenseStatusBadgeProps> = ({ status, disable = false }) => {
    let badgeColor: string

    switch (status) {
        case ExpenseStatus.approved:
            badgeColor = 'bg-[#DFF8DE] text-[#11A88D]'
            break
        case ExpenseStatus.pending:
            badgeColor = 'bg-[#F8F1DE] text-[#A89011]'
            break
        case ExpenseStatus.denied:
            badgeColor = 'bg-[#CF491E]/10 text-[#CF491E]/90'
            break
        case ExpenseStatus.partialApproval:
            badgeColor = 'bg-[#FFEFE7] text-[#FF9152]'
            break
        case ExpenseStatus.reimbursed:
            badgeColor = 'bg-[#EBF5FF] text-[#5C9BFF]'
            break
        case ExpenseStatus.disabled:
            badgeColor = 'bg-[#F9F9F9] text-[#DADCE0]'
            break
        case ExpenseStatus.closed:
        default:
            badgeColor = 'bg-[#E9D2FD]/80 text-[purple]'
            break
    }

    return (
        <div
            className={` py-1 px-4 rounded-2xl inline-flex items-center justify-center whitespace-nowrap ${
                disable ? 'bg-[#F9F9F9] text-[#DADCE0]' : badgeColor
            }`}
        >
            {capitalizeText(status === ExpenseStatus.denied ? 'Declined' : status)}
        </div>
    )
}

export default ExpenseStatusBadge
