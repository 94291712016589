import { useAuthContext } from '@/context'
import { authRouteConst } from '@/routes/auth.route'
import { generalRouteConst } from '@/routes/route.const'
import { RoleEnum } from '@/types/org'
import { Loader2 } from 'lucide-react'
import { ReactElement, ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

const PublicRouteWrapper = ({ component }: { component: ReactNode }): ReactElement => {
    const { loggedIn, role, accounts, selectedAccount } = useAuthContext()
    const location = useLocation()
    const checkoutPath = location.pathname.includes(generalRouteConst.invoiceCheckout)

    const redirectPath = () => {
        if (selectedAccount && !checkoutPath) {
            return [RoleEnum.OWNER, RoleEnum.ADMIN].includes(role?.name as RoleEnum)
                ? generalRouteConst.adminOverview
                : generalRouteConst.userOverview
        }
        if (accounts?.length > 1 && !selectedAccount && !checkoutPath) {
            return authRouteConst.chooseAccount
        }

        return location.pathname
    }

    if (loggedIn && !accounts?.length) {
        return (
            <div className='w-screen h-screen flex items-center justify-center'>
                <Loader2 size={100} className='animate-spin' />
            </div>
        )
    }

    return !loggedIn ? <>{component}</> : <Navigate to={redirectPath()} replace />
}

export default PublicRouteWrapper
