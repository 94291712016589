import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import PageContent from '@/layout/PageContent'
import { InfoIcon } from 'lucide-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import filter from '@/assets/filter.svg'
import { noop } from '@tanstack/react-table'
import EmptyStates from '@/components/EmptyStates'
import BudgetEmpty from '@/assets/budget-empty-state.svg'
import BudgetCards from './components/BudgetCards'
import CreateBudgetModal from '@/components/Modals/Budget/CreateBudgetRoot'
import { useAuthContext, useBudgetContext } from '@/context'
import BudgetRequest from '@/components/Modals/Budget/BudgetRequests'
import { useGetOrgBudget } from '@/services/budget/queries'
import ProgressBar from '@/components/ProgressBar'
import { formatAmount } from '@/utils/money'
import { debounce } from 'lodash'
import notFound from '@/assets/not-found.svg'
import { prodEnvironment } from '@/utils/utils'
import V2Pagination from '@/components/Table/V2Pagination'
import { Pagination } from '@/types/utils'
import useAccessToModule from '@/hooks/useAccessToModule'
import { ModuleNameEnum } from '@/types/billing'

const Budgets = () => {
    const [masterPopup, setMasterPopup] = useState(false)
    const [createModal, setCreateModal] = useState(false)
    const [requestsModal, setRequestsModal] = useState(false)
    const { selectedAccount, setShowUpgradeModal, setCurrentModule } = useAuthContext()
    const [searchQuery, setSearchQuery] = useState('')
    const [backspaceClicked, setBackSpaceClicked] = useState(false)
    const { pageNumber, setPaginationDetails, setPageNumberClicked, pageNumberClicked, setPageNumber } =
        useBudgetContext()

    const { enabled: isBudgetsEnabled } = useAccessToModule({ module: ModuleNameEnum.BUDGETS })

    const handleKeyDown = (event: any) => {
        if (event.key === 'Backspace') {
            setBackSpaceClicked(true)
        }
    }

    const {
        data: org_budget,
        isLoading,
        isRefetching,
        refetch: refetchAllOrgBudget,
    } = useGetOrgBudget({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            search: searchQuery,
            page: pageNumber.toString(),
        },
        onSuccess: data => {
            if (data?.pagination) {
                setPaginationDetails(data?.pagination)
            }
        },
    })

    const debouncedSearch = useRef(
        debounce(() => {
            refetchAllOrgBudget()
        }, 300)
    ).current

    useEffect(() => {
        if (backspaceClicked) {
            refetchAllOrgBudget()
            setBackSpaceClicked(false)
        }
        if (searchQuery) {
            debouncedSearch()
        }
        return () => {
            debouncedSearch.cancel()
        }
    }, [backspaceClicked, debouncedSearch, refetchAllOrgBudget, searchQuery])

    useEffect(() => {
        if (pageNumber && pageNumberClicked) {
            refetchAllOrgBudget()
            setPageNumberClicked(false)
        }
    }, [pageNumber, pageNumberClicked])

    useEffect(() => {
        if (location.pathname.includes('quick_actions')) {
            setCreateModal(true)
        }
    }, [])

    const orgBudget = useMemo(() => org_budget?.budgets ?? [], [org_budget])
    const orgBudgetSummary = useMemo(() => org_budget?.summary, [org_budget])
    const orgBudgetPagination = useMemo(() => org_budget?.pagination, [org_budget]) as Pagination

    if (isLoading) return <ProgressBar />

    const tablePaginationNo = Array.from({ length: orgBudgetPagination?.totalPages ?? 0 }, (_, idx) => idx)

    return (
        <section className='bg-white pb-[7rem] min-h-[100vh] px-2 md:px-0'>
            <PageContent>
                <div className='w-full py-6 block'>
                    <div className='border border-[#DADCE0] rounded-2xl px-6 pt-6 pb-[34px]'>
                        <div className='flex justify-between items-center'>
                            <div className='flex items-center gap-2 relative'>
                                <h1 className='text-xl font-medium'>Total Budget</h1>
                                <InfoIcon
                                    className='rotate-180 cursor-pointer'
                                    color='#838383'
                                    onClick={() => setMasterPopup(!masterPopup)}
                                />
                                {masterPopup && (
                                    <div className='absolute top-10 -left-8 lg:left-[11rem] z-[100] px-4 lg:px-6 py-4 pt-6 w-[400px] lg:w-[800px] bg-white rounded-3xl border border-[#DADCE0]'>
                                        <p className='text-[#5E5E5E] font-medium'>
                                            The Master Budget serves as a reference point for creating new budgets. It
                                            can't be deleted but can be edited. For example, if you set the Master
                                            Budget at 1 million Naira, creating a 200,000 Naira category budget deducts
                                            that amount, leaving 800,000 Naira available. The Master Budget establishes
                                            a baseline for financial planning, allowing focused budgets for categories,
                                            departments or projects.
                                        </p>
                                        <div className='flex justify-end mt-5'>
                                            <button
                                                onClick={() => setMasterPopup(false)}
                                                className='text-lg border border-transparent font-medium text-brand mr-6 hover:border hover:border-[#DADCE0] rounded-lg p-3 '
                                            >
                                                I understand
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='flex items-center gap-10 gap-y-5 mt-8 flex-wrap'>
                            <div className=''>
                                <h2 className='text-[#5E5E5E] mb-2'>Allocated</h2>
                                <p className='font-semibold text-lg'>
                                    {formatAmount(orgBudgetSummary?.total_allocated)}
                                </p>
                            </div>
                            <div className=''>
                                <h2 className='text-[#5E5E5E] mb-2'>Spent</h2>
                                <p className='text-lg'>
                                    {formatAmount(
                                        Number(orgBudgetSummary?.total_allocated) -
                                            Number(orgBudgetSummary?.total_available)
                                    )}
                                </p>
                            </div>
                            <div className=''>
                                <h2 className='text-[#5E5E5E] mb-2'>Available</h2>
                                <p className='text-lg'>{formatAmount(orgBudgetSummary?.total_available)}</p>
                            </div>
                        </div>
                    </div>

                    <div className='flex items-center justify-between gap-y-4 my-6 flex-wrap'>
                        <div className='flex items-center gap-5'>
                            <div className='border border-[#DADCE0] h-[56px] w-[200px] lg:w-[314px] rounded-lg flex items-center overflow-hidden '>
                                <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full'>
                                    <CiSearch fontSize={25} />
                                </div>
                                <input
                                    type='text'
                                    placeholder='Enter budget name'
                                    className='h-full w-[90%] outline-none'
                                    onChange={e => setSearchQuery(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                            {!prodEnvironment() && (
                                <div className='hidden'>
                                    <Select onValueChange={noop} value='all_budgets'>
                                        <SelectTrigger className='w-[8rem] lg:w-[10rem] h-[55px] border-none bg-[#F9F9F9] text-[#5E5E5E]'>
                                            <SelectValue />
                                        </SelectTrigger>
                                        <SelectContent className='w-[10rem]'>
                                            <SelectItem value='all_budgets'>All budgets</SelectItem>
                                            <SelectItem value='1 month'>Last Month</SelectItem>
                                            <SelectItem value='3 months'>Last 3 Months</SelectItem>
                                            <SelectItem value='6 months'>Last 6 Months</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>
                            )}
                        </div>
                        <div className='flex items-center gap-6'>
                            {!prodEnvironment() && (
                                <div className='hidden h-[50px] w-[50px] border border-[#DADCE0] rounded-lg  items-center justify-center p-3 cursor-pointer'>
                                    <img src={filter} alt='button' />
                                </div>
                            )}

                            <Button
                                className='min-w-full lg:min-w-[140px]'
                                onClick={() => {
                                    if (!isBudgetsEnabled) {
                                        setShowUpgradeModal(true)
                                        setCurrentModule(ModuleNameEnum.BUDGETS)
                                        return
                                    }
                                    setCreateModal(true)
                                }}
                            >
                                Create Budget
                            </Button>
                        </div>
                    </div>

                    {orgBudget.length <= 0 && searchQuery.length <= 0 && !isRefetching && (
                        <div className='mx-auto text-sm'>
                            <EmptyStates
                                description='Budgets help to monitor and control the spending activities of your users'
                                title='Create budgets and control spending'
                                image={BudgetEmpty}
                            />
                        </div>
                    )}
                    {orgBudget.length <= 0 && isRefetching ? (
                        <ProgressBar />
                    ) : (
                        orgBudget.length <= 0 &&
                        (searchQuery.length > 0 || isRefetching) && (
                            <div
                                className={`'h-[450px] flex flex-col text-center justify-center items-center pb-10 ${
                                    isRefetching ? 'opacity-30' : ''
                                }`}
                            >
                                <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                                <span className='text-xl font-bold text-[#5E5E5E] mt-3'>Sorry! No result found :(</span>
                                <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                    Oops! It seems we couldn't find any matching results for your search. Please try
                                    again with different keywords or refine your search fiter for better results.
                                </span>
                            </div>
                        )
                    )}

                    {orgBudget?.length > 0 && (
                        <div
                            className={`grid grid-cols-1 lg:grid-cols-2 gap-6 ${isRefetching ? 'opacity-50' : 'opacity-100'}`}
                        >
                            {orgBudget?.map(budget => <BudgetCards budget={budget} key={budget._id} />)}
                        </div>
                    )}
                </div>
            </PageContent>
            {createModal && <CreateBudgetModal closeModal={setCreateModal} />}
            {requestsModal && <BudgetRequest closeModal={setRequestsModal} />}
            <div className='px-5 py-3'>
                <V2Pagination
                    setCurrentPageNumber={setPageNumber}
                    paginationOption={tablePaginationNo}
                    pagination={orgBudgetPagination}
                />
            </div>
        </section>
    )
}

export default Budgets
