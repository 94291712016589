import React, { useEffect, useState } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { AiOutlineClose } from 'react-icons/ai'
import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Textarea } from '@/components'

import { EmployeeType } from '@/types/org'
import { Plus } from 'lucide-react'
import { useResolveBankMutation } from '@/services/payout/mutations'
import { BankTypes } from '@/services/payout/types'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { Minus } from 'lucide-react'
import { LoaderIcon } from 'react-hot-toast'

import { toastHandler } from '@/components/utils/Toast'
import { useMutationUpdateVendor } from '@/services/vendors/mutations'
import { Vendor } from '@/types/vendor'
import { CategoryType } from '@/types/categories'

type Props = {
    setCloseModal: React.Dispatch<React.SetStateAction<boolean>>
    orgMembers: EmployeeType[]
    categories: CategoryType[]
    allBankList: BankTypes[]
    vendor_details?: Vendor
}
type BankProps = {
    bank_code: string
    account_number: string
}

export default function EditExistingVendor({
    setCloseModal,
    categories,
    orgMembers,
    allBankList,
    vendor_details,
}: Props) {
    const defaultState = {
        name: vendor_details?.name,
        category: vendor_details?.category?.id,
        email: vendor_details?.email,
        owner: vendor_details?.owner?.id,
        description: vendor_details?.description,
        status: vendor_details?.status === 'ACTIVE' ? 'ACTIVE' : 'INACTIVE',
    }
    // console.log(vendor_details?.status)

    const [bankDetails, setBankDetails] = useState<BankProps>({
        bank_code: vendor_details?.bankAccount?.bank_code ?? '',
        account_number: vendor_details?.bankAccount?.account_number ?? '',
    })
    const [existingVendorProps, setExistingVendorProps] = useState(defaultState)
    const [showBankForm, setShowBankForm] = useState(true)
    const [errMsg, setErrMsg] = useState(false)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target

        //

        if (name === 'status') {
            setExistingVendorProps(prevState => ({
                ...prevState,
                status: value,
            }))
            return
        }
        setExistingVendorProps(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }

    const {
        mutate: confirmBankFn,
        data: resolvedBankDetails,
        isLoading: isBankResolving,
        isSuccess: isBankResolved,
    } = useResolveBankMutation({})

    const { mutate: updateVendor, isLoading } = useMutationUpdateVendor({
        onSuccess: () => {
            toastHandler({ message: 'Vendor Updated', state: 'success' })
            setCloseModal(false)
        },
    })
    const handleExistingVendor = () => {
        const { category, description, owner, name, email } = existingVendorProps
        // mutate({ ...existingVendorProps })

        if (!category || !description || !owner || !name || !email) {
            toastHandler({ message: 'Fill in the form details', state: 'error' })
            return
        }
        if (!resolvedBankDetails || !bankDetails.account_number || !bankDetails.bank_code) {
            setErrMsg(true)
            toastHandler({ message: 'Confirm your bank details', state: 'error' })
            return
        }

        updateVendor({ update: { ...existingVendorProps, ...bankDetails }, vendor_id: vendor_details?.id })
    }

    const disableButton = () => {
        const res = Object.values({ ...existingVendorProps, ...bankDetails })
            .map(value => !!value)
            .some(data => data === false)
        return res
    }

    useEffect(() => {
        const { account_number } = bankDetails
        if (account_number?.length === 10) {
            confirmBankFn({
                account_number: bankDetails.account_number,
                bank_code: bankDetails.bank_code,
                account_name: resolvedBankDetails?.account_name,
            })
            return
        }
    }, [bankDetails.account_number])

    return (
        <ModalWrapper variants={undefined}>
            <section className='w-screen lg:w-[44rem] max-h-[85vh] overflow-scroll pb-8'>
                <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-end '>
                    <div className='flex items-center w-full justify-between h-full'>
                        <h2 className='flex-1 font-semibold text-[#31254B] text-center'>Edit Vendor</h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={() => setCloseModal(false)}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>

                <section className='flex py-6 px-4 lg:p-6 mt-3 gap-[2rem]'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label className='flex items-center gap-1'>
                            Vendor name
                            <span className='text-[red]'>*</span>
                        </label>
                        <Input
                            type='text'
                            placeholder='Enter name of vendor'
                            name='name'
                            value={existingVendorProps.name}
                            onChange={handleInputChange}
                            className='border-[#DADCE0]  h-[3rem]'
                        />
                    </div>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label className='flex items-center gap-1'>
                            Category
                            <span className='text-[red]'>*</span>
                        </label>
                        <div>
                            <Select
                                name='category'
                                value={existingVendorProps.category}
                                onValueChange={data => setExistingVendorProps(prev => ({ ...prev, category: data }))}
                            >
                                <SelectTrigger className='w-full h-[3rem] border-[#DADCE0]'>
                                    <SelectValue placeholder='Select expense category' />
                                </SelectTrigger>
                                <SelectContent className='h-[17rem] overflow-scroll'>
                                    {categories?.length > 0 ? (
                                        categories.map(category => (
                                            <SelectItem key={category.id} value={category.id}>
                                                {category.label}
                                            </SelectItem>
                                        ))
                                    ) : (
                                        <>
                                            <SelectItem value=''>Please wait for a little while</SelectItem>
                                        </>
                                    )}
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                </section>
                <section className='flex px-4 lg:px-6 gap-[2rem]'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label className='flex items-center gap-1'>
                            Email address
                            <span className='text-[red]'>*</span>
                        </label>
                        <Input
                            type='email'
                            placeholder='Enter name of vendor'
                            name='email'
                            value={existingVendorProps.email}
                            onChange={handleInputChange}
                            className='border-[#DADCE0]  h-[3rem]'
                        />
                    </div>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label className='flex items-center gap-1'>
                            Vendor owner
                            <span className='text-[red]'>*</span>
                        </label>
                        <div>
                            <Select
                                name='owner'
                                value={existingVendorProps.owner}
                                onValueChange={data => setExistingVendorProps(prev => ({ ...prev, owner: data }))}
                            >
                                <SelectTrigger className='w-full h-[3rem] border-[#DADCE0]'>
                                    <SelectValue placeholder='Select Vendor owner' />
                                </SelectTrigger>
                                <SelectContent className='h-[15rem] overflow-scroll'>
                                    {orgMembers?.length > 0 ? (
                                        orgMembers.map(orgMember => (
                                            <SelectItem key={orgMember.id} value={orgMember.id}>
                                                {orgMember?.user?.first_name} {orgMember?.user?.last_name}
                                            </SelectItem>
                                        ))
                                    ) : (
                                        <>
                                            <SelectItem value=''>Please wait for a little while</SelectItem>
                                        </>
                                    )}
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='flex flex-col flex-1 gap-3 px-4 lg:px-6 mt-5'>
                        <label className='flex items-center justify-between'>
                            <span className='flex items-center gap-1'>
                                Description
                                <span className='text-[red]'>*</span>
                            </span>
                            <span className='text-xs'>
                                {existingVendorProps?.description && existingVendorProps?.description.length} / 120
                            </span>
                        </label>
                        <Textarea
                            name='description'
                            placeholder='Vendor description'
                            className='border border-[#DADCE0] pt-4 min-h-[5.6rem] text-18px resize-none'
                            value={existingVendorProps.description}
                            onChange={e => handleInputChange(e)}
                            maxLength={120}
                        />
                    </div>
                    <span className='flex items-center gap-1 mx-4 lg:mx-6 mt-4'>Set status</span>{' '}
                    <div className='flex gap-10 items-end text-sm mt-2'>
                        <label
                            htmlFor={'active'}
                            className='flex items-center gap-3 rounded-md mt-3 mx-6 cursor-pointer whitespace-nowrap first:mt-0'
                        >
                            <input
                                aria-label={'active'}
                                type='radio'
                                name={'status'}
                                id={'active'}
                                defaultChecked={vendor_details?.status === 'ACTIVE' && true}
                                value={'ACTIVE'}
                                className='accent-[#454ADE] cursor-pointer'
                                onChange={e => handleInputChange(e)}
                            />
                            Active
                        </label>
                        <label
                            htmlFor={'inactive'}
                            className='flex items-center gap-3 rounded-md mx-5 cursor-pointer whitespace-nowrap first:mt-0'
                        >
                            <input
                                aria-label={'inactive'}
                                type='radio'
                                name={'status'}
                                id={'inactive'}
                                value={'INACTIVE'}
                                defaultChecked={vendor_details?.status === 'INACTIVE' && true}
                                className='accent-[#454ADE] cursor-pointer'
                                onChange={e => handleInputChange(e)}
                            />
                            Inactive
                        </label>
                    </div>
                </section>
                <section
                    className={` mt-5 ${showBankForm ? 'bg-[#F9F9F9] px-6' : 'bg-transparent'} rounded-lg py-6 mx-6 `}
                >
                    {!showBankForm ? (
                        <div className='flex gap-4 items-center'>
                            <Button
                                icon={<Plus />}
                                className='bg-transparent text-[#202020] border-[#DADCE0] border hover:bg-transparent hover:opacity-70 px-4 text-sm'
                                onClick={() => setShowBankForm(true)}
                            >
                                Add payment details{' '}
                            </Button>

                            {errMsg && (
                                <span className='text-xs text-[#CF491E]'>Please add vendor payment details</span>
                            )}
                        </div>
                    ) : (
                        <>
                            <div className='flex justify-between items-center mb-5'>
                                <h3 className='text-lg font-medium'>Add payment details</h3>
                                <Minus
                                    className='bg-[#F2F2F2] rounded-full p-2 cursor-pointer'
                                    width={30}
                                    height={30}
                                    onClick={() => setShowBankForm(false)}
                                />
                            </div>

                            <div className='flex gap-[2rem]'>
                                <div className='w-1/2 '>
                                    <label className='font-normal pb-3 inline-block text-sm' htmlFor='acct_name'>
                                        Bank name
                                    </label>
                                    <Select
                                        name='accnt_number'
                                        onValueChange={data => setBankDetails(prev => ({ ...prev, bank_code: data }))}
                                        value={bankDetails?.bank_code}
                                    >
                                        <SelectTrigger className='w-full h-[45px] border-[#DADCE0] bg-base_gray_100 placeholder:text-[base_gray_100]  text-[14px]'>
                                            <SelectValue placeholder={'Select bank name'} className='' />
                                        </SelectTrigger>
                                        <SelectContent className='h-[17rem] overflow-scroll w-[18rem]'>
                                            {allBankList?.map(bank => (
                                                <SelectItem
                                                    value={bank.code}
                                                    key={bank.code}
                                                    className='cursor-pointer'
                                                >
                                                    {bank.name}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>
                                <div>
                                    <label className='font-normal pb-3 inline-block text-sm' htmlFor='accnt_number'>
                                        Account number
                                    </label>
                                    <Input
                                        placeholder='Enter account number'
                                        id='accnt_number'
                                        className='h-[45px] border-[#DADCE0]'
                                        type='number'
                                        value={bankDetails?.account_number as string}
                                        onChange={e => {
                                            // if (bankDetails.account_number.length > 10) return
                                            setBankDetails(prev => ({ ...prev, account_number: e.target.value }))
                                        }}
                                        maxLength={10}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {isBankResolved && (
                        <div className='flex items-center gap-2 text-[#19C37D] mt-4'>
                            <IoIosCheckmarkCircle className='' />
                            <label htmlFor='' className='text-sm font-medium'>
                                {resolvedBankDetails?.account_name}
                            </label>
                        </div>
                    )}
                    {isBankResolving && (
                        <div className='flex gap-3 text-[#838383] text-sm items-center mt-3'>
                            <span>Fetching bank name</span>
                            <LoaderIcon className='animate-spin w-4 h-4' />
                        </div>
                    )}
                </section>
                <section className='mx-6 mt-4'>
                    <Button
                        className='ml-auto'
                        onClick={handleExistingVendor}
                        loading={isLoading}
                        disabled={disableButton()}
                    >
                        Update vendor
                    </Button>
                </section>
            </section>
        </ModalWrapper>
    )
}
