import SaveSendModal from '@/components/Modals/Invoice/SaveSendModal'
import { toastHandler } from '@/components/utils/Toast'
import { FileUploadFormatProps } from '@/hooks/useSingleFileUpload'
import { useCreateInvoice, useUpdateInvoice } from '@/services/invoice/mutations'
import { Customers } from '@/types/customers'
import { Menu } from '@headlessui/react'
import moment from 'moment'
import { useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { TfiAngleDown } from 'react-icons/tfi'
import { useNavigate, useParams } from 'react-router-dom'
import { FormStateType } from '../../components/utils'

type Props = {
    parentState: FormStateType
    logoFile?: FileUploadFormatProps
    orgCustomers: Customers[]
    disable: boolean
}

export enum CreateInvoiceActions {
    SAVE_ONLY = 'SAVE_ONLY',
    SAVE_AND_SEND = 'SAVE_AND_SEND',
}

export default function SaveInvoice({ parentState, logoFile, orgCustomers, disable }: Props) {
    const navigate = useNavigate()
    const { invoice_id } = useParams()

    const { mutate: createInvoiceFn, isLoading } = useCreateInvoice({
        onSuccess() {
            navigate(-1)
            toastHandler({ message: 'Invoice created', state: 'success' })
        },
    })
    const { mutate: updateInvoiceFn, isLoading: isUpdating } = useUpdateInvoice({
        onSuccess() {
            navigate(-1)
            toastHandler({ message: 'Invoice updated', state: 'success' })
        },
        queryParams: {
            invoice_id: invoice_id as string,
        },
    })

    const handleSubmit = () => {
        const { customer, due_date, issue_date, message, item, tax, payment_method, footer } = parentState

        const payload = {
            customer,
            due_date: moment(due_date).utc(),
            issue_date: moment(issue_date).utc(),
            message,
            items: item.map(item_detials => ({
                name: item_detials?.name,
                description: item_detials?.description,
                quantity: item_detials?.quantity,
                unit_cost: item_detials?.unit_cost,
            })),
            tax: tax ?? '0',
            payment_method,
            accept_part_payment: parentState?.part_payment,
            action: CreateInvoiceActions.SAVE_ONLY,
            payout_bank_account: parentState?.account,
            footnote: footer,
        }

        if (invoice_id) {
            updateInvoiceFn(payload)
            return
        }
        createInvoiceFn(payload)
    }
    const disableBtn = () => {
        let btnState = false
        if (
            parentState.customer &&
            parentState.issue_date &&
            parentState.due_date &&
            parentState.item?.length > 0 &&
            parentState?.payment_method
        ) {
            btnState = true
        }
        return btnState
    }

    const [openModal, setOpenModal] = useState(false)
    return (
        <Menu as='div' className='relative'>
            <Menu.Button
                className={`${
                    !disableBtn() ? 'bg-[#454ade]/70 cursor-not-allowed' : 'bg-[#454ADE]'
                } text-white px-6 h-[56px] rounded-lg text-[14px] flex items-center gap-2 disabled:opacity-60`}
                disabled={isLoading || isUpdating || !disableBtn() || disable}
            >
                <span>Save invoice</span>

                {isLoading || isUpdating ? <CgSpinner className='animate-spin' /> : <TfiAngleDown />}
            </Menu.Button>
            <Menu.Items className='w-[172.56px] flex flex-col py-[13px] px-0 rounded-lg justify-center border border-[#DADCE0]  mt-2 z-10 bg-[#3A3F99] text-[#fff] absolute right-32 bottom-8'>
                <Menu.Item>
                    <span
                        className='px-4 py-2 cursor-pointer hover:bg-[#454ADE] hover:text-white'
                        onClick={handleSubmit}
                    >
                        Save only
                    </span>
                </Menu.Item>
                <Menu.Item>
                    <span
                        onClick={() => setOpenModal(true)}
                        className='px-4 py-2 cursor-pointer hover:bg-[#454ADE] hover:text-white'
                    >
                        Save and send
                    </span>
                </Menu.Item>
            </Menu.Items>
            {openModal && (
                <SaveSendModal
                    closeModal={setOpenModal}
                    parentState={parentState}
                    logoFile={logoFile}
                    orgCustomers={orgCustomers}
                />
            )}
        </Menu>
    )
}
