import { ExpenseContextProvider } from '@/context/expense'
import AdminRouteWrapper from '@/hoc/AdminRouteWrapper'
import PrivateRouteWrapper from '@/hoc/PrivateRouteWrapper'
import DashboardLayout from '@/layout/DashboardLayout'
import Budgets from '@/modules/dashboard/Budget/Budgets'
import Overview from '@/modules/dashboard/Overview/Overview'
import { Outlet, Route, Routes } from 'react-router-dom'
import ImpersonationRouteWrapper from '@/hoc/ImpersonationRouteWrapper'
import InvoiceCheckout from '@/modules/checkout/InvoiceCheckout/InvoiceCheckout'
import BusinessDirectory from '@/modules/dashboard/BusinessDirectory/BusinessDirectory'
import BusinessOrgDirectory from '@/modules/dashboard/BusinessDirectory/partials/BusinessOrgDirectory'
import OverviewDashboard from '@/modules/dashboard/Overview/OverviewDashboard'
import ImageViewr from '@/components/Imageviewer'
import { useAuthRoutes } from './auth.route'
import NotFound from '@/components/NotFound'
import { useExpensesRoute } from './expense.route'
import { useTransactionsRoute } from './transaction.route'
import { useCompanySettingsRoute, useUserSettingsRoute } from './settings.route'
import { useCompanyPayrollContractRoutes, useUserPayrollContractRoutes } from './payroll.route'
import { useBudgetRoutes } from './budget.route'
import { useUserRoutes } from './users.route'
import { usePartnerRoutes } from './partner.route'
import { useInvoiceRoutes } from './invoice.route'
import { useAccountingRoutes } from './accountting.route'
// import { useBillPaymentsRoute } from './bills.route'
import { useAccountsRoutes } from './accounts.route'
import RedirectHandler from '@/hoc/RedirectHandler'
import { useVaultRoute } from './vault.route'
import { useVendorManagementRoutes } from './vendor-management.route'

const AppRoutes = () => {
    const authRoutes = useAuthRoutes()
    const expensesRoutes = useExpensesRoute()
    const transactionsRoutes = useTransactionsRoute()
    // const billPaymentRoutes = useBillPaymentsRoute()
    const userSettingsRoutes = useUserSettingsRoute()
    const companySettingsRoutes = useCompanySettingsRoute()

    const userPayrollContractRoutes = useUserPayrollContractRoutes()
    const companyPayrollContractRoutes = useCompanyPayrollContractRoutes()
    const budgetRoutes = useBudgetRoutes()
    const userPageRoutes = useUserRoutes()
    const partnersRoutes = usePartnerRoutes()
    const invoiceRoutes = useInvoiceRoutes()
    const accountingroutes = useAccountingRoutes()
    const accountsroutes = useAccountsRoutes()
    const vaultroutes = useVaultRoute()
    const vendorManagementRoutes = useVendorManagementRoutes()

    return (
        <Routes>
            <Route path='*' element={<NotFound />} />
            {authRoutes}

            <Route path='/invoice-checkout/:invoice_id' element={<InvoiceCheckout />} />
            <Route path='/redirect' element={<RedirectHandler />} />

            {/* User route */}
            <Route path='/dashboard' element={<PrivateRouteWrapper component={<DashboardLayout />} />}>
                <Route path='view-file' element={<ImageViewr />} />
                <Route index path='overview' element={<Overview />} />
                <Route index path='overview/compliant' element={<Overview />} />
                {expensesRoutes} {/* Expense Routes */}
                {transactionsRoutes} {/* Transactions Routes */}
                {userSettingsRoutes} {/* user settings Routes */}
                {userPayrollContractRoutes} {/* payroll  Routes */}
                <Route path='budget' element={<Budgets />} />
            </Route>

            {/* ADMIN / OWNER ROUTES HERE */}
            <Route
                path='/admin/dashboard'
                element={
                    <ExpenseContextProvider>
                        <AdminRouteWrapper component={<DashboardLayout />} />
                    </ExpenseContextProvider>
                }
            >
                {expensesRoutes} {/* Expense Routes */}
                {transactionsRoutes} {/* Transactions Routes */}
                {companySettingsRoutes} {/* settings Routes */}
                {budgetRoutes} {/* budget Routes */}
                {userPageRoutes} {/* user page Routes */}
                {partnersRoutes} {/* partners Routes */}
                {companyPayrollContractRoutes} {/* payroll Routes */}
                {invoiceRoutes} {/* invoice Routes */}
                {/* {billPaymentRoutes} bill payments Routes */}
                {accountingroutes} {/* accounting Routes */}
                {accountsroutes} {/* accounts Routes */}
                {vaultroutes} {/* vault Routes */}
                {vendorManagementRoutes}
                {/*  */}
                <Route path='view-file' element={<ImageViewr />} />
                <Route index path='overview' element={<OverviewDashboard />} />
                <Route index path='overview/compliant' element={<OverviewDashboard />} />
                {/* Business Directory */}
                <Route
                    path='business-directory'
                    element={
                        <ImpersonationRouteWrapper>
                            <Outlet />
                        </ImpersonationRouteWrapper>
                    }
                ></Route>
                <Route path='business-directory' element={<BusinessDirectory />} />
                <Route path='business-directory/:subRoute' element={<BusinessDirectory />} />
                <Route
                    path='business-directory/manage_business/:user_id/:directory_subRoute'
                    element={<BusinessOrgDirectory />}
                />
            </Route>
        </Routes>
    )
}

export default AppRoutes
