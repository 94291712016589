import { ProfileEditIcon, ProfileImage } from '@/assets/assets.export'
import { Button } from '@/components'
import { useEffect, useState } from 'react'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import UserProfileEdit from '../components/UserProfileEdit'
import { TbReload } from 'react-icons/tb'
import { MdCancel } from 'react-icons/md'
import { useAuthContext } from '@/context'
import { capitalizeText } from '@/utils/string'
import { format } from 'date-fns'
import UpdateProfilePicture from '@/components/Modals/UserSettings/UpdateProfilePic/UpdateProfilePicture'
import { isAdminRoute } from '@/utils/permissions'
import RemoveProfilePicture from '@/components/Modals/Settings/RemoveProfilePicture/RemoveProfilePicture'

export default function UserProfileSettings () {
    const { selectedAccount, user } = useAuthContext()
    const [removeConfirmationModal, setRemoveConfirmationModal] = useState(false)
    const {
        first_name,
        email,
        last_name,
        address,
        phone,
        postal_code,
        date_of_birth,
        country,
        city,
        state,
        profile_photo,
    } = selectedAccount?.user || {}
    const role = capitalizeText(selectedAccount?.role.name)
    const profile = {
        first_name: { name: first_name, title: 'First name' },
        last_name: { name: last_name, title: 'Last name' },
        role: { name: role, title: 'Role' },
        email: { name: email, title: 'Email address' },
        phone_number: { name: phone ?? 'N/A', title: 'Phone number' },
        address: { name: address ?? 'N/A', title: 'Address line' },
        city: { name: city ?? 'N/A', title: 'City' },
        state: { name: state ?? 'N/A', title: 'State' },
        country: { name: country ?? 'N/A', title: 'Country' },
        postal_code: { name: postal_code ?? 'N/A', title: 'Postal code' },
        dob: { name: date_of_birth ?? 'N/A', title: 'Date of birth' },
    }

    const [editProfileDetails, setEditProfileDetails] = useState(false)
    const [updateProfilePicModal, setUpdateProfilePicModal] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [isMeRefetching, setisMeRefetching] = useState(false)

    useEffect(() => {
        if (!updateProfilePicModal || !editProfileDetails) {
            setisMeRefetching(true)

            const timeout = setTimeout(() => {
                setisMeRefetching(false)
            }, 3000)

            return () => {
                clearTimeout(timeout)
            }
        }
    }, [updateProfilePicModal, editProfileDetails])

    return (
        <main className='mr-6 mt-2'>
            <section className='mb-6'>
                <h3 className='font-medium text-xl mb-5'>Profile picture</h3>

                <div className='w-[12rem] border border-[#DADCE0] rounded-xl px-4 pt-5'>
                    <img
                        src={
                            user?.profile_photo && user?.profile_photo?.length > 0 ? user?.profile_photo : ProfileImage
                        }
                        alt='profile'
                        className=' w-full  p-2 opacity-80 border border-[#DADCE0] rounded-xl'
                    />

                    <span className='flex gap-2 mx-2 items-center mb-3 text-[#5E5E5E] justify-between mt-[10px]'>
                        <button
                            type='button'
                            className='text-xs flex items-center gap-1 cursor-pointer'
                            onClick={() => setUpdateProfilePicModal(true)}
                        >
                            <TbReload /> Update
                        </button>
                        {user?.profile_photo && user?.profile_photo?.length > 0 && (
                            <button
                                type='button'
                                className='text-[#CF491E] flex gap-1 text-xs cursor-pointer items-center'
                                onClick={() => setRemoveConfirmationModal(true)}
                            >
                                <MdCancel className='w-4 h-4' />
                                Remove
                            </button>
                        )}
                    </span>
                </div>
            </section>
            <section className='border border-[#DADCE0] rounded-xl p-4'>
                <section className='flex justify-between items-center'>
                    <h3 className='font-medium text-xl'>Personal information</h3>
                    {!editProfileDetails && (
                        <span
                            className='flex items-center gap-2 text-[#5E5E5E] border-[#DADCE0] rounded-md p-2 border px-4 cursor-pointer'
                            onClick={() => setEditProfileDetails(true)}
                        >
                            <ProfileEditIcon />
                            <button type='button'>Edit</button>
                        </span>
                    )}
                </section>
                <section className={`mt-8 ${isMeRefetching ? 'opacity-30' : ''} `}>
                    {!editProfileDetails && (
                        <div className=' mt-7'>
                            <div className='flex flex-wrap gap-x-[6rem] gap-5 mb-6'>
                                {Object.keys(profile)
                                    .slice(0, 4)
                                    .map(profile_title => (
                                        <div className='flex flex-col w-[8rem]'>
                                            <span className='text-[#838383] font-normal text-md'>
                                                {profile[profile_title as keyof typeof profile].title}
                                            </span>
                                            <span className='font-medium mt-2'>
                                                {capitalizeText(profile[profile_title as keyof typeof profile].name!)}
                                            </span>
                                        </div>
                                    ))}
                            </div>
                            <div className='flex flex-wrap  gap-x-[6rem] mr-8 mb-6'>
                                {Object.keys(profile)
                                    .slice(4, 7)
                                    .map(profile_title => (
                                        <div className='flex flex-col w-[8rem]'>
                                            <span className='text-[#838383] font-normal text-md'>
                                                {profile[profile_title as keyof typeof profile].title}
                                            </span>
                                            <span className='font-medium mt-2'>
                                                {profile[profile_title as keyof typeof profile].name}
                                            </span>
                                        </div>
                                    ))}
                            </div>
                            <div className='flex flex-wrap gap-x-[6rem]'>
                                {Object.keys(profile)
                                    .slice(7)
                                    .map(profile_title => {
                                        if (profile_title !== 'dob') {
                                            return (
                                                <div className='flex flex-col w-[8rem]'>
                                                    <span className='text-[#838383] font-normal text-md'>
                                                        {profile[profile_title as keyof typeof profile].title}
                                                    </span>
                                                    <span className='font-medium mt-2'>
                                                        {capitalizeText(
                                                            profile[profile_title as keyof typeof profile].name!
                                                        )}
                                                    </span>
                                                </div>
                                            )
                                        } else
                                            return (
                                                <div className='flex flex-col'>
                                                    <span className='text-[#838383] font-normal text-md'>
                                                        {profile.dob.title}
                                                    </span>
                                                    <span className='font-medium mt-2'>
                                                        {date_of_birth
                                                            ? format(
                                                                  new Date(date_of_birth as unknown as Date),
                                                                  'iii. LLL dd, yyyy'
                                                              )
                                                            : 'N/A'}
                                                    </span>
                                                </div>
                                            )
                                    })}
                            </div>
                            {/* <div className='mt-6 flex flex-col'>
                                <span className='text-[#838383] font-normal text-md'>{profile.dob.title}</span>
                                <span className='font-medium mt-2'>
                                    {date_of_birth
                                        ? format(new Date(date_of_birth as unknown as Date), 'iii. LLL dd, yyyy')
                                        : 'N/A'}
                                </span>
                            </div> */}
                        </div>
                    )}
                </section>

                {editProfileDetails && <UserProfileEdit setCloseEditProfile={setEditProfileDetails} />}
            </section>
            {false && (
                <section className='border border-[#DADCE0] rounded-xl p-4 mt-5'>
                    <div className='flex justify-between'>
                        <h3 className='font-medium text-xl'>KYC documents</h3>
                    </div>
                    <div>
                        <div className='mt-6 flex flex-col'>
                            <div className='text-[#838383] font-normal text-sm flex justify-between'>
                                <span className=''> ID verification</span>
                                <span className='flex gap-2 items-center text-xs'>
                                    <IoIosCheckmarkCircle className='text-[#19C37D] w-4 h-4' />
                                    Verified
                                </span>
                            </div>
                            <div className='font-normal text-md flex justify-between mt-2'>
                                <span className='font-medium'> Drivers_license_2022.pdf</span>
                                <span className='text-[#454ADE] text-xs'>View</span>
                            </div>
                        </div>
                        <div className='font-normal flex flex-col mt-5 '>
                            <span className='text-sm font-normal text-[#838383] mb-1'> ID type</span>
                            <span className='font-medium'>Driver’s License</span>
                        </div>
                        <div className='font-normal flex flex-col mt-5 '>
                            <span className='text-sm font-normal text-[#838383] mb-1'> ID number</span>
                            <span className='font-medium'>A109278172</span>
                        </div>
                    </div>
                </section>
            )}

            {isAdminRoute() && (
                <Button
                    className='bg-transparent hover:bg-transparent text-[#CF491E] mx-auto mt-8'
                    onClick={() => setOpenModal(true)}
                >
                    Deactivate account
                </Button>
            )}

            {openModal && (
                // <DeactivateEmployee
                //     employeeId={selectedAccount?.id ?? ''}
                //     setIsDeleteDialogOpen={setOpenModal}
                //     setEmployeeDetails={setEmployeeDetails}
                // />
                <></>
            )}

            {updateProfilePicModal && (
                <UpdateProfilePicture closeModal={setUpdateProfilePicModal} src={profile_photo ?? ProfileImage} />
            )}
            {removeConfirmationModal && <RemoveProfilePicture closeModal={setRemoveConfirmationModal} />}
        </main>
    )
}
