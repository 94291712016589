import { ReactNode } from 'react'

type Props = {
    children: ReactNode
}

const PageContent = ({ children }: Props) => {
    return <div className='lg:w-[93%] mx-auto '>{children}</div>
}

export default PageContent
