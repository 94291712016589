import AccountingContextProvider from '@/context/accounting'
import Accounting from '@/modules/dashboard/Accounting/Accounting'
import { Outlet, Route } from 'react-router-dom'

export const accountingRoutes = {
    baseUrl: 'accounting',
    subRoute: 'accounting/:accountingSubRoute',
}
export const useAccountingRoutes = () => {
    return (
        <Route
            path={accountingRoutes.subRoute}
            element={
                <AccountingContextProvider>
                    <Outlet />
                </AccountingContextProvider>
            }
        >
            <Route index element={<Accounting />} />
        </Route>
    )
}
