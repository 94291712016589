import { PayrollContextProvider } from '@/context/payroll'
import ContractMoreDetails from '@/modules/dashboard/Contract/components/ContractMoreDetails/ContractMoreDetails'
import Contract from '@/modules/dashboard/Contract/Contract'
import LeaveMoreDetails from '@/modules/dashboard/Payroll/components/LeaveMoreDetails/LeaveMoreDetailsPage'
import NewBenefitPages from '@/modules/dashboard/Payroll/components/NewBenefit/NewBenefitPageRoot'
import RunPayrollDetailsPage from '@/modules/dashboard/Payroll/components/RunPayrollDetailsPage'
import BenefitDetails from '@/modules/dashboard/Payroll/partials/BenefitDetails/BenefitDetails'
import { PayHistoryDetails } from '@/modules/dashboard/Payroll/partials/Payhistory/PayHistoryDetails'
import Payroll from '@/modules/dashboard/Payroll/Payroll'
import UserContract from '@/modules/dashboard/UserContract/UserContract'
import UserContractMoreDetails from '@/modules/dashboard/UserPayroll/components/MoreDetailsContracts/UserMoreDetailsContracts'
import MoreDetailsPayHistory from '@/modules/dashboard/UserPayroll/components/MoreDetailsPayHistory/MoreDetailsPayHistory'
import UserBenefitDetails from '@/modules/dashboard/UserPayroll/partials/UserBenefitDetails/UserBenefitDetails'
import UserPayroll from '@/modules/dashboard/UserPayroll/UserPayroll'
import { Outlet, Route } from 'react-router-dom'

const payrollRoutes = {
    user: {
        payrollSubRoute: 'payroll/:payroll_subRoute',
        contractSubRoute: 'contract/:contract_subRoute',
        contractDetails: 'contract/:payroll_subRoute/:contract_id',
        payHistory: 'payroll/pay_history/:pay_history_id',
        leaveDetails: 'payroll/leave/:leave_id',
        benefitDetails: 'payroll/my_benefits/:benefit_id',
    },
}

export const useUserPayrollContractRoutes = () => {
    return (
        <Route
            element={
                <PayrollContextProvider>
                    <Outlet />
                </PayrollContextProvider>
            }
        >
            <Route path={payrollRoutes.user.payrollSubRoute} element={<UserPayroll />} />
            <Route path={payrollRoutes.user.contractSubRoute} element={<UserContract />} />
            <Route path={payrollRoutes.user.contractDetails} element={<UserContractMoreDetails />} />
            <Route path={payrollRoutes.user.payHistory} element={<MoreDetailsPayHistory />} />
            <Route path={payrollRoutes.user.leaveDetails} element={<LeaveMoreDetails />} />
            <Route path={payrollRoutes.user.benefitDetails} element={<UserBenefitDetails />} />
        </Route>
    )
}

export const useCompanyPayrollContractRoutes = () => {
    return (
        <Route
            element={
                <PayrollContextProvider>
                    <Outlet />
                </PayrollContextProvider>
            }
        >
            <Route path='payroll/:payroll_subRoute' element={<Payroll />} />
            <Route path='contract/:contract_subRoute' element={<Contract />} />
            <Route path='contract/:contract_subRoute/:contract_id' element={<ContractMoreDetails />} />
            <Route path='payroll/:payroll_subRoute/:payroll_freq/:payroll_id' element={<RunPayrollDetailsPage />} />
            <Route path='payroll/:payroll_subRoute/:payroll_id' element={<PayHistoryDetails />} />
            <Route path='payroll/benefits/new_benefit' element={<NewBenefitPages />} />
            <Route path='payroll/leave/:leave_id' element={<LeaveMoreDetails />} />
            <Route path='payroll/benefits/:benefit_id' element={<BenefitDetails />} />
        </Route>
    )
}
