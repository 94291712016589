import { CancelIcon, FolderIcon } from '@/assets/assets.export'
import ModalWrapper from '../ModalWrapper'
import { useState } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { Input } from '@/components/Input'
import { Button } from '@/components/Button'
import { useCreateVaultItem, useUpdateVaultItem } from '@/services/vault/mutations'
import { VaultItem, VaultListData } from '@/services/vault/types'
import { useGetVaultItems } from '@/services/vault/queries'
import { toastHandler } from '@/components/utils/Toast'
import { capitalizeText } from '@/utils/string'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    rename?: boolean
    fileType?: string
    vault?: VaultListData
    item?: VaultItem
}

export const CreateNewFolder = ({ closeModal, rename = false, fileType, vault, item }: Props) => {
    const [formState, setFormState] = useState({
        parentFolder: '',
        name: item ? item.name : '',
    })
    const allow = [formState.parentFolder, formState.name].every(entry => entry.length > 0)

    const { mutate: updateFn, isLoading: isUpdating } = useUpdateVaultItem({
        onSuccess: () => {
            toastHandler({ message: `${capitalizeText(fileType ? 'file' : 'folder')} renamed successfully` })
            closeModal(false)
        },
    })

    const { mutate: createFn, isLoading: isCreating } = useCreateVaultItem({
        onSuccess: () => {
            toastHandler({ message: `Folder created successfully` })
            closeModal(false)
        },
    })
    const { data } = useGetVaultItems({
        queryParams: {
            vault: vault?.id as string,
            parent: vault?.id as string,
        },
        enabled: !rename,
    })

    const handleButton = () => {
        if (rename) {
            updateFn({
                name: formState.name,
                id: item?.id as string,
            })
            return
        }

        if (!rename) {
            createFn({
                name: formState.name,
                type: 'folder',
                vault: vault?.id,
                parent: formState.parentFolder,
            })
        }
    }
    return (
        <ModalWrapper variants={undefined}>
            <main className=' w-screen lg:w-[31rem]'>
                <section className='flex items-center border-b border-[#DADCE0] p-4 justify-between'>
                    <span className='flex-1 text-xl font-medium ml-5'>
                        {rename ? 'Rename' : 'Create new'} {fileType ? 'file' : 'folder'}
                    </span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <div className='px-4 lg:px-4 p-7 pt-5 overflow-y-scroll'>
                    <div className={`flex flex-col flex-1 ${rename && 'hidden'}`}>
                        <label className='mb-3' htmlFor='folder'>
                            Choose a folder to save it in
                        </label>
                        <Select
                            name='folder'
                            onValueChange={value => {
                                setFormState(prevState => ({
                                    ...prevState,
                                    parentFolder: value,
                                }))
                            }}
                            value={formState.parentFolder}
                        >
                            <SelectTrigger className=' text-base h-[3rem]  text-[#5E5E5E] border-gray3 flex '>
                                {formState?.parentFolder ? <SelectValue /> : <span>Select root folder</span>}
                            </SelectTrigger>
                            <SelectContent className='max-h-[12rem] overflow-y-scroll w-[27.5rem]'>
                                <SelectItem value={vault?.id as string} className='pl-3'>
                                    <div className='flex items-center gap-3'>
                                        <FolderIcon className={`w-4 h-4`} />
                                        <div className='flex flex-col items-start'>
                                            <h6 className='text sm'>Root Vault</h6>
                                        </div>
                                    </div>
                                </SelectItem>
                                {data
                                    ?.filter(item => item.type === 'folder')
                                    .map(num => (
                                        <SelectItem value={num?.id} className='pl-3' key={num?.id}>
                                            <div className='flex items-center gap-3'>
                                                <FolderIcon className={`w-4 h-4`} />
                                                <div className='flex flex-col items-start'>
                                                    <h6 className='text sm'>{num?.name}</h6>
                                                </div>
                                            </div>
                                        </SelectItem>
                                    ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div className={`flex flex-col flex-1 gap-3 ${rename ? 'mt-2' : 'mt-10'}`}>
                        <p className='w-full text-right -mb-10 text-sm text-gray7'>
                            {formState.name.length}/50 characters
                        </p>
                        <label className='flex items-center gap-1'>
                            {fileType ? 'File' : 'Folder'} name
                            <span className='text-[red]'>*</span>
                        </label>
                        <Input
                            type='text'
                            placeholder={`Name this ${fileType ? 'file' : 'folder'}`}
                            name='name'
                            value={formState.name}
                            onChange={e => {
                                if (e.target.value.length === 51) return
                                setFormState(prev => ({ ...prev, name: e.target.value }))
                            }}
                            className='border-[#DADCE0]  h-[3rem]'
                        />
                    </div>
                </div>
                <div className='flex items-center border-t border-[#DADCE0] p-4 justify-between'>
                    <>&nbsp;</>
                    <Button
                        disabled={rename ? formState.name.length <= 0 || isUpdating : !allow || isCreating}
                        onClick={handleButton}
                        loading={isCreating || isUpdating}
                    >
                        {rename ? 'Update' : 'Create'} {fileType ? 'File' : 'Folder'}
                    </Button>
                </div>
            </main>
        </ModalWrapper>
    )
}
