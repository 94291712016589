import { getSortString } from '@/components/Table/utils'
import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import { useAuthContext, useEmployeesContext } from '@/context'
import PageContent from '@/layout/PageContent'
import { useGetOrgMembers, useGetTeamsInOrg } from '@/services/employees/queries'
import { isDevelopmentEnvironment, isStagingEnvironment } from '@/utils/utils'
import { ColumnSort } from '@tanstack/react-table'
import { addDays } from 'date-fns'
import { motion } from 'framer-motion'
import { debounce } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import EmployeeMembers from './partials/EmployeeMembers/EmployeeMembers'
import EmployeeTeam from './partials/EmployeeTeam/EmployeeTeam'
import EmployeeTeams from './partials/EmployeeTeams/EmployeeTeams'
import Events from './partials/Events/Events'

const tabValues = {
    members: 'members',
    teams: 'teams',
    events: 'events',
}

//the key that was used for routing to the single team page from the ROuter.tsx page
const params_key = 'team_id'

export default function Employees() {
    const {
        showTeamBreadCrumbs,
        setOrgMembers,
        setTotalTeams,
        filterTeamsBy,
        setPendingOrgMembers,
        // sort,
        filterOrgMembersBy,
        filterEmployee,
        setFilterEmployee,
    } = useEmployeesContext()

    const [backspaceClicked, setBackSpaceClicked] = useState(false)
    const location = useLocation()
    const isMembersPage = location.pathname.includes('/employees/members')

    const params = useParams()

    const handleKeyDown = (event: any) => {
        if (event.key === 'Backspace') {
            setBackSpaceClicked(true)
        }
    }

    const { employee_subRoute } = useParams()
    const navigate = useNavigate()

    const { selectedAccount } = useAuthContext()

    const {
        refetch: refetchOrgMembers,
        isFetching: isOrgMembersFetching,
        isRefetching: isOrgMembersReFetching,
        isLoading: isOrgMembersLoading,
    } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            // status: `ACTIVE`,
            sort: getSortString(filterOrgMembersBy?.bySort as ColumnSort),
            search: filterOrgMembersBy?.bySearch ? filterOrgMembersBy?.bySearch : '',
            role: filterEmployee?.role,
            start_date: new Date(
                filterEmployee?.period?.from ?? filterEmployee?.period?.time_in_words
            ) as unknown as string,
            end_date: (filterEmployee?.period?.to ?? addDays(new Date(), 2)) as unknown as string,
        },
        onSuccess: e => {
            // setOrgMembers(e.filter(acct => acct.status === 'ACTIVE'))
            setOrgMembers(e)
            setPendingOrgMembers(e.filter(acct => acct.status === 'INACTIVE'))
            setFilterEmployee(prev => ({ ...prev, filter_now: false }))
        },
    })

    const {
        refetch: refetchTeams,
        isFetching: isOrgTeamsFetching,
        isLoading: isOrgTeamsLoading,
        isRefetching: isOrgTeamsReFetching,
    } = useGetTeamsInOrg({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            sort: getSortString(filterTeamsBy?.bySort as ColumnSort),
            // search: filterTeamsBy?.bySearch,
            search: filterTeamsBy?.bySearch && filterTeamsBy.bySearch.length > 0 ? filterTeamsBy.bySearch : undefined,
        },
        onSuccess: e => {
            setTotalTeams(e)
        },
    })

    const debouncedOrgMemberSearch = useRef(
        debounce(() => {
            refetchOrgMembers()
        }, 300)
    ).current
    useEffect(() => {
        if (filterOrgMembersBy?.bySearch) {
            debouncedOrgMemberSearch()
        }
        return () => {
            debouncedOrgMemberSearch.cancel()
        }
    }, [filterOrgMembersBy?.bySearch])

    useEffect(() => {
        if (filterOrgMembersBy?.bySort) {
            refetchOrgMembers()
        }
    }, [filterOrgMembersBy?.bySort])

    //=======making a query search after every 3 seconds provided there's a searched query======//
    const debouncedSearch = useRef(
        debounce(() => {
            refetchTeams()
        }, 300)
    ).current
    useEffect(() => {
        if (filterTeamsBy?.bySearch) {
            debouncedSearch()
        }
        return () => {
            debouncedSearch.cancel()
        }
    }, [filterTeamsBy?.bySearch])

    useEffect(() => {
        if (filterTeamsBy?.bySort) {
            refetchTeams()
        }
    }, [filterTeamsBy?.bySort])

    useEffect(() => {
        if (true && filterTeamsBy?.bySearch?.length === 0) {
            refetchTeams()
            setBackSpaceClicked(false)
        }
    }, [refetchTeams, filterTeamsBy?.bySearch])

    useEffect(() => {
        if (backspaceClicked && filterOrgMembersBy?.bySearch?.length === 0) {
            refetchOrgMembers()
            setBackSpaceClicked(false)
        }
    }, [filterOrgMembersBy?.bySearch, refetchOrgMembers])
    //=====refetches the org members when the advanced filter is used
    useEffect(() => {
        if (backspaceClicked) {
            refetchOrgMembers()
            setBackSpaceClicked(false)
        }
        if (filterEmployee.filter_now || isMembersPage) {
            refetchOrgMembers()
        }
    }, [filterEmployee.filter_now, refetchOrgMembers, backspaceClicked, isMembersPage])

    return (
        <section className='h-full mt-6'>
            {/* <PageContent> */}
            {showTeamBreadCrumbs || params[params_key] ? (
                <EmployeeTeam />
            ) : (
                <Tabs defaultValue={employee_subRoute} className='overflow-scroll' value={employee_subRoute}>
                    <TabsList className='border-none'>
                        <TabTriggersContainer>
                            <TabsTrigger
                                value='members'
                                onClick={() => navigate(`/admin/dashboard/employees/${tabValues.members}`)}
                                className={`relative border-none ${
                                    tabValues.members === employee_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {tabValues.members === employee_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                    Members
                                </motion.span>
                            </TabsTrigger>
                            <TabsTrigger
                                value='teams'
                                onClick={() => navigate(`/admin/dashboard/employees/${tabValues.teams}`)}
                                className={`relative border-none ${
                                    tabValues.teams === employee_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {tabValues.teams === employee_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-1 bottom-1.5'>
                                    Teams
                                </motion.span>
                            </TabsTrigger>
                            <TabsTrigger
                                value={tabValues.events}
                                onClick={() => navigate(`/admin/dashboard/employees/${tabValues.events}`)}
                                className={`relative border-none ${
                                    tabValues.events === employee_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {tabValues.events === employee_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-1 bottom-1.5'>
                                    Events
                                </motion.span>
                            </TabsTrigger>
                        </TabTriggersContainer>
                    </TabsList>
                    <TabsContent value={tabValues.members}>
                        <PageContent>
                            <EmployeeMembers
                                onKeyDown={handleKeyDown}
                                isLoading={isOrgMembersLoading}
                                isRefetching={isOrgMembersReFetching || isOrgMembersFetching}
                            />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={tabValues.teams}>
                        <PageContent>
                            <EmployeeTeams
                                onKeyDown={handleKeyDown}
                                isLoading={isOrgTeamsFetching || isOrgTeamsLoading || isOrgTeamsReFetching}
                            />
                        </PageContent>
                    </TabsContent>
                    {(isStagingEnvironment() || isDevelopmentEnvironment()) && (
                        <TabsContent value={tabValues.events}>
                            <PageContent>
                                <Events />
                            </PageContent>
                        </TabsContent>
                    )}
                </Tabs>
            )}
            {/* </PageContent> */}
        </section>
    )
}
