import Table from '@/components/Table'
// import CheckBox from '@/components/Table/CheckBox'
import TableActions from '@/components/Table/TableActions'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import filter from '@/assets/filter.svg'
// import downArrow from '@/assets/down-arrow.svg'
import { ColumnSort } from '@/types/utils'
import { usePayrollContext } from '@/context'
import { OrgContractPayHistory, ContractHistory } from '@/types/payroll'
import { isAdminRoute } from '../../../../../../utils/permissions'
import TransactionStatusBadge from '@/modules/dashboard/Transactions/components/TransactionStatusBadge'
import PayHistoryEmptyState from '@/assets/pay-history-empty-state.svg'

type Props = { contractHistory: OrgContractPayHistory }

export default function OrgContractTable({ contractHistory }: Props) {
    const navigate = useNavigate()
    const columnHelper = createColumnHelper<ContractHistory>()
    const { setSort } = usePayrollContext()
    const [rowSelection, setRowSelection] = React.useState({})
    const [pageNumber, setPageNumber] = useState(1)

    const handleMoreDetails = (data: ContractHistory) => {
        if (!isAdminRoute()) return
        navigate(`/admin/dashboard/contract/contract_list/${data?.contract?.id}`)
    }
    const handleRowClick = (data: ContractHistory) => {
        if (!isAdminRoute()) return
        navigate(`/admin/dashboard/contract/contract_list/${data?.contract?.id}`)
    }

    const handleSort = (sort: ColumnSort) => {
        setSort?.(sort)
    }

    const checkIfOnContractDetailsPage = () => window.location.pathname.includes('contract_list')

    const columns: ColumnDef<ContractHistory, any>[] = [
        // columnHelper.display({
        //     id: 'select',
        //     header: ({ table }) => (
        //         <CheckBox
        //             {...{
        //                 checked: table?.getIsAllRowsSelected(),
        //                 indeterminate: table?.getIsSomeRowsSelected(),
        //                 onChange: table?.getToggleAllRowsSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     cell: ({ row }) => (
        //         <CheckBox
        //             {...{
        //                 checked: row.getIsSelected(),
        //                 disabled: !row.getCanSelect(),
        //                 indeterminate: row.getIsSomeSelected(),
        //                 onChange: row.getToggleSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     enableSorting: false,
        // }),
        columnHelper.accessor('contract.contract_title', {
            header: () => <p>Contract Title</p>,
            cell: cell => <>{capitalizeText(cell.renderValue())}</>,
            enableSorting: false,
        }),
        columnHelper.accessor('milestone.title', {
            header: () => <>Milestone Title</>,
            cell: cell => {
                return <div>{cell.renderValue()}</div>
            },
            enableSorting: true,
        }),

        columnHelper.accessor('createdAt', {
            header: () => <p>Pay day</p>,
            cell: cell => <span>{cell.renderValue() && format(new Date(cell.renderValue()), 'dd/MM/yyyy')}</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('milestone.amount', {
            header: () => <p>Pay out</p>,
            cell: cell => <span>{formatAmount(cell.renderValue())}</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('transaction', {
            header: () => <>Transaction Status</>,
            cell: cell => {
                return <TransactionStatusBadge status={cell.renderValue()?.status?.toLowerCase()} />
            },
            enableSorting: true,
        }),

        columnHelper.display({
            id: 'actions',
            header: () => (
                <div className='flex items-center gap-2'>
                    <div className='h-[40px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-2 cursor-pointer'>
                        <img src={filter} alt='button' />
                    </div>
                    {/* <div className='h-[40px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-2 cursor-pointer'>
                        <img src={downArrow} alt='button' />
                    </div> */}
                </div>
            ),
            cell: row => {
                return checkIfOnContractDetailsPage() ? null : (
                    <TableActions
                        actions={[
                            {
                                label: 'More details',
                                method: () => {
                                    handleMoreDetails(row?.row.original)
                                },
                            },
                        ]}
                    />
                )
            },
        }),
    ]

    return (
        <section className='h-full'>
            <Table
                data={contractHistory?.history}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleRowClicked={handleRowClick}
                pagination_data={contractHistory?.pagination}
                currentPageNumber={pageNumber}
                setCurrentPageNumber={setPageNumber}
                rowClickable={isAdminRoute() ? true : false}
                handleSort={handleSort}
                emptyStateDescription='Monitor payments that are being paid to your bank account'
                emptyStateTitle='No Payment made!'
                emptyStateImg={PayHistoryEmptyState}
            />
        </section>
    )
}
