import { DedicatedBankAccountType } from './accounts'
import { Customers } from './customers'
import { OrgType, UserOrgAccount } from './org'

export enum InvoiceStatus {
    PAID = 'PAID',
    PARTLY_PAID = 'PARTLY PAID',
    UNPAID = 'UNPAID',
    OVERDUE = 'OVERDUE',
    PENDING = 'PENDING',
    DRAFT = 'DRAFT',
}
export type AddItemProps = {
    quantity: string | number
    description: string
    unit_cost: string | number
    total_cost?: string | number
    uniq_id?: string
    _id?: string
    id?: string
    name: string
    account?: string
}

export type Invoice = {
    _id: string

    id: string
    org: OrgType
    issuer: UserOrgAccount
    customer: Customers
    issue_date: Date
    createdAt: Date
    due_date: Date
    total_amount: string
    tax: string
    items: {
        unit_cost: string
        name: string
        quantity: number
        _id: string
        description?: string
    }[]
    receipt_url?: string
    status: InvoiceStatus
    message: string
    invoice_id: string
    payment_method?: string
    accept_part_payment: boolean
    logo?: string
    invoice_attachment_url: string
    metadata?: {
        payment_method: string
        amount: string
        payment_date: string
        createdAt: string
        updatedAt: string
        id: string
    }
    payout_bank_account: DedicatedBankAccountType
    paid: { $numberDecimal: string }
    footer?: string
}
export type InvoiceSummary = {
    _id: number | null
    total_payments_received: string
    total_invoices: number
    paid: number
    pending: number
    unpaid: number
    drafts: number
}
