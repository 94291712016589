import React from 'react'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import TableActions from '@/components/Table/TableActions'
import Table from '@/components/Table'
// import CheckBox from '@/components/Table/CheckBox'
// import downArrow from '@/assets/down-arrow.svg'
import filter from '@/assets/filter.svg'

import { capitalizeText } from '@/utils/string'
import { useNavigate } from 'react-router-dom'
import ExpenseStatusBadge from '../../Expenses/components/ExpenseStatus'
import { ExpenseStatus } from '../../Expenses/const'
import { differenceInDays, format, isDate } from 'date-fns'
import { usePayrollContext } from '@/context'
import { UserTypeLeave } from '@/types/payroll'

interface LeaveTableProps {
    leaveRequests: UserTypeLeave[]
    setIsDeleteDialogOpenForUser?: (userId: string) => void
}

const UserLeaveTable = ({ leaveRequests }: LeaveTableProps) => {
    const { setSelectedLeaveData } = usePayrollContext()

    const resultObject: any = {}

    leaveRequests.forEach(obj => {
        resultObject[obj.id] = {
            start_date: obj.start_date,
            end_date: obj.end_date,
        }
    })
    const navigate = useNavigate()

    const [rowSelection, setRowSelection] = React.useState({})

    const handleMoreDetails = (leave: UserTypeLeave) => {
        setSelectedLeaveData(leave)
        navigate(`/dashboard/payroll/leave/${leave?.id}`)
    }

    const columnHelper = createColumnHelper<UserTypeLeave>()

    //=====the getrow model for the table caused too many renders and that's why i opted for this solution=====
    // const selectedLeave = useMemo(() => {
    //     const selectedRow = Object.keys(rowSelection)
    //     return leaveRequests.filter((_, idx) => selectedRow.includes(String(idx)))
    // }, [rowSelection])

    const columns: ColumnDef<UserTypeLeave, any>[] = [
        // columnHelper.display({
        //     id: 'select',
        //     header: ({ table }) => {
        //         return (
        //             <CheckBox
        //                 {...{
        //                     checked: table.getIsAllRowsSelected(),
        //                     indeterminate: table.getIsSomeRowsSelected(),
        //                     onChange: table.getToggleAllRowsSelectedHandler(),
        //                 }}
        //             />
        //         )
        //     },
        //     cell: ({ row }) => {
        //         return (
        //             <CheckBox
        //                 {...{
        //                     checked: row.getIsSelected(),
        //                     disabled: !row.getCanSelect(),
        //                     indeterminate: row.getIsSomeSelected(),
        //                     onChange: row.getToggleSelectedHandler(),
        //                 }}
        //             />
        //         )
        //     },
        //     enableSorting: false,
        // }),
        columnHelper.accessor('leave_type', {
            header: () => <>Type</>,
            cell: cell => capitalizeText(cell.renderValue() as string),
            enableSorting: true,
        }),
        columnHelper.accessor('start_date', {
            header: () => <>Leave period</>,
            cell: cell => {
                return (
                    isDate(new Date(cell.renderValue())) && (
                        <div>
                            {format(new Date(cell.renderValue()), 'dd/MM/yyyy')} -{' '}
                            {format(new Date(cell.row.original?.end_date), 'dd/MM/yyyy')}
                            <p className='mt-1 text-[#5E5E5E]'>
                                {differenceInDays(new Date(cell.row.original?.end_date), new Date(cell.renderValue()))}{' '}
                                days
                            </p>
                        </div>
                    )
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('reason', {
            header: () => <p>Description</p>,
            cell: cell => (
                <span className={`max-w-[13rem] block truncate`}>
                    {cell.renderValue()?.length > 0 ? cell.renderValue() : 'N/A'}
                </span>
            ),
            enableSorting: false,
        }),
        columnHelper.accessor('status', {
            header: () => <>Status</>,
            cell: cell => {
                // console.log(cell.row.)
                if (cell.renderValue() === 'declined') {
                    return <ExpenseStatusBadge status={ExpenseStatus.denied} />
                }
                return <ExpenseStatusBadge status={cell.renderValue()} />
            },
            enableSorting: true,
        }),
        columnHelper.display({
            id: 'actions',
            header: () => (
                <div className='flex items-center gap-2'>
                    <div className='h-[40px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-2 cursor-pointer'>
                        <img src={filter} alt='button' />
                    </div>
                    {/* <div className='h-[40px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-2 cursor-pointer'>
                        <img src={downArrow} alt='button' />
                    </div> */}
                </div>
            ),
            cell: row => {
                return (
                    <TableActions
                        actions={[
                            {
                                label: 'More details',
                                method: () => {
                                    handleMoreDetails(row?.row.original)
                                },
                            },
                        ]}
                    />
                )
            },
        }),
    ]

    const handleRowClick = (leave: UserTypeLeave) => {
        setSelectedLeaveData(leave)
        navigate(`/dashboard/payroll/leave/${leave?.id}`)
    }

    return (
        <main className='mt-10'>
            <Table
                data={leaveRequests}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleRowClicked={handleRowClick}
                rowClickable={true}
            />
        </main>
    )
}

export default UserLeaveTable
