import { Button } from '@/components'
import { useAuthContext } from '@/context'
import { ExpenseType } from '@/types/expense'
import React, { ReactElement } from 'react'
import { ExpenseStatus } from '../const'

type Props = {
    component: ReactElement
    expense: ExpenseType
}

export default function WithAuthorizeExpensePermission({ component, expense }: Props) {
    const checkIfOnUserView = window.location.pathname.includes('admin/dashboard/expenses/expense_list')

    const { selectedAccount } = useAuthContext()

    if (!checkIfOnUserView) return
    if (expense?.status === ExpenseStatus.reimbursed) return component

    if (expense?.approvers?.length == 0 && expense.status === ExpenseStatus.approved) return component

    if (!expense?.approvers?.some(approve => approve?.account?.id === selectedAccount?.id)) {
        return <Button disabled> Cannot authorize this expense</Button>
    }
    return component
}
