import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import PageContent from '@/layout/PageContent'
import { useNavigate, useParams } from 'react-router-dom'
import PayHistory from './partials/PayHistory/PayHistory'
import UserLeaveManagement from './partials/LeaveManagement/UserLeaveManagement'
import PaymentProfile from './partials/PaymentProfile/PaymentProfile'
import UserBenefits from './partials/Benefits/UserBenefits'
import { motion } from 'framer-motion'

const payroll_routes_const = {
    pay_history: 'pay_history',
    my_benefits: 'my_benefits',
    my_contracts: 'my_contracts',
    leave: 'leave',
    analytics: 'analytics',
    profile: 'profile',
}
export default function UserPayroll() {
    const { payroll_subRoute } = useParams()
    const navigate = useNavigate()

    return (
        <section className='h-full mt-6 px-2 md:px-0'>
            <section className=' pb-[7rem]'>
                <Tabs defaultValue={payroll_subRoute} value={payroll_subRoute}>
                    <TabsList className='border-none'>
                        <TabTriggersContainer>
                            <TabsTrigger
                                value={payroll_routes_const.pay_history}
                                onClick={() => navigate(`/dashboard/payroll/${payroll_routes_const.pay_history}`)}
                                className={`relative border-none ${
                                    payroll_routes_const.pay_history === payroll_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {payroll_routes_const.pay_history === payroll_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                    History
                                </motion.span>
                            </TabsTrigger>
                            <TabsTrigger
                                value={payroll_routes_const.profile}
                                onClick={() => navigate(`/dashboard/payroll/${payroll_routes_const.profile}`)}
                                className={`relative border-none ${
                                    payroll_routes_const.profile === payroll_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {payroll_routes_const.profile === payroll_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                    Profile
                                </motion.span>
                            </TabsTrigger>
                            {import.meta.env.VITE_APP_ENV !== 'production' && (
                                <TabsTrigger
                                    value={payroll_routes_const.my_benefits}
                                    onClick={() => navigate(`/dashboard/payroll/${payroll_routes_const.my_benefits}`)}
                                    className={`relative border-none ${
                                        payroll_routes_const.my_benefits === payroll_subRoute ? '!text-white' : ''
                                    } text-sm`}
                                >
                                    {payroll_routes_const.my_benefits === payroll_subRoute && (
                                        <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                    )}
                                    <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                        Benefits
                                    </motion.span>
                                </TabsTrigger>
                            )}
                            <TabsTrigger
                                value={payroll_routes_const.leave}
                                onClick={() => navigate(`/dashboard/payroll/${payroll_routes_const.leave}`)}
                                className={`relative border-none ${
                                    payroll_routes_const.leave === payroll_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {payroll_routes_const.leave === payroll_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                    Leave
                                </motion.span>
                            </TabsTrigger>
                        </TabTriggersContainer>
                    </TabsList>

                    <TabsContent value={payroll_routes_const.pay_history}>
                        <PageContent>
                            <PayHistory />
                        </PageContent>
                    </TabsContent>

                    <TabsContent value={payroll_routes_const.profile}>
                        <PageContent>
                            <PaymentProfile />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={payroll_routes_const.my_benefits}>
                        <PageContent>
                            <UserBenefits />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={payroll_routes_const.leave}>
                        <PageContent>
                            <UserLeaveManagement />
                        </PageContent>
                    </TabsContent>

                    {/* <TabsContent value={payroll_routes_const.analytics}>
                        <PageContent>Analytics</PageContent>
                    </TabsContent>                     */}
                </Tabs>
            </section>
        </section>
    )
}
