import usePersistedState from '@/hooks/usePersistedState'
import { queryClient } from '@/queryClient'
import { useQueryMe } from '@/services/auth/queries'
import { useGetOrgPlans } from '@/services/billing/queries'
import { Billing, ModuleNameEnum } from '@/types/billing'
import { RoleEnum, UserOrgAccount } from '@/types/org'
import { RoleType } from '@/types/roles'
import { User } from '@/types/user'
import { Dispatch, ReactNode, SetStateAction, createContext, useCallback, useEffect, useState } from 'react'

export type AuthContextDefaultTypes = {
    user: User | undefined
    setUser: Dispatch<SetStateAction<User>>
    loggedIn: boolean
    setLoggedIn: Dispatch<SetStateAction<boolean>>
    accounts: UserOrgAccount[]
    setAccounts: Dispatch<SetStateAction<UserOrgAccount[]>>
    selectedAccount: UserOrgAccount | undefined
    setSelectedAccount: Dispatch<SetStateAction<UserOrgAccount | undefined>>
    role: RoleType | undefined
    viewMode: RoleEnum
    loginUser: (arg: { user: User; accounts: UserOrgAccount[]; selectedAccount: UserOrgAccount | null }) => void
    logoutUser: () => void
    setShowKYCModalOnce: Dispatch<SetStateAction<boolean>>
    showKYCModalOnce: boolean
    setShowUpgradeModal: Dispatch<SetStateAction<boolean>>
    showUpgradeModal: boolean
    setBankDetails: Dispatch<SetStateAction<boolean>>
    bankDetails: boolean
    setSignUpStep: Dispatch<SetStateAction<number>>
    signUpStep: number
    orgPlans?: Billing[]
    currentModule?: ModuleNameEnum
    setCurrentModule: Dispatch<SetStateAction<ModuleNameEnum | undefined>>
    redirectUrl: string
    setRedirectURL: Dispatch<SetStateAction<string>>
}

const defaultValues: AuthContextDefaultTypes = {
    user: undefined,
    loggedIn: false,
    setLoggedIn: () => undefined,
    setUser: () => undefined,
    accounts: [],
    setAccounts: () => undefined,
    selectedAccount: undefined,
    role: undefined,
    viewMode: RoleEnum.EMPLOYEE,
    logoutUser: () => undefined,
    loginUser: () => undefined,
    setShowKYCModalOnce: () => undefined,
    showKYCModalOnce: false,
    setShowUpgradeModal: () => undefined,
    showUpgradeModal: false,
    setSelectedAccount: () => undefined,
    signUpStep: 1,
    setSignUpStep: () => undefined,
    setBankDetails: () => undefined,
    bankDetails: false,
    orgPlans: undefined,
    currentModule: undefined,
    setCurrentModule: () => undefined,
    redirectUrl: '',
    setRedirectURL: () => undefined,
}

export const AuthContext = createContext(defaultValues)

export default function AuthContextProvider({ children }: { children: ReactNode }) {
    const [loggedIn, setLoggedIn] = usePersistedState({ key: 'loggedIn', defaultValue: false })
    const [user, setUser] = usePersistedState({ key: 'user', defaultValue: undefined })
    const [accounts, setAccounts] = usePersistedState({ key: 'accounts', defaultValue: undefined })
    const [selectedAccount, setSelectedAccount] = usePersistedState({ key: 'selectedAccount', defaultValue: undefined })
    const [showKYCModalOnce, setShowKYCModalOnce] = useState(false)
    const [showUpgradeModal, setShowUpgradeModal] = useState(false)
    const [currentModule, setCurrentModule] = useState<ModuleNameEnum>()
    const [bankDetails, setBankDetails] = useState(false)
    const [redirectUrl, setRedirectURL] = useState('')

    const [signUpStep, setSignUpStep] = useState(1)

    const { data, isLoading } = useQueryMe({ enabled: !!loggedIn })

    const { data: orgPlans } = useGetOrgPlans({
        enabled: !!loggedIn,
    })

    const role = selectedAccount?.role as UserOrgAccount['role']
    const viewMode = role?.name as RoleEnum

    const logoutUser = useCallback(() => {
        setLoggedIn(false)
        setUser(null)
        setAccounts(null)
        setSelectedAccount(null)
        queryClient.removeQueries(['/me'])
    }, [setAccounts, setLoggedIn, setSelectedAccount, setUser])

    const loginUser = useCallback(
        ({ user, accounts }: { user: User; accounts: UserOrgAccount[] }) => {
            setUser(user)
            setAccounts(accounts)

            if (accounts.length === 1) {
                setSelectedAccount(accounts[0])
            }
        },
        [setUser, setAccounts, selectedAccount, setSelectedAccount]
    )

    useEffect(() => {
        if (!isLoading) {
            if (data) {
                loginUser({
                    user: data.user,
                    accounts: data.accounts,
                })

                if (!selectedAccount) return
                setSelectedAccount(() => data?.accounts?.find(account => selectedAccount?.id === account.id))
            } else {
                logoutUser()
            }
        }
    }, [data, setUser, isLoading, setLoggedIn, accounts, setAccounts, setSelectedAccount, loginUser, logoutUser])

    return (
        <AuthContext.Provider
            value={{
                loggedIn,
                user,
                setUser,
                setLoggedIn,
                accounts,
                setAccounts,
                selectedAccount,
                role,
                viewMode,
                loginUser,
                logoutUser,
                setShowKYCModalOnce,
                showKYCModalOnce,
                setSelectedAccount,
                signUpStep,
                setSignUpStep,
                bankDetails,
                setBankDetails,
                showUpgradeModal,
                setShowUpgradeModal,
                orgPlans,
                currentModule,
                setCurrentModule,
                redirectUrl,
                setRedirectURL,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
