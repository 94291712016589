import Table from '@/components/Table'
import TableActions from '@/components/Table/TableActions'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
// import downArrow from '@/assets/down-arrow.svg'
import filter from '@/assets/filter.svg'
import React from 'react'
// import CheckBox from '@/components/Table/CheckBox'
import BillEmptyState from '@/assets/BillEmptyState.svg'
import FilterVendors from '@/components/Modals/Vendors/AdvancedFilter'
import { useBillPaymentContext } from '@/context'
import { BillType } from '@/services/bill-payment/types'
import { Pagination } from '@/types/utils'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import BillStatusBadge from './BillStatusBadge'

interface BillPaymentListTableProps {
    bills: BillType[]
    pagination_data: Pagination
}

const BillPaymentListTable = ({ bills, pagination_data }: BillPaymentListTableProps) => {
    const navigate = useNavigate()

    const columnHelper = createColumnHelper<BillType>()
    const [rowSelection, setRowSelection] = React.useState({})

    const [filterBillPaymentModal, setFilterBillPaymentModal] = React.useState(false)

    const { setSelectedBillRequest, pageNumber, setPageNumberClicked, setPageNumber } = useBillPaymentContext()

    //context api to sort the table
    const columns: ColumnDef<BillType, any>[] = [
        columnHelper.accessor('bill_id', {
            header: () => {
                return <p className='whitespace-nowrap text-[95%] '>Bill ID</p>
            },
            cell: cell => <>{cell.renderValue()}</>,
            enableSorting: false,
        }),
        columnHelper.accessor('vendor.name', {
            header: () => <p className='whitespace-nowrap text-[95%]'>Vendor</p>,
            cell: cell => (
                <div>
                    <p className='font-normal mb-1 text-sm'>{capitalizeText(cell.renderValue()) ?? 'N/A'}</p>
                </div>
            ),
            enableSorting: false,
        }),
        columnHelper.accessor('amount', {
            header: () => <p>Amount</p>,
            cell: cell => {
                return <div>{formatAmount(cell.renderValue())}</div>
            },
            enableSorting: true,
        }),
        columnHelper.accessor('due_date', {
            header: () => <p>Due date</p>,
            cell: cell => {
                return <>{cell.renderValue() ? format(new Date(cell.renderValue()), 'dd/MM/yyyy') : 'N/A'}</>
            },
            enableSorting: false,
        }),
        columnHelper.accessor('_id', {
            header: () => <p>Type</p>,
            cell: () => {
                return <>Invoice bill</>
            },
            enableSorting: false,
        }),
        columnHelper.accessor('status', {
            header: () => <p>Status</p>,
            cell: cell => {
                return (
                    <>
                        <BillStatusBadge status={cell.renderValue()} allowBg={true} />
                    </>
                )
            },
            enableSorting: false,
        }),
        columnHelper.display({
            id: 'actions',
            header: () => (
                <div className='flex items-center gap-2'>
                    <div
                        className='h-[40px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-2 cursor-pointer'
                        onClick={() => setFilterBillPaymentModal(true)}
                    >
                        <img src={filter} alt='button' />
                    </div>
                    {/* <div
                        className='h-[40px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-2 cursor-pointer'
                        // onClick={() => {
                        //     downloadTableFileFn({ expenses })
                        // }}
                    >
                        <img src={downArrow} alt='button' />
                    </div> */}
                </div>
            ),

            cell: cell => (
                <TableActions
                    actions={[
                        {
                            label: 'More details',
                            method: () => {
                                setSelectedBillRequest(cell?.row?.original)
                                navigate(
                                    `/admin/dashboard/vendor_management/bill_payment/lists/${cell.row.original._id}`
                                )
                            },
                        },
                    ]}
                />
            ),
        }),
    ]
    const handleRowClick = (request: BillType) => {
        setSelectedBillRequest(request)
        navigate(`/admin/dashboard/vendor_management/bill_payment/lists/${request?._id}`)
    }

    return (
        <div>
            <Table
                data={bills}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                pagination_data={pagination_data}
                currentPageNumber={pageNumber}
                setCurrentPageNumber={setPageNumber}
                setIsPageNumberClicked={setPageNumberClicked}
                handleRowClicked={handleRowClick}
                rowClickable={true}
                emptyStateImg={BillEmptyState}
                emptyStateTitle='Streamlined Management of Invoices and Utility Bills'
                emptyStateDescription={`Simplify your bill Processing - handle invoices and utility payments with ease. Click on the 'Create Bill' button at the top right corner to get started`}
            />

            {filterBillPaymentModal && <FilterVendors closeModal={setFilterBillPaymentModal} />}
        </div>
    )
}

export default BillPaymentListTable
