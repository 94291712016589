import React from 'react'
import BenefitSummaryCard from '../../components/BenefitSummaryCard'
import ProgressBar from '@/components/ProgressBar'
import { OrgBenefitProps } from '@/types/benefits'

type Props = {
    benefits: OrgBenefitProps[]
    loading: any
}

export default function ActiveBenefits({ benefits, loading }: Props) {
    if (loading) return <ProgressBar />

    return (
        <section className='mt-10 flex flex-col gap-6'>
            {benefits.map(benefit => (
                <BenefitSummaryCard benefit={benefit} />
            ))}
        </section>
    )
}
