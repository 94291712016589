import { DocumentIcon } from '@/assets/assets.export'
import { Clock } from 'lucide-react'
import DocumentCategoryBadge from '../DocumentCategoryBadge'
import { DocumentCategory } from '@/types/vault'
import doc from '@/assets/v2dashboard/doc-img.png'
import { FaEllipsisVertical } from 'react-icons/fa6'
import { Menu } from '@headlessui/react'
import { PenLine } from 'lucide-react'
import { FileDown } from 'lucide-react'
import { BsSend } from 'react-icons/bs'
import { HiOutlineDocumentDuplicate } from 'react-icons/hi2'
import { MdOutlineMoveToInbox } from 'react-icons/md'
import { Trash2 } from 'lucide-react'
import { useMemo, useState } from 'react'
import { MoveFileModal } from '@/components/Modals/Vault/MoveFileModal'
import { DeleteModal } from '@/components/Modals/Vault/DeleteModal'
import { CreateNewFolder } from '@/components/Modals/Vault/CreateNewFolder'
import { ShareAccessModal } from '@/components/Modals/Vault/ShareAccessModal'
import { useAuthContext } from '@/context'
import { useGetOrgMembers } from '@/services/employees/queries'
import { VaultItem } from '@/services/vault/types'
import { formatDistanceToNow } from 'date-fns'
import { downloadFile } from '@/utils/utils'

type FileContainerProps = {
    file: VaultItem
}
export const FileContainer = ({ file }: FileContainerProps) => {
    const [showMove, setShowMove] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [showRename, setShowRename] = useState(false)
    const [type, setType] = useState('')
    const [showShareAccess, setShowShareAccess] = useState(false)

    const { selectedAccount } = useAuthContext()
    const { data: employees } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: `ACTIVE`,
        },
    })
    const orgMembers = useMemo(() => employees ?? [], [employees])

    return (
        <div className='h-[204px] bg-offwhite border border-gray8 p-3 rounded-xl'>
            <div className='flex justify-between gap-2'>
                <div className='flex items-center gap-2'>
                    <DocumentIcon />
                    <p className='text-xs font-medium truncate max-w-[7.8rem]'>{file.name}</p>
                </div>
                <Menu as='div' className='relative'>
                    <Menu.Button className=''>
                        <FaEllipsisVertical color='#A4A4A4' />
                    </Menu.Button>
                    <Menu.Items className='w-[185px] flex flex-col absolute -right-8 p-3 px-0 rounded-lg justify-center border border-[#DADCE0] bg-white mt-2 z-10'>
                        <Menu.Item>
                            <span
                                className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2 text-sm'
                                onClick={() => {
                                    setShowRename(true)
                                }}
                            >
                                <PenLine size={16} />
                                Rename file
                            </span>
                        </Menu.Item>
                        <Menu.Item>
                            <span
                                className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2 text-sm'
                                onClick={() => {
                                    downloadFile(file.url, file.name)
                                }}
                            >
                                <FileDown size={16} />
                                Download file
                            </span>
                        </Menu.Item>
                        <Menu.Item>
                            <span
                                className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2 text-sm'
                                onClick={() => {
                                    setShowShareAccess(true)
                                }}
                            >
                                <BsSend size={16} />
                                Share file
                            </span>
                        </Menu.Item>
                        <Menu.Item>
                            <span
                                className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2 text-sm'
                                onClick={() => {
                                    setType('duplicate')
                                    setShowMove(true)
                                }}
                            >
                                <HiOutlineDocumentDuplicate size={16} />
                                Duplicate
                            </span>
                        </Menu.Item>
                        <Menu.Item>
                            <span
                                className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2 text-sm'
                                onClick={() => {
                                    setType('move')
                                    setShowMove(true)
                                }}
                            >
                                <MdOutlineMoveToInbox size={16} className='-rotate-90' />
                                Move to
                            </span>
                        </Menu.Item>
                        <Menu.Item>
                            <div className='border-t border-gray8 mt-1'>
                                <span
                                    className='px-4 text-errorRed py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2 text-sm mt-1'
                                    onClick={() => {
                                        setShowDelete(true)
                                    }}
                                >
                                    <Trash2 size={16} />
                                    Delete file
                                </span>
                            </div>
                        </Menu.Item>
                    </Menu.Items>
                </Menu>
            </div>
            <img src={doc} alt='' className='mt-2 mb-3 h-[115px] rounded-lg overflow-hidden w-full' />
            <div className='flex justify-between gap-2'>
                <div className='flex items-center gap-2'>
                    <Clock size={14} color='#5E5E5E' />
                    <p className='text-xs font-medium truncate max-w-[7rem] text-[#A4A4A4]'>
                        {formatDistanceToNow(new Date(file?.createdAt), { addSuffix: true })}
                    </p>
                </div>
                <DocumentCategoryBadge status={DocumentCategory.sla} allowBg={true} />
            </div>
            {showMove && <MoveFileModal closeModal={setShowMove} file={file} actionType={type} />}
            {showDelete && <DeleteModal closeModal={setShowDelete} file={file} fileType={'file'} />}
            {showRename && <CreateNewFolder closeModal={setShowRename} rename={true} fileType={'file'} item={file} />}
            {showShareAccess && (
                <ShareAccessModal closeModal={setShowShareAccess} fileType='file' orgMembers={orgMembers} />
            )}
        </div>
    )
}
