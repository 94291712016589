import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import PageContent from '@/layout/PageContent'
import { cn } from '@/lib/styling'
import React, { useMemo, useState } from 'react'
import ActiveBenefits from '../ActiveBenefits/ActiveBenefits'
import { useGetOrgBenefits } from '@/services/benefits/queries'
import { useAuthContext } from '@/context'

const benefits_routes_const = {
    active: 'active',
    inactive: 'inactive',
}

export default function UserBenefits() {
    const [active, setActive] = useState('active')
    const { selectedAccount } = useAuthContext()

    const handletabChange = (tabName: string) => {
        setActive(tabName)
    }

    const { data: user_benefits, isLoading } = useGetOrgBenefits({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            employee: selectedAccount?.id as string,
        },
    })

    const userBenefits = useMemo(() => user_benefits ?? [], [user_benefits])

    return (
        <section>
            <Tabs defaultValue={active} value={active}>
                <TabsList>
                    <TabTriggersContainer className='lg:w-[100%]'>
                        <TabsTrigger
                            value={benefits_routes_const.active}
                            className={cn(
                                'underline_animation_normal',
                                benefits_routes_const.active === active
                                    ? 'underline_animation_selected'
                                    : 'underline_animation_notSelected'
                            )}
                            onClick={() => handletabChange(benefits_routes_const.active)}
                        >
                            Active
                            <span className='bg-[#D5D5F5] rounded-2xl px-4 inline-block ml-2'>
                                {userBenefits.length}
                            </span>
                        </TabsTrigger>
                        <TabsTrigger
                            value={benefits_routes_const.inactive}
                            className={cn(
                                'underline_animation_normal',
                                benefits_routes_const.inactive === active
                                    ? 'underline_animation_selected'
                                    : 'underline_animation_notSelected'
                            )}
                            onClick={() => handletabChange(benefits_routes_const.inactive)}
                        >
                            Inactive
                            <span className='bg-[#D5D5F5] rounded-2xl px-4 inline-block ml-2'>1</span>
                        </TabsTrigger>
                    </TabTriggersContainer>
                </TabsList>
                <TabsContent value={benefits_routes_const.active}>
                    {/* <PageContent> */}
                    <section>
                        <ActiveBenefits loading={isLoading} benefits={userBenefits} />
                    </section>
                    {/* </PageContent> */}
                </TabsContent>
                <TabsContent value={benefits_routes_const.inactive}>
                    <PageContent>
                        <section>Inactive Benefits</section>
                    </PageContent>
                </TabsContent>
            </Tabs>
        </section>
    )
}
