import ModalWrapper from '../ModalWrapper'
import { CancelIcon } from '@/assets/assets.export'
import { Button } from '@/components/Button'
import { toastHandler } from '@/components/utils/Toast'
import { useDeleteVaultItem } from '@/services/vault/mutations'
import { VaultItem } from '@/services/vault/types'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    file: VaultItem
    fileType: string
}

export const DeleteModal = ({ closeModal, fileType, file }: Props) => {
    const { mutate: deleteFn, isLoading } = useDeleteVaultItem({
        queryParams: {
            id: file.id,
        },
        onSuccess: () => {
            toastHandler({
                message: `${fileType === 'file' ? 'File' : 'Folder'} deleted successfully`,
                state: 'success',
            })
            closeModal(false)
        },
    })
    return (
        <ModalWrapper variants={undefined}>
            <main className=' w-screen lg:w-[31rem]'>
                <section className='flex items-center border-b border-[#DADCE0] p-4 justify-between'>
                    <span className='flex-1 text-xl font-medium ml-5'>
                        Delete {fileType === 'file' ? 'file' : 'folder'} - {file.name}
                    </span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <div className='p-7 pt-5'>
                    <p className='font-medium'>
                        Deleting this {fileType === 'file' ? 'file' : 'folder'} is a permanent action. Once deleted, you
                        won't be able to access it again. Do you still want to delete this{' '}
                        {fileType === 'file' ? 'file' : 'folder'}?
                    </p>
                </div>
                <div className='flex items-center border-t border-[#DADCE0] p-4 justify-between'>
                    <>&nbsp;</>
                    <Button
                        className='bg-errorRed'
                        onClick={() => deleteFn({})}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        Yes, Delete
                    </Button>
                </div>
            </main>
        </ModalWrapper>
    )
}
