import Accounts from '@/modules/dashboard/Accounts/Accounts'
import AccountDetailsPage from '@/modules/dashboard/Accounts/partials/AccountsDetailsPage'
import { Route } from 'react-router-dom'

export const accountsRoutes = {
    baseUrl: 'accounts',
    subRoute: 'accounts/:accountsSubRoute',
    accountsDetails: ':accountId',
}
export const useAccountsRoutes = () => {
    return (
        <Route path={accountsRoutes.subRoute}>
            <Route index element={<Accounts />} />
            <Route path={accountsRoutes.accountsDetails} element={<AccountDetailsPage />} />
            accountId
        </Route>
    )
}
