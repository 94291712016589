import { Button } from '@/components'
import { GenerateManualPayroll } from '@/components/Modals/Payroll/GenerateManualPayroll/GenerateManualPayroll'
import PaymentRequests from '@/components/Modals/Payroll/PaymentRequests/PaymentsRequests'

import { useAuthContext } from '@/context'
import { useGeneratePayroll } from '@/services/payroll/mutations'
import { useGetEligibleEmployees } from '@/services/payroll/queries'
import { useState } from 'react'
import RunPayrollDetailsPage from '../../components/RunPayrollDetailsPage'

//const filter_payroll = ['All', 'Paid', 'Processed', 'Overdue']

export default function RunPayroll() {
    const [showProrateModal, setShowProrateModal] = useState(false)
    const [showPRModal, setShowPRModal] = useState(false)
    const { selectedAccount } = useAuthContext()

    const {
        mutate: manualGeneratePayroll,
        isLoading: isGeneratingPayroll,
        isSuccess,
    } = useGeneratePayroll({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
    })

    const { data: manualPayrollData } = useGetEligibleEmployees({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
    })

    return (
        <section>
            <div className='block lg:flex justify-between items-center mb-6 lg:mb-0 my-7'>
                <div className='mb-4 lg:mb-0'>
                    <h1 className='text-dark font-medium text-lg'>Run payroll</h1>
                    <p className='text-gray7 text-sm'>
                        Process salaries, generate payroll manually and manage payment requests.
                    </p>
                </div>
                <div className='flex lg:justify-end gap-5'>
                    {/* <Button
                        className='bg-transparent text-black border border-[#DADCE0] px-3 min-h-[45px] min-w-0 rounded-lg text-xs flex items-center gap-2 font-normal hover:bg-transparent'
                        onClick={() => {
                            manualGeneratePayroll({})
                        }}
                        loading={isGeneratingPayroll}
                    >
                        Test Payroll
                    </Button> */}
                    {/* <Button
                        className='bg-transparent text-black border border-[#DADCE0] px-3 min-h-[45px] min-w-0 rounded-lg text-xs flex items-center gap-2 font-normal hover:bg-transparent'
                        onClick={() => setShowPRModal(true)}
                    >
                        Payment Requests
                    </Button> */}
                    <Button
                        className='bg-[#454ADE] text-white px-3 min-h-[45px] w-full lg:w-auto min-w-0 rounded-lg text-xs flex items-center gap-2'
                        onClick={() => setShowProrateModal(true)}
                    >
                        Generate payroll
                    </Button>
                </div>
            </div>
            <div>
                <RunPayrollDetailsPage />
            </div>

            {/* {showProrateModal && <GenerateManualSalary closeModal={setShowProrateModal} />} */}
            {showProrateModal && (
                <GenerateManualPayroll
                    closeModal={setShowProrateModal}
                    manualPayrollData={manualPayrollData!}
                    generatePayroll={manualGeneratePayroll}
                    isGeneratingPayroll={isGeneratingPayroll}
                    isSuccess={isSuccess}
                />
            )}
            {showPRModal && <PaymentRequests closeModal={setShowPRModal} />}
        </section>
    )
}
