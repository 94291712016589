import { OrgType } from './org'

export enum ModuleNameEnum {
    EXPENSES = 'expenses',
    BUDGETS = 'budgets',
    INVOICE = 'invoices',
    CUSTOMERS = 'customers',
    PEOPLE = 'people',
    PAYROLL = 'payroll',
    CONTRACTS = 'contracts',
    VENDORS = 'vendors',
    OCR_SCAN = 'ocr_scan',
    KYB = 'kyb',
}

const modules = [
    ModuleNameEnum.BUDGETS,
    ModuleNameEnum.CONTRACTS,
    ModuleNameEnum.CUSTOMERS,
    ModuleNameEnum.EXPENSES,
    ModuleNameEnum.INVOICE,
    ModuleNameEnum.OCR_SCAN,
    ModuleNameEnum.PAYROLL,
    ModuleNameEnum.PEOPLE,
    ModuleNameEnum.VENDORS,
] as const

export type ModuleType = (typeof modules)[number]
export type Billing = {
    modules: {
        [key in ModuleType]: {
            allocated: number
            is_unlimited: boolean
        }
    } & { [key: string]: { allocated: number; is_unlimited: number } }
    name: string
    archived: boolean
    price: string
    description: string
    metadata: string[]
    createdAt: Date
    updatedAt: Date
    id: string
}

export type BusinessPlanBilling = {
    addons: {
        expenses: number
        invoices: number
        customers: number
        payroll: number
        contracts: number
        vendors: number
        budgets: number
        people: number
        [key: string]: number
    }
    org: string
    plan: Billing | string
    amount: number
    next_renewal_date: string
    createdAt: Date
    updatedAt: Date
    id: string
}

export type SubscriptionType = {
    plan: Billing
    business_plan: BusinessPlanBilling
    price: number
    org: OrgType
    start_date: string
    end_date: string
    status: string
    next_renewal_date: string
    id: string
}
export type UsageRecordType = {
    subscription: string
    modules: {
        [key in ModuleType]: {
            allocated: number
            available: number
            is_unlimited: boolean
        } & { [key: string]: { allocated: number; is_unlimited: number; available: number } }
    }
}

export type OrgSubscription = {
    business_plan: string
    createdAt: string
    end_date: string
    id: string
    next_renewal_date: string
    org: string
    plan: string
    start_date: string
    status: string
    updatedAt: string
}
