import { ExpenseType } from '@/types/expense'
import React, { useEffect, useMemo, useState } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { useForm } from 'react-hook-form'
import { AiOutlineClose } from 'react-icons/ai'
import PreviewExpense from './PreviewExpense'
import ExpenseForm from './ExpenseForm'
import { Button } from '@/components'
import { ExpenseFieldProps } from './utils'
import { FileUploadFormatProps, fileEmptyState } from '@/hooks/useSingleFileUpload'
import { useMutationCreateSingleExpense, useMutationUpdateExpense } from '@/services/expenses/mutations'
import { useGetOrgBudget } from '@/services/budget/queries'
import { useAuthContext } from '@/context'
import { ExpenseStatus } from '@/modules/dashboard/Expenses/const'
import { toastHandler } from '@/components/utils/Toast'
import { useNavigate } from 'react-router-dom'
import { QueryOrgBudgetType } from '@/services/budget/types'
import { CategoryType } from '@/types/categories'
import { isAdminRoute } from '@/utils/permissions'

interface Props {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    categories: CategoryType[]
    hasAPrimaryBank: boolean
    edit?: boolean
    selectedExpense?: ExpenseType
}

export default function ExpenseFormRoot({ setShowModal, edit, categories, selectedExpense, hasAPrimaryBank }: Props) {
    const [preview, setPreview] = useState(1)
    const [scanWithOCR, setScanWithOCR] = useState(false)
    const [selectedCategoryObject, setSelectedCategoryObject] = useState<CategoryType | undefined>()
    const [proofExpenseFile, setProofExpenseFile] = useState<FileUploadFormatProps | undefined>(fileEmptyState)
    const selectedExpenseStatus = selectedExpense?.status as ExpenseStatus
    const [expenseStatus, setExpenseStatus] = useState<ExpenseStatus>(
        selectedExpenseStatus
            ? selectedExpenseStatus === ExpenseStatus.draft
                ? ExpenseStatus.pending
                : selectedExpenseStatus
            : ExpenseStatus.pending
    )

    const navigate = useNavigate()
    const { selectedAccount } = useAuthContext()
    const { data: org_budget } = useGetOrgBudget({
        enabled: isAdminRoute(),
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            account: selectedExpense?.submitter?.id as string,
        },
    })
    const orgBudget = useMemo(() => org_budget ?? [], [org_budget]) as QueryOrgBudgetType

    const { mutate: submitCreateExpense, isLoading: isExpenseLoading } = useMutationCreateSingleExpense({
        queryParams: {
            scanWithOCR: scanWithOCR as unknown as string,
        },
        onSuccess: () => {
            setShowModal(false)
        },
    })
    const { mutate: authorizeUpdateFn, isLoading: isUpdateLoading } = useMutationUpdateExpense({
        queryParams: { id: selectedExpense?.id as string },
        onSuccess: () => {
            setShowModal(false)
        },
    })

    const {
        handleSubmit,
        control,
        watch,
        formState: { isValid },
        getValues,
    } = useForm<ExpenseFieldProps>({
        defaultValues: {
            amount: selectedExpense?.amount,
            budget: selectedExpense?.budget?._id ?? selectedExpense?.budget?.id,
            category: selectedExpense?.category?.id,
            attact_budget: selectedExpense?.budget?._id ?? selectedExpense?.budget?.id ? 'link' : 'no_link',
            date: selectedExpense?.date ? (new Date(selectedExpense?.date) as unknown as string) : undefined,
            description: selectedExpense?.description,
            expense_type: selectedExpense?.reimbursable ? 'reimbursable' : 'non-reimbursable',
            merchant: selectedExpense?.merchant_name,
        },
    })

    const handleManageExpense = (values: ExpenseFieldProps, e?: any) => {
        const status = selectedExpenseStatus
            ? selectedExpenseStatus === ExpenseStatus.draft
                ? ExpenseStatus.pending
                : selectedExpenseStatus
            : e?.nativeEvent.submitter?.name //saved at the button

        setExpenseStatus(status)
        const formdata = new FormData()
        formdata.append('file', (proofExpenseFile?.fileBeforeFormData as Blob) ?? '')
        formdata.append('merchant_name', values.merchant)

        formdata.append('date', values?.date)

        formdata.append('amount', (Number(values.amount) / 100).toString()) //convert back to naira
        formdata.append('category', values.category)
        formdata.append('org', selectedAccount?.org?.id as string)
        formdata.append('description', values.description)
        formdata.append('reimbursable', values?.expense_type === 'reimbursable' ? true : (false as any))
        formdata.append('draft', (status === ExpenseStatus.draft ? true : false) as any)
        formdata.append('status', status)
        formdata.append(
            'budget',
            values?.attact_budget === 'link' ? (values?.budget as string) : (undefined as unknown as string)
        )

        if (edit) {
            authorizeUpdateFn(formdata, {
                onSuccess: () => {
                    toastHandler({
                        message: status === ExpenseStatus.draft ? 'Draft Updated' : 'Expense updated',
                        state: 'success',
                    })
                },
            })
            return
        }

        submitCreateExpense(formdata, {
            onSuccess: () => {
                toastHandler({
                    message: status === ExpenseStatus.draft ? 'Expense drafted' : 'Expense submitted',
                    state: 'success',
                })
            },
        })
    }

    useEffect(() => {
        setSelectedCategoryObject(categories.find(category => category.id === getValues?.()?.category))
    }, [getValues?.()?.category, categories])

    return (
        <ModalWrapper closeModal={() => setShowModal(false)}>
            <section className='w-screen lg:w-[50rem] min-h-[69vh] max-h-[90vh] overflow-scroll relative'>
                <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-end sticky top-0 bg-white rounded-t-lg z-50'>
                    <div className='flex items-center w-[65%] justify-between h-full '>
                        <h2 className='font-semibold text-[#31254B]'>{edit ? 'Update' : 'Create New'} Expense</h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={() => setShowModal(false)}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <form className='' onSubmit={handleSubmit(handleManageExpense)}>
                    <div className='min-h-[49vh] max-h-[70vh] overflow-scroll mb-10'>
                        {hasAPrimaryBank && (
                            <div className='mt-4 mx-4 p-3 mb-6 flex justify-between items-center bg-[#F8F1DE] text-[#5E5E5E] text-xs gap-9 rounded-lg'>
                                <p>
                                    Please note that you won’t be able to submit a reimbursable expense if you have not
                                    linked your bank account.
                                </p>
                                <Button
                                    className='px-2 min-w-[120px] text-xs'
                                    onClick={() => {
                                        navigate('/dashboard/settings/user/payment')
                                    }}
                                >
                                    Link bank account
                                </Button>
                            </div>
                        )}
                        <div>
                            {preview === 1 && (
                                <ExpenseForm
                                    categories={categories}
                                    control={control}
                                    watch={watch}
                                    setScanWithOCR={setScanWithOCR}
                                    proofExpenseFile={proofExpenseFile}
                                    setProofExpenseFile={setProofExpenseFile}
                                    isEdit={edit}
                                    hasPrimaryBank={hasAPrimaryBank}
                                    orgBudget={orgBudget}
                                />
                            )}
                            {preview === 2 && (
                                <PreviewExpense
                                    selectedExpense={selectedExpense}
                                    setPreview={setPreview}
                                    formProps={getValues?.()}
                                    category={selectedCategoryObject}
                                    budget={() => {
                                        return (orgBudget as QueryOrgBudgetType)?.budgets?.find(
                                            budget => budget?._id === getValues()?.budget
                                        )?.name as string
                                    }}
                                    file={proofExpenseFile}
                                />
                            )}
                        </div>
                    </div>
                    <div className='bg-white fixed bottom-0 border-t border-gray8 w-full flex items-center justify-end gap-4  px-6 py-2 rounded-b-md'>
                        <Button
                            className='bg-transparent disabled:bg-transparent disabled:opacity-40 text-[#202020] px-5 h-[3.5rem] rounded-lg text-xs hover:bg-transparent min-w-[100px]'
                            type='submit'
                            loading={expenseStatus === ExpenseStatus.draft && (isExpenseLoading || isUpdateLoading)}
                            name={ExpenseStatus.draft}
                            disabled={
                                !isValid ||
                                (expenseStatus === ExpenseStatus.pending && (isExpenseLoading || isUpdateLoading))
                            }
                        >
                            {edit ? 'Update' : 'Save'} as draft
                        </Button>

                        {preview === 1 && (
                            <Button
                                type='button'
                                disabled={!isValid}
                                className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px]'
                                onClick={() => {
                                    setPreview(2)
                                }}
                            >
                                Preview {edit ? 'Changes' : 'Expense'}
                            </Button>
                        )}
                        {preview === 2 && (
                            <Button
                                type={'submit'}
                                className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px]'
                                loading={
                                    expenseStatus === ExpenseStatus.pending && (isExpenseLoading || isUpdateLoading)
                                }
                                name={ExpenseStatus.pending}
                                disabled={
                                    expenseStatus === ExpenseStatus.draft && (isExpenseLoading || isUpdateLoading)
                                }
                            >
                                Submit {edit ? 'Changes' : 'Expense'}
                            </Button>
                        )}
                    </div>
                </form>
            </section>
        </ModalWrapper>
    )
}
