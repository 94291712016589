import { CancelIcon } from '@/assets/assets.export'
import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import ModalWrapper from '@/components/Modals/ModalWrapper'
import { capitalizeText } from '@/utils/string'
import { Check, Plus } from 'lucide-react'
import { useMemo, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'

import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import { useCreateBusinessPlan, useUpdateBusinessPlan } from '@/services/billing/mutations'
import { useGetDirectoryPlans } from '@/services/billing/queries'
import { BusinessPlanBilling } from '@/types/billing'
import { useParams } from 'react-router-dom'

type FormFeatureType = {
    name: string
    amount: string
}

type FormType = {
    plan_id: string
    plan_amount: number
    feature: FormFeatureType[]
    next_renewal_date: string
}

type Props = {
    closeModal: () => void
    edit?: boolean
    planDetails?: BusinessPlanBilling
}

const CreateBusinessPlan = ({ closeModal, edit, planDetails }: Props) => {
    const { user_id: org_id } = useParams()
    const ref = useRef<DatePicker>(null)
    const [features, setFeatures] = useState([
        { name: 'Invoice', id: 'invoice' },
        { name: 'Expenses', id: 'expenses' },
        { name: 'Budgets', id: 'budgets' },
        { name: 'Customers', id: 'customers' },
        { name: 'People', id: 'people' },
        { name: 'Payroll', id: 'payroll' },
        { name: 'Contracts', id: 'contracts' },
        { name: 'Vendors', id: 'vendors' },
    ])
    const [newFeatureName, setNewFeatureName] = useState<string | null>(null)

    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm<FormType>({
        defaultValues: {
            plan_amount: Number(planDetails?.amount),
            plan_id: typeof planDetails?.plan === 'string' ? planDetails?.plan : planDetails?.plan?.id,
            next_renewal_date: planDetails?.next_renewal_date,
            feature:
                planDetails?.addons &&
                Object.keys(planDetails?.addons)?.map(key => ({
                    name: key,
                    amount: planDetails?.addons?.[key].toString() ?? '',
                    // unlimited: planDetails?.modules?.[key as ModuleType]?.is_unlimited,
                })),
        },
    })

    const {
        fields: featureFields,
        remove: removeFeature,
        append: appendFeature,
    } = useFieldArray({
        control,
        name: 'feature',
    })

    const { data } = useGetDirectoryPlans()
    const orgPlans = useMemo(() => data ?? [], [data])

    const { mutate: createBusinessPlanFn, isLoading } = useCreateBusinessPlan({
        onSuccess: () => {
            closeModal()
        },
    })
    const { mutate: updateBusinessPlanFn, isLoading: isUpdatingBusinessPlan } = useUpdateBusinessPlan({
        queryParams: {
            id: planDetails?.id as string,
        },
        onSuccess: () => {
            closeModal()
        },
    })

    const onSubmit = (values: FormType) => {
        const data_to_server = {
            plan: values?.plan_id,
            org: org_id,
            amount: Number(values.plan_amount) / 100, //converts back to naira
            addons: values.feature.reduce((acc, obj) => ({ ...acc, [obj.name]: obj.amount }), {}),
            next_renewal_date: values.next_renewal_date.toString(),
        }
        if (edit) {
            updateBusinessPlanFn(data_to_server)
            return
        }

        createBusinessPlanFn(data_to_server)
    }
    return (
        <ModalWrapper>
            <section className='w-screen lg:w-[42rem] min-h-fit max-h-[90vh] overflow-scroll relative'>
                <form action='' onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex justify-between items-center border-b border-[#DADCE0] py-4'>
                        <h2 className='text-lg font-medium flex-1 text-center'>
                            {edit ? 'Edit' : 'Create'} business plan
                        </h2>

                        <button
                            className='bg-[#F2F2F2] rounded-full p-1 w-6 h-6 appearance-none inline-flex justify-center items-center mr-3'
                            aria-label='Close'
                            onClick={() => {
                                closeModal()
                            }}
                        >
                            <CancelIcon />
                        </button>
                    </div>

                    <div className='sm:grid grid-cols-1  sm:grid-cols-2 gap-6 gap-y-1 mx-2 sm:mx-6 mt-6'>
                        <fieldset>
                            <label className='text-sm' htmlFor='plan_id'>
                                Plan name
                            </label>
                            <Controller
                                name={`plan_id`}
                                rules={{
                                    required: true,
                                    minLength: 2,
                                }}
                                control={control}
                                render={({ field }) => (
                                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                                        <SelectTrigger className='h-[2.5rem] inline-flex justify-between border-[#DADCE0]  border rounded-md p-2 text-xs  py-4'>
                                            {!field.value ? (
                                                <span>Select plan</span>
                                            ) : (
                                                <SelectValue placeholder='Select feature' />
                                            )}
                                        </SelectTrigger>

                                        <SelectContent
                                            position='popper'
                                            className='shadow-md bg-white rounded-md px-3 text-sm text-[#202020]'
                                        >
                                            {orgPlans.map(plan => (
                                                <SelectItem
                                                    value={plan.id}
                                                    className='cursor-pointer flex item-center gap-2'
                                                >
                                                    {capitalizeText(plan.name)}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                )}
                            />
                        </fieldset>
                        <fieldset className='mb-[15px] flex flex-col flex-1  gap-1'>
                            <label className='text-sm' htmlFor='plan_name'>
                                Plan amount
                            </label>
                            <Controller
                                name='plan_amount'
                                rules={{
                                    required: true,
                                    minLength: 2,
                                }}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        min={0}
                                        value={formatAmount(field.value).replaceAll('₦', '')}
                                        onChange={e => {
                                            const value_entered = formatAmountWithoutSymbols(e.target.value)
                                            field.onChange(value_entered)
                                        }}
                                        placeholder='Enter plan amount'
                                        className='border-[#DADCE0] h-[2.5rem] placeholder:text-xs'
                                    />
                                )}
                            />
                        </fieldset>
                        <fieldset className='mb-[15px] flex flex-col flex-1  gap-1'>
                            <label className='text-sm' htmlFor='plan_name'>
                                Next renewal date
                            </label>
                            <Controller
                                name='next_renewal_date'
                                rules={{
                                    required: true,
                                    minLength: 2,
                                }}
                                control={control}
                                render={({ field }) => (
                                    <CustomDatePicker
                                        {...field}
                                        ref={ref}
                                        dateFormat={'dd/MM/yyyy'}
                                        onChange={date => {
                                            field.onChange(date)
                                        }}
                                        selected={field.value ? new Date(field.value) : undefined}
                                        value={field.value ? new Date(field.value).toDateString() : undefined}
                                    />
                                )}
                            />
                        </fieldset>
                        <p className='block font-semibold text-lg text-[#31254B] mb-3 col-span-2'>Add ons</p>

                        {featureFields?.map((field, idx) => (
                            <div
                                key={field.id}
                                className='col-span-2 grid grid-cols-2 gap-6 bg-gray4/10 p-3 rounded-md relative'
                            >
                                <button className='absolute -top-2 -right-2' onClick={() => removeFeature(idx)}>
                                    <CancelIcon className='w-5 h-5 stroke-red-500 rounded-full p-1  bg-red-50' />
                                </button>
                                <fieldset>
                                    <label className='text-xs block font-medium mb-1' htmlFor='plan_name'>
                                        Feature type
                                    </label>
                                    <Controller
                                        name={`feature.${idx}.name`}
                                        rules={{
                                            required: true,
                                            minLength: 2,
                                        }}
                                        control={control}
                                        render={({ field }) => (
                                            <Select onValueChange={field.onChange} defaultValue={field.value}>
                                                <SelectTrigger className='h-[2.5rem] inline-flex justify-between border-[#DADCE0]  border rounded-md p-2 text-xs  py-4'>
                                                    {!field.value ? (
                                                        <span>Select feature</span>
                                                    ) : (
                                                        <SelectValue placeholder='Select feature' />
                                                    )}
                                                </SelectTrigger>

                                                <SelectContent
                                                    position='popper'
                                                    className='shadow-md bg-white rounded-md w-max px-3 text-sm text-[#202020]'
                                                >
                                                    {features.map(feature => (
                                                        <SelectItem
                                                            value={feature.id}
                                                            className='cursor-pointer flex item-center gap-2'
                                                        >
                                                            {capitalizeText(feature.name)}
                                                        </SelectItem>
                                                    ))}

                                                    {!newFeatureName ? (
                                                        <button
                                                            value='new_feature'
                                                            className='cursor-pointer flex item-center gap-2 rounded border border-gray4 p-1 text-xs mt-3'
                                                            onClick={() => setNewFeatureName('name')}
                                                        >
                                                            + Add new feature
                                                        </button>
                                                    ) : (
                                                        <div className='flex items-center justify-center gap-1 mt-3 border border-gray3 rounded'>
                                                            <input
                                                                type='text'
                                                                className='p-1 outline-none'
                                                                onChange={e => setNewFeatureName(e.target.value)}
                                                            />
                                                            <Check
                                                                className='cursor-pointer'
                                                                onClick={() => {
                                                                    setFeatures(prev => [
                                                                        ...prev,
                                                                        { name: newFeatureName, id: newFeatureName },
                                                                    ])
                                                                    setNewFeatureName(null)
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </SelectContent>
                                            </Select>
                                        )}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label className='text-xs block font-medium mb-1' htmlFor='plan_name'>
                                        Feature amount
                                    </label>
                                    <Controller
                                        name={`feature.${idx}.amount`}
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                min={0}
                                                type='number'
                                                placeholder='Enter feature amount'
                                                className='border-[#DADCE0] h-[2.5rem] placeholder:text-xs'
                                            />
                                        )}
                                    />
                                </fieldset>
                            </div>
                        ))}
                    </div>
                    <button
                        onClick={() => {
                            appendFeature({ amount: '0', name: '' })
                        }}
                        className='mb-24 mt-6 mx-6  border border-gray3 rounded-md p-2 flex items-center justify-center gap-2 text-xs'
                    >
                        <Plus className='opacity-50 w-4 h-4' />
                        Add new feature
                    </button>

                    <div className='bg-white fixed bottom-0 border-t border-gray8 w-full flex items-center justify-end gap-4  px-6 py-2 rounded-b-md'>
                        <Button
                            loading={isUpdatingBusinessPlan || isLoading}
                            disabled={!isValid}
                            className='my-1 ml-auto mr-6'
                            type='submit'
                        >
                            Create
                        </Button>
                    </div>
                </form>
            </section>
        </ModalWrapper>
    )
}

export default CreateBusinessPlan
