import { ContractPayrollType } from '@/types/payroll'
import { isAdminRoute } from '@/utils/permissions'
import React, { ReactNode } from 'react'

type Props = {
    contract: ContractPayrollType
    children: ReactNode
}
export default function CheckToEnableEditContractButton({ contract, children }: Props) {
    // const checkIfAllMileStoneHasBeenPaid = (contract?.milestones ?? []).every(
    //     milestone => milestone?.payment_status === 'paid'
    // )
    if (isAdminRoute() && contract?.status === 'active') return <>{children}</>
    return <></>
}
