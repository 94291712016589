import { Button } from '@/components'
import { useAuthContext, useEmployeesContext } from '@/context'
import { useMutationInviteEmployee } from '@/services/employees/mutations'
import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import ModalWrapper from '../../ModalWrapper'
import MultipleInvite from './MultipleInvite'

const emailRegexExp =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

type InviteModalContainerProps = {
    children: React.JSX.Element[]
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}
export default function InviteModalContainer({ children, closeModal }: InviteModalContainerProps) {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0)
    const [multipleInvite, setMultipleInvite] = useState(false)

    const { selectedAccount } = useAuthContext()

    const { inviteQueryText, setInviteQueryText } = useEmployeesContext()

    useEffect(() => {
        setInviteQueryText({ email_query: '', role: '' })
    }, [])

    const { mutate: inviteEmployeesFn, isLoading: isEmployeeInvited } = useMutationInviteEmployee({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
        onSuccess: () => {
            setSelectedTabIndex(1)
        },
    })
    const handleSendInvite = () => {
        inviteEmployeesFn({ invite_list: [{ email: inviteQueryText.email_query, role: inviteQueryText.role }] })
        // setSelectedTabIndex(1)
    }
    const isValidEmail = emailRegexExp.test(inviteQueryText.email_query)

    return (
        <ModalWrapper variants={undefined}>
            <section className='max-h-[85vh] w-screen max-w-[30rem] lg:min-w-[30rem] overflow-y-scroll my-3'>
                <div className='flex items-center border-b border-[#DADCE0]/50 p-2 '>
                    <h1 className='flex-1 text-center font-semibold text-lg text-[#31254B]'>
                        {multipleInvite ? 'Invite users' : 'Invite user'}
                    </h1>
                    <AiOutlineClose
                        className='bg-[#F2F2F2] text-[#838383] inline-block w-8 h-8 p-2 rounded-full cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </div>
                {multipleInvite && <MultipleInvite setSelectedTabIndex={setSelectedTabIndex} />}

                {!multipleInvite && children[selectedTabIndex]}

                {!multipleInvite && selectedTabIndex === 0 && (
                    <div className=' mx-auto px-8'>
                        <Button
                            className='w-full mt-10'
                            onClick={handleSendInvite}
                            loading={isEmployeeInvited}
                            disabled={!isValidEmail || inviteQueryText.role.length < 1}
                        >
                            Send invite
                        </Button>
                        <button
                            type='button'
                            className='inline-block text-[#5E5E5E] text-center w-full mt-4 text-xs'
                            onClick={() => setMultipleInvite(true)}
                        >
                            I want to send multiple invites with different roles
                        </button>
                    </div>
                )}
            </section>
        </ModalWrapper>
    )
}
