import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import TableActions from '@/components/Table/TableActions'
import Table from '@/components/Table'
import { useState } from 'react'
// import CheckBox from '@/components/Table/CheckBox'
import TransactionEmptyStateIcon from '@/assets/v2dashboard/recent-transactions.svg'
import { format } from 'date-fns'
import { formatAmount } from '@/utils/money'
import { useNavigate } from 'react-router-dom'
import { isAdminRoute } from '@/utils/permissions'
import TransactionStatusBadge from '@/modules/dashboard/Transactions/components/TransactionStatusBadge'
import { TransactionType } from '@/types/transactions'

interface PaymentHistoryProps {
    payments: TransactionType[]
}

const PaymentHistoryTable = ({ payments }: PaymentHistoryProps) => {
    const [selectedTransactions, setSelectedTransactions] = useState<TransactionType>()
    const columnHelper = createColumnHelper<TransactionType>()

    console.warn(selectedTransactions)
    const navigate = useNavigate()

    //context api to sort the table
    const columns: ColumnDef<TransactionType, any>[] = [
        columnHelper.accessor('transaction_id', {
            header: () => {
                return <p className='whitespace-nowrap text-[95%] '>Transaction ID</p>
            },
            cell: cell => (
                <div>
                    <p className='font-normal mb-1 text-sm'>{cell.renderValue()}</p>
                </div>
            ),
            enableSorting: false,
        }),
        columnHelper.accessor('createdAt', {
            header: () => <p className='whitespace-nowrap text-[95%]'>Payment Date</p>,
            cell: cell => (
                <div>
                    <p className='font-normal mb-1 text-sm'>
                        {format(new Date(cell.renderValue()), 'dd/MM/yyyy')},{' '}
                        {format(new Date(cell.renderValue()), 'hh:mm a')}
                    </p>
                </div>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('debit.amount', {
            header: () => <p>Amount</p>,
            cell: cell => {
                const debitAmt = cell.renderValue()
                return (
                    <span className='font-normal whitespace-nowrap'>
                        {cell.row.original.ownerTransactionType === 'DEBIT' ? (
                            <span className='text-[#CF491E]'>{formatAmount(debitAmt)}</span>
                        ) : (
                            <span className='text-[#19C37D]'>{formatAmount(cell.row.original.credit.amount)}</span>
                        )}
                    </span>
                )
            },
            enableSorting: true,
        }),

        columnHelper.accessor('type', {
            header: () => <p>Type</p>,
            cell: cell => <span className='font-normal'>{cell.renderValue()}</span>,
            enableSorting: false,
        }),

        columnHelper.accessor('status', {
            header: () => <p>Status</p>,
            cell: cell => {
                return (
                    <>
                        <TransactionStatusBadge status={cell.renderValue().toLowerCase()} />
                    </>
                )
            },
            enableSorting: false,
        }),
        columnHelper.display({
            id: 'actions',
            cell: cell => (
                <TableActions
                    actions={[
                        {
                            label: 'More details',
                            method: () => {
                                setSelectedTransactions(cell?.row?.original)
                                isAdminRoute()
                                    ? navigate(
                                          `/admin/dashboard/transactions/transactions_history/${
                                              cell.row.original.id ?? cell.row.original.id
                                          }`
                                      )
                                    : navigate(
                                          `/dashboard/transactions/transactions_history/${
                                              cell.row.original.id ?? cell.row.original.id
                                          }`
                                      )
                            },
                        },
                    ]}
                />
            ),
        }),
    ]
    // const handleRowClick = (transaction: TransactionType) => {
    //     setSelectedTransactions(transaction)
    //     isAdminRoute()
    //         ? navigate(`/admin/dashboard/transactions/transactions_history/${transaction?.id ?? transaction?._id}`)
    //         : navigate(`/dashboard/transactions/transactions_history/${transaction?.id ?? transaction?._id}`)
    // }

    return (
        <div>
            <Table
                data={payments}
                columns={columns}
                emptyStateTitle='No transactions'
                emptyStateDescription='No transaction has been made yet'
                emptyStateImg={TransactionEmptyStateIcon}
            />
        </div>
    )
}

export default PaymentHistoryTable
