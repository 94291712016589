import { ExpenseType } from '@/types/expense'
import { formatAmount } from '@/utils/money'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import ExpenseStatusBadge from '../../Expenses/components/ExpenseStatus'
import { ExpenseStatus } from '../../Expenses/const'
import TableActions from '@/components/Table/TableActions'
import Table from '@/components/Table'
import { capitalizeText } from '../../../../utils/string'
import { useState } from 'react'
import { useMutationAuthorizeExpense } from '@/services/expenses/mutations'
import { isAdmin, isAdminRoute } from '@/utils/permissions'
import { useAuthContext } from '@/context'

type Props = {
    expenses: ExpenseType[]
    adminRoute: boolean
}

export default function OverviewExpenseListTable({ expenses }: Props) {
    const [rowToBeModified, setRowToBeModified] = useState('')
    const { selectedAccount } = useAuthContext()

    const { mutate: authorizeExpenseRowFn, isLoading: isExpenseAuthorized } = useMutationAuthorizeExpense({
        queryParams: {
            id: rowToBeModified as string,
        },
    })
    const handleDeclineRowExpense = async (rowId: string) => {
        setRowToBeModified(rowId)
        authorizeExpenseRowFn({ status: ExpenseStatus.denied })
    }
    const handleApproveRowExpense = async (rowId: string) => {
        setRowToBeModified(rowId)
        authorizeExpenseRowFn({ status: ExpenseStatus.approved })
    }

    const columnHelper = createColumnHelper<ExpenseType>()

    const columns: ColumnDef<ExpenseType, any>[] = [
        columnHelper.accessor('expense_id', {
            header: () => {
                return <p>Expense ID</p>
            },
            cell: cell => <span className='opacity-90'>{cell.renderValue()}</span>,
            enableSorting: false,
        }),
        columnHelper.accessor('submitter.user', {
            header: () => <p>User</p>,
            cell: cell => {
                return (
                    <span className='opacity-90'>
                        {cell.renderValue()?.first_name} {cell.renderValue()?.last_name}
                        <span className='block font-xs text-[#838383]'>
                            {format(new Date(cell.row?.original.date), 'dd/MM/yyyy')} •{' '}
                            {format(new Date(cell.row?.original.createdAt), 'ccc, h:mm aa')}
                        </span>
                    </span>
                )
            },
            enableSorting: false,
        }),
        columnHelper.accessor('amount', {
            header: () => <p>Amount</p>,
            cell: cell => <span className='font-light text-[#5E5E5E]'>{formatAmount(cell.renderValue())}</span>,
            enableSorting: false,
        }),
        columnHelper.accessor('category.label', {
            header: () => <p>Category</p>,
            cell: cell => <span className='font-light text-[#5E5E5E]'>{cell.renderValue()}</span>,
            enableSorting: false,
        }),
        columnHelper.accessor('status', {
            header: () => <p>Status</p>,
            enableSorting: false,
            cell: cell => <ExpenseStatusBadge status={cell.renderValue()} />,
        }),
        columnHelper.accessor('approvers', {
            header: () => <p>Approver(s)</p>,
            cell: cell => (
                <span className='opacity-90'>
                    {cell.renderValue()
                        ? `${capitalizeText(cell.renderValue()[0]?.account?.user?.first_name)} ${capitalizeText(
                              cell.renderValue()[0]?.account?.user?.last_name
                          )}`
                        : 'Not Approved'}
                    <span className='block text-[#838383] text-xs'>Not Approved</span>
                </span>
            ),

            enableSorting: false,
        }),
        // {adminRoute ? : {}}
        columnHelper.display({
            id: 'actions',

            cell: cell =>
                isAdmin(selectedAccount!) &&
                isAdminRoute() && (
                    <TableActions
                        actions={[
                            {
                                label: 'Approve',
                                method: () => handleApproveRowExpense?.(cell?.row?.original?.id),
                                hidden:
                                    cell?.row.original.status === ExpenseStatus.approved ||
                                    cell?.row.original.status === ExpenseStatus.denied ||
                                    cell.row.original.status === ExpenseStatus.draft,
                            },
                            {
                                label: 'Decline',
                                method: () => {
                                    handleDeclineRowExpense?.(cell?.row?.original?.id)
                                },
                                hidden:
                                    cell?.row.original.status === ExpenseStatus.approved ||
                                    cell?.row.original.status === ExpenseStatus.denied ||
                                    cell.row.original.status === ExpenseStatus.draft,
                            },
                            // {
                            //     label: cell.row.original.status === ExpenseStatus.draft ? 'Edit Details' : 'More details',
                            //     method: () => {
                            //         handleMoreDetails(cell?.row?.original)
                            //     },
                            // },
                        ]}
                    />
                ),
        }),
    ]
    return (
        <section className={`mt-1 ${isExpenseAuthorized ? 'opacity-30' : 'opacity-100'} max-h-[33.8rem]`}>
            <Table data={expenses} columns={columns} />
        </section>
    )
}
