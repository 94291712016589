import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CompanySettingsHeading, subRouteHelper } from '../../utils'
import { GreaterThan } from '@/assets/assets.export'

type Props = {
    children: React.JSX.Element[]
}

export default function CompanySettingsContainer({ children }: Props) {
    const { company_subRoute } = useParams()
    const navigate = useNavigate()

    const pageIndex = company_subRoute && subRouteHelper[company_subRoute as keyof typeof subRouteHelper]

    return (
        <main className='bg-[#F9F9F9] h-full overflow-y-scroll'>
            <section className='font-medium pr-6 pb-4 pt-4 ml-[1.5rem] lg:ml-[2.9rem] mx-auto flex gap-1'>
                <span onClick={() => navigate('/admin/dashboard/settings/company')} className='cursor-pointer'>
                    Company settings
                </span>
                <span className='text-[#838383] flex gap-1'>
                    {' '}
                    <GreaterThan />
                    {CompanySettingsHeading[company_subRoute as keyof typeof CompanySettingsHeading]}
                </span>
            </section>
            <hr className='text-[#DADCE0] ' />
            <section
                className={`pt-4 mx-1 mb-[3rem] md:mx-[1.2rem] lg:ml-[3rem] lg:max-w-[90rem] lg:mx-auto overflow-y-scroll ${pageIndex && pageIndex < 5 && 'mb-[8rem]'}`}
            >
                {pageIndex !== undefined && typeof pageIndex !== 'string' ? children[pageIndex] : null}
            </section>
        </main>
    )
}
