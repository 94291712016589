import Table from '@/components/Table'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

type SubscriptionType = {
    transaction_id: string
    payment_date: string
    amount: string
    plan: string
    initiated_by: string
    payment_method: string
}
export default function BusinessSubscriptionTable() {
    const subscription = [
        {
            transaction_id: 'TRX120091',
            payment_date: '27/06/2023, 10:24 AM',
            amount: '₦240,000.00',
            plan: 'Standard / year',
            initiated_by: 'Babajide Mathew',
            payment_method: 'Card',
        },
        {
            transaction_id: 'TRX120091',
            payment_date: '27/06/2023, 10:24 AM',
            amount: '₦240,000.00',
            plan: 'Standard / year',
            initiated_by: 'Babajide Mathew',
            payment_method: 'Card',
        },
        {
            transaction_id: 'TRX120091',
            payment_date: '27/06/2023, 10:24 AM',
            amount: '₦240,000.00',
            plan: 'Standard / year',
            initiated_by: 'Babajide Mathew',
            payment_method: 'Card',
        },
    ]
    const columnHelper = createColumnHelper<SubscriptionType>()

    const columns: ColumnDef<SubscriptionType, any>[] = [
        columnHelper.accessor('transaction_id', {
            header: () => {
                return <p>Transaction ID</p>
            },
            cell: cell => <span className='text-gray7 font-medium'>{cell.renderValue()}</span>,
            enableSorting: false,
        }),
        columnHelper.accessor('payment_date', {
            header: () => {
                return <p>Payment Date</p>
            },
            cell: cell => <span className='text-gray7 font-medium'>{cell.renderValue()}</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('amount', {
            header: () => <p>Amount</p>,
            cell: cell => <span className='text-errorRed'>{cell.renderValue()}</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('plan', {
            header: () => <p>Plan</p>,
            cell: cell => <span className='text-gray7 font-medium'>{cell.renderValue()}</span>,

            enableSorting: false,
        }),
        columnHelper.accessor('initiated_by', {
            header: () => <p>Initiated by</p>,
            cell: cell => <span className='text-gray7 font-medium'>{cell.renderValue()}</span>,
            enableSorting: false,
        }),
        columnHelper.accessor('payment_method', {
            header: () => <p>Payment method</p>,
            cell: cell => <span className='text-gray7 font-medium'>{cell.renderValue()}</span>,
            enableSorting: false,
        }),
    ]
    return (
        <div className='rounded-lg'>
            <Table
                data={subscription}
                columns={columns}
                emptyStateTitle='No Subscription'
                emptyStateDescription='No subscription has been created yet'
            />
        </div>
    )
}
