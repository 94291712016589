import React, { useEffect, useState } from 'react'

import { Control, Controller } from 'react-hook-form'
import usePersistedState from '@/hooks/usePersistedState'
import { formProps } from './utils'
import { useSendTransferOtp } from '@/services/payout/mutations'
import { calculateTransferFee, formatAmount } from '@/utils/money'
import { QueryOrgVendors } from '@/services/vendors/types'
import { QueryOrgMembers } from '@/services/employees/types'
import { EmployeeType } from '@/types/org'
import { QueryGetAllBanks, UserAccount } from '@/services/payout/types'
import { Vendor } from '@/types/vendor'

type ConfirmTransferProps = {
    selectedMembers: {
        amount: string
        label: string
        value: string
        bankAccounts: UserAccount[]
    }[]
    control: Control<formProps, any>
    values: () => formProps
    orgMembers?: QueryOrgMembers
    orgVendors?: QueryOrgVendors
    resolvedBank: Record<'account_number' | 'account_name', string>
    allBankList: QueryGetAllBanks
}

const ConfirmTransfer = ({
    selectedMembers,
    control,
    values,
    orgMembers,
    orgVendors,
    resolvedBank,
    allBankList,
}: ConfirmTransferProps) => {
    const [timer, setTimer] = usePersistedState({ key: 'payrollTimer', defaultValue: 120 })

    const [userPaymentAccount, setUserPaymentAccount] = useState<EmployeeType[]>([])
    const [vendorAccount, setVendorAccount] = useState<Vendor>()
    const [externalUserBankname, setExternalUserBankName] = useState('')

    const { mutate: sendTransferOtpFn } = useSendTransferOtp({})
    // console.log(values?.())

    useEffect(() => {
        setExternalUserBankName(allBankList?.find(bank => bank?.code === values?.()?.accnt_name)?.name as string)
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (timer > 0) {
                setTimer(timer - 1)
            }
            if (timer === 0) {
                setTimer(0)
                clearInterval(interval)
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    useEffect(() => {
        setUserPaymentAccount(() => {
            return values?.()?.employeeNum !== 'multiple'
                ? (orgMembers?.filter(member => member?.id === values?.()?.employee) as EmployeeType[])
                : ([] as EmployeeType[])
        })
    }, [orgMembers, orgVendors, values])

    useEffect(() => {
        setVendorAccount(() => orgVendors?.vendors?.find(vendor => vendor?.id === values?.()?.vendor))
    }, [orgVendors])

    const getTotalAmountForMultipleMembers = () => {
        return selectedMembers?.reduce((acc, val) => {
            if (values?.()?.sameAmountCheckBox?.toString() === 'true') {
                return Number(acc) + Number(values?.()?.amount)
            }
            return Number(acc) + Number(val?.amount ?? 0)
        }, 0)
    }
    // console.log(values?.())

    return (
        <div className=''>
            <h1 className='text-xl font-semibold text-[#202020] mb-3'>Confirm Transfer</h1>
            <div className='bg-[#F9F9F9] rounded-xl p-5 pb-4  mb-8'>
                {values?.()?.employeeNum !== 'multiple' &&
                    values?.()?.recipient !== 'vendors' &&
                    values?.()?.recipient !== 'recipient' &&
                    userPaymentAccount?.[0]?.bankAccounts
                        ?.filter(bank => !!bank?.is_primary)
                        ?.map(member => {
                            return (
                                <>
                                    <div className='mb-5'>
                                        <h1>Amount</h1>
                                        <p className='text-2xl font-semibold text-[#202020]'>
                                            {formatAmount(values?.()?.amount)}
                                            {/* {formatAmount(feePlusAmount * 100)} */}
                                        </p>
                                        <span className='text-[12px] text-[#838383] mb-1 block'>
                                            Fee:{' '}
                                            {Number(values?.()?.amount) > 0
                                                ? formatAmount(calculateTransferFee(values?.()?.amount) * 100)
                                                : formatAmount(0)}
                                        </span>
                                    </div>

                                    <div className='grid grid-cols-3 gap-5 bg-[#EFEFF9] p-3 rounded-lg'>
                                        <div>
                                            <h3 className='text-[#5E5E5E] font-medium'>Acct Name</h3>
                                            <p className='text-lg font-medium truncate'>
                                                {member?.account_name ?? 'N/A'}
                                            </p>
                                        </div>

                                        <div>
                                            <h3 className='text-[#5E5E5E] font-medium'>Acct Number</h3>
                                            <p className='text-lg font-medium'>{member?.account_number ?? ' N/A'}</p>
                                        </div>
                                        <div>
                                            <h3 className='text-[#5E5E5E] font-medium'>Bank</h3>
                                            <p className='text-lg font-medium truncate'>{member?.bank_name ?? 'N/A'}</p>
                                        </div>
                                    </div>
                                </>
                            )
                        })}

                {values?.()?.recipient === 'recipient' && (
                    <div className='flex gap-5 flex-wrap'>
                        <div>
                            <h3 className='text-[#5E5E5E] font-medium'>Acct Name</h3>
                            <p className='text-lg font-medium'>{resolvedBank?.account_name ?? 'N/A'}</p>
                        </div>

                        <div>
                            <h3 className='text-[#5E5E5E] font-medium'>Acct Number</h3>
                            <p className='text-lg font-medium'>{resolvedBank?.account_number ?? ' N/A'}</p>
                        </div>
                        <div>
                            <h3 className='text-[#5E5E5E] font-medium'>Bank</h3>
                            <p className='text-lg font-medium'>{externalUserBankname ?? 'N/A'}</p>
                        </div>

                        <div>
                            <h3 className='text-[#5E5E5E] font-medium'>Amount</h3>

                            <p className='text-lg font-medium'>{formatAmount(values?.()?.amount)}</p>
                        </div>
                    </div>
                )}

                {values?.()?.employeeNum === 'multiple' && (
                    <>
                        <div className=''>
                            <h1>Total amount</h1>
                            <p className='text-2xl font-semibold text-[#202020]'>
                                {formatAmount(getTotalAmountForMultipleMembers())}
                            </p>
                            <span className='text-[12px] text-[#838383] mb-1 block'>
                                Fee:{' '}
                                {Number(values?.()?.amount) > 0
                                    ? formatAmount(calculateTransferFee(getTotalAmountForMultipleMembers() + '') * 100)
                                    : formatAmount(0)}
                            </span>
                        </div>
                        <p className='mt-4 text-[#5E5E5E] font-medium '>Recipients {selectedMembers?.length}</p>
                        <div className='rounded-xl pb-3 pt-2 flex flex-wrap gap-3 gap-y-2 bg-[#EFEFF9] mt-2'>
                            {selectedMembers.map(member => {
                                const primaryBankAccount = member?.bankAccounts?.find(account => account?.is_primary)

                                return (
                                    <div className=' px-3 py-2 rounded-md text-sm font-medium grid grid-cols-4 gap-4'>
                                        <div className='whitespace-nowrap'>
                                            <p> Acct name </p>
                                            <p className='truncate'>{primaryBankAccount?.account_name}</p>
                                        </div>
                                        <div className='whitespace-nowrap'>
                                            <p>Acct no </p>
                                            <p>{primaryBankAccount?.account_number}</p>
                                        </div>
                                        <div className='whitespace-nowrap'>
                                            <p>Bank name </p>
                                            <p className='truncate'>{primaryBankAccount?.bank_name}</p>
                                        </div>

                                        <div className='whitespace-nowrap'>
                                            <p>Amount </p>
                                            <p>
                                                {values?.().sameAmountCheckBox
                                                    ? formatAmount(values?.()?.amount)
                                                    : formatAmount(member.amount)}
                                            </p>
                                        </div>

                                        {/* {JSON.stringify(primaryBankAccount)} */}
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )}
                {values?.()?.recipient === 'vendors' && (
                    <>
                        <div className='mb-5'>
                            <h1>Amount</h1>
                            <p className='text-2xl font-semibold text-[#202020]'>{formatAmount(values?.()?.amount)}</p>
                            <span className='text-[12px] text-[#838383] mb-1 block'>
                                Fee:{' '}
                                {Number(values?.()?.amount) > 0
                                    ? formatAmount(calculateTransferFee(values?.()?.amount) * 100)
                                    : formatAmount(0)}
                            </span>
                        </div>
                        <div className='grid grid-cols-3 gap-3 bg-[#EFEFF9] p-3 rounded-lg'>
                            <div>
                                <h3 className='text-[#5E5E5E] font-medium'>Acct Name</h3>
                                <p className='text-lg font-medium truncate'>{vendorAccount?.name ?? 'N/A'}</p>
                            </div>

                            <div>
                                <h3 className='text-[#5E5E5E] font-medium'>Acct Number</h3>
                                <p className='text-lg font-medium'>
                                    {vendorAccount?.bankAccount?.account_number ?? ' N/A'}
                                </p>
                            </div>
                            <div>
                                <h3 className='text-[#5E5E5E] font-medium'>Bank</h3>
                                <p className='text-lg font-medium truncate'>
                                    {vendorAccount?.bankAccount?.bank_name ?? 'N/A'}
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <section className=''>
                <p className='mt-[10px] font-medium text-[#202020] text-[14px]'>
                    An OTP has been sent to your email to verify this action. Kindly enter OTP to proceed
                </p>
                <form>
                    <div className='flex flex-col md:flex-row mt-6 '>
                        <div className='flex flex-col flex-1 gap-3'>
                            <label htmlFor='email' className='text-[#202020] text-[14px]'>
                                Enter OTP
                            </label>
                            <Controller
                                name='otp'
                                rules={{
                                    required: true,
                                    minLength: 4,
                                }}
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className='h-[3.5rem] text-dark rounded-md border border-gray1 focus:border-2 focus:border-brand bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none disabled:cursor-not-allowed'
                                        type='number'
                                        {...field}
                                        placeholder='Enter your OTP'
                                    />
                                )}
                            />
                        </div>
                    </div>
                </form>
                <p className='mt-4 text-sm font-medium'>
                    I did not receive OTP.{' '}
                    <button
                        className='text-[#454ADE] font-semibold disabled:text-[#A8A8A8]'
                        onClick={() => {
                            sendTransferOtpFn({})
                            setTimer(120)
                        }}
                        disabled={timer > 0}
                    >
                        {' '}
                        Resend
                    </button>{' '}
                    {timer !== 0 && <span className='text-[#A8A8A8]'>in {timer < 10 ? `0${timer}` : timer} secs</span>}
                </p>
            </section>
        </div>
    )
}

export default ConfirmTransfer
