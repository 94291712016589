import ProgressBar from '@/components/ProgressBar'
import { useAuthContext } from '@/context'
import { useGetOrgPlans } from '@/services/billing/queries'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { format } from 'date-fns'
import { ArrowRight } from 'lucide-react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import PlansCard from './components/PlansCard'
import { Billing } from '@/types/billing'

export type Method = {
    type: string
    name: string
    account_name: string
    card_number: string
    card_type: string
    primary: boolean
    balance?: number
}

export default function PlansAndPricing() {
    const { selectedAccount } = useAuthContext()
    const orgBusinessPlan = selectedAccount?.org?.business_plan
    const orgSubscription = selectedAccount?.org?.subscription

    const { data, isLoading } = useGetOrgPlans({})
    const orgPlans = useMemo(() => data ?? [], [data])

    const payment_details = [
        {
            name: 'Payment date',
            desc: orgSubscription?.start_date
                ? format(new Date(orgSubscription?.start_date), 'dd/MM/yyyy  (KK:mm:ss)')
                : '',
        },
        { name: 'Plan', desc: `${capitalizeText((orgBusinessPlan?.plan as Billing)?.name)} / month` },
        { name: 'Total amount', desc: formatAmount(orgBusinessPlan?.amount) },
        {
            name: 'Next due date',
            desc:
                orgSubscription?.next_renewal_date &&
                format(new Date(orgSubscription?.next_renewal_date), 'dd/MM/yyyy    (KK:mm:ss)'),
        },
    ]

    const navigate = useNavigate()

    if (isLoading) return <ProgressBar />
    return (
        <main>
            <section className='flex justify-between items-end'>
                <div className='flex flex-col'>
                    <span className='font-semibold text-lg'>Subscription</span>
                    <span className='text-[#5E5E5E] font-medium text-sm mt-2'>
                        Manage your pricing plan and upgrade plans when necessary.
                    </span>
                </div>
            </section>
            <section className='mt-10 mr-5 lg:mr-10'>
                <section className='grid grid-cols-1 lg:grid-cols-3 justify-evenly gap-12'>
                    {orgPlans
                        ?.filter(plan => !plan?.archived)
                        ?.sort((plan_1, plan_2) =>
                            orgBusinessPlan?.plan === plan_1?.id ? -1 : plan_2?.id === orgBusinessPlan?.plan ? 1 : 0
                        )
                        .map(plan => (
                            <PlansCard
                                plan_id={plan?.id}
                                amount={formatAmount(plan?.price)}
                                desc={plan?.description}
                                title={plan?.name}
                                active={(orgBusinessPlan?.plan as Billing)?.id === plan?.id}
                                plans={plan?.metadata}
                                btn_title={orgBusinessPlan?.plan === plan?.id ? 'Current Plan' : 'Upgrade Plan'}
                            />
                        ))}
                </section>
                <section className='mt-10 bg-white rounded-xl p-6'>
                    <div className='flex justify-between'>
                        <span className='font-semibold text-lg'>Last payment</span>
                        <button
                            className='flex items-center gap-2 text-[#454ADE] text-[.95rem]'
                            onClick={() => navigate('/admin/dashboard/settings/company/payment/history')}
                        >
                            See payment history <ArrowRight />{' '}
                        </button>
                    </div>
                    <div className='flex flex-wrap gap-4 mt-8'>
                        {payment_details
                            .filter(detail => !!detail.desc)
                            .map(detail => (
                                <div className='flex flex-col min-w-[10rem]'>
                                    <span className='text-[#838383] font-medium text-base'>{detail.name}</span>
                                    <span className='font-medium mt-2 text-base'>{capitalizeText(detail.desc)}</span>
                                </div>
                            ))}
                    </div>
                </section>
            </section>
        </main>
    )
}
