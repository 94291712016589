import { LogOutIcon } from 'lucide-react'

import UserSettingsBox from './components/UserSettingsBox'
import { useAuthContext } from '@/context'
import { account_settings } from './utils'
import { useNavigate } from 'react-router-dom'
import { useLogoutUser } from '@/services/auth/mutations'

export default function UserSettings() {
    const { logoutUser } = useAuthContext()
    const adminViewSettings = location.pathname.includes('admin')

    const navigate = useNavigate()

    const { mutate: logoutFn } = useLogoutUser({
        onSuccess: () => {
            logoutUser()
            navigate('/')
        },
    })

    const handleLogOut = () => {
        logoutUser()
        logoutFn({})
    }

    return (
        <main className={`mt-8 ${adminViewSettings ? '' : ' px-4 lg:px-[2.99rem]'} mx-auto  min-h-[100vh]`}>
            <section className='flex justify-between items-end'>
                <div className='flex flex-col'>
                    <span className='font-semibold text-xl'>Account settings</span>
                    <span className='text-[#5E5E5E] font-medium mt-2 w-[250px] md:w-auto'>
                        {' '}
                        Make changes to your profile, security and more.
                    </span>
                </div>
                <div className='flex gap-2 items-center text-[#CF491E] lg:mr-8' onClick={handleLogOut}>
                    <LogOutIcon />
                    Log out
                </div>
            </section>
            <section className='grid_container gap-6 mt-8 pb-24 md:pb-0 max-w-[90rem]'>
                {account_settings.map(setting => {
                    if (adminViewSettings && !setting?.enableforAdmin) return
                    return (
                        <UserSettingsBox
                            title={setting.title}
                            title_exp={setting.title_exp}
                            to={setting.to}
                            key={setting.type}
                        />
                    )
                })}
            </section>
        </main>
    )
}
