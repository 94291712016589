import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import React, { useState } from 'react'
import { leaveDays } from '../../../helper'
import { LeaveSettingsType } from '@/types/payroll'
import { useUpdateOrgLeaveSettings } from '@/services/payroll/mutations'
import { toastHandler } from '@/components/utils/Toast'

type Props = {
    setEditLeavSettings: React.Dispatch<React.SetStateAction<boolean>>
    leaveSettingsData: LeaveSettingsType | undefined
}
export default function LeaveGenerationSetting({ setEditLeavSettings, leaveSettingsData }: Props) {
    const [leaveSettings, setLeaveSettings] = useState({
        sick_days: leaveSettingsData?.sick_days?.toString(),
        vacation: leaveSettingsData?.vacation?.toString(),
        personal: leaveSettingsData?.miscellaneous?.toString(),
        childSupport: leaveSettingsData?.parental?.toString(),
    })

    const { mutate: updateOrgLeaveSettings, isLoading } = useUpdateOrgLeaveSettings({
        queryParams: {
            leave_setting_id: leaveSettingsData?.id as string,
        },
        onSuccess: () => {
            setEditLeavSettings(false)
            toastHandler({ message: 'Changes saved', state: 'success' })
        },
    })
    return (
        <div className='p-6 rounded-lg border border-[#DADCE0] my-5'>
            <div className='flex  justify-between items-center mb-6'>
                <div className=''>
                    <h2 className='text-lg font-medium'>Leave management</h2>
                    <p className='text-sm text-[#5E5E5E] font-medium mt-1'>Set leave allowances for all your users</p>
                </div>
            </div>
            <div className='flex flex-wrap gap-x-12 gap-y-6 mb-11'>
                <div className='min-w-[20rem] w-1/4'>
                    <h2 className='text-[#838383] text-base mb-4'>Sick days</h2>
                    <Select
                        name='sick_days'
                        onValueChange={data =>
                            setLeaveSettings(prev => ({
                                ...prev,
                                sick_days: data,
                            }))
                        }
                        value={leaveSettings?.sick_days}
                    >
                        <SelectTrigger
                            className={`h-[2.5rem] border-[#DADCE0] bg-base_gray_100  text-base w-full font-medium `}
                        >
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent className='text-sm w-[19rem]'>
                            {leaveDays.sickDays.map(option => (
                                <SelectItem value={option.id} className='cursor-pointer'>
                                    {option.name} in a year
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div className='min-w-[20rem] w-1/4'>
                    <h2 className='text-[#838383] text-base mb-4'>Vacation</h2>
                    <Select
                        name='pay_day'
                        onValueChange={data =>
                            setLeaveSettings(prev => ({
                                ...prev,
                                vacation: data,
                            }))
                        }
                        value={leaveSettings?.vacation}
                    >
                        <SelectTrigger
                            className={`h-[2.5rem] border-[#DADCE0] bg-base_gray_100  text-base w-full font-medium `}
                        >
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent className='text-sm w-[19rem]'>
                            {leaveDays.vacation.map(option => (
                                <SelectItem value={option.id} className='cursor-pointer'>
                                    {option.name} in a year
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div className='min-w-[20rem] w-1/4'>
                    <h2 className='text-[#838383] text-base mb-4'>Personal</h2>
                    <Select
                        name='personal'
                        onValueChange={data =>
                            setLeaveSettings(prev => ({
                                ...prev,
                                personal: data,
                            }))
                        }
                        value={leaveSettings?.personal}
                    >
                        <SelectTrigger
                            className={`h-[2.5rem] border-[#DADCE0] bg-base_gray_100  text-base w-full font-medium `}
                        >
                            <SelectValue placeholder={'Select an option'} />
                        </SelectTrigger>
                        <SelectContent className='text-sm w-[19rem]'>
                            {leaveDays.personal.map(option => (
                                <SelectItem value={option.id} className='cursor-pointer'>
                                    {option.name} in a year
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div className='min-w-[20rem] w-1/4'>
                    <h2 className='text-[#838383] text-base mb-4'>Maternity / Paternity</h2>
                    <Select
                        name='childSupport'
                        onValueChange={data =>
                            setLeaveSettings(prev => ({
                                ...prev,
                                childSupport: data,
                            }))
                        }
                        value={leaveSettings?.childSupport}
                    >
                        <SelectTrigger
                            className={`h-[2.5rem] border-[#DADCE0] bg-base_gray_100  text-base w-full font-medium `}
                        >
                            <SelectValue placeholder={'Select an option'} />
                        </SelectTrigger>
                        <SelectContent className='text-sm w-[19rem]'>
                            {leaveDays.childSupport.map(option => (
                                <SelectItem value={option.id} className='cursor-pointer'>
                                    {option.name} in a year
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
            </div>
            <div className='flex gap-3 justify-end'>
                <Button
                    className='bg-transparent hover:bg-transparent hover:border hover:border-[#838383] text-[#202020]'
                    onClick={() => setEditLeavSettings(false)}
                >
                    cancel
                </Button>
                <Button
                    onClick={() => {
                        updateOrgLeaveSettings({
                            ...leaveSettings,
                            miscellaneous: leaveSettings?.personal,
                            parental: leaveSettings?.childSupport,
                        })
                    }}
                    loading={isLoading}
                >
                    Save changes
                </Button>
            </div>
        </div>
    )
}
