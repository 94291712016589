import { Button } from '@/components'
import ModalWrapper from '../../ModalWrapper'
import { toastHandler } from '@/components/utils/Toast'
import { Customers } from '@/types/customers'
import { useMutationDeleteCustomer } from '@/services/customers/mutations'
import { useNavigate } from 'react-router-dom'

type PaymentProps = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    customer?: Customers
    fromDetails?: boolean
}

const DeleteCustomer = ({ closeModal, customer, fromDetails }: PaymentProps) => {
    const navigate = useNavigate()

    const { mutate: deleteCustomer, isLoading: isDEleting } = useMutationDeleteCustomer({
        queryParams: {
            id: customer?.id as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'customer deleted', state: 'success' })
            closeModal(false)
            if (fromDetails) {
                navigate(-1)
            }
        },
    })
    return (
        <ModalWrapper>
            <main className='h-[14rem] w-screen lg:w-[34rem]'>
                <div className='pt-10'>
                    <p className='text-[#5E5E5E] font-semibold text-lg mb-10 w-[85%] mx-auto'>
                        Are you sure you want to delete this customer? This action is irreversible.
                    </p>
                    <div className='flex gap-5 justify-end pr-10'>
                        <Button
                            className='bg-transparent text-[#202020] px-5 h-[3.5rem] rounded-lg text-xs disabled:bg-transparent hover:bg-transparent min-w-[100px]'
                            onClick={() => closeModal(false)}
                            disabled={isDEleting}
                        >
                            Cancel
                        </Button>

                        <Button
                            className='bg-errorRed text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px] hover:bg-errorRed hover:opacity-90 disabled:bg-errorRed disabled:opacity-30'
                            onClick={() => {
                                deleteCustomer({})
                            }}
                            loading={isDEleting}
                        >
                            Delete Customer
                        </Button>
                    </div>
                </div>
            </main>
        </ModalWrapper>
    )
}

export default DeleteCustomer
