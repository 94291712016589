import { useNavigate, useParams } from 'react-router-dom'
import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import PageContent from '@/layout/PageContent'
import TransactionsHistory from './partials/TransactionsHistory'
import TransactionAnalytics from './partials/TransactionAnalytics'
import { isAdminRoute } from '@/utils/permissions'
import { motion } from 'framer-motion'
import { SwitchOrgInViewMode } from '@/components/SwitchOrgInView'

const transactions_routes_const = {
    history: 'transactions_history',
    analytics: 'transactions_analytics',
}
const Transactions = () => {
    const { transactions_subRoute } = useParams()
    const navigate = useNavigate()
    const adminRoute = isAdminRoute()

    const orgViewSubroute = location.pathname.includes('company') ? '/company' : '/personal'

    return (
        <>
            {isAdminRoute() && (
                <section className='border-b border-gray4'>
                    {' '}
                    <SwitchOrgInViewMode />
                </section>
            )}
            <section className='text-gray7 mt-6'>
                <Tabs defaultValue={transactions_subRoute} value={transactions_subRoute}>
                    <TabsList className='border-none'>
                        <TabTriggersContainer>
                            <TabsTrigger
                                value={transactions_routes_const.history}
                                onClick={() =>
                                    navigate(
                                        `/${adminRoute ? 'admin/' : ''}dashboard/transactions/${
                                            transactions_routes_const.history
                                        }${adminRoute ? orgViewSubroute : ''}`
                                    )
                                }
                                className={`relative border-none ${
                                    transactions_routes_const.history === transactions_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {transactions_routes_const.history === transactions_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                    Transactions
                                </motion.span>
                            </TabsTrigger>
                            <TabsTrigger
                                value={transactions_routes_const.analytics}
                                onClick={() =>
                                    navigate(
                                        `/${adminRoute ? 'admin/' : ''}dashboard/transactions/${
                                            transactions_routes_const.analytics
                                        }${adminRoute ? orgViewSubroute : ''}`
                                    )
                                }
                                className={`relative border-none ${
                                    transactions_routes_const.analytics === transactions_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {transactions_routes_const.analytics === transactions_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                    Analytics
                                </motion.span>
                            </TabsTrigger>
                        </TabTriggersContainer>
                    </TabsList>
                    <TabsContent value={transactions_routes_const.history}>
                        <PageContent>
                            <TransactionsHistory />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={transactions_routes_const.analytics}>
                        <PageContent>
                            <TransactionAnalytics />
                        </PageContent>
                    </TabsContent>
                </Tabs>
            </section>
        </>
    )
}

export default Transactions
