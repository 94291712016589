// import CheckBox from '@/components/Table/CheckBox'
import StatusColor from '@/components/utils/StatusColor'
import { ContractPayrollType, MilestoneType } from '@/types/payroll'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import React, { useState } from 'react'
import TableActions from '@/components/Table/TableActions'
import Table from '@/components/Table'
import { formatAmount } from '@/utils/money'
import { format } from 'date-fns'
import ContractPaymentRoot from '@/components/Modals/Payroll/Contract/ContractPaymentRoot'
import { isAdminRoute } from '@/utils/permissions'
import { useUpdateMilestoneStatus } from '@/services/payroll/mutations'
import { usePayrollContext } from '@/context'
import { CgSpinner } from 'react-icons/cg'
import { MoreHorizontal } from 'lucide-react'
import DeleteMilestonePrompt from '@/components/Modals/Payroll/Contract/DeleteMilestonePrompt'

const MilestonesTable = ({ data, contract }: { data: MilestoneType[]; contract?: ContractPayrollType }) => {
    const [showPaymentModal, setShowPaymentModal] = useState(false)
    const [showDeletePrompt, setShowDeletePrompt] = useState(false)

    const { setSelectedContract, setUserContractPayroll } = usePayrollContext()
    const [mileStoneData, setMileStoneData] = useState<MilestoneType>()
    const [milestoneToDel, setMilestoneToDel] = useState<MilestoneType>()

    const columnHelper = createColumnHelper<MilestoneType>()

    const { mutate: updateStatusFn, isLoading } = useUpdateMilestoneStatus({
        queryParams: {
            contract_id: contract?.id as string,
            milestone_id: (mileStoneData?._id ?? mileStoneData?.id) as string,
        },
        onSuccess: () => {
            setSelectedContract(undefined)
            setUserContractPayroll(undefined)
            // setRowId(undefined)
        },
    })

    const [rowSelection, setRowSelection] = React.useState({})
    const columns: ColumnDef<MilestoneType, any>[] = [
        // columnHelper.display({
        //     id: 'select',
        //     header: ({ table }) => (
        //         <CheckBox
        //             {...{
        //                 checked: table?.getIsAllRowsSelected(),
        //                 indeterminate: table?.getIsSomeRowsSelected(),
        //                 onChange: table?.getToggleAllRowsSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     cell: ({ row }) => (
        //         <CheckBox
        //             {...{
        //                 checked: row.getIsSelected(),
        //                 disabled: !row.getCanSelect(),
        //                 indeterminate: row.getIsSomeSelected(),
        //                 onChange: row.getToggleSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     enableSorting: false,
        // }),
        columnHelper.accessor('title', {
            header: () => <p>Milestone title</p>,
            cell: cell => cell.renderValue(),
            // enableSorting: true,
        }),
        columnHelper.accessor('due_date', {
            header: () => <>Due date</>,
            cell: cell => {
                return <>{cell?.renderValue() && format(new Date(cell?.renderValue()), 'dd/MM/yyyy')}</>
            },
            enableSorting: true,
        }),
        columnHelper.accessor('amount', {
            header: () => <p>Amount</p>,
            cell: cell => <div className=''>{formatAmount(cell.renderValue())}</div>,
            enableSorting: true,
        }),
        columnHelper.accessor('progress_status', {
            header: () => <p>Status</p>,
            cell: cell => {
                return <StatusColor status={contract?.status === 'cancelled' ? 'cancelled' : cell?.renderValue()} />
            },
            enableSorting: true,
        }),
        columnHelper.accessor('payment_status', {
            header: () => <p>Payment</p>,
            cell: cell => (
                <div className=''>
                    {cell?.row?.original?.payment_status === 'paid' ? (
                        <div>
                            <h6>Paid</h6>
                            <p className='mt-1 text-[#5E5E5E]'>{formatAmount(cell.row.original.amount)}</p>
                        </div>
                    ) : cell?.row?.original?.payment_status === 'processing' ? (
                        <div>
                            <h6>Processing</h6>
                            <p className='mt-1 text-[#5E5E5E]'>{formatAmount(cell.row.original.amount)}</p>
                        </div>
                    ) : (
                        'Unpaid'
                    )}
                </div>
            ),
            enableSorting: true,
        }),
        columnHelper.display({
            id: 'actions',
            cell: row => {
                const rowId = row.row.original._id ?? row.row.original.id
                const selectedMilestoneId = mileStoneData?._id ?? mileStoneData?.id
                const paymentStatus = row.row.original.payment_status
                const progressStatus = row.row.original.progress_status

                if (contract?.status === 'cancelled') return null
                if (isLoading && rowId !== selectedMilestoneId)
                    return <MoreHorizontal className='h-5 w-5' aria-hidden='true' />
                if (isLoading && rowId === selectedMilestoneId) return <CgSpinner className='animate-spin' />
                if (paymentStatus === 'paid' || paymentStatus === 'processing') return
                if (progressStatus === 'completed' && !isAdminRoute()) return
                return (
                    <TableActions
                        actions={[
                            {
                                label: 'Mark as completed',
                                method: () => {
                                    setMileStoneData(row?.row.original as unknown as MilestoneType)
                                    updateStatusFn({ progress_status: 'completed' })
                                    // setShowPaymentModal(true)
                                },
                                hidden: progressStatus !== 'in progress',
                                actionColor: 'text-[#5E5E5E]',
                            },
                            {
                                label: 'Mark as in progress',
                                method: () => {
                                    setMileStoneData(row?.row.original as unknown as MilestoneType)
                                    updateStatusFn({ progress_status: 'in progress' })
                                    // setShowPaymentModal(true)
                                },
                                hidden: progressStatus !== 'pending',
                                actionColor: 'text-[#5E5E5E]',
                            },
                            {
                                label: 'Mark as approved',
                                method: () => {
                                    setMileStoneData(row?.row.original as unknown as MilestoneType)
                                    setShowPaymentModal(true)
                                },
                                hidden: !isAdminRoute() || progressStatus !== 'completed',
                                actionColor: 'text-[#5E5E5E]',
                            },
                            {
                                label: 'Delete milestone',
                                method: () => {
                                    //
                                    setMilestoneToDel(row.row.original)
                                    setShowDeletePrompt(true)
                                },
                                hidden: !isAdminRoute() || progressStatus === 'completed',
                                actionColor: 'text-[#5E5E5E]',
                            },
                        ]}
                    />
                )
            },
        }),
    ]

    return (
        <section className={`px-2 ${isLoading ? 'opacity-40' : 'opacity-100'}`}>
            <Table
                data={data}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                // handleSort={handleSort}
            />
            {showPaymentModal && (
                <ContractPaymentRoot
                    closeModal={setShowPaymentModal}
                    contract={contract}
                    mileStoneData={mileStoneData}
                />
            )}
            {showDeletePrompt && (
                <DeleteMilestonePrompt
                    closeModal={setShowDeletePrompt}
                    milestone={milestoneToDel!}
                    contractId={contract?.id}
                />
            )}
        </section>
    )
}

export default MilestonesTable
