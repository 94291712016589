import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import PageContent from '@/layout/PageContent'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'

import UserContractPayProfile from '../Contract/partials/ContractPayroll/UserContractPayProfile'
import UserContractPayHistory from './partials/UserContractPayHistory'

const contract_routes_const = {
    my_contracts: 'contract_list',

    pay_history: 'pay_history',
}
export default function UserContract() {
    const { contract_subRoute } = useParams()
    const navigate = useNavigate()

    return (
        <section className='h-full bg-white px-2 md:px-0'>
            <section className=' pb-[7rem]'>
                <Tabs defaultValue={contract_subRoute} value={contract_subRoute}>
                    <TabsList>
                        <TabTriggersContainer>
                            <TabsTrigger
                                value={contract_routes_const.my_contracts}
                                onClick={() => navigate(`/dashboard/contract/${contract_routes_const.my_contracts}`)}
                                className='relative border-none'
                            >
                                {contract_routes_const.my_contracts === contract_subRoute && (
                                    <motion.div
                                        layoutId='selected_btn'
                                        className={'absolute bottom-0 w-full h-[2px] bg-black'}
                                    />
                                )}
                                My Contracts
                            </TabsTrigger>
                            <TabsTrigger
                                value={contract_routes_const.pay_history}
                                onClick={() => navigate(`/dashboard/contract/${contract_routes_const.pay_history}`)}
                                className='relative border-none'
                            >
                                {contract_routes_const.pay_history === contract_subRoute && (
                                    <motion.div
                                        layoutId='selected_btn'
                                        className={'absolute bottom-0 w-full h-[2px] bg-black'}
                                    />
                                )}
                                Pay history
                            </TabsTrigger>
                        </TabTriggersContainer>
                    </TabsList>

                    <TabsContent value={contract_routes_const.pay_history}>
                        <PageContent>
                            <UserContractPayHistory />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={contract_routes_const.my_contracts}>
                        <PageContent>
                            <UserContractPayProfile />
                        </PageContent>
                    </TabsContent>
                </Tabs>
            </section>
        </section>
    )
}
