// import CheckBox from '@/components/Table/CheckBox'
import TableActions from '@/components/Table/TableActions'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import React, { useState } from 'react'
import Table from '@/components/Table'
import { useInvoiceContext } from '@/context'
import { formatAmount } from '../../../../utils/money'
import { ColumnSort } from '@/types/utils'
import InvoiceEmptyState from '@/assets/invoice-empty-state.svg'
import { AddItemProps } from '@/types/invoice'
import DeleteItemPrompt from '@/components/Modals/Invoice/DeleteItemPrompt'
import { Delete } from 'lucide-react'

type InvoiceTableProps = {
    data: AddItemProps[]
}
const InvoiceItemTable = ({ data }: InvoiceTableProps) => {
    const columnHelper = createColumnHelper<AddItemProps>()
    const { setSort } = useInvoiceContext()

    const [promptModal, setPromptModal] = useState(false)
    const [itemDetails, setItemDEtails] = useState<AddItemProps>()
    // const navigate = useNavigate()

    const [rowSelection, setRowSelection] = React.useState({})

    // const { invoice_item_id } = useParams()
    const handleSort = (sort: ColumnSort) => {
        setSort?.(sort)
    }

    const columns: ColumnDef<AddItemProps, any>[] = [
        // columnHelper.display({
        //     id: 'select',
        //     header: ({ table }) => (
        //         <CheckBox
        //             {...{
        //                 checked: table?.getIsAllRowsSelected(),
        //                 indeterminate: table?.getIsSomeRowsSelected(),
        //                 onChange: table?.getToggleAllRowsSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     cell: ({ row }) => (
        //         <CheckBox
        //             {...{
        //                 checked: row.getIsSelected(),
        //                 disabled: !row.getCanSelect(),
        //                 indeterminate: row.getIsSomeSelected(),
        //                 onChange: row.getToggleSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     enableSorting: false,
        // }),
        columnHelper.accessor('name', {
            header: () => <p>Title</p>,
            cell: cell => <span>{cell.renderValue()}</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('unit_cost', {
            header: () => <p>Unit Cost</p>,
            cell: cell => {
                return formatAmount(cell.renderValue())
            },
            enableSorting: true,
        }),
        // columnHelper.accessor('quantity', {
        //     header: () => <>Units</>,
        //     cell: cell => cell.renderValue(),
        //     enableSorting: true,
        // }),
        // columnHelper.accessor('total_cost', {
        //     header: () => <p>Total cost</p>,
        //     cell: cell => {
        //         const total = (cell.row.original.quantity as number) * Number(cell.row.original.unit_cost)
        //         return formatAmount(total)
        //     },
        //     enableSorting: true,
        // }),
        columnHelper.accessor('description', {
            header: () => <p>Description</p>,
            cell: cell => {
                return <>{cell.renderValue() ?? 'N/A'}</>
            },
            enableSorting: true,
        }),
        columnHelper.display({
            id: 'actions',
            cell: row => {
                return (
                    <TableActions
                        actions={[
                            {
                                label: 'Delete Item',
                                icon: Delete,
                                method: () => {
                                    setPromptModal(true)
                                    setItemDEtails(row?.row.original)
                                    handleRowClick(row?.row.original)
                                },
                                actionColor: 'text-[#CF491E]',
                            },
                        ]}
                    />
                )
            },
        }),
    ]

    const handleRowClick = (invoiceItem: AddItemProps) => {
        // navigate(`/admin/dashboard/invoice/${invoice_item_id}/${invoiceItem._id}`)

        setItemDEtails(invoiceItem)
        // console.warn(invoiceItem + 'here')
    }
    return (
        <section className='h-full'>
            <Table
                data={data}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleRowClicked={handleRowClick}
                rowClickable={true}
                handleSort={handleSort}
                emptyStateDescription='Create and issue invoices to your customers by clicking the “New invoice item” button at the top-right corner'
                emptyStateTitle='No invoice item created or issued yet'
                emptyStateImg={InvoiceEmptyState}
            />
            {promptModal && <DeleteItemPrompt closeModal={setPromptModal} itemDetails={itemDetails} />}
        </section>
    )
}

export default InvoiceItemTable
