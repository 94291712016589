export type filter_by_Props = {
    [key: string]: any
}

export const modalVariants = {
    hidden: {
        opacity: 0,
        x: '100%',
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.3,
        },
    },
}

const date = new Date()

export const FilterVaultUtils = {
    periods: [
        {
            period: 'Today',
            type: 'today',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth(), date.getDate())}`
            },
        },
        {
            period: 'Yesterday',
            type: 'yesterday',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)}`
            },
        },
        {
            period: '3 days ago',
            type: '3days',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth(), date.getDate() - 3)}`
            },
        },
        {
            period: '2 weeks ago',
            type: '2weeks',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth(), date.getDate() - 14)}`
            },
        },
    ],
    due_time: [
        {
            period: '24 Hours ago',
            type: '24hrs',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)}`
            },
        },
        {
            period: 'Last 7 days',
            type: '7days',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7)}`
            },
        },
        {
            period: 'Last month',
            type: 'last_month',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth() - 1, date.getDate() - 1)}`
            },
        },
        {
            period: 'Last 6 months',
            type: '6_month',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth() - 6, date.getDate() - 1)}`
            },
        },
    ],
    filter_by: [
        { Type: { type: 'type' } },
        { Owner: { type: 'owner' } },
        { Categories: { type: 'categories' } },
        { Location: { type: 'location' } },
        { 'Date Created': { type: 'date_created' } },
        { 'Access Type': { type: 'access_type' } },
        { Approvals: { type: 'approvals' } },
    ],

    amount: [
        {
            amount: 'Less than ₦50,000.00',
            type: 'lessthan_50k',
            method: () => {
                return {
                    max: '49,999',
                    min: '0',
                }
            },
        },
        {
            amount: '₦50,000.00 - ₦200,000.00 ',
            type: 'lessthan_200k',
            method: () => {
                return {
                    max: '199,999',
                    min: '50,000',
                }
            },
        },
        {
            amount: '₦201,000.00 - ₦500,000.00 ',
            type: 'lessthan_500k',
            method: () => {
                return {
                    max: '499,999',
                    min: '201,000',
                }
            },
        },
        {
            amount: '₦501,000.00 - ₦1,000,000.00 ',
            type: 'lessthan_1m',
            method: () => {
                return {
                    max: '999,999',
                    min: '501,000',
                }
            },
        },
        {
            amount: '₦1,001,000.00 - ₦5,000,000.00',
            type: 'lessthan_5m',
            method: () => {
                return {
                    max: '4,999,999',
                    min: '1,001,000',
                }
            },
        },
        {
            amount: '₦5,001,000.00 - ₦10,000,000.00',
            type: 'lessthan_10m',
            method: () => {
                return {
                    max: '9,999,999',
                    min: '5,000,000',
                }
            },
        },
        {
            amount: '₦10,000,000.00+',
            type: 'greaterthan_10m',
            method: () => {
                return {
                    max: '9,999,999,999,999,999,999,999,999,999,999',
                    min: '10,000,000',
                }
            },
        },
    ],
}

export const VaultsDropdowns = {
    type: ['Any', 'PDF', 'DOCX', 'XLSX', 'PNG', 'JPG'],
    access_type: ['View only', 'View and edit'],
    categories: ['Bill', 'Expense', 'Receipt', 'Contract', 'SLA'],
    approvals: ['Awaiting your approval', 'Pending approval'],
    date_created: FilterVaultUtils.periods,
}

export const filter_consts = {
    owner: 'owner',
    location: 'location',
    reimbursable: 'reimbursable',
    initial: 'initial',
    max: 'max',
    min: 'min',
}
