import { TransactionContextProvider } from '@/context/transactions'
import { VendorsContextProvider } from '@/context/vendors'
import Customers from '@/modules/dashboard/Customers/Customers'
import CustomerDetails from '@/modules/dashboard/Customers/partials/CustomerDetails'
import Vendor from '@/modules/dashboard/Vendor/Vendor'
import Vendors from '@/modules/dashboard/Vendors/Vendors'
import { Outlet, Route } from 'react-router-dom'

export const partnerRoute = {}
export const usePartnerRoutes = () => {
    return (
        <Route
            element={
                <TransactionContextProvider>
                    <VendorsContextProvider>
                        <Outlet />
                    </VendorsContextProvider>
                </TransactionContextProvider>
            }
        >
            <Route path='vendors' element={<Vendors />} />
            <Route path='customers' element={<Customers />} />
            <Route path='customers/quick_actions' element={<Customers />} />
            <Route path='vendors/:vendor_id' element={<Vendor />} />
            <Route path='customers/:customer_id' element={<CustomerDetails />} />
        </Route>
    )
}
