import { useAuthContext } from '@/context'

import { ReactElement, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

const AdminRouteWrapper = ({ component }: { component: ReactNode }): ReactElement => {
    const { loggedIn, role } = useAuthContext()

    return loggedIn && (role?.name === 'ADMIN' || role?.name === 'OWNER' || role?.name === 'FINANCE_ADMIN') ? (
        <>{component}</>
    ) : (
        <Navigate to='/login' replace />
    )
}

export default AdminRouteWrapper
