import React, { createContext, useEffect, useState } from 'react'
import { TransactionType } from '@/types/transactions'
import { Pagination } from '@/types/utils'
import { isAdminRoute } from '@/utils/permissions'
import { useNavigate } from 'react-router-dom'
import { transactionRouteConst } from '@/routes/transaction.route'

export type formProps = {
    status: string
    flagged: string
    period: { from: Date | null; to: Date | null; time_in_words: string }
    mode: string
    initiated_by: string
    destination: string
    transaction_type: string
    amount: { min: string; max: string; amount_in_words: string }
    filter_now?: boolean
}

export const advancedFilteringProps = {
    status: '',
    flagged: '',
    period: { from: null, to: null, time_in_words: '' },
    mode: '',
    initiated_by: '',
    destination: '',
    transaction_type: '',
    amount: { min: '', max: '', amount_in_words: '' },
}

export type defaultTransactionProps = {
    selectedTransactions: TransactionType | undefined
    setSelectedTransactions: React.Dispatch<React.SetStateAction<TransactionType | undefined>>
    filterTransactions: formProps
    setFilterTransactions: React.Dispatch<React.SetStateAction<formProps>>
    pageNumberClicked: boolean
    setPageNumberClicked: React.Dispatch<React.SetStateAction<boolean>>
    pagination_details?: Pagination
    setPaginationDetails: React.Dispatch<React.SetStateAction<Pagination | undefined>>
    pageNumber: number
    setPageNumber: React.Dispatch<React.SetStateAction<number>>
}

const defaultTransactionState: defaultTransactionProps = {
    selectedTransactions: undefined,
    setSelectedTransactions: () => undefined,
    filterTransactions: advancedFilteringProps,
    setFilterTransactions: () => undefined,
    pageNumberClicked: false,
    setPageNumberClicked: () => undefined,
    pagination_details: undefined,
    setPaginationDetails: () => undefined,
    pageNumber: 1,
    setPageNumber: () => undefined,
}

export const TransactionContext = createContext(defaultTransactionState)

export const TransactionContextProvider = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate()
    const [selectedTransactions, setSelectedTransactions] = useState<TransactionType>()
    const [filterTransactions, setFilterTransactions] = useState<formProps>(advancedFilteringProps)
    const [pagination_details, setPaginationDetails] = useState<Pagination | undefined>()
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [pageNumberClicked, setPageNumberClicked] = useState(false)

    useEffect(() => {
        // to make sure that only the admin route gets to see the company's transaction
        if (!isAdminRoute() && window.location.pathname.includes(transactionRouteConst.company)) {
            navigate(-1)
        }
    }, [])

    const value = {
        selectedTransactions,
        setSelectedTransactions,
        filterTransactions,
        setFilterTransactions,
        pagination_details,
        setPaginationDetails,
        pageNumber,
        setPageNumber,
        pageNumberClicked,
        setPageNumberClicked,
    }
    return <TransactionContext.Provider value={value}>{children}</TransactionContext.Provider>
}
