import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import PageContent from '@/layout/PageContent'
import { motion } from 'framer-motion'
import { useNavigate, useParams } from 'react-router-dom'
import { PayrollAnalytics } from './partials/Analytics/PayrollAnalytics'
import Benefits from './partials/Benefits/Benefits'
import LeaveManagement from './partials/LeaveManagement/LeaveManagement'
import Payhistory from './partials/Payhistory/Payhistory'
import PayrollList from './partials/PayrollList/PayrollList'
import RunPayroll from './partials/RunPayroll/RunPayroll'
import PayrollSettings from './partials/Settings/PayrollSettings'
import { prodEnvironment } from '@/utils/utils'

const payroll_routes_const = {
    list: 'payroll_list',
    run_payroll: 'run_payroll',
    benefits: 'benefits',
    leave: 'leave',
    analytics: 'analytics',
    settings: 'setings',
    pay_history: 'pay_history',
    contract: 'contract',
}
export default function Payroll() {
    const { payroll_subRoute } = useParams()
    const navigate = useNavigate()
    // console.log({ data: import.meta.env })
    return (
        <section className='h-full'>
            <section className=' mt-5 '>
                <Tabs defaultValue={payroll_subRoute} value={payroll_subRoute}>
                    <TabsList className='border-none w-full overflow-y-scroll  overflow-x-scroll'>
                        <TabTriggersContainer className='min-w-[40rem]'>
                            <TabsTrigger
                                value={payroll_routes_const.list}
                                onClick={() => navigate(`/admin/dashboard/payroll/${payroll_routes_const.list}`)}
                                className={`relative border-none ${
                                    payroll_routes_const.list === payroll_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {payroll_routes_const.list === payroll_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles !w-[115%]'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                    Payment Profiles
                                </motion.span>
                            </TabsTrigger>

                            <TabsTrigger
                                value={payroll_routes_const.run_payroll}
                                onClick={() => navigate(`/admin/dashboard/payroll/${payroll_routes_const.run_payroll}`)}
                                className={`relative border-none ${
                                    payroll_routes_const.run_payroll === payroll_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {payroll_routes_const.run_payroll === payroll_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                    Run payroll
                                </motion.span>
                            </TabsTrigger>

                            <TabsTrigger
                                value={payroll_routes_const.pay_history}
                                onClick={() => navigate(`/admin/dashboard/payroll/${payroll_routes_const.pay_history}`)}
                                className={`relative border-none ${
                                    payroll_routes_const.pay_history === payroll_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {payroll_routes_const.pay_history === payroll_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                    Pay history
                                </motion.span>
                            </TabsTrigger>
                            {!prodEnvironment() ? (
                                <TabsTrigger
                                    value={payroll_routes_const.benefits}
                                    onClick={() =>
                                        navigate(`/admin/dashboard/payroll/${payroll_routes_const.benefits}`)
                                    }
                                    className={`relative border-none ${
                                        payroll_routes_const.benefits === payroll_subRoute ? '!text-white' : ''
                                    } text-sm`}
                                >
                                    {payroll_routes_const.benefits === payroll_subRoute && (
                                        <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                    )}
                                    <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                        Benefits
                                    </motion.span>
                                </TabsTrigger>
                            ) : null}
                            <TabsTrigger
                                value={payroll_routes_const.leave}
                                onClick={() => navigate(`/admin/dashboard/payroll/${payroll_routes_const.leave}`)}
                                className={`relative border-none ${
                                    payroll_routes_const.leave === payroll_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {payroll_routes_const.leave === payroll_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-1 bottom-1.5'>
                                    Leave
                                </motion.span>
                            </TabsTrigger>
                            {!prodEnvironment() ? (
                                <TabsTrigger
                                    value={payroll_routes_const.analytics}
                                    onClick={() =>
                                        navigate(`/admin/dashboard/payroll/${payroll_routes_const.analytics}`)
                                    }
                                    className={`relative border-none ${
                                        payroll_routes_const.analytics === payroll_subRoute ? '!text-white' : ''
                                    } text-sm`}
                                >
                                    {payroll_routes_const.analytics === payroll_subRoute && (
                                        <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                    )}
                                    <motion.span layout='position' className='z-20 relative left-1 bottom-1.5'>
                                        Analytics
                                    </motion.span>
                                </TabsTrigger>
                            ) : null}
                            <TabsTrigger
                                value={payroll_routes_const.settings}
                                onClick={() => navigate(`/admin/dashboard/payroll/${payroll_routes_const.settings}`)}
                                className={`relative border-none ${
                                    payroll_routes_const.settings === payroll_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {payroll_routes_const.settings === payroll_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                    Settings
                                </motion.span>
                            </TabsTrigger>
                        </TabTriggersContainer>
                    </TabsList>
                    <TabsContent value={payroll_routes_const.list}>
                        <PageContent>
                            <PayrollList />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={payroll_routes_const.run_payroll}>
                        <PageContent>
                            <RunPayroll />
                        </PageContent>
                    </TabsContent>

                    <TabsContent value={payroll_routes_const.pay_history}>
                        <PageContent>
                            <Payhistory />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={payroll_routes_const.benefits}>
                        <PageContent>
                            <Benefits />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={payroll_routes_const.leave}>
                        <PageContent>
                            <LeaveManagement />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={payroll_routes_const.analytics}>
                        <PageContent>
                            <PayrollAnalytics />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={payroll_routes_const.settings}>
                        <PageContent>
                            <PayrollSettings />
                        </PageContent>
                    </TabsContent>
                </Tabs>
            </section>
        </section>
    )
}
