import { LogOutIcon } from 'lucide-react'
import { useAuthContext } from '@/context'
import { account_settings } from '../../utils'
import CompanySettingsBox from '../../components/CompanySettingsBox'
import { useLogoutUser } from '@/services/auth/mutations'
import { useNavigate } from 'react-router-dom'
import { IsOwner } from '@/utils/permissions'
import { prodEnvironment } from '@/utils/utils'

export default function CompanySettings() {
    const { logoutUser } = useAuthContext()
    const navigate = useNavigate()

    const { mutate: logoutFn } = useLogoutUser({
        onSuccess: () => {
            logoutUser()
            navigate('/')
        },
    })

    const handleLogOut = () => {
        logoutUser()
        logoutFn({})
    }

    return (
        <main className=' mt-8 max-w-[90rem]'>
            <section className='flex justify-between items-end'>
                <div className='flex flex-col'>
                    <span className='font-semibold text-xl'>Company information</span>
                    <span className='text-[#5E5E5E] font-medium mt-2'>
                        Make changes to company profile, security and more here.
                    </span>
                </div>
                <div
                    className='flex gap-2 items-center text-[#CF491E] ml-8 mr-2 lg:ml-0 lg:mr-8 cursor-pointer whitespace-nowrap'
                    onClick={handleLogOut}
                >
                    <LogOutIcon />
                    Log out
                </div>
            </section>
            <section className='grid_container gap-6 mt-8 pb-28 lg:pb-0 mx-auto'>
                {account_settings.map(setting => {
                    if (setting?.type === 'security' && !IsOwner()) return
                    if (setting?.type === 'notification' && prodEnvironment()) return
                    return (
                        <CompanySettingsBox
                            title={setting.title}
                            title_exp={setting.title_exp}
                            to={setting.to}
                            key={setting.type}
                        />
                    )
                })}
            </section>
        </main>
    )
}
