import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext, useRolesContext } from '@/context'
import { useMutateChangeEmployeeRole } from '@/services/employees/mutations'
import { EmployeeType, RoleEnum } from '@/types/org'
import { RoleType } from '@/types/roles'
import { isAdmin } from '@/utils/permissions'
import { capitalizeText } from '@/utils/string'
import { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import ModalWrapper from '../../ModalWrapper'

type EditEmployeeProps = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    selectedEmployee: EmployeeType[]
}

const EditEmployeeModal = ({ selectedEmployee, closeModal }: EditEmployeeProps) => {
    const [selectedRole, setSelectedRole] = useState(selectedEmployee[0]?.role?.id ?? selectedEmployee[0]?.role?._id)
    const { selectedAccount } = useAuthContext()
    const { roles } = useRolesContext()

    const { mutate: changeRoleFn, isLoading: isChangingRole } = useMutateChangeEmployeeRole({
        queryParams: {
            org_account_id: selectedEmployee[0]?.id as string,
        },
        onSuccess() {
            closeModal(false)
            toastHandler({ message: 'Change(s) saved', state: 'success' })
        },
    })
    const handleChangeEmployeeRole = () => {
        changeRoleFn({ role: selectedRole })
    }

    const disableRoles = (role: RoleType) => {
        const userRole = selectedEmployee?.[0]?.role?.name
        const isOrgCreator =
            selectedEmployee?.[0]?.user?.email === selectedAccount?.org?.email &&
            selectedEmployee?.[0]?.role?.name === 'OWNER'

        if (isOrgCreator && userRole === 'OWNER') return true
        if (isOrgCreator && selectedAccount?.role?.name === 'OWNER') return false
        // if (selectedEmployee?.[0]?.role?.name === 'OWNER') return true
        if (role?.name === userRole) return true
        if (role?.name === 'OWNER' && (userRole === RoleEnum.ADMIN || RoleEnum.EMPLOYEE || RoleEnum.CONTRACTOR))
            return true
    }

    return (
        <ModalWrapper variants={undefined}>
            <div className='w-screen lg:w-[37rem] max-h-[90vh] overflow-scroll'>
                <div className='border-b border-[#DADCE0] py-3 px-2  flex justify-end'>
                    <div className='flex items-center w-[90%] lg:w-[65%] justify-between h-full'>
                        <h2 className='font-semibold text-[#31254B]'>
                            Edit - {selectedEmployee[0].user.first_name} {selectedEmployee[0].user.last_name}
                        </h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={() => closeModal(false)}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <form onSubmit={(e: React.FormEvent) => e.preventDefault()} className='mt-2 pb-6'>
                    <div className='px-[1.3rem] lg:p-[1.8rem] py-3 max-h-[400px] lg:max-h-[80vh] overflow-scroll '>
                        <div className={`flex flex-col gap-[1.6rem] mb-8`}>
                            <div className='flex flex-col flex-1 gap-2'>
                                <label className='flex items-center gap-1'>First name</label>
                                <Input
                                    type='text'
                                    name='first_name'
                                    value={selectedEmployee[0].user.first_name}
                                    className=' border-[#DADCE0]  h-[3rem] bg-[#F9F9F9] text-[#A8A8A8]'
                                    disabled
                                />
                            </div>

                            <div className='flex flex-col flex-1 gap-2'>
                                <label className='flex items-center gap-1'>Last name</label>
                                <Input
                                    type='text'
                                    name='last_name'
                                    value={selectedEmployee[0].user.last_name}
                                    className=' border-[#DADCE0]  h-[3rem] bg-[#F9F9F9] text-[#A8A8A8]'
                                    disabled
                                />
                            </div>
                            <div className='flex flex-col flex-1 gap-2'>
                                <label className='flex items-center gap-1'>Email address</label>
                                <Input
                                    type='email'
                                    name='last_name'
                                    value={selectedEmployee[0].user.email}
                                    className=' border-[#DADCE0]  h-[3rem] bg-[#F9F9F9] text-[#A8A8A8]'
                                    disabled
                                />
                            </div>

                            <div className='flex flex-col flex-1 gap-2'>
                                <label className='flex items-center gap-1'>Phone number</label>
                                <Input
                                    type='+tel'
                                    name='last_name'
                                    value={`${selectedEmployee[0].user.phone ?? 'No Number Added'}`}
                                    className={`border-[#DADCE0]  h-[3rem]  bg-[#f9f9f9] text-[#A8A8A8]`}
                                    disabled
                                />
                            </div>
                            <div className='flex flex-col flex-1 gap-2'>
                                <label className='flex items-center gap-1'>Role</label>
                                <div>
                                    <Select
                                        name='org_type'
                                        onValueChange={data => {
                                            setSelectedRole(data)
                                        }}
                                        value={selectedRole}
                                        disabled={!isAdmin(selectedAccount!)}
                                    >
                                        <SelectTrigger className='w-full h-[3rem] border-[#DADCE0]'>
                                            <SelectValue placeholder='Select role' />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {roles?.map(role => (
                                                <SelectItem value={role.id} disabled={disableRoles(role)}>
                                                    {capitalizeText(role.name)}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='px-[1.3rem] lg:px-[1.8rem] pt-3 border-t border-gray8'>
                        <Button
                            className='min-w-[100px] min-h-[20px] ml-auto'
                            onClick={handleChangeEmployeeRole}
                            loading={isChangingRole}
                        >
                            Save changes
                        </Button>
                    </div>
                </form>
            </div>
        </ModalWrapper>
    )
}

export default EditEmployeeModal
