import React from 'react'
// import BenefitsStatusBadge from './BenefitsStatusBadge'
// import PlaceHolderImg from '@/assets/ihms.svg'
import { useNavigate } from 'react-router-dom'
import { OrgBenefitProps } from '@/types/benefits'
import { formatAmount } from '@/utils/money'
import { addMonths, addYears, format } from 'date-fns'

type Props = {
    benefit: OrgBenefitProps
}

export default function BenefitSummaryCard({ benefit }: Props) {
    const navigate = useNavigate()
    const effectiveDate =
        benefit?.plan?.frequency === 'monthly'
            ? addMonths(new Date(benefit?.plan?.createdAt), 1)
            : addYears(new Date(benefit?.plan?.createdAt), 1)
    return (
        <section className='border border-[#DADCE0] rounded-2xl p-5 cursor-pointer'>
            <div className='flex justify-between items-center'>
                {/* <div className='flex flex-col md:flex-row  items-center gap-2 md:gap-4'>
                    <div>
                        <img src={PlaceHolderImg} alt='Benefit Company logo' />                                    
                    </div>
                    <BenefitsStatusBadge status='health' />
                </div> */}
                <span>{benefit?.plan?.name}</span>
                <span
                    className='text-[#454ADE] bg-[#EFEFF9] px-4 p-1 rounded-2xl font-medium'
                    onClick={() => navigate(benefit?._id)}
                >
                    {' '}
                    View more
                </span>
            </div>
            <section className='mt-4 grid grid-cols-2 md:grid-cols-4 gap-4 lg:gap-7'>
                <div>
                    <h5 className='text-[#5E5E5E] text-sm mb-1'>Plan</h5>
                    <div className='font-medium'>{benefit?.plan?.name}</div>
                </div>
                <div>
                    <h5 className='text-[#5E5E5E] text-sm mb-1'>Benefit Cost</h5>
                    <div className='font-medium'>
                        {formatAmount(benefit?.plan?.amount)}{' '}
                        <span className='text-[#5e5e5e] text-xs'>/ {benefit?.plan?.frequency}</span>
                    </div>
                </div>
                <div>
                    <h5 className='text-[#5E5E5E] text-sm mb-1'>Effective date</h5>
                    <div className='font-medium'>{format(new Date(benefit?.plan?.createdAt), 'dd/MM/yyyy')}</div>
                </div>
                <div>
                    <h5 className='text-[#5E5E5E] text-sm mb-1'>Next payment</h5>
                    <div className='font-medium'>{format(new Date(effectiveDate), 'dd/MM/yyyy')}</div>
                </div>
            </section>
        </section>
    )
}
