import { VaultContextProvider } from '@/context/vault'
import Vault from '@/modules/dashboard/Vault/Vault'
import { Outlet, Route } from 'react-router-dom'

export const vaultRouteConst = {
    vaultBaseUrl: 'vault',
    vaultSubRoute: 'vault/:vault_subRoute',
    personalVaultRoute: 'vault/:vault_subRoute/personal',
    companyVaultRoute: 'vault/:vault_subRoute/company',
    company: 'company', //for admin only
}

export const useVaultRoute = () => {
    const { companyVaultRoute, vaultBaseUrl, vaultSubRoute, personalVaultRoute } = vaultRouteConst
    const routes = [companyVaultRoute, vaultBaseUrl, vaultSubRoute, personalVaultRoute]

    return (
        <Route
            element={
                <VaultContextProvider>
                    <Outlet />
                </VaultContextProvider>
            }
        >
            {routes.map(route => (
                <Route path={route} key={route} element={<Vault />} />
            ))}
            {/* <Route path={vaultRouteConst.billPaymentDetails} element={<BillPaymentDetails />} /> */}
        </Route>
    )
}
