import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { MinusSquareIcon, PlusSquareIcon } from 'lucide-react'
import * as React from 'react'

import { cn } from '@/lib/styling'

const Accordion = AccordionPrimitive.Root

const AccordionItem = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
    <AccordionPrimitive.Item ref={ref} className={cn('border-b', className)} {...props} />
))
AccordionItem.displayName = 'AccordionItem'

interface AccountTriggerProps extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> {
    righticon: React.ReactNode
}
const AccordionTrigger = React.forwardRef<React.ElementRef<typeof AccordionPrimitive.Trigger>, AccountTriggerProps>(
    ({ className, children, righticon, ...props }, ref) => (
        <AccordionPrimitive.Header className='flex'>
            <AccordionPrimitive.Trigger
                ref={ref}
                className={cn(
                    `flex flex-1 items-center justify-between text-sm font-medium transition-all [&[data-state=open]>.plus]:hidden [&[data-state=closed]>.minus]:hidden `,
                    className
                )}
                {...props}
            >
                {children}

                {righticon ? (
                    righticon
                ) : (
                    <>
                        <PlusSquareIcon className='plus h-5 w-5 shrink-0 text-muted-foreground transition-transform duration-200' />
                        <MinusSquareIcon className='minus h-5 w-5 shrink-0 text-muted-foreground transition-transform duration-200' />
                    </>
                )}
                {/* <ChevronDownIcon className='h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200' /> */}
            </AccordionPrimitive.Trigger>
        </AccordionPrimitive.Header>
    )
)
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Content
        ref={ref}
        className={cn(
            'overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
            className
        )}
        {...props}
    >
        <div className='pb-0 pt-0'>{children}</div>
    </AccordionPrimitive.Content>
))
AccordionContent.displayName = AccordionPrimitive.Content.displayName

export { Accordion, AccordionContent, AccordionItem, AccordionTrigger }
