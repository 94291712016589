import { useEffect, useState } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { ReactComponent as MoneyIcon } from '@/assets/payroll-analytics-icon.svg'
import { OverAPeriodIcon } from '@/assets/assets.export'
import { Bar, BarChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts'
import { formatAmount } from '@/utils/money'
import { CustomBarTooltip } from '@/components/Tooltips/CustomTooltip'
import { format } from 'date-fns'
import { capitalizeText } from '@/utils/string'
import { useGetPayrollAnalytics, useGetTopPayrollEarners } from '@/services/payroll/queries'
import { useAuthContext } from '@/context'
import ProgressBar from '@/components/ProgressBar'
import { highestMonth, lowestMonth } from './utils'
import { Payments } from '@/services/payroll/types'

export const PayrollAnalytics = () => {
    const thisYear = new Date().getFullYear()
    const [summaryYear, setSummaryYear] = useState(thisYear + '')
    const [payrollYear, setPayrollYear] = useState(thisYear + '')
    const [topEarnersFreq, setTopEarnersFreq] = useState('monthly')
    const { selectedAccount } = useAuthContext()

    const {
        data: payrollSummary,
        refetch,
        isLoading,
        isRefetching,
    } = useGetPayrollAnalytics({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            year: summaryYear as string,
        },
    })
    const { data: payrollEarners, isLoading: isLoadingPayrollEarners } = useGetTopPayrollEarners({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
    })

    const paymentsToString = () => {
        if (payrollSummary) {
            return payrollSummary?.payments.map(item => ({
                ...item,
                total: String(item.total),
            }))
        } else return [{}] as Payments[]
    }
    const averageTotal = () => {
        if (paymentsToString()) {
            const { total, count } = paymentsToString().reduce(
                (accumulator, monthData) => {
                    accumulator.total += Number(monthData.total)
                    if (Number(monthData.total) > 0) {
                        accumulator.count++
                    }
                    return accumulator
                },
                { total: 0, count: 0 }
            )
            const averageTotal = count > 0 ? total / count : 0

            return averageTotal
        } else return 0
    }
    const selectedTop = payrollEarners && payrollEarners?.filter(payroll => payroll.frequency === topEarnersFreq)[0]
    useEffect(() => {
        refetch()
    }, [summaryYear, payrollYear])

    if (isLoading || isLoadingPayrollEarners) return <ProgressBar />

    return (
        <div className='w-full py-6 block'>
            <section className='p-6 rounded-[20px] mb-6 bg-white'>
                <div className='flex justify-between items-start mb-6'>
                    <span className='font-semibold block text-lg'>Payroll insights</span>

                    <div>
                        <Select name='summaryYear' onValueChange={setSummaryYear} value={summaryYear}>
                            <SelectTrigger className='w-full'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className='w-[8rem]'>
                                <SelectItem value={`${thisYear - 1}`}>Last year</SelectItem>
                                <SelectItem value={`${thisYear}`}>This year</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                <div className={`grid gird-cols-1 md:grid-cols-8 gap-[20px] ${isRefetching ? 'opacity-30' : ''} `}>
                    <div className='col-span-3 py-2 flex justify-between flex-col '>
                        <div className='flex gap-6'>
                            <div className='bg-[#EFEFF9] rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <MoneyIcon className={`fill-brand stroke-[#fff] scale-125`} />
                            </div>
                            <div className=''>
                                <span className='text-[#5E5E5E] text-lg font-medium block '>Total salaries amount</span>
                                <span className='text-3xl lg:text-4xl font-bold block'>
                                    {formatAmount(payrollSummary?.summary.total_salaries_amount)}
                                </span>
                            </div>
                        </div>
                        <div className='flex justify-between items-center mt-3 lg:mt-0'>
                            <p className='text-[#5e5e5e] text-sm font-semibold'>
                                Spent
                                {/* <span className='text-[#CF491E]  '> {percentageIcon}% </span> */} more than previous
                                year ({summaryYear})
                            </p>
                        </div>
                    </div>

                    <div className='col-span-5 grid grid-cols-2 md:grid-cols-3 gap-[10px]'>
                        <div className='flex gap-6'>
                            <div className='bg-[#EFF7F9] rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <MoneyIcon className={`fill-[#45B0DE] stroke-[#fff] scale-125`} />
                            </div>
                            <div className=''>
                                <p className='text-[#5E5E5E] text-sm lg:text-base'>Total payroll</p>
                                <p className='text-xl lg:text-2xl font-medium '>
                                    {payrollSummary?.summary.total_payroll}
                                </p>
                            </div>
                        </div>
                        <div className='flex gap-6'>
                            <div className='bg-[#EFFFED] rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <p className='w-[50px] flex items-center justify-center'>
                                    <MoneyIcon className={`fill-brightGreen stroke-[#fff] scale-125`} />
                                </p>
                            </div>
                            <div className=''>
                                <p className='text-[#5E5E5E] text-sm lg:text-base'>Processed payroll</p>
                                <p className='text-xl lg:text-2xl font-medium '>
                                    {payrollSummary?.summary.processed_payroll}
                                </p>
                            </div>
                        </div>
                        <div className='flex gap-6'>
                            <div className='bg-lightOrange rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <MoneyIcon className={`fill-burntOrange stroke-[#fff] scale-125`} />
                            </div>
                            <div className=''>
                                <p className='text-[#5E5E5E] text-sm lg:text-base'>Due payroll</p>
                                <p className='text-xl lg:text-2xl font-medium '>
                                    {payrollSummary?.summary.due_payroll}
                                </p>
                            </div>
                        </div>
                        <div className='flex gap-6'>
                            <div className='bg-[#FFEDED] rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <MoneyIcon className={`fill-errorRed stroke-[#fff] scale-125`} />
                            </div>
                            <div className=''>
                                <p className='text-[#5E5E5E] text-sm lg:text-base'>Overdue payroll</p>
                                <p className='text-xl lg:text-2xl font-medium '>
                                    {payrollSummary?.summary.overdue_payroll}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='mt-4 border-t border-[#DADCE0] pt-5 flex items-center gap-5'>
                    <div className='w-[200px]'>
                        <p className='text-sm font-medium text-gray7'>Total deductions</p>
                        <p className='text-[20px] font-semibold'>₦3,977,000.20</p>
                    </div>
                    <div className='w-[200px]'>
                        <p className='text-sm font-medium text-gray7'>Tax deductions</p>
                        <p className='text-[20px] font-semibold'>₦ 1,507,080.20</p>
                    </div>
                    <div className='w-[200px]'>
                        <p className='text-sm font-medium text-gray7'>Tax deductions</p>
                        <p className='text-[20px] font-semibold'>₦ 500,000.00</p>
                    </div>
                    <div className='w-[200px]'>
                        <p className='text-sm font-medium text-gray7'>Other deductions</p>
                        <p className='text-[20px] font-semibold'>₦ 1,000,000.00</p>
                    </div>
                </div> */}
            </section>

            <section className='p-4 pt-6 bg-white rounded-[20px] mb-8'>
                <div className='flex items-center justify-between mb-6'>
                    <span className='font-semibold w-[230px] md:w-auto'>Salary payment overview by time period</span>
                    <div>
                        <Select name='payrollYear' onValueChange={setPayrollYear} value={payrollYear}>
                            <SelectTrigger className='w-full'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className='w-[8rem]'>
                                <SelectItem value={`${thisYear - 1}`}>Last year</SelectItem>
                                <SelectItem value={`${thisYear}`}>This year</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <div className={`${isRefetching ? 'opacity-30' : ''}`}>
                    {/*==== Bar chart ====*/}
                    {averageTotal()! > 0 ? (
                        <>
                            <div className={`bg-white p-4 rounded-md`}>
                                <ResponsiveContainer height={350} width={'100%'} className={'mx-auto'}>
                                    <BarChart data={paymentsToString()!} margin={{ left: 60 }}>
                                        <CartesianGrid
                                            stroke='#DADCE0'
                                            vertical={false}
                                            strokeDasharray='5 5'
                                            fill='white'
                                        />
                                        <XAxis
                                            dataKey='name'
                                            stroke='#888888'
                                            fontSize={12}
                                            tickLine={false}
                                            tickFormatter={name => name.slice(0, 3)}
                                            axisLine={false}
                                            // widths={90}
                                            // hide={}
                                        />
                                        <YAxis
                                            dataKey='total'
                                            stroke='#888888'
                                            fontSize={12}
                                            tickLine={false}
                                            axisLine={false}
                                            tickFormatter={value => formatAmount(value)}
                                            hide={false}
                                            label={{
                                                value: 'Bill payments in Naira',
                                                angle: -90,
                                                position: 'insideLeft',
                                                offset: -50,
                                            }}
                                            offset={10}
                                            type='number'
                                        />
                                        <Bar dataKey='total' fill='#454ADE' radius={[4, 4, 0, 0]} barSize={50} />
                                        <Tooltip
                                            filterNull={true}
                                            cursor={false}
                                            wrapperStyle={{ borderRadius: '15px', zIndex: '10' }}
                                            content={<CustomBarTooltip />}
                                        />
                                        <ReferenceLine y={0} stroke='#DADCE0' type='linear' />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                            <div className='flex flex-col lg:flex-row ml-4 gap-4 mb-3'>
                                <div className='px-4 py-3 bg-offwhite w-auto lg:w-[462px] rounded-lg'>
                                    In the month of{' '}
                                    <span className='font-semibold text-brand'>
                                        {payrollSummary && highestMonth(payrollSummary?.payments).name}
                                    </span>
                                    ,you paid the highest salary (
                                    <span className='font-semibold'>
                                        {payrollSummary && formatAmount(highestMonth(payrollSummary?.payments).total)}
                                    </span>
                                    ). While you had the lowest salary paid amount in the month of{' '}
                                    <span className='font-semibold text-brand'>
                                        {' '}
                                        {payrollSummary && lowestMonth(payrollSummary?.payments).name}
                                    </span>{' '}
                                    (
                                    <span className='font-semibold'>
                                        {payrollSummary && formatAmount(lowestMonth(payrollSummary?.payments).total)}
                                    </span>
                                    ).
                                </div>
                                <div className='px-4 py-3 bg-offwhite w-auto lg:w-[462px] rounded-lg lg:flex items-center'>
                                    The average salary amount paid was{' '}
                                    <span className='font-semibold text-brand ml-1'>
                                        {formatAmount(averageTotal())}
                                    </span>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className='flex items-center justify-center flex-col text-gray7 h-full min-h-[320px]'>
                            <OverAPeriodIcon />
                            <p className='text-sm mt-6'>No payments have been made yet</p>
                        </div>
                    )}
                </div>
            </section>

            <section className='bg-white rounded-xl p-2 lg:p-4 xl:p-6 flex flex-col gap-4 h-full min-h-[450px] mb-32'>
                <div className='flex items-center justify-between rounded text-dark mb-4'>
                    <div className=''>
                        <span className=' font-semibold text-base'>Top spenders - Users</span>
                        <p className='text-sm text-gray7 mt-1'>See the top 5 users bill payments were made for</p>
                    </div>

                    <div>
                        <Select onValueChange={val => setTopEarnersFreq(val)} value={topEarnersFreq}>
                            <SelectTrigger className='w-full'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className='w-[8rem]'>
                                <SelectItem value={`monthly`}>Monthly</SelectItem>
                                <SelectItem value={`weekly`}>Weekly</SelectItem>
                                <SelectItem value={`biweekly`}>Bi-Weekly</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <div className={`flex flex-col gap-4`}>
                    {selectedTop && selectedTop?.profiles.length > 0 ? (
                        selectedTop?.profiles
                            ?.filter((_, i) => i < 5)
                            .map(earner => (
                                <>
                                    <div className='border border-[#DADCE0] rounded-lg py-4 px-5'>
                                        <div className='text-sm lg:text-base font-semibold grid grid-cols-3 items-center'>
                                            <p className='flex gap-3 items-center'>
                                                {earner.account.profile_photo ? (
                                                    <img
                                                        src={earner.account.profile_photo}
                                                        alt=''
                                                        className='w-[30px] h-[30px] rounded-full '
                                                    />
                                                ) : (
                                                    <p className='w-[30px] h-[30px] bg-[#D9D9D9] text-[#838383] flex justify-center items-center rounded-full tracking-wider'>
                                                        {capitalizeText(earner.account.first_name)?.substring(0, 1)}
                                                        {capitalizeText(earner.account.last_name)?.substring(0, 1)}
                                                    </p>
                                                )}
                                                {earner.account.first_name} {earner.account.last_name}{' '}
                                            </p>
                                            <p className='text-center'>
                                                {format(new Date(earner.account.createdAt), 'dd/MM/yyyy')}
                                            </p>
                                            <p className='text-right'>
                                                {formatAmount(earner.net_salary.$numberDecimal)}
                                            </p>
                                        </div>
                                    </div>
                                </>
                            ))
                    ) : (
                        <div className='flex items-center justify-center flex-col text-gray7 h-full min-h-[320px]'>
                            <OverAPeriodIcon />
                            <p className='text-sm mt-6'>Nothing to see here yet</p>
                        </div>
                    )}
                </div>
            </section>
        </div>
    )
}
