import { BizpendLogo } from '@/assets/assets.export'
import { useAuthContext } from '@/context'
import { useSwitchAccount } from '@/services/auth/mutations'
import { UserOrgAccount } from '@/types/org'
import { QueryCache } from '@tanstack/react-query'
import { ArrowRight } from 'lucide-react'
import { usePostHog } from 'posthog-js/react'
import { useEffect, useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { useNavigate } from 'react-router-dom'

export default function SwitchAccount() {
    const { accounts, setSelectedAccount, selectedAccount, redirectUrl } = useAuthContext()
    const posthog = usePostHog()
    const [clickedAccount, setClickedAccount] = useState('')
    const navigate = useNavigate()
    const queryCache = new QueryCache({})

    const { mutate: switchAccountFn, isLoading: isSwitching } = useSwitchAccount({
        onSuccess(data: any) {
            if (data.account_data) {
                posthog.identify(data.account_data.id, {
                    first_name: data.account_data.user.first_name,
                    last_name: data.account_data.user.last_name,
                    email: data.account_data.user.email,
                    account_id: data.account_data.id,
                    org_id: data.account_data.org?.id,
                    org_name: data.account_data.org?.org_name,
                })
            }

            if (redirectUrl.length > 0) {
                navigate(redirectUrl, { replace: true })
                return
            }
            if (selectedAccount?.role.name === 'ADMIN' || selectedAccount?.role.name === 'OWNER') {
                navigate('/admin/dashboard/overview')
            } else {
                navigate('/dashboard/overview')
            }
        },
    })

    const handleSelectAccount = (account: UserOrgAccount) => {
        posthog.reset()
        setSelectedAccount(account)
        switchAccountFn({ account: account?.id })
        queryCache.clear()
    }

    useEffect(() => {
        if (redirectUrl.length > 0) {
            const parsedSearchUrl = new URL(redirectUrl, 'http://app.bizpend.com').searchParams
            const accountId = parsedSearchUrl.get('account_id')
            const accountInURl = accounts.filter(acct => acct.org).filter(acct => acct?.id === accountId)?.[0]
            handleSelectAccount(accountInURl)
        }
    }, [redirectUrl])

    return (
        <main className='flex flex-col items-center pt-[4rem] bg-[#FAFAFD] h-[100vh] '>
            <BizpendLogo className='mb-7' />
            <h1 className='font-bold text-4xl text-[#31254B]'>Hi,there</h1>
            <p className='mt-5 w-[50%] text-center mb-5'>Choose from your accounts below to be logged in</p>
            <div className='flex flex-col text-[#202020] font-medium text-xl my-8 w-[25rem] mb-12'>
                {accounts
                    .filter(acct => acct.org)
                    .map(account => (
                        <>
                            <div
                                className='flex justify-between items-center cursor-pointer mb-4 bg-white hover:bg-brand hover:text-white rounded-lg p-4'
                                role='button'
                                onClick={() => {
                                    handleSelectAccount(account)
                                    setClickedAccount(account?.id)
                                }}
                            >
                                <span className='font-medium'>{account?.org?.org_name}</span>
                                {isSwitching && account?.id === clickedAccount ? (
                                    <CgSpinner className='animate-spin' />
                                ) : (
                                    <ArrowRight />
                                )}
                            </div>
                        </>
                    ))}
            </div>
        </main>
    )
}
