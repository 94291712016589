import { SeverError } from '@/assets/assets.export'
import { Button } from '@/components'
import { FallbackProps } from 'react-error-boundary'
import { useNavigate } from 'react-router-dom'

export default function ErrorFallBack({ error, resetErrorBoundary }: FallbackProps) {
    const navigate = useNavigate()
    return (
        <div role='alert' className='grid place-items-center h-[100vh]'>
            <div className='flex justify-center items-center flex-col'>
                <SeverError />
                <p>Something went wrong:</p>
                <pre className='mt-4 text-[red]'>{error.message}</pre>
                <p>{JSON.stringify(error)}</p>
                <Button
                    onClick={() => {
                        resetErrorBoundary()
                        navigate(-1)
                    }}
                    className='mt-14'
                >
                    Try Again
                </Button>
            </div>
        </div>
    )
}
