import React, { useEffect, useState } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { AiOutlineClose } from 'react-icons/ai'
import { Input, Button, Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '@/components'
import { EmployeeType } from '@/types/org'
import { useMutationCreateTeam, useMutationUpdateTeam } from '@/services/employees/mutations'
import { useAuthContext, useEmployeesContext } from '@/context'
import { toastHandler } from '@/components/utils/Toast'
import SelectWIthChip from '@/components/SelectWithChip'
import { capitalizeText } from '@/utils/string'

type CreateTeamModalProps = {
    members?: EmployeeType[]
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    edit?: boolean
}
const formEmptyState = {
    name: '',
    members: [],
}

export default function CreateTeamModal({ members, closeModal, edit }: CreateTeamModalProps) {
    const { teamsObj, orgMembers } = useEmployeesContext()

    const prevMembers: { label: string; value: string }[] =
        edit && teamsObj
            ? teamsObj.users.map(user => ({
                  label: user?.account.user?.first_name + ' ' + user?.account.user?.last_name,
                  value: user?.account.id ?? user?.account._id,
              }))
            : ([] as { label: string; value: string }[])

    const prevTeamLeadObj = teamsObj?.users.filter(user => user.isTeamLead)[0]
    const prevLead = edit && teamsObj ? prevTeamLeadObj?.account?.id ?? prevTeamLeadObj?.account?._id : ''

    const [formState, setFormState] = useState(
        edit ? { name: teamsObj?.name, members: teamsObj?.users } : formEmptyState
    )
    const [selectedMembers, setSelectedMembers] = useState<{ label: string; value: string }[]>(prevMembers)
    const [teamLead, setTeamLead] = useState(prevLead)
    const [orgMembersData, setOrgMembersData] = useState<{ label: string; value: string }[]>([])
    const { selectedAccount } = useAuthContext()

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target
        setFormState(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }

    const { mutate: createTeamFn, isLoading: isCreatingTeam } = useMutationCreateTeam({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
        onSuccess: () => {
            closeModal(false)
            toastHandler({ message: 'New Team Created', state: 'success' })
        },
    })
    const { mutate: updateTeamFn, isLoading: isUpdatingTeam } = useMutationUpdateTeam({
        queryParams: {
            team_id: teamsObj?.id ?? (teamsObj?._id as string),
        },
        onSuccess: () => {
            closeModal(false)
            toastHandler({ message: 'Changes saved', state: 'success' })
        },
    })

    const handleCreateTeam = () => {
        !edit
            ? createTeamFn({
                  name: formState.name,
                  team_lead: teamLead,
                  members: selectedMembers.map(member => member?.value),
              })
            : updateTeamFn({
                  name: formState.name,
                  team_lead: teamLead,
                  members: selectedMembers.map(member => member?.value),
              })
    }

    useEffect(() => {
        if (members) {
            setOrgMembersData(
                members.map(member => ({
                    label: `${capitalizeText(member?.user?.first_name)} ${capitalizeText(member?.user?.last_name)}`,
                    value: member?.id,
                }))
            )
        } else if (orgMembers) {
            setOrgMembersData(
                orgMembers.map(orgMembers => ({
                    label: `${capitalizeText(orgMembers?.user?.first_name)} ${capitalizeText(orgMembers?.user?.last_name)}`,
                    value: orgMembers?.id,
                }))
            )
        }
    }, [members, orgMembers])

    const disableBtn = selectedMembers.length < 0 || !formState.name || !teamLead

    return (
        <ModalWrapper variants={undefined}>
            <section className='max-h-[85vh] w-screen lg:max-w-[42rem] overflow-y-scroll my-4'>
                <div className='flex items-center border-b border-[#DADCE0]/50 p-3 '>
                    <h1 className='flex-1 text-center font-semibold text-2xl text-[#31254B]'>
                        {edit ? 'Save' : 'Create New'} Team
                    </h1>
                    <AiOutlineClose
                        className='bg-[#F2F2F2] text-[#838383] inline-block w-8 h-8 p-2 rounded-full cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </div>
                <div className='px-4 lg:px-8 p-8 text-base marker mt-3 mx-auto'>
                    <div className='flex flex-col gap-[1.8rem] mb-6'>
                        <div className='flex flex-col flex-1 gap-3'>
                            <label className='flex items-center gap-1'>
                                Team name
                                <span className='text-[red]'>*</span>
                            </label>
                            <Input
                                type='text'
                                name='name'
                                value={formState.name}
                                placeholder='Enter team name'
                                className=' border-[#DADCE0] max-w-[38rem] lg:w-[38rem] h-[3.5rem]'
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='flex flex-col flex-1 gap-3 '>
                            <label className='flex items-center gap-1'>
                                Team Members
                                <span className='text-errorRed'>*</span>
                            </label>
                            <div className=''>
                                <SelectWIthChip
                                    options={orgMembersData}
                                    placeholder='Click to see users'
                                    onChange={members_details => {
                                        setSelectedMembers(members_details)
                                    }}
                                    value={selectedMembers}
                                />
                            </div>
                        </div>
                        <div className='flex flex-col flex-1 gap-3 '>
                            <label className='flex items-center gap-1'>
                                Select team lead
                                <span className='text-errorRed'>*</span>
                            </label>
                            <div className=''>
                                <Select
                                    name='team_lead'
                                    value={teamLead}
                                    onValueChange={teamLead => setTeamLead(teamLead)}
                                >
                                    <SelectTrigger
                                        className='w-full h-[4rem] border-[#DADCE0]'
                                        variant={teamLead ? 'selected' : 'default'}
                                    >
                                        <SelectValue placeholder='Select team lead' />
                                    </SelectTrigger>
                                    <SelectContent className='max-h-[200px] overflow-scroll w-[24rem] lg:w-[38rem]'>
                                        {selectedMembers.length > 0 ? (
                                            selectedMembers.map(memb => (
                                                <SelectItem key={memb.value} value={memb.value}>
                                                    {memb.label}
                                                </SelectItem>
                                            ))
                                        ) : (
                                            <>
                                                <SelectItem value=''>No team member selected yet</SelectItem>
                                            </>
                                        )}
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <Button
                            className='min-w-[100px] mt-10 ml-auto'
                            onClick={handleCreateTeam}
                            loading={isCreatingTeam || isUpdatingTeam}
                            disabled={disableBtn}
                        >
                            {edit ? 'Save team' : 'Create team'}
                        </Button>
                    </div>
                </div>
            </section>
        </ModalWrapper>
    )
}
