import React from 'react'
import { BusinessInfo } from '../components/BusinessInfo'
import { FormProvider, useForm } from 'react-hook-form'
import { AddressInfo } from '../components/AddressInfo'
import { IncorporationInfo } from '../components/IncorporationInfo'
import { BizCompanyComplianceType } from '../utils'

type Props = {
    setStep: React.Dispatch<React.SetStateAction<number>>
    step: number
}
export const CompleteCompanyCompliance = ({ setStep, step }: Props) => {
    const methods = useForm<Partial<BizCompanyComplianceType>>()

    return (
        <FormProvider {...methods}>
            <form onSubmit={e => e.preventDefault()} className='flex-1 bg-white rounded-xl p-5 md:px-8 shadow-sm'>
                {step === 1 && <BusinessInfo setStep={setStep} />}
                {step === 2 && <AddressInfo setStep={setStep} />}
                {step === 3 && <IncorporationInfo setStep={setStep} />}
            </form>
        </FormProvider>
    )
}
