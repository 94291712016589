import downArrow from '@/assets/whiteDownload.svg'
import { Button } from '@/components'
import InvoiceReminder from '@/components/Modals/Invoice/InvoiceReminder'
import RecordPayment from '@/components/Modals/Invoice/RecordPaymentModal'
import ProgressBar from '@/components/ProgressBar'
import StatusColor from '@/components/utils/StatusColor'
import { useInvoiceContext } from '@/context'
import { useGetInvoiceByID, useGetPossibleTransactionMatch } from '@/services/invoice/queries'
import { InvoiceStatus } from '@/types/invoice'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { format } from 'date-fns'
import { Download } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { Link, useNavigate, useParams } from 'react-router-dom'
import InvoiceAmountTable from '../../components/InvoiceAmountTable'
import MatchInvoiceTransactions from '@/components/Modals/Invoice/InvoiceMatching/MatchInvoiceTransactions'

export default function InvoiceDetails() {
    const navigate = useNavigate()
    const { setEditModal, setInvoiceDetails } = useInvoiceContext()
    const { invoice_id } = useParams()
    const [recordPayment, setRecordPayment] = useState(false)
    const [resendInvoice, setResendInvoice] = useState(false)
    const [openMatchedTransactionModal, setOpenMatchedTransactionModal] = useState(false)

    const { data: invoice_details, isLoading } = useGetInvoiceByID({
        queryParams: {
            invoice_id: invoice_id as string,
        },
    })

    const { data } = useGetPossibleTransactionMatch({
        queryParams: {
            invoice_id: '66e2b2fd123b89666dfabb94',
        },
    })

    const matchedTransactions = useMemo(() => data ?? [], [data])
    const invoiceDetails = useMemo(() => invoice_details, [invoice_details])
    useEffect(() => {
        setInvoiceDetails(invoiceDetails)
    }, [invoice_details])

    if (isLoading) return <ProgressBar />
    return (
        <div>
            <div className='bg-[#F9F9F9] py-[30px] px-[20px] lg:px-[40px] mb-[80px]'>
                <div className='mb-8 inline-flex items-center cursor-pointer' onClick={() => navigate(-1)}>
                    <BsArrowLeft />
                    <p className='ml-2 font-semibold text-lg'>back</p>
                </div>
                <>
                    <div className='bg-white px-5 py-6 mb-4 rounded-xl'>
                        <div className='flex justify-between'>
                            {Number(matchedTransactions?.length) > 0 && (
                                <div className='flex items-center justify-between  gap-6 text-black/80 text-sm font-medium border bg-errorRed/10 border-errorRed rounded-lg p-3 py-1.5'>
                                    <p>
                                        Multiple payments detected! {matchedTransactions?.length} transactions seem to
                                        match this Invoice
                                    </p>
                                    <Button
                                        onClick={() => setOpenMatchedTransactionModal(true)}
                                        className='bg-black/80 hover:bg-black px-2.5 min-w-[100px] min-h-[25px] py-0 text-xs'
                                    >
                                        Please review to confirm
                                    </Button>
                                </div>
                            )}
                            {invoiceDetails?.invoice_attachment_url && (
                                <button
                                    className='text-sm font-medium text-brand flex items-center  gap-1 ml-auto'
                                    onClick={() =>
                                        window.open(
                                            `${invoiceDetails?.invoice_attachment_url}`,
                                            '_blank',
                                            'rel=noopener noreferrer'
                                        )
                                    }
                                >
                                    <Download className='w-4 h-4' /> Download{' '}
                                </button>
                            )}
                        </div>

                        <div className='flex flex-col gap-4 text-[#202020]'>
                            <h1 className='text-lg font-semibold border-b border-[#DADCE0] py-3'>
                                {invoiceDetails?.invoice_id} - {formatAmount(Number(invoiceDetails?.total_amount))}
                            </h1>

                            <div className='flex justify-between flex-col-reverse lg:flex-row gap-4 lg:gap-0'>
                                <div className='w-full lg:w-[70%]'>
                                    <div className='grid grid-cols-2 md:grid-cols-4 gap-4 gap-y-6 text-base mb-12'>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Invoice ID</h2>
                                            <p>{invoiceDetails?.invoice_id}</p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Customer</h2>
                                            <p>{invoiceDetails?.customer?.name}</p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Issue date</h2>
                                            <p>
                                                {invoiceDetails?.issue_date &&
                                                    format(new Date(invoiceDetails?.issue_date), 'dd/MM/yyyy')}
                                            </p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Due date</h2>
                                            <p>
                                                {invoiceDetails?.issue_date &&
                                                    format(new Date(invoiceDetails?.due_date), 'dd/MM/yyyy')}
                                            </p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Total Amount</h2>
                                            <p className=''>{formatAmount(Number(invoiceDetails?.total_amount))}</p>
                                        </div>
                                        {invoiceDetails?.status === 'PAID' && (
                                            <div className=''>
                                                <h2 className='font-semibold mb-2'>Paid Amount</h2>
                                                <p className=''>
                                                    {formatAmount(Number(invoiceDetails?.metadata?.amount))}
                                                </p>
                                            </div>
                                        )}
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Status</h2>

                                            <p className=''>
                                                <StatusColor
                                                    disableBgColor={true}
                                                    status={invoiceDetails?.status as string}
                                                />
                                            </p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Created by</h2>
                                            <div className='w-[100%]'>
                                                <p>
                                                    {invoiceDetails?.issuer?.user?.first_name}{' '}
                                                    {invoiceDetails?.issuer?.user?.last_name}
                                                </p>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Date Created</h2>
                                            <p>
                                                {invoiceDetails?.createdAt &&
                                                    format(new Date(invoiceDetails?.createdAt), 'dd/MM/yyyy')}
                                            </p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Payment method</h2>
                                            <p>
                                                {capitalizeText(invoiceDetails?.payment_method)}
                                                {invoiceDetails?.payment_method === 'BOTH' ? ' (online & offline)' : ''}
                                            </p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Amount paid</h2>
                                            <p>{formatAmount(Number(invoiceDetails?.paid?.$numberDecimal))}</p>
                                        </div>
                                    </div>
                                    {invoiceDetails?.status !== InvoiceStatus.PAID && (
                                        <div className='flex justify-end flex-col lg:flex-row'>
                                            <div className='flex-1'>
                                                <Button
                                                    className=' mx-auto xs:mx-0 xs:min-w-[130px] min-h-[35px] min-w-full '
                                                    onClick={() => {
                                                        setEditModal(true)
                                                        navigate(
                                                            `/admin/dashboard/invoice/update_invoice/${
                                                                invoiceDetails?._id ?? invoiceDetails?.id
                                                            }`
                                                        )
                                                    }}
                                                    variant={'outline'}
                                                >
                                                    Edit
                                                </Button>
                                            </div>
                                            <div className='flex flex-wrap gap-3 lg:gap-6 justify-between w-full lg:w-auto mt-6 lg:mt-0 lg:justify-center mb-8 mx-auto'>
                                                <Button
                                                    className='bg-transparent text-[#5e5e5e] hover:bg-transparent min-w-[120px] border border-gray8'
                                                    onClick={() => setResendInvoice(true)}
                                                >
                                                    Send reminder
                                                </Button>
                                                <Button
                                                    className=' bg-[#19C37D] hover:bg-[#19C37D] hover:opacity-90 text-white px-0 min-w-[46%] lg:min-w-[120px]'
                                                    onClick={() => setRecordPayment(true)}
                                                >
                                                    Mark as paid
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className=''>
                                    <Link
                                        className='relative'
                                        to={invoiceDetails?.logo as string}
                                        target='_blank'
                                        download
                                    >
                                        <img
                                            src={invoiceDetails?.logo}
                                            className='object-cover w-[123px] h-[123px] rounded-lg inline-flex'
                                        />
                                        <img
                                            src={downArrow}
                                            alt='button'
                                            className='absolute -bottom-11 right-[13px] bg-[#5E5E5ED4] rounded-md p-1 cursor-pointer text-[#19C37D]/90'
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='p-5 bg-white rounded-xl overflow-y-scroll'>
                        <InvoiceAmountTable invoiceDetails={invoiceDetails} />
                    </div>
                </>

                {openMatchedTransactionModal && (
                    <MatchInvoiceTransactions
                        invoice={invoiceDetails}
                        matchedTransactions={matchedTransactions}
                        closeModal={() => setOpenMatchedTransactionModal(false)}
                    />
                )}
                {recordPayment && <RecordPayment closeModal={setRecordPayment} invoiceDetails={invoiceDetails!} />}
                {resendInvoice && <InvoiceReminder closeModal={setResendInvoice} invoice={invoiceDetails!} />}
            </div>
        </div>
    )
}
