import { useMemo } from 'react'
// import downArrow from '@/assets/down-arrow.svg'
import PlaceHolderImg from '@/assets/ihms.svg'
import ProgressBar from '@/components/ProgressBar'
import StatusColor from '@/components/utils/StatusColor'
import PageContent from '@/layout/PageContent'
import { useGetOrgBenefitsById } from '@/services/benefits/queries'
import { OrgBenefitProps } from '@/types/benefits'
import { EmployeeType } from '@/types/org'
import { formatAmount } from '@/utils/money'
import { addMonths, addYears, format } from 'date-fns'
import { ArrowLeft } from 'lucide-react'
import { useNavigate, useParams } from 'react-router-dom'

export default function UserBenefitDetails() {
    const { benefit_id } = useParams()
    const navigate = useNavigate()
    const { data: benefit_details, isLoading } = useGetOrgBenefitsById({
        queryParams: {
            benefit_id: benefit_id as string,
        },
    })

    const benefitDetails = useMemo(() => benefit_details ?? ({} as OrgBenefitProps), [benefit_details])
    const effectiveDate = format(isLoading ? new Date() : new Date(benefitDetails?.plan?.createdAt), 'dd/MM/yyyy')
    const nextDate = isLoading
        ? benefitDetails?.plan?.frequency === 'monthly'
            ? addMonths(new Date(benefitDetails?.plan?.createdAt || new Date()), 1)
            : addYears(new Date(benefitDetails?.plan?.createdAt || new Date()), 1)
        : new Date()
    const acct_manager_details = benefitDetails?.manager as EmployeeType
    const details = [
        {
            name: 'HMO vendor',
            desc: 'Reliance HMO',
        },
        {
            name: 'Plan',
            desc: benefitDetails?.plan?.name,
        },
        {
            name: 'Coverage type',
            desc: 'Single',
        },
        {
            name: 'Benefit cost',
            desc: `${formatAmount(benefitDetails?.plan?.amount)}/ ${benefitDetails?.plan?.frequency}`,
        },
        {
            name: 'Cost sharing',
            desc: `${benefitDetails?.cost_sharing?.company}% company, ${benefitDetails?.cost_sharing?.employee}% user (salary deduction)`,
            id: 'cost_sharing',
        },
        {
            name: 'Effective date',
            desc: effectiveDate,
            // desc : ''
        },
        {
            name: 'Next payment',
            desc: format(nextDate, 'dd/MM/yyyy'),
            // desc: ''
        },
        {
            name: 'Status',
            desc: <StatusColor status={benefitDetails.status} />,
        },
        {
            name: 'Account manager ',
            desc: acct_manager_details?.user?.first_name + ' ' + acct_manager_details?.user?.last_name,
        },
        {
            name: 'Account  manager contact ',
            desc: acct_manager_details?.user?.phone + ', ' + acct_manager_details?.user?.email,
            id: 'manager_contact',
        },
    ]

    if (isLoading) return <ProgressBar />
    return (
        <PageContent>
            <div className='flex gap-4 items-center mt-8' onClick={() => navigate(-1)}>
                <ArrowLeft role='button' className='' /> go back
            </div>
            <section className='bg-white p-5 rounded-xl mt-10 px-8'>
                <div className='border-b border-[#DADCE0] pb-3 flex flex-col lg:flex-row gap-5 lg:items-center justify-between mt-5'>
                    <div className=''>
                        <img src={PlaceHolderImg} alt='Benefit Company logo' />
                    </div>
                    {/* <div className='flex gap-2 cursor-pointer items-center'>
                        <div className='h-[28px] w-[28px] border-2 border-[#DADCE0] rounded-[5px] flex items-center justify-center p-2 '>
                            <img src={downArrow} alt='button' className='scale-[2]' />
                        </div>
                        <p className='font-semibold text-base'>Download all details on this page</p>
                    </div> */}
                </div>
                <section className='flex flex-wrap gap-4 gap-y-6 max-w-[50rem] mt-5'>
                    {details.map((detail, idx) => (
                        <div
                            className={`flex flex-col  ${
                                detail?.id === 'cost_sharing'
                                    ? 'w-[30rem]'
                                    : detail?.id === 'manager_contact'
                                      ? 'w-[15rem]'
                                      : 'w-[10rem]'
                            } `}
                            key={idx}
                        >
                            <h4 className='text-md font-medium'>{detail.name}</h4>
                            <span className='text-[#5E5E5E] text-[1rem]'>{detail.desc}</span>
                        </div>
                    ))}
                </section>
            </section>
            <section className='bg-white p-5 rounded-xl mt-10 px-8'>
                <h3 className='border-b pb-3 mb-5 border-[#DADCE0] text-xl font-medium'>Coverage</h3>
                <ul className='grid grid-cols-2 list-disc list-inside gap-y-4 mt-6 max-w-[60rem]'>
                    <li>Combined medical cover of ₦1.8 Million per year</li>{' '}
                    <li>Combined medical cover of ₦1.8 Million per year</li>{' '}
                    <li>Combined medical cover of ₦1.8 Million per year</li>{' '}
                    <li>Combined medical cover of ₦1.8 Million per year</li>{' '}
                    <li>Combined medical cover of ₦1.8 Million per year</li>{' '}
                    <li>Combined medical cover of ₦1.8 Million per year</li>
                    {/* <div className='col-span-2 flex justify-end gap-4 mt-8'>
                        <div className='h-[28px] w-[28px] border-2 border-[#DADCE0] rounded-[5px] flex items-center justify-center p-2 '>
                            <img src={downArrow} alt='button' className='scale-[2]' />
                        </div>
                        <p className='font-medium text-base'>Download all details on this page</p>
                    </div> */}
                </ul>
            </section>
        </PageContent>
    )
}
