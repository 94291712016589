import { CancelIcon } from '@/assets/assets.export'
import ModalWrapper from '../../ModalWrapper'
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { useRef, useState } from 'react'
import { modalVariants } from '../../Expense/utils'
import useOutsideAlerter from '@/hooks/useClickOutside'
import DatePicker from 'react-datepicker'
import { useRequestLeave } from '@/services/payroll/mutations'
import { useAuthContext } from '@/context'
import { differenceInDays } from 'date-fns'
import { toastHandler } from '@/components/utils/Toast'
import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}
const LeaveRequestModal = ({ closeModal }: Props) => {
    const ref = useRef<HTMLDivElement>(null)
    useOutsideAlerter(ref, closeModal)
    const calendarRef1 = useRef<DatePicker>(null)
    const calendarRef2 = useRef<DatePicker>(null)

    const { selectedAccount } = useAuthContext()

    const formEmptyState = {
        leave_type: '',
        start_date: '',
        end_date: '',
        reason: '',
    }

    const [formState, setFormState] = useState(formEmptyState)

    const { mutate: requestLeaveFn, isLoading } = useRequestLeave({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
        onSuccess() {
            toastHandler({ message: 'Leave successfully created', state: 'success' })
            closeModal(false)
        },
    })

    const disableButton = () => {
        const res = Object.values({ ...formState })
            .map(value => !!value)
            .some(data => data === false)

        return res
    }

    return (
        <ModalWrapper variants={modalVariants} className='justify-end right-8'>
            <main className='h-[100vh] overflow-scroll bg-white min-w-[27rem]' ref={ref}>
                <section className='flex items-center border-b border-[#DADCE0] p-4 '>
                    <span className='flex-1 text-center text-xl font-medium'>Assign leave</span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>

                <form className=' overflow-scroll  px-6 mt-8 h-[85%] flex-col flex text-[13px]'>
                    <div className='flex flex-col  gap-3'>
                        <label className='flex items-center gap-1'>
                            Leave type
                            <span className='text-[red]'>*</span>
                        </label>
                        <Select
                            name='name'
                            onValueChange={value => {
                                setFormState(prevState => ({
                                    ...prevState,
                                    leave_type: value,
                                }))
                            }}
                            value={formState.leave_type}
                        >
                            <SelectTrigger className='h-[3.5rem] border-[#DADCE0] bg-base_gray_100 text-[13px]'>
                                {(formState.leave_type as keyof typeof formState) ? (
                                    <SelectValue />
                                ) : (
                                    <span className='text-gray-400'>Sick days, vacation etc.</span>
                                )}
                            </SelectTrigger>
                            <SelectContent className='w-[18.9rem] max-h-[200px] overflow-scroll'>
                                <SelectItem value='sick_days'>Sick days</SelectItem>
                                <SelectItem value='vacation'>Vacation</SelectItem>
                                <SelectItem value='conference'>Conference</SelectItem>
                                <SelectItem value='parental'>Parental</SelectItem>
                                <SelectItem value='miscellaneous'>Miscellaneous</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                    <div className='flex flex-col  gap-3 mt-6'>
                        <label className='flex items-center gap-1'>
                            Start date
                            <span className='text-[red]'>*</span>
                        </label>
                        <CustomDatePicker
                            ref={calendarRef1}
                            placeholderText='Choose start date'
                            onChange={date => {
                                setFormState(prev => ({
                                    ...prev,
                                    start_date: date as unknown as string,
                                    end_date: '',
                                }))
                            }}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            selected={formState.start_date ? new Date(formState.start_date) : undefined}
                        />
                    </div>
                    <div className='flex flex-col  gap-3 mt-6'>
                        <label className='flex items-center gap-1'>
                            End date
                            <span className='text-[red]'>*</span>
                        </label>
                        <CustomDatePicker
                            ref={calendarRef2}
                            placeholderText='Choose end date'
                            onChange={date => {
                                setFormState(prev => ({
                                    ...prev,
                                    end_date: date as unknown as string,
                                }))
                            }}
                            minDate={formState.start_date as unknown as Date}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            selected={formState.end_date ? new Date(formState.end_date) : undefined}
                        />
                    </div>
                    <p className='text-xs text-brand mt-2'>
                        {formState.end_date && formState.start_date && (
                            <>
                                {differenceInDays(new Date(formState.end_date), new Date(formState.start_date))} days
                                leave
                            </>
                        )}
                    </p>
                    <div className='flex flex-col  gap-3 mt-6'>
                        <label className='flex items-center justify-between gap-1'>
                            <span>
                                Reason for leave
                                <span className='text-[red]'> *</span>
                            </span>
                            <span>{formState.reason.length}/120</span>
                        </label>
                        <textarea
                            name=''
                            id=''
                            rows={5}
                            maxLength={120}
                            className='border-[#DADCE0]  placeholder:text-[#A8A8A8]  text-dark rounded-md border focus:border-brand bg-transparent py-2 text-sm  cursor-pointer w-full pl-2'
                            onChange={e => {
                                setFormState(prev => ({ ...prev, reason: e.target.value }))
                            }}
                            value={formState.reason}
                        ></textarea>
                    </div>
                    <div className='flex-1 flex justify-end'>
                        <div className='mt-auto flex justify-end gap-4 '>
                            <button onClick={() => closeModal(false)} className='text-gray-600'>
                                Cancel
                            </button>
                            <Button
                                type='button'
                                loading={isLoading}
                                onClick={() => {
                                    requestLeaveFn(formState)
                                }}
                                disabled={disableButton()}
                            >
                                Request Leave
                            </Button>
                        </div>
                    </div>
                </form>
            </main>
        </ModalWrapper>
    )
}

export default LeaveRequestModal
