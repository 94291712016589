import receipt from '@/assets/v2dashboard/receipt.svg'
import graph from '@/assets/v2dashboard/graph.svg'
import note from '@/assets/v2dashboard/note.svg'
import people from '@/assets/v2dashboard/people.svg'
import { MoveRight } from '@/assets/assets.export'
import { useNavigate } from 'react-router-dom'

export const QuickActions = () => {
    const navigate = useNavigate()

    return (
        <div className='md:border-l border-gray5 flex-1 md:pl-4'>
            <h1 className='text-base font-medium text-gray7 mb-5'>Quick actions</h1>
            <div className='grid grid-cols-2 gap-3'>
                {quickActions.map((action, idx) => (
                    <>
                        <div className={`bg-[${action.bgColor}] p-4 relative rounded-xl overflow-hidden`} key={idx}>
                            <h1 className='mb-12 text-lg font-medium text-[#202020]'>{action.title}</h1>
                            <div className='flex items-center gap-2 md:gap-4 cursor-pointer w-fit'>
                                <p
                                    onClick={() => {
                                        navigate(action.link)
                                    }}
                                    className={`text-xs md:w-[60px] xl:w-auto font-semibold text-[${action.arrowColor}]`}
                                    style={{ color: action.arrowColor }}
                                >
                                    {action.action}
                                </p>
                                <MoveRight color={action.arrowColor} className='hidden sm:inline-block' />
                            </div>
                            <img
                                src={action.img}
                                alt=''
                                className='absolute bottom-0 right-0 scale-75 sm:scale-100 xl:scale-[1.1]'
                            />
                        </div>
                    </>
                ))}
            </div>
        </div>
    )
}

const quickActions = [
    {
        title: 'Expense',
        action: 'Create an expense',
        bgColor: '#EFEFF9',
        arrowColor: '#454ADE',
        img: receipt,
        disabled: false,
        link: '/admin/dashboard/expenses/quick_actions',
    },
    {
        title: 'Budget',
        action: 'Create a budget',
        bgColor: '#EBF5FF',
        arrowColor: '#5C9BFF',
        img: graph,
        disabled: false,
        link: '/admin/dashboard/budget/quick_actions',
    },
    {
        title: 'Invoice',
        action: 'Create an invoice',
        bgColor: '#EFF7F9',
        arrowColor: '#3095C1',
        img: note,
        disabled: false,
        link: '/admin/dashboard/invoice/create_invoice',
    },
    {
        title: 'Customer',
        action: 'Create a customer',
        bgColor: '#FFEFE7',
        arrowColor: '#FF9152',
        img: people,
        disabled: false,
        link: '/admin/dashboard/customers/quick_actions',
    },
]
