import { ReactComponent as AccountingIcon } from '@/assets/accounting.svg'
import { ReactComponent as ArrowLeftRight } from '@/assets/arrowrightleft.svg'
import { ReactComponent as DashboardIcon } from '@/assets/dashboard-icon.svg'
import { ReactComponent as LogoutIcon } from '@/assets/logout.svg'
import { ReactComponent as PayRollIcon } from '@/assets/payroll.svg'
import { ReactComponent as ContractsIcon } from '@/assets/contracts.svg'
import { ReactComponent as VaultIcon } from '@/assets/vault.svg'
import { ReactComponent as SettingsIcon } from '@/assets/settings.svg'
import { ReactComponent as EmployeesIcon } from '@/assets/sidebar-employees.svg'
import { ReactComponent as MoneyIcon } from '@/assets/sidebar-expense.svg'
import classNames from 'classnames'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import {
    AccountsIcon,
    BackIcon,
    BudgetIcon,
    BusinessDirectoryIcon,
    CustomersIcon,
    DangerIcon,
    InvoiceIcon,
    PersonalCardIcon,
    VendorsIcon,
} from '@/assets/assets.export'
import Logo from '@/assets/bizpendlogo.png'

import { useAuthContext } from '@/context'
import { useLogoutUser } from '@/services/auth/mutations'
import { RoleEnum } from '@/types/org'
import { useCheckIfAdminBizpendEmail } from '@/utils/bizpend'
import { isAdminRoute, IsOwner } from '@/utils/permissions'
// import { useQueryClient } from '@tanstack/react-query'
import { motion } from 'framer-motion'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import useFeatureFlag from '@/lib/feature-flags/useFeatureFlag'
import { isDevelopmentEnvironment, isStagingEnvironment } from '@/utils/utils'

const linkClasses = 'px-3 py-2 flex items-center gap-3 cursor-pointer hover:bg-[#F9F6FF] pl-5'
const route_const = ['payroll', 'contract']

type SideBarProps = {
    onRouteChange: (routeName: string) => void
    setShowSidebar?: Dispatch<SetStateAction<boolean>>
}

const SideBar = ({ onRouteChange, setShowSidebar }: SideBarProps) => {
    const { role, logoutUser, selectedAccount } = useAuthContext()

    const navigate = useNavigate()

    const payrollFlagEnabled = useFeatureFlag('show_payroll')
    const contractFlagEnabled = useFeatureFlag('show_contract')
    const isBizpendAdmin = useCheckIfAdminBizpendEmail(selectedAccount?.user?.email)

    const pathname = window.location.pathname

    const { mutate: logoutFn } = useLogoutUser({
        onSuccess: () => {
            logoutUser()
            navigate('/')
        },
    })

    const handleLogOut = () => {
        logoutUser()
        logoutFn({})
    }

    const showBankAccounts = useFeatureFlag('show_bank_accounts')

    const sidebarRoute = [
        {
            head: 'Finances',
            routesBelow: [
                showBankAccounts && {
                    name: 'Accounts',
                    icon: AccountsIcon,
                    route: 'accounts/bizpend_accounts',
                    enabledFor: [RoleEnum.OWNER, RoleEnum.ADMIN, RoleEnum.FINANCE_ADMIN],
                    hideForUser: true,
                },
                {
                    name: 'Transactions',
                    icon: ArrowLeftRight,
                    route: 'transactions/transactions_history/company',
                    enabledFor: [
                        RoleEnum.OWNER,
                        RoleEnum.EMPLOYEE,
                        RoleEnum.USER,
                        RoleEnum.CONTRACTOR,
                        RoleEnum.FINANCE_ADMIN,
                        RoleEnum.ADMIN,
                    ],
                },
                {
                    name: 'Invoices',
                    icon: InvoiceIcon,
                    route: 'invoice/invoice_list',
                    enabledFor: [
                        RoleEnum.OWNER,
                        RoleEnum.ADMIN,
                        RoleEnum.EMPLOYEE,
                        RoleEnum.USER,
                        RoleEnum.CONTRACTOR,
                        RoleEnum.FINANCE_ADMIN,
                    ],
                    hideForUser: true,
                },
                // (isStagingEnvironment() || isDevelopmentEnvironment()) && {
                //     name: 'Bill payment',
                //     icon: CustomersIcon,
                //     route: 'bill_payment/lists',
                //     enabledFor: [RoleEnum.OWNER, RoleEnum.ADMIN, RoleEnum.FINANCE_ADMIN],
                //     hideForUser: true,
                // },
                {
                    name: 'Expenses',
                    icon: MoneyIcon,
                    route: 'expenses/expense_list/company',
                    enabledFor: [
                        RoleEnum.OWNER,
                        RoleEnum.EMPLOYEE,
                        RoleEnum.USER,
                        RoleEnum.CONTRACTOR,
                        RoleEnum.FINANCE_ADMIN,
                        RoleEnum.ADMIN,
                    ],
                },
                {
                    name: 'Budgets',
                    icon: BudgetIcon,
                    route: 'budget',
                    enabledFor: [
                        RoleEnum.OWNER,
                        RoleEnum.ADMIN,
                        RoleEnum.EMPLOYEE,
                        RoleEnum.USER,
                        RoleEnum.CONTRACTOR,
                        RoleEnum.FINANCE_ADMIN,
                    ],

                    hideForUser: true,
                },
                (isStagingEnvironment() || isDevelopmentEnvironment()) && {
                    name: 'Accounting',
                    icon: AccountingIcon,
                    route: 'accounting/main?type=all',
                    enabledFor: [RoleEnum.OWNER, RoleEnum.ADMIN, RoleEnum.FINANCE_ADMIN],
                    hideForUser: true,
                },
                {
                    name: 'Customers',
                    icon: CustomersIcon,
                    route: 'customers',
                    enabledFor: [RoleEnum.OWNER, RoleEnum.ADMIN, RoleEnum.FINANCE_ADMIN],
                    hideForUser: true,
                },
            ].filter(Boolean),
        },
        {
            head: 'People',
            routesBelow: [
                {
                    name: 'Payroll',
                    icon: PayRollIcon,
                    route: 'payroll/payroll_list',
                    enabledFor: [
                        RoleEnum.OWNER,
                        RoleEnum.ADMIN,
                        RoleEnum.FINANCE_ADMIN,
                        RoleEnum.EMPLOYEE,
                        RoleEnum.USER,
                        RoleEnum.CONTRACTOR,
                    ],
                },
                {
                    name: 'Contracts',
                    icon: ContractsIcon,
                    route: 'contract/contract_list',
                    enabledFor: [
                        RoleEnum.OWNER,
                        RoleEnum.ADMIN,
                        RoleEnum.FINANCE_ADMIN,
                        RoleEnum.EMPLOYEE,
                        RoleEnum.USER,
                        RoleEnum.CONTRACTOR,
                    ],
                },
                {
                    name: 'Vendor Management',
                    icon: VendorsIcon,
                    route: 'vendor_management/vendors',
                    enabledFor: [RoleEnum.OWNER, RoleEnum.ADMIN, RoleEnum.FINANCE_ADMIN],
                    hideForUser: true,
                },
                {
                    name: 'People',
                    icon: EmployeesIcon,
                    route: 'employees/members',
                    enabledFor: [RoleEnum.OWNER, RoleEnum.ADMIN, RoleEnum.FINANCE_ADMIN],
                    hideForUser: true,
                },
            ],
        },
        {
            head: 'Documents',
            routesBelow: [
                // (isStagingEnvironment() || isDevelopmentEnvironment()) &&
                {
                    name: 'Vault',
                    icon: VaultIcon,
                    route: 'vault/documents',
                    enabledFor: [RoleEnum.OWNER, RoleEnum.ADMIN, RoleEnum.FINANCE_ADMIN],
                    hideForUser: true,
                },
            ],
        },
    ]

    return (
        <div className='w-60 border-r border-[#DADCE0] h-screen min-h-screen bg-[#ffffff] flex flex-col overflow-scroll'>
            <div className='flex-1'>
                <div className='px-2 lg:px-6 py-3 border-b border-[#DADCE0] flex items-center justify-between'>
                    <img src={Logo} alt='logo' className='w-[137px]' />
                    <BackIcon
                        className='cursor-pointer hover:bg-slate-100 p-1 rounded block lg:hidden'
                        onClick={() => setShowSidebar?.(false)}
                    />
                </div>

                {/*=== sidebar top links ===*/}
                <div className='py-3 text-sm flex flex-col gap-1 px-3'>
                    {IsOwner() && !selectedAccount?.org?.kyb_completed && (
                        <button
                            className='flex gap-3 px-3 bg-red-100 py-2 items-center'
                            onClick={() => {
                                navigate('/admin/dashboard/settings/company/kyb')
                            }}
                        >
                            <DangerIcon color='#ef4444' className='stroke-red-500' /> Complete KYB
                        </button>
                    )}

                    <SidebarLink item={dashboardRoute} onRouteChange={onRouteChange} />
                    {sidebarRoute.map((layer, i) => (
                        <div className='mt-4' key={i}>
                            <div className='flex gap-3 flex-col'>
                                {layer.routesBelow
                                    .filter(route => !!route)
                                    .filter(route => route?.enabledFor?.includes(role?.name as RoleEnum))
                                    .map((route, key) => {
                                        if (
                                            route_const.some(route_substr =>
                                                route?.name.toLowerCase().includes(route_substr)
                                            ) &&
                                            !payrollFlagEnabled &&
                                            !contractFlagEnabled
                                        )
                                            return
                                        return <SidebarLink key={key} item={route} onRouteChange={onRouteChange} />
                                    })}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className='mt-2 '>
                <div className='flex flex-col gap-3 px-3 py-5 border-t border-b border-[#DADCE0] text-sm mb-3'>
                    <SidebarLink item={settingsRoute} onRouteChange={onRouteChange} />
                    <div className={classNames(linkClasses)} onClick={handleLogOut}>
                        <LogoutIcon />
                        Log out
                    </div>
                    {isBizpendAdmin && (
                        <div className='relative '>
                            {pathname.includes('/admin/dashboard/business-directory') ? (
                                <div className='w-full h-[60px] bg-[#f3eefb]'></div>
                            ) : (
                                <BusinessDirectoryIcon className='rounded-2xl w-full mx-auto' />
                            )}
                            <button
                                className={classNames(
                                    location.pathname.includes(`/admin/dashboard/business-directory`)
                                        ? `text-brand`
                                        : 'text-white',
                                    'absolute inset-0 flex items-center justify-center font-medium gap-3'
                                )}
                                onClick={() =>
                                    navigate(`/admin/dashboard/business-directory/manage_business`, {
                                        state: { show: isBizpendAdmin },
                                    })
                                }
                            >
                                <PersonalCardIcon
                                    className={classNames(
                                        location.pathname.includes('/admin/dashboard/business-directory')
                                            ? `fill-[#454ADE]`
                                            : ''
                                    )}
                                />
                                Business directory
                            </button>
                        </div>
                    )}
                </div>{' '}
            </div>
        </div>
    )
}

interface SidebarLinkProps {
    item: {
        route: string
        icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
        name: string
        hideForUser?: boolean
        subRouteLink?: boolean
        badge?: React.ReactNode
    }
}
// const isAdmin = isAdminRoute()

const SidebarLink = ({ item, onRouteChange }: SidebarLinkProps & SideBarProps) => {
    const location = useLocation()
    const handleRouteSwitch = () => {
        onRouteChange(item.name)
    }
    const isAdmin = isAdminRoute()
    const [settingsRoute, setSettingsRoute] = useState('settings/company')
    const [payrollRoute, setPayrollRoute] = useState('payroll/payroll_list')

    useEffect(() => {
        if (item.name == 'Settings' && !isAdmin) {
            setSettingsRoute('settings/user')
        }
        if (item.name == 'Payroll' && !isAdmin) {
            setPayrollRoute('payroll/pay_history')
        }
    }, [isAdmin])

    const routeCheck = ({ item }: SidebarLinkProps) => {
        if (item.name == 'Settings') {
            return settingsRoute
        } else if (item.name == 'Payroll') {
            return payrollRoute
        } else if (item.name === 'Expenses' && !isAdmin) {
            return 'expenses/expense_list'
        } else if (item.name === 'Transactions' && !isAdmin) {
            return 'transactions/transactions_history'
        } else return item.route
    }

    const location_path =
        item.name === 'Expenses' || item.name === 'Transactions'
            ? item.route.split('/').at(-3)
            : item.route.split('/').at(-2)

    return (
        <Link
            to={routeCheck({ item })}
            className={classNames(
                location.pathname.includes(location_path as string) ||
                    location.pathname.includes(`/dashboard/${item.route}`)
                    ? `text-[#454ADE] font-semibold`
                    : ' text-[#202020]',
                !isAdmin && item.hideForUser ? 'hidden' : '',
                !item.subRouteLink ? ' text-sm' : 'font-normal text-xs pl-16',
                'flex items-center hover:bg-[#F9F6FF] relative'
            )}
            onClick={handleRouteSwitch}
            id={`${item.name.toLowerCase()}-sidebar`}
        >
            {(location.pathname.includes(`/dashboard/${item.route}`) ||
                location.pathname.includes(location_path as string)) && (
                <motion.span
                    className={'absolute inset-0  bg-[#F9F6FF] rounded-lg text-[#454ADE]'}
                    layoutId='sidebar__links'
                />
            )}
            <span className={`${linkClasses} z-10`}>
                {item.icon && (
                    <item.icon
                        className={classNames(
                            location.pathname.includes(`/dashboard/${item.route}`) ||
                                location.pathname.includes(location_path as string)
                                ? ` fill-[#454ADE] stroke-[#fff]`
                                : 'fill-[#fff] stroke-[#202020]',
                            'z-10'
                        )}
                    />
                )}
                {item.name}
            </span>
            <span className='z-10'>{item?.badge}</span>
        </Link>
    )
}

const dashboardRoute = {
    name: 'Dashboard',
    icon: DashboardIcon,
    route: 'overview',
    enabledFor: [
        RoleEnum.OWNER,
        RoleEnum.ADMIN,
        RoleEnum.FINANCE_ADMIN,
        RoleEnum.EMPLOYEE,
        RoleEnum.USER,
        RoleEnum.CONTRACTOR,
    ],
}
const settingsRoute = {
    name: 'Settings',
    icon: SettingsIcon,
    route: 'settings',
    enabledFor: [
        RoleEnum.OWNER,
        RoleEnum.ADMIN,
        RoleEnum.FINANCE_ADMIN,
        RoleEnum.EMPLOYEE,
        RoleEnum.USER,
        RoleEnum.CONTRACTOR,
    ],
}

export default SideBar
