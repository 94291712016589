import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { usePaystack } from '@/hooks/usePaystackPayment'
import { useVerifyFundingMutation } from '@/services/funding/mutation'
import { calculateFundingFee, formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { IsKYBVerified } from '@/utils/permissions'
import { useState } from 'react'
import { TbCurrencyNaira } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

interface Step1Props {
    toggleStep: (step: number) => void
    setAmount: React.Dispatch<React.SetStateAction<number>>
    amount: number
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const Step1 = ({ setAmount, amount, setShowModal }: Step1Props) => {
    const { selectedAccount } = useAuthContext()
    const navigate = useNavigate()
    const { mutate: verifyFundingFn } = useVerifyFundingMutation({
        onSuccess: () => {
            setShowModal(false)
        },
    })
    const [fee, setFee] = useState<number>(0)
    const [inAmount, setInAmount] = useState<number>(0)
    const { initializePayment } = usePaystack({
        customConfig: {
            metadata: {
                base_amt: amount,
                fee: fee,
            },
            fee,
            amount: (Number(amount) + fee) * 100,
            email: selectedAccount?.user?.email as string,
            publicKey: import.meta.env.VITE_PAYSTACK_PUB_KEY,
        },
    })

    const onError = () => {
        throw new Error('An Error Occured')
    }

    const handleFundWallet = () => {
        if (amount <= 0) {
            toastHandler({ message: 'Please enter an amount', state: 'error' })
            return
        }
        // console.log('here')
        initializePayment((reference: any) => {
            verifyFundingFn({ reference: reference?.reference, org: selectedAccount?.org?.id })
            toastHandler({ message: 'Successfully funded your account', state: 'success' })
        }, onError)
    }

    // const nextStep = () => {
    //     if (amount <= 0) {
    //         toastHandler({ message: 'Please enter an amount', state: 'error' })
    //         return
    //     }
    //     toggleStep(2)
    // }
    return (
        <aside className='pb-12 flex flex-col p-4'>
            <div className='flex-1'>
                {/* <div className='flex justify-end mb-4'>
                    <span className='py-1 px-4 bg-[#EFEFF9] rounded-2xl flex items-center gap-2'>
                        1 / 3 <span className='font-semibold'> steps</span>
                    </span>
                </div> */}

                <div className='mb-2 mt-4'>
                    <label className='text-[12px] font-medium'>
                        Amount to fund <span className='text-[red]'>*</span>{' '}
                    </label>
                    <div className='flex border border-[#DADCE0] w-full h-[48px] rounded-lg mt-2 overflow-hidden'>
                        <div className='w-[10%] flex items-center justify-center text-[#838383]'>
                            <TbCurrencyNaira fontSize={25} />
                        </div>
                        <input
                            type='text'
                            placeholder='0.00'
                            className='outline-none w-[90%] h-full'
                            required
                            min={1}
                            value={formatAmount(inAmount).replaceAll('₦', '')}
                            onChange={e => {
                                // if (Number(e.target.value) < 1) return
                                const value_entered = formatAmountWithoutSymbols(e.target.value)
                                setInAmount(Number(value_entered))
                                setAmount(Number(value_entered) / 100)
                                setFee(calculateFundingFee(Number(value_entered) / 100))
                            }}
                        />
                    </div>
                    {amount > 0 && (
                        <span className='text-[12px] text-[#838383]'>
                            Fee: {formatAmount(calculateFundingFee(amount) * 100)}
                        </span>
                    )}
                </div>
                {inAmount < 100000 && <span className='text-errorRed text-xs mb-2 block'>Minimum amount: ₦1,000</span>}

                {/* <div className='mb-10'>
                    <span className='text-[#202020] text-[12px]'>Payment method</span>
                    <div className='mt-2 grid grid-cols-2 gap-4 text-[12px]'>
                        <div className='bg-[#F2F2F2] py-3 px-4 rounded-lg flex items-center gap-2 cursor-pointer'>
                            <img src={visa} alt='card type' />
                            Debit card
                        </div>

                        <div className='bg-[#F2F2F2] py-3 px-4 rounded-lg flex items-center gap-2 cursor-pointer'>
                            <img src={transfer} alt='transfer' />
                            Bank transfer
                        </div>
                    </div>
                </div> */}
            </div>

            <button
                className='bg-[#454ADE] w-full h-[48px] rounded-lg text-white disabled:opacity-40'
                onClick={handleFundWallet}
                disabled={inAmount < 100000 || !IsKYBVerified()}
            >
                Next
            </button>

            {!selectedAccount?.org?.kyb_completed && inAmount > 99999 && (
                <p className='text-red-500 text-sm mt-2 font-medium'>
                    To fund wallet, you need to{' '}
                    <button
                        onClick={() => {
                            navigate('/admin/dashboard/settings/company/kyb')
                        }}
                        className='underline hover:opacity-60'
                    >
                        complete kyb
                    </button>
                </p>
            )}
        </aside>
    )
}

export default Step1
