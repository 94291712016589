import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import PageContent from '@/layout/PageContent'
import { useNavigate, useParams } from 'react-router-dom'

import ContractPayroll from './partials/ContractPayroll/ContractPayroll'
import { motion } from 'framer-motion'
import OrgContractPayHistoryPage from './partials/OrgContractPayHistory/OrgContractPayHistory'

const contract_routes_const = {
    list: 'contract_list',
    pay_history: 'pay_history',
    contract: 'contract',
}
export default function Contract() {
    const { contract_subRoute } = useParams()
    const navigate = useNavigate()

    return (
        <section className='h-full mt-5 px-2 md:px-0'>
            <section className=''>
                <Tabs defaultValue={contract_subRoute} value={contract_subRoute}>
                    <TabsList className='border-none'>
                        <TabTriggersContainer>
                            <TabsTrigger
                                value={contract_routes_const.list}
                                onClick={() => navigate(`/admin/dashboard/contract/${contract_routes_const.list}`)}
                                className={`relative border-none ${
                                    contract_routes_const.list === contract_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {contract_routes_const.list === contract_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                    Contracts
                                </motion.span>
                            </TabsTrigger>
                            <TabsTrigger
                                value={contract_routes_const.pay_history}
                                onClick={() =>
                                    navigate(`/admin/dashboard/contract/${contract_routes_const.pay_history}`)
                                }
                                className={`relative border-none ${
                                    contract_routes_const.pay_history === contract_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {contract_routes_const.pay_history === contract_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                    Pay history
                                </motion.span>
                            </TabsTrigger>
                        </TabTriggersContainer>
                    </TabsList>
                    <TabsContent value={contract_routes_const.list}>
                        <PageContent>
                            <ContractPayroll />
                        </PageContent>
                    </TabsContent>

                    <TabsContent value={contract_routes_const.pay_history}>
                        <PageContent>
                            <OrgContractPayHistoryPage />
                        </PageContent>
                    </TabsContent>
                </Tabs>
            </section>
        </section>
    )
}
