import ModalWrapper from '@/components/Modals/ModalWrapper'
import { AiOutlineClose } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

type Props = {
    benefit_name: string
    benefit_plan: string
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function BenefitAddedSuccessModal({ benefit_name, benefit_plan, closeModal }: Props) {
    const navigate = useNavigate()
    benefit_plan = benefit_plan?.split('_').join(' ')
    return (
        <ModalWrapper variants={undefined}>
            <section className='sm:max-w-[35rem] sm:min-w-[30rem] my-3 h-[30rem] max-h-[30rem]'>
                <div className='flex items-center justify-end border-b border-[#DADCE0]/50 p-2 '>
                    <AiOutlineClose
                        className=' bg-[#F2F2F2] text-[#838383] inline-block w-8 h-8 p-2 rounded-full cursor-pointer'
                        onClick={() => {
                            navigate('/admin/dashboard/payroll/benefits')
                            closeModal(false)
                        }}
                    />
                </div>
                <div>
                    <svg
                        className='checkmark w-[56px] h-[56px] rounded-full block'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 52 52'
                    >
                        <circle className='checkmark__circle' cx='26' cy='26' r='25' fill='none' />{' '}
                        <path className='checkmark__check' fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
                    </svg>
                </div>
                <div className='flex flex-col justify-end items-center  my-8 text-[#202020]'>
                    <h3 className='font-semibold text-xl mt-2'>Payment successful</h3>
                    <p className='text-[#5E5E5E] text-center w-[80%] my-3'>
                        Congratulations! You have successfully made payment for the{' '}
                        <span className='text-brand'>{benefit_plan}</span> health plan.
                    </p>
                    <p className='text-[#5E5E5E] text-center w-[80%] my-3'>
                        A representative from <span className='text-brand'>{benefit_name}</span> will reach out to all
                        designated beneficiaries through their emails to seamlessly onboard them.
                    </p>
                </div>
            </section>
        </ModalWrapper>
    )
}
