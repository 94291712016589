import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import PageContent from '@/layout/PageContent'
import { motion } from 'framer-motion'
import { useNavigate, useParams } from 'react-router-dom'
import BillApprovalPolicy from '../../BillPayments/partials/BillApprovalPolicy'
import { BillPaymentAnalytics } from '../../BillPayments/partials/BillPaymentAnalytics'
import { BillPaymentLists } from '../../BillPayments/partials/BillPaymentLists'
import Vendors from '../Vendors'

const vendor_mgmt_const = {
    vendors: 'vendors',
    lists: 'lists',
    analytics: 'analytics',
    settings: 'settings',
}
export default function VendorManagement() {
    const { vendor_management_subRoute, bill_payment_subRoute } = useParams()
    const navigate = useNavigate()

    return (
        <section className='h-full mt-5'>
            <section className=' pb-[7rem]'>
                <Tabs
                    defaultValue={vendor_management_subRoute}
                    value={vendor_management_subRoute === 'vendors' ? 'vendors' : bill_payment_subRoute}
                >
                    <TabsList className='border-none'>
                        <TabTriggersContainer>
                            <TabsTrigger
                                value={vendor_mgmt_const.vendors}
                                onClick={() =>
                                    navigate(`/admin/dashboard/vendor_management/${vendor_mgmt_const.vendors}`)
                                }
                                className={`relative border-none ${
                                    vendor_mgmt_const.vendors === vendor_management_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {vendor_mgmt_const.vendors === vendor_management_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                    Vendors
                                </motion.span>
                            </TabsTrigger>
                            <TabsTrigger
                                value={vendor_mgmt_const.lists}
                                onClick={() =>
                                    navigate(
                                        `/admin/dashboard/vendor_management/bill_payment/${vendor_mgmt_const.lists}`
                                    )
                                }
                                className={`relative border-none ${
                                    vendor_mgmt_const.lists === bill_payment_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {vendor_mgmt_const.lists === bill_payment_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                    Bill payment
                                </motion.span>
                            </TabsTrigger>
                            <TabsTrigger
                                value={vendor_mgmt_const.analytics}
                                onClick={() =>
                                    navigate(
                                        `/admin/dashboard/vendor_management/bill_payment/${vendor_mgmt_const.analytics}`
                                    )
                                }
                                className={`relative border-none ${
                                    vendor_mgmt_const.analytics === bill_payment_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {vendor_mgmt_const.analytics === bill_payment_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                    Analytics
                                </motion.span>
                            </TabsTrigger>
                            <TabsTrigger
                                value={vendor_mgmt_const.settings}
                                onClick={() =>
                                    navigate(
                                        `/admin/dashboard/vendor_management/bill_payment/${vendor_mgmt_const.settings}`
                                    )
                                }
                                className={`relative border-none ${
                                    vendor_mgmt_const.settings === bill_payment_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {vendor_mgmt_const.settings === bill_payment_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                    Settings
                                </motion.span>
                            </TabsTrigger>
                        </TabTriggersContainer>
                    </TabsList>
                    <TabsContent value={vendor_mgmt_const.vendors}>
                        <Vendors />
                    </TabsContent>
                    <TabsContent value={vendor_mgmt_const.lists}>
                        <PageContent>
                            <BillPaymentLists />
                        </PageContent>
                    </TabsContent>

                    <TabsContent value={vendor_mgmt_const.analytics}>
                        <PageContent>
                            <BillPaymentAnalytics />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={vendor_mgmt_const.settings}>
                        <PageContent>
                            <BillApprovalPolicy />
                        </PageContent>
                    </TabsContent>
                </Tabs>
            </section>
        </section>
    )
}
