import { Button } from '@/components'
import profileAdd from '@/assets/profile-add.svg'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { OrgBenefitProps } from '@/types/benefits'
import { formatAmount } from '@/utils/money'
import { useNavigate } from 'react-router-dom'
import EmptyStates from '@/components/EmptyStates'
import { BenefitsSvg } from '@/assets/assets.export'
import AddUsersToBenefit from '@/components/Modals/Payroll/Benefits/AddUsersToBenefit'
import { useState } from 'react'

type Props = {
    orgBenefits: OrgBenefitProps[]
}
const ActiveBenefits = ({ orgBenefits }: Props) => {
    const navigate = useNavigate()
    const [selectedBenefit, setSelectedBenefit] = useState<OrgBenefitProps>()
    const [openAddModal, setOpenAddModal] = useState(false)
    return (
        <section className='mt-8 '>
            <div className='mb-6'>
                <h1 className='text-xl font-semibold'>Active benefits</h1>
                <p className='text-base text-[#5E5E5E] font-medium mt-2'>
                    These are the existing benefits your users are enrolled in.
                </p>
            </div>
            <div className={`flex flex-col gap-6 `}>
                {orgBenefits.length > 0 ? (
                    orgBenefits?.map(benefit => (
                        <div className='border border-[#DADCE0] rounded-2xl  px-5 py-4 '>
                            <div className='mb-7 flex justify-between items-center'>
                                <span>
                                    {benefit?.plan?.name}
                                    {/* <img src={relianceHMO} alt='' /> */}
                                </span>
                                <button
                                    className='bg-[#EFEFF9] text-[14px] text-brand rounded-2xl font-medium px-3 p-1'
                                    onClick={() => navigate(benefit?._id)}
                                >
                                    View more
                                </button>
                            </div>
                            <div className='flex justify-between items-end flex-col lg:flex-row'>
                                <div className='flex gap-5 lg:gap-10 items-center flex-wrap'>
                                    <div className=''>
                                        <h1 className='font-medium text-sm flex gap-1 items-center text-[#5E5E5E]'>
                                            Plan
                                        </h1>
                                        <p className='mt-2 font-semibold'>{benefit?.plan?.name}</p>
                                    </div>
                                    <div className=''>
                                        <h1 className='font-medium text-sm flex gap-1 items-center text-[#5E5E5E]'>
                                            Enrolled users
                                        </h1>
                                        <p className='mt-2 font-semibold'>{benefit?.beneficiaries?.length}</p>
                                    </div>
                                    <div className=''>
                                        <h1 className='font-medium text-sm flex gap-1 items-center text-[#5E5E5E]'>
                                            Benefit Cost
                                            <AiOutlineInfoCircle color='#838383' />
                                        </h1>
                                        <p className='mt-2 font-semibold'>
                                            {formatAmount(benefit?.plan?.amount)}{' '}
                                            <span className='text-[#5E5E5E] text-xs'>/ {benefit?.plan?.frequency}</span>
                                        </p>
                                    </div>
                                    <div className=''>
                                        <h1 className='font-medium text-sm flex gap-1 items-center text-[#5E5E5E]'>
                                            Effective date
                                        </h1>
                                        <p className='mt-2 font-semibold'>N/A</p>
                                    </div>
                                    <div className=''>
                                        <h1 className='font-medium text-sm flex gap-1 items-center text-[#5E5E5E]'>
                                            Next payment
                                        </h1>
                                        <p className='mt-2 font-semibold'>N/A</p>
                                    </div>
                                </div>
                                <Button
                                    className='bg-transparent text-[#202020] border border-[#DADCE0] hover:bg-transparent'
                                    onClick={() => {
                                        setOpenAddModal(true)
                                        setSelectedBenefit(benefit)
                                    }}
                                >
                                    <img src={profileAdd} alt='' /> Add User
                                </Button>
                            </div>
                        </div>
                    ))
                ) : (
                    <>
                        <section className='grid place-items-center'>
                            <EmptyStates
                                title=''
                                description='You don’t have any active benefits yet. Click the “Acquire benefit” button at the top-right to acquire your first benefit.'
                                SvgComponent={BenefitsSvg}
                            />
                        </section>
                    </>
                )}
            </div>
            {openAddModal && <AddUsersToBenefit closeModal={setOpenAddModal} benefit={selectedBenefit} />}
        </section>
    )
}

export default ActiveBenefits
