import { Button } from '@/components'
import { useAuthContext } from '@/context'
import usePersistedState from '@/hooks/usePersistedState'
import { useGetCompanyKYB, useGetOrgInsight } from '@/services/auth/queries'
import { useGetOrgMembers } from '@/services/employees/queries'
import { BsArrowRight } from 'react-icons/bs'
import { FaCircleCheck } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

export const UserHelperSteps = () => {
    const navigate = useNavigate()
    const { setShowKYCModalOnce } = useAuthContext()
    const [userDismissHelperSteps, setUserDismissHelperSteps] = usePersistedState({
        key: 'user_dismiss_helper_steps',
        defaultValue: true,
    })
    return userDismissHelperSteps ? (
        <div className='STEPS mb-3 mt-4 p-5 bg-white rounded-2xl'>
            <div className='flex items-start justify-between mb-6 flex-wrap gap-3'>
                <div className=''>
                    <h1 className='font-semibold text-lg'>Get Started in a Few Simple Steps!</h1>
                    <p className='text-base text-[#5E5E5E]'>
                        To make the most of your experience, here are your first steps:
                    </p>
                </div>
                <Button
                    className='text-base text-[#5E5E5E] cursor-pointer font-medium bg-transparent border border-[#5e5e5e5e] hover:bg-transparent hover:opacity-50'
                    onClick={() => setUserDismissHelperSteps(false)}
                >
                    Dismiss
                </Button>
            </div>
            <div className='col-span-4 grid grid-cols-1 lg:grid-cols-4 gap-[20px]'>
                {userSteps.map((step, index) => (
                    <div className=''>
                        {/* {!selectedAccount?.user?.is_kyc_complete && } */}
                        <div
                            className={`rounded-lg p-6 ${index == 0 && 'bg-[#FFFEEC]'}  bg-[${
                                step.color
                            }] flex flex-col justify-between gap-3 h-full`}
                            key={index}
                        >
                            <div className=''>
                                <h1 className='text-base font-semibold mb-1'>{step.title}</h1>
                                <p className='text-[#5E5E5E] text-sm'>{step.description}</p>
                            </div>
                            <div
                                className={`flex ml-auto gap-3 items-center  ${
                                    step.disabled ? 'opacity-30 cursor-not-allowed' : 'opacity-100 cursor-pointer'
                                }`}
                                onClick={() => {
                                    if (step.action === 'Complete KYC' && !step.disabled) {
                                        setShowKYCModalOnce(true)
                                    }
                                    if (step.action === 'Create Expense' && !step.disabled) {
                                        navigate('/dashboard/expenses/expense_list')
                                    }
                                    if (step.action === 'Add Bank' && !step.disabled) {
                                        navigate('/dashboard/settings/user/payment')
                                    }
                                }}
                            >
                                <p className='text-xs font-semibold'>{step.action}</p>
                                <BsArrowRight size={25} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <></>
    )
}

type AdminProps = {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}
const admin_dismis_key = {
    true: 'true',
    false: 'false',
    admin_dismiss_helper_steps: 'admin_dismiss_helper_steps',
}

export const AdminHelperSteps = ({ setShowModal }: AdminProps) => {
    const navigate = useNavigate()
    const { selectedAccount } = useAuthContext()

    const [adminDismissHelperSteps, setAdminDismissHelperSteps] = usePersistedState({
        key: `${admin_dismis_key.admin_dismiss_helper_steps}${selectedAccount?.org?.id}`,
        defaultValue: true,
    })

    const { data: org_members } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
    })
    const { data: company_kyb } = useGetCompanyKYB({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
    })
    const { data } = useGetOrgInsight({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
        },
    })
    const kybDone = !selectedAccount?.org?.kyb_completed || company_kyb?.verification_status === 'UNVERIFIED'
    const isFunded = (data?.current_balance as number) > 0
    const isAddedMembers = (org_members?.length as number) > 1

    return adminDismissHelperSteps ? (
        <div className='STEPS mb-8 mt-4 p-5 bg-white rounded-2xl'>
            <div className='flex items-center justify-between mb-9 flex-wrap gap-3'>
                <div className=''>
                    <h1 className='font-semibold text-lg'>Get Started in a Few Simple Steps!</h1>
                </div>
                <Button
                    className='border-[#5e5e5e5e] hover:bg-transparent hover:opacity-80 disabled:opacity-80 disabled:cursor-not-allowed'
                    variant={'outline'}
                    onClick={() => {
                        setAdminDismissHelperSteps(false)
                    }}
                    disabled={!(!kybDone && isFunded && isAddedMembers)}
                >
                    Dismiss
                </Button>
            </div>
            <div className='col-span-4 grid grid-cols-1 lg:grid-cols-3 gap-[20px]'>
                {adminSteps.map((step, index) => (
                    <div className=''>
                        <div
                            className={`border border-gray5 rounded-lg p-6 bg-gradient-to-l from-[#EFEFF966] to-[#454ADE01]  flex  justify-between gap-3 h-full ${
                                step.disabled ? 'opacity-30 cursor-not-allowed' : 'opacity-100 cursor-pointer'
                            } `}
                            key={index}
                            style={{
                                background: `radial-gradient(circle at bottom right, ${step.color} -30%, #FAFAFD 50%)`,
                            }}
                            onClick={() => {
                                if (step.action === 'Complete KYB') {
                                    if (kybDone) {
                                        navigate('/admin/dashboard/settings/company/kyb?from=get_started')
                                    } else navigate('/admin/dashboard/settings/company/kyb')
                                }
                                if (step.action === 'Invite user' && !step.disabled) {
                                    navigate('/admin/dashboard/employees/members?from=get_started')
                                }
                                if (step.action === 'Customize control' && !step.disabled) {
                                    navigate('/admin/dashboard/settings/spend_control')
                                }
                                if (step.action === 'Fund wallet' && !step.disabled) {
                                    setShowModal(true)
                                }
                            }}
                        >
                            <div className='flex gap-3 items-center'>
                                <FaCircleCheck
                                    color={
                                        (step.action === 'Complete KYB' && !kybDone) ||
                                        (step.action === 'Invite user' && isAddedMembers) ||
                                        (step.action === 'Fund wallet' && isFunded)
                                            ? '#37b937'
                                            : '#5E5E5E'
                                    }
                                    className='rounded-full'
                                    size={25}
                                />
                                <h1 className='text-base font-semibold'>{step.title}</h1>
                                <p className='text-[#5E5E5E] text-sm hidden'>{step.description}</p>
                            </div>
                            <div className={`flex ml-auto gap-3 items-center`}>
                                <p className='text-xs font-semibold hidden'>{step.action}</p>
                                <BsArrowRight size={25} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <></>
    )
}

const adminSteps = [
    {
        title: 'Complete KYB',
        description: 'Complete KYC for secure, compliant access to our powerful app features.',
        action: 'Complete KYB',
        color: '#454ADE',
    },
    {
        title: 'Invite Users',
        description: 'Invite users and assign roles to  manage company expenses together!',
        action: 'Invite user',
        color: '#E17323',
        disabled: false,
    },
    {
        title: 'Fund Your Account',
        description: 'Fund your wallet for seamless transactions and financial management experience.',
        action: 'Fund wallet',
        color: '#45B0DE',
        disabled: false,
    },
]
const userSteps = [
    {
        title: 'Add New Bank',
        description: 'Connect multiple bank accounts to get reimbursed directly to your bank.',
        action: 'Add Bank',
        color: '#EBF5FF',
        disabled: false,
    },
    {
        title: 'Create an Expense',
        description: 'Record your expenses for tracking and reimbursements of funds.',
        action: 'Create Expense',
        color: '#EFEFF9',
        disabled: false,
    },
]
