import { useGetCheckoutInvoiceByID } from '@/services/invoice/queries'
import { formatAmount } from '@/utils/money'
import { format } from 'date-fns'
import { Download } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import InvoicePaymentSidebar from './partials/InvoicePaymentSidebar/InvoicePaymentSidebar'
import Logo from '@/assets/bizpendlogo.png'
import { CgSpinner } from 'react-icons/cg'
import { Button } from '@/components'
import CheckoutPaymentModal from '@/components/Modals/Invoice/CheckoutPaymentModal'
import { useMadeOfflinePayment } from '@/services/invoice/mutations'
import { toastHandler } from '@/components/utils/Toast'

export default function InvoiceCheckout() {
    const { invoice_id } = useParams()
    const [openModal, setOpenModal] = useState(false)

    const [amount, setAmount] = useState('')

    const { mutate: offlinePaymentFn, isLoading: isMakingOfflinePayment } = useMadeOfflinePayment({
        queryParams: {
            invoice_id: invoice_id as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'Payment successful', state: 'success' })
            setOpenModal(false)
        },
    })

    const {
        data: invoice_details,
        isLoading,
        refetch,
    } = useGetCheckoutInvoiceByID({
        queryParams: {
            invoice_id: invoice_id as string,
        },
        onSuccess: data => {
            setAmount(data?.total_amount)
        },
    })

    const handlePayment = async () => {
        const data = await refetch()

        if (!(data?.data?.status === 'PAID')) {
            offlinePaymentFn({})
        } else {
            toastHandler({ message: 'Invoice had previously been paid', state: 'error' })
        }
    }

    const invoiceDetails = useMemo(() => invoice_details, [invoice_details])

    console.log(invoiceDetails)

    return (
        <main className='w-full h-full min-h-[100vh] grid grid-cols-3'>
            <div className='p-4 block md:hidden'>
                <img src={Logo} alt='logo' className='w-[137px]' />
            </div>
            <section className='col-span-0 md:col-span-1 h-full'>
                <InvoicePaymentSidebar />
            </section>
            <section className='bg-[#F9F9F9] col-span-3 md:col-span-2 flex justify-center pt-5 md:pt-14  px-2 md:px-6 lg:mx-0'>
                <section className='w-[37rem]'>
                    <div>
                        <h1 className='font-medium texg-lg md:text-xl'>Make payment</h1>
                        <p className='text-[#5e5e5e] text-sm md:text-base'>
                            Pay and settle this invoice from
                            <span className='text-brand'> {invoiceDetails?.org?.org_name}</span>
                        </p>
                    </div>
                    <div className='bg-[#fff] p-4 md:p-6 rounded-2xl mt-7'>
                        <div className='border border-[#DADCE0] grid-cols-2 p-4 rounded-2xl text-sm md:text-base'>
                            <div className='flex justify-between items-center'>
                                <div>
                                    <p className='text-[#5E5E5E]'>Amount due</p>
                                    <h2 className='font-medium'>
                                        {isLoading ? (
                                            <CgSpinner className='animate-spin' />
                                        ) : (
                                            <>
                                                {formatAmount(
                                                    Number(invoiceDetails?.total_amount) -
                                                        Number(invoiceDetails?.paid?.$numberDecimal)
                                                )}
                                            </>
                                        )}
                                    </h2>
                                </div>
                                <div>
                                    <a
                                        className='text-brand flex items-center gap-1 bg-[#EFEFF9] rounded-md p-1 text-sm px-2'
                                        href={invoiceDetails?.invoice_attachment_url as string}
                                        download={`bizpend_transaction.pdf`}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <Download size={15} /> Download
                                    </a>
                                </div>
                            </div>
                            <hr className='text-[#DADCE0] mt-2' />
                            <div className='flex justify-between items-center mt-2 '>
                                <div>
                                    <p className='text-[#5E5E5E]'>Issue date</p>
                                </div>
                                <div className='font-medium'>
                                    {isLoading ? (
                                        <CgSpinner className='animate-spin' />
                                    ) : (
                                        invoiceDetails?.issue_date &&
                                        format(new Date(invoiceDetails?.issue_date), 'dd/MM/yyyy')
                                    )}
                                </div>
                            </div>
                            <div className='flex justify-between items-center mt-2 '>
                                <div>
                                    <p className='text-[#5E5E5E]'>Due date</p>
                                </div>
                                <div className='font-medium'>
                                    {isLoading ? (
                                        <CgSpinner className='animate-spin' />
                                    ) : (
                                        invoiceDetails?.due_date &&
                                        format(new Date(invoiceDetails?.due_date), 'dd/MM/yyyy')
                                    )}
                                </div>
                            </div>
                            <div className='flex justify-between items-center mt-2 '>
                                <div>
                                    <p className='text-[#5E5E5E]'> To</p>
                                </div>
                                <div className='font-medium'>
                                    {isLoading ? (
                                        <CgSpinner className='animate-spin' />
                                    ) : (
                                        invoiceDetails?.customer?.name
                                    )}
                                </div>
                            </div>
                            <div className='flex justify-between items-center mt-2 '>
                                <div>
                                    <p className='text-[#5E5E5E]'>From</p>
                                </div>
                                <div className='font-medium'>
                                    {isLoading ? <CgSpinner className='animate-spin' /> : invoiceDetails?.org?.org_name}
                                </div>
                            </div>
                        </div>
                        <hr className='text-[#DADCE0] mt-5' />
                        <div>
                            <div className='border border-[#DADCE0] p-4 rounded-2xl mt-4 text-sm md:text-base'>
                                <h3 className='font-semibold'>Payment details</h3>
                                <div className='space-y-3 my-4 mb-8'>
                                    <div className='flex justify-between'>
                                        <h5 className='text-gray7 text-sm'>Bank name</h5>
                                        <p className='font-medium'>{invoiceDetails?.payout_bank_account?.bank_name}</p>
                                    </div>
                                    <div className='flex justify-between'>
                                        <h5 className='text-gray7 text-sm'>Account name</h5>
                                        <p className='font-medium'>
                                            {invoiceDetails?.payout_bank_account?.account_name}
                                        </p>
                                    </div>
                                    <div className='flex justify-between'>
                                        <h5 className='text-gray7 text-sm'>Account number </h5>
                                        <p className='font-medium'>
                                            {invoiceDetails?.payout_bank_account?.account_number}
                                        </p>
                                    </div>
                                </div>
                                <Button
                                    className='bg-[#19C37D] hover:bg-[#19C37D]/80 min-w-[120px] md:min-w-[165px] min-h-[40px] md:min-h-[50px] mx-auto'
                                    onClick={() => {
                                        setOpenModal(true)
                                    }}
                                    disabled={invoiceDetails?.status === 'PAID'}
                                >
                                    {invoiceDetails?.status === 'PAID'
                                        ? 'Invoice has alredy been paid'
                                        : `Make payment now`}{' '}
                                </Button>

                                {openModal && (
                                    <CheckoutPaymentModal
                                        loading={isMakingOfflinePayment || isLoading}
                                        handleBtnClick={() => handlePayment()}
                                        invoiceDetails={invoiceDetails}
                                        closeModal={() => setOpenModal(false)}
                                        amount={amount}
                                        setAmount={setAmount}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </main>
    )
}
