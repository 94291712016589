import { InvoiceContextProvider } from '@/context/InvoiceReminder'
import Invoices from '@/modules/dashboard/Invoices/Invoices'
import CreateInvoice from '@/modules/dashboard/Invoices/partials/CreateInvoice/CreateInvoice'
import InvoiceDetails from '@/modules/dashboard/Invoices/partials/InvoiceDetails/InvoiceDetails'
import { Outlet, Route } from 'react-router-dom'

export const useInvoiceRoutes = () => {
    return (
        <Route
            element={
                <InvoiceContextProvider>
                    <Outlet />
                </InvoiceContextProvider>
            }
        >
            <Route path='invoice/:invoice_subRoute' element={<Invoices />} />
            <Route path='invoice/create_invoice' element={<CreateInvoice />} />
            <Route path='invoice/update_invoice/:invoice_id' element={<CreateInvoice />} />
            <Route path='invoice/:invoice_subRoute/:invoice_id' element={<InvoiceDetails />} />
        </Route>
    )
}
