import { Button } from '@/components'
import AssignLeaveModal from '@/components/Modals/Payroll/LeaveRequest/AssignLeaveModal'
import ProgressBar from '@/components/ProgressBar'
import { getSortString } from '@/components/Table/utils'
import { useAuthContext, usePayrollContext } from '@/context'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useGetLeaveRequests } from '@/services/payroll/queries'
import { RoleEnum } from '@/types/org'
import { ColumnSort } from '@/types/utils'
import { capitalizeText } from '@/utils/string'
import { debounce } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import LeaveRequestsTable from '../../components/LeaveRequestsTable'
import notFound from '@/assets/not-found.svg'

const LeaveManagement = () => {
    // const [assignLeaveModal, setAssignLeaveModal] = useState(false)
    const { selectedAccount } = useAuthContext()
    const { assignLeaveModal, setAssignLeaveModal, sortLeave } = usePayrollContext()
    const [searchQuery, setSearchQuery] = useState('')
    const [backspaceClicked, setBackSpaceClicked] = useState(false)
    const [backspace, setBackSpace] = useState(false)

    const [orgMembersData, setOrgMembersData] = useState<{ label: string; value: string }[]>([])

    const { data: org_members } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
    })
    const {
        data: leaveRequests,
        isLoading,

        isRefetching,
        refetch: refetchAllOrgLeave,
    } = useGetLeaveRequests({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            sort: getSortString(sortLeave as ColumnSort),
            search: searchQuery,
        },
        onSuccess: () => {
            setBackSpace(false)
        },
    })
    const orgMembers = useMemo(() => org_members ?? [], [org_members])

    useEffect(() => {
        if (orgMembers) {
            setOrgMembersData(
                orgMembers
                    .filter(member => ![RoleEnum.EMPLOYEE, RoleEnum.CONTRACTOR].includes(member?.role?.name))
                    .map(member => ({
                        label: `${capitalizeText(member?.user?.first_name)} ${capitalizeText(member?.user?.last_name)}`,
                        value: member.id,
                    }))
            )
        }
    }, [orgMembers])

    const debouncedSearch = useRef(
        debounce(() => {
            refetchAllOrgLeave()
        }, 300)
    ).current

    const handleKeyDown = (event: any) => {
        if (event.key === 'Backspace') {
            setBackSpaceClicked(true)
            setBackSpace(true)
        }
    }
    useEffect(() => {
        if (backspaceClicked) {
            refetchAllOrgLeave()
            setBackSpaceClicked(false)
        }
        if (sortLeave) {
            refetchAllOrgLeave()
        }
        if (searchQuery) {
            debouncedSearch()
        }
        return () => {
            debouncedSearch.cancel()
        }
    }, [backspaceClicked, debouncedSearch, refetchAllOrgLeave, searchQuery, sortLeave])

    if (isLoading) return <ProgressBar />

    return (
        <main className='pt-10 h-[90vh] overflow-scroll px-1 lg:px-0'>
            <section className='flex justify-between flex-col gap-4 lg:flex-row lg:gap-0'>
                <div className='border border-[#DADCE0] h-[56px] w-full lg:w-[25rem] rounded-lg flex items-center overflow-hidden'>
                    <div className='text-[#A8A8A8] flex items-center justify-center h-full px-3'>
                        <CiSearch fontSize={25} />
                    </div>
                    <input
                        type='text'
                        placeholder='Search by name, email or phone number'
                        className='flex-1 outline-none bg-transparent'
                        onChange={e => setSearchQuery(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div className='flex gap-6'>
                    <Button className='w-full lg:w-auto' onClick={() => setAssignLeaveModal(true)}>
                        Assign leave
                    </Button>
                </div>
            </section>
            <div className=''>
                {leaveRequests?.length === 0 && isRefetching ? (
                    <ProgressBar />
                ) : (leaveRequests?.length === 0 && searchQuery?.length < 1 && !backspace) ||
                  (leaveRequests && leaveRequests?.length > 0 && searchQuery?.length >= 0 && !backspace) ? (
                    <section className={` transition-all rounded-lg ${isRefetching ? 'opacity-30' : 'opacity-100'} `}>
                        <LeaveRequestsTable leaveRequests={leaveRequests!} orgMembersData={orgMembersData} />
                    </section>
                ) : (
                    <div className='h-[300px] flex flex-col text-center justify-center items-center'>
                        {searchQuery && leaveRequests?.length === 0 ? (
                            <>
                                <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                                <span className='text-xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                                <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                    "Oops! It seems we couldn't find any matching results for your search. Please try
                                    again with different keywords or refine your filter criteria for better results.
                                </span>
                            </>
                        ) : (
                            <ProgressBar />
                        )}
                    </div>
                )}
            </div>
            {assignLeaveModal && <AssignLeaveModal closeModal={setAssignLeaveModal} orgMembers={orgMembers} />}
        </main>
    )
}

export default LeaveManagement
