import { useEffect, useMemo, useState } from 'react'
import { motion } from 'framer-motion'
import { useGetAllVaults, useGetFolderItems } from '@/services/vault/queries'
import ProgressBar from '@/components/ProgressBar'
import { VaultItem } from '@/services/vault/types'
import { useSearchParams } from 'react-router-dom'
import { FileLists } from '../Files/FileLists'
import { FolderLists } from '../Folders/FolderLists'
import { SingleFolderFullView } from './SingleFolderFullView'

type ViewProps = {
    setCreateFolder: React.Dispatch<React.SetStateAction<boolean>>
}

export const ItemsView = ({ setCreateFolder }: ViewProps) => {
    const [showFolder, setShowFolder] = useState(false)

    const { data: vaults, isLoading: isLoadingVaults } = useGetAllVaults({})
    const vaultsData = useMemo(() => vaults ?? [], [vaults])
    const singleVault = vaultsData?.[0]
    const [searchParams] = useSearchParams()

    const {
        data: vaultItems,
        isLoading: isLoadingVaultItems,
        refetch,
        isRefetching,
    } = useGetFolderItems({
        queryParams: {
            vault: singleVault?.id,
            parent: searchParams.get('folder') ?? singleVault?.id,
        },
        enabled: !!vaults,
    })

    const folders = vaultItems?.filter(item => item.type === 'folder')
    const files = vaultItems?.filter(item => item.type === 'file')

    useEffect(() => {
        refetch()
    }, [searchParams])

    if (isLoadingVaults || isLoadingVaultItems || isRefetching)
        return (
            <div className='w-full overflow-hidden flex items-center justify-center max-h-[600px]'>
                <ProgressBar />
            </div>
        )
    return (
        <>
            {!searchParams.get('folder') ? (
                <>
                    <div className='flex items-center gap-4'>
                        <div className='flex gap-4 p-1 w-[9.3rem] rounded-lg border border-gray8'>
                            <div
                                className={`${'bg-#F9F9F9 text-brand'}bg-[#EFEFF9] px-2 py-1 rounded cursor-pointer relative`}
                                onClick={() => {
                                    setShowFolder(false)
                                    setCreateFolder(false)
                                }}
                            >
                                {!showFolder && (
                                    <motion.span
                                        layoutId='expense_timeline__animation'
                                        className='bg-[#EFEFF9] rounded-md z-0 text-brand inset-0 absolute inline-block w-full h-full '
                                    />
                                )}
                                <motion.span
                                    layout='position'
                                    className={`z-20 sticky font-semibold ${!showFolder ? 'text-brand' : 'text-gray7'}`}
                                >
                                    Files
                                </motion.span>
                            </div>
                            <div
                                className={`${'bg-#F9F9F9 text-[#5E5E5E]'}bg-[#EFEFF9] px-2 py-1 rounded cursor-pointer relative w-full`}
                                onClick={() => {
                                    setShowFolder(true)
                                    setCreateFolder(true)
                                }}
                            >
                                {showFolder && (
                                    <motion.span
                                        layoutId='expense_timeline__animation'
                                        className='bg-[#EFEFF9] rounded-md inset-0 absolute inline-block w-full h-full '
                                    />
                                )}
                                <motion.span
                                    layout='position'
                                    className={`${showFolder ? 'text-brand' : 'text-gray7'} inset-0 absolute inline-block w-full h-full px-2 py-1 font-semibold`}
                                >
                                    Folders
                                </motion.span>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4 grid grid-cols-1 lg:grid-cols-4 gap-4'>
                        {!showFolder && <FileLists files={files as VaultItem[]} />}
                        {showFolder && <FolderLists folders={folders as VaultItem[]} />}
                    </div>
                </>
            ) : (
                <>
                    <SingleFolderFullView items={vaultItems as VaultItem[]} />
                </>
            )}
        </>
    )
}
