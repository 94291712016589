import React from 'react'
import { ExpenseStatus } from '../const'
import ExpenseStatusBadge from './ExpenseStatus'
import { ExpenseType } from '@/types/expense'
import { format } from 'date-fns'
import { capitalizeText } from '@/utils/string'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useAuthContext } from '@/context'
import { Clock, ThumbsUp } from 'lucide-react'
import { TimerIcon } from '@/assets/assets.export'
import { Check } from 'lucide-react'

const approvers_timeline = {
    0: 'First',
    1: 'Second',
    2: 'Third',
    3: 'Fourth',
    4: 'Fifth',
    5: 'Sixth',
    6: 'Seventh',
}

export default function ExpenseDetailsTimeLine({ expense }: { expense: ExpenseType }) {
    const { selectedAccount } = useAuthContext()
    const { data: org_members } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
    })
    const rearrangeApprovers = () => {
        return expense?.approvers?.sort?.(
            (approver1, approver2) => Number(approver2.approved) - Number(approver1.approved)
        )
    }
    const lastApprover = rearrangeApprovers()[expense?.approvers?.length - 1]
    const reimburser = org_members?.find(
        memb => memb?.user?._id === (expense?.transactions?.[0]?.initiated_by as unknown as string)
    )

    return (
        <div className='flex flex-col text-[#202020]'>
            <h1 className='text-lg font-semibold border-b border-[#DADCE0] py-3 mb-7'>Expense journey timeline</h1>
            <div className='flex items-start gap-6'>
                <div className='relative'>
                    <span className='py-[9px] px-[9px] bg-[#D5D5F5] rounded-lg flex'>
                        <Clock strokeWidth={1.5} />
                    </span>
                    <span className='px-[.5px] py-10 bg-[#A8A8A8] absolute left-[20px] top-[41px]'></span>
                </div>
                <div className='-mt-1'>
                    <div className='max-w-[200px] mb-3 text-sm'>
                        <ExpenseStatusBadge status={'pending' as ExpenseStatus} />
                    </div>
                    <div className='flex gap-6 text-base mb-5'>
                        <div className=''>
                            <h2 className='text-[#838383] text-sm'>Created by</h2>
                            <p className='font-semibold mt-1 text-sm mb-1'>
                                {expense?.submitter?.user?.first_name ?? 'N/A'}{' '}
                                {expense?.submitter?.user?.last_name ?? ''}
                            </p>
                        </div>
                        <div className=''>
                            <h2 className='text-[#838383] text-sm'>Date</h2>
                            <p className='font-semibold mt-1 text-sm mb-1'>
                                {expense?.createdAt ? format(new Date(expense?.createdAt), 'dd/MM/yyyy') : 'N/A'}
                            </p>
                        </div>
                        <div className=''>
                            <h2 className='text-[#838383] text-sm'>Time</h2>
                            <p className='font-semibold mt-1 text-sm mb-1'>
                                {' '}
                                {expense?.createdAt ? format(new Date(expense?.createdAt), 'hh:mm a') : 'N/A'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {rearrangeApprovers()
                .slice(0, expense?.approvers?.length - 1)
                .map((approver, idx) => (
                    <div className='flex items-start gap-6 mb-5'>
                        <div className='relative '>
                            <span
                                className={`flex py-[9px] px-[9px] ${
                                    approver?.approved ? 'bg-[#D5D5F5]' : 'bg-[#FAFAFD]'
                                }  rounded-lg`}
                            >
                                <TimerIcon className={approver?.approved ? 'opacity-100' : 'opacity-10'} />
                            </span>
                            <span
                                className={`px-[.5px] py-9  ${
                                    approver?.approved ? 'bg-[#A8A8A8]' : 'bg-[#FAFAFD]'
                                } absolute left-[20px] top-[41px]`}
                            ></span>
                        </div>
                        <div className=''>
                            <div className='flex gap-6 text-base'>
                                <div className=''>
                                    <h2 className='text-[#838383] text-sm'>
                                        {approvers_timeline[idx as keyof typeof approvers_timeline]} approver
                                    </h2>
                                    <p className='font-semibold mb-1'>
                                        {capitalizeText(approver?.account?.user?.first_name)}{' '}
                                        {capitalizeText(approver?.account?.user?.last_name)}
                                    </p>
                                </div>
                            </div>
                            <div className='max-w-[200px] mb-1 text-sm'>
                                <ExpenseStatusBadge
                                    disable={!approver?.approved}
                                    status={ExpenseStatus.partialApproval}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            <div className='flex items-start gap-6 mb-5'>
                <div className='relative'>
                    {expense?.approvers?.length === 0 && expense?.status !== ExpenseStatus.denied ? (
                        <>
                            <span className={`flex py-[9px] px-[9px] bg-[#D5D5F5] rounded-lg`}>
                                <ThumbsUp strokeWidth={1.5} />
                            </span>
                            <span className={`px-[.5px] py-9 bg-[#A8A8A8] absolute left-[20px] top-[41px]`}></span>
                        </>
                    ) : expense?.approvers?.length > 0 && expense?.status !== ExpenseStatus.denied ? (
                        <>
                            <span
                                className={`flex py-[9px] px-[9px] ${
                                    lastApprover?.approved ? 'bg-[#D5D5F5]' : 'bg-[#FAFAFD]'
                                }  rounded-lg`}
                            >
                                <ThumbsUp
                                    strokeWidth={1.5}
                                    className={lastApprover?.approved ? 'opacity-100' : 'opacity-10'}
                                />
                            </span>
                            {expense.reimbursable && expense.status !== ExpenseStatus.denied && (
                                <span
                                    className={`px-[.5px] py-9  ${
                                        lastApprover?.approved ? 'bg-[#A8A8A8]' : 'bg-[#FAFAFD]'
                                    } absolute left-[20px] top-[41px]`}
                                ></span>
                            )}
                        </>
                    ) : (
                        <>
                            <span className={`flex py-[9px] px-[9px] bg-[#D5D5F5] rounded-lg`}>
                                <ThumbsUp strokeWidth={1.5} />
                            </span>
                        </>
                    )}
                </div>
                <div className=''>
                    <div className='flex gap-6 text-base'>
                        <div className=''>
                            <h2 className='text-[#838383] text-sm'>
                                {expense?.status !== ExpenseStatus.denied ? 'Approved' : 'Denied'} by (Final)
                            </h2>

                            <p className='font-semibold mb-1'>
                                {expense?.approvers?.length === 0
                                    ? 'Auto Approval'
                                    : `
                                ${capitalizeText(lastApprover?.account?.user?.first_name)}
                                 ${capitalizeText(lastApprover?.account?.user?.last_name)}`}
                            </p>
                        </div>
                    </div>
                    <div className='max-w-[200px] mb-1 text-sm'>
                        {expense?.approvers?.length === 0 && expense?.status !== ExpenseStatus.denied ? (
                            <ExpenseStatusBadge status={ExpenseStatus.approved} />
                        ) : expense?.approvers?.length > 0 && expense?.status !== ExpenseStatus.denied ? (
                            <ExpenseStatusBadge disable={!lastApprover?.approved} status={ExpenseStatus.approved} />
                        ) : (
                            <ExpenseStatusBadge status={ExpenseStatus.denied} />
                        )}
                    </div>
                </div>
            </div>
            {expense.reimbursable && expense.status !== ExpenseStatus.denied && (
                <div className='flex items-start gap-6 mb-5'>
                    <div className='relative'>
                        <span
                            className={`flex py-[9px] px-[9px] ${
                                expense?.status === ExpenseStatus.reimbursed ? 'bg-[#D5D5F5]' : 'bg-[#FAFAFD]'
                            }  rounded-lg`}
                        >
                            <Check
                                className={expense?.status === ExpenseStatus.reimbursed ? 'opacity-100' : 'opacity-10'}
                            />
                        </span>
                    </div>
                    <div className=''>
                        <div className='flex gap-6 text-base'>
                            <div className=''>
                                <h2 className='text-[#838383] text-sm'>Reimbursed by (Final)</h2>
                                <p className='font-semibold mb-1'>
                                    {reimburser ? (
                                        <>
                                            {reimburser?.user?.first_name} {reimburser?.user?.last_name}
                                        </>
                                    ) : (
                                        <>
                                            {capitalizeText(lastApprover?.account?.user?.first_name)}{' '}
                                            {capitalizeText(lastApprover?.account?.user?.last_name ?? 'Not Available')}
                                        </>
                                    )}
                                </p>
                            </div>
                        </div>
                        <div className='max-w-[200px] mb-1 text-sm'>
                            <ExpenseStatusBadge
                                disable={expense?.status !== ExpenseStatus.reimbursed}
                                status={ExpenseStatus.reimbursed}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
