import { useEffect, useState } from 'react'
import { Menu } from '@headlessui/react'
import check from '@/assets/tiny-check.svg'
import settings from '@/assets/setting-2.svg'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { motion } from 'framer-motion'
import { useAuthContext } from '@/context'
import { useMarkNotifcationAsRead } from '@/services/notifications/mutations'
import { isAdminRoute } from '@/utils/permissions'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { NotificationType } from '@/services/notifications/types'
import { queryClient } from '@/queryClient'
import { orgNotificationsKey, personalNotificationsKey } from '@/services/notifications/queries'

const nots_routes = {
    all: 'all',
    personal: 'personal',
}

type NotsProps = {
    orgNots: NotificationType[]
    personalNots: NotificationType[]
}

export const NotificationsModal = ({ orgNots, personalNots }: NotsProps) => {
    const [option, setOption] = useState(isAdminRoute() ? 'all' : 'personal')

    const { selectedAccount, user } = useAuthContext()

    const { mutate: markAsReadFn } = useMarkNotifcationAsRead({})

    const handleMarkAsRead = () => {
        markAsReadFn(
            option === 'all'
                ? {
                      org: selectedAccount?.org.id as string,
                      read: true,
                  }
                : {
                      account: selectedAccount?.id as string,
                      read: true,
                  }
        )
    }

    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: orgNotificationsKey })
        queryClient.invalidateQueries({ queryKey: personalNotificationsKey })
    }, [])
    return (
        <section className='relative'>
            <Menu>
                <Menu.Button>
                    <div className='p-2 bg-[#EFEFF9] rounded-full cursor-pointer w-8 lg:w-10 h-8 lg:h-10 flex justify-center items-center'>
                        <IoMdNotificationsOutline fontSize={25} className='w-full' />
                    </div>
                </Menu.Button>
                <Menu.Items className='absolute -right-[14.4rem] md:-right-[18rem] top-[4.75rem] bg-white shadow-2xl pb-0 rounded-md z-50 md:min-w-[610px] w-[410px] overflow-hidden'>
                    <div className='border-b border-[#DADCE0]  flex justify-end px-4 py-3'>
                        <div className='flex items-center justify-between w-full h-full'>
                            <h2 className='font-semibold text-lg'>Notifications</h2>
                            <div
                                className='flex items-center justify-center cursor-pointer gap-1 font-semibold'
                                onClick={handleMarkAsRead}
                            >
                                <img src={check} alt='' />
                                Mark all as read
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-between px-4 border-b border-[#DADCE0]'>
                        <div className='flex  gap-9 pt-3'>
                            {isAdminRoute() && (
                                <p
                                    className={`relative border-none p-2 pr-0 cursor-pointer ${
                                        option === 'personal' ? 'opacity-40' : ''
                                    }`}
                                    onClick={() => setOption('all')}
                                >
                                    {nots_routes.all === option && (
                                        <motion.div
                                            layoutId='selected_btn'
                                            className={'absolute bottom-0 w-full h-[2px] bg-black'}
                                        />
                                    )}
                                    All{' '}
                                    <span
                                        className={`py-1 p-3 rounded-full font-semibold text-sm ml-1 ${
                                            option === 'all' ? 'text-white bg-brand' : 'text-white bg-gray7'
                                        }`}
                                    >
                                        {orgNots?.length}
                                    </span>
                                </p>
                            )}
                            <p
                                onClick={() => setOption('personal')}
                                className={` relative border-none p-2 pr-0 pb-4 cursor-pointer ${
                                    option === 'all' ? 'opacity-40' : ''
                                }`}
                            >
                                {nots_routes.personal === option && (
                                    <motion.div
                                        layoutId='selected_btn'
                                        className={'absolute bottom-0 w-full h-[2px] bg-black'}
                                    />
                                )}
                                Personal{' '}
                                <span
                                    className={`py-1 p-3 rounded-full font-semibold text-sm ml-1 ${
                                        option === 'personal' ? 'text-white bg-brand' : 'text-white bg-gray7'
                                    }`}
                                >
                                    {personalNots?.length}
                                </span>
                            </p>
                        </div>
                        <img src={settings} alt='' />
                    </div>
                    <div className='h-[25rem] overflow-scroll'>
                        {option === 'all' && (
                            <div className=''>
                                {orgNots?.length > 0 ? (
                                    orgNots?.map((notif, i) => (
                                        <Menu.Item>
                                            <Link to={`${notif.route}`} key={i}>
                                                <div
                                                    className={`p-4 ${
                                                        notif.read ? 'bg-[#EFEFF9]' : 'bg-white'
                                                    } cursor-pointer  flex items-center gap-4 border-b border-[#DADCE0]`}
                                                >
                                                    <div className=''>
                                                        {notif.metadata ? (
                                                            <img
                                                                src={notif.metadata.photo}
                                                                alt=''
                                                                className='w-[40px] h-[40px] rounded-full'
                                                            />
                                                        ) : (
                                                            <p className='w-[40px] h-[40px] bg-[#D9D9D9] text-[#838383] flex justify-center items-center rounded-full tracking-wider'>
                                                                {user?.first_name?.[0]?.toUpperCase()}{' '}
                                                                {user?.last_name?.[0]?.toUpperCase()}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className='w-full'>
                                                        <h2 className='text-[15px] font-semibold'>{notif.message}</h2>
                                                        <p className='text-[#838383] text-[13px]'>
                                                            {moment(notif.createdAt).fromNow()}
                                                        </p>
                                                        {/* <div className='bg-white p-3 rounded-xl mt-3'>
                                                <h2 className='text-sm font-semibold'>
                                                    Hotel accommodation for 3 team members on-site at Greece
                                                </h2>
                                                <div className='flex gap-3 mt-2'>
                                                    <p className='text-gray7 text-[13px]'>₦50,000.00</p>
                                                    <p className='text-gray7 text-[13px]'>Travel</p>
                                                    <p className='text-gray7 text-[13px]'>Partially approved</p>
                                                    <p className='text-gray7 text-[13px]'>Reimbursable</p>
                                                </div>
                                                <div className='flex justify-end mt-3'>
                                                    <Button
                                                        type='submit'
                                                        className='bg-transparent hover:bg-transparent border border-white min-w-[30px] hover:border-errorRed text-[#CF491E] mr-4 text-xs'
                                                    >
                                                        Decline
                                                    </Button>
                                                    <Button className='bg-[#19C37D] hover:bg-[#19C37D] hover:opacity-90 text-white px-8 min-w-[30px]  h-[36px] rounded-lg text-xs py-[0px]'>
                                                        Approve
                                                    </Button>
                                                </div>
                                            </div> */}
                                                    </div>
                                                </div>
                                            </Link>
                                        </Menu.Item>
                                    ))
                                ) : (
                                    <div className='flex justify-center items-center h-[300px]'>
                                        No notifications yet
                                    </div>
                                )}
                                {/* {notifications?.map((notif, i) => (
                                    <div
                                        className='bg-white p-4 flex gap-4 border-b border-[#DADCE0] items-center'
                                        key={i}
                                    >
                                        <div className=''>
                                            <img src={wallet} alt='' />
                                        </div>
                                        <div className='w-full'>
                                            <h2 className='text-[15px] font-semibold'>{notif.title}</h2>
                                            <p className='text-[#838383] text-[13px]'>
                                                {moment(notif.createdAt).fromNow()}
                                            </p>
                                        </div>
                                    </div>
                                ))} */}
                            </div>
                        )}
                        {option === 'personal' && (
                            <div>
                                {personalNots?.length > 0 ? (
                                    personalNots?.map((notif, i) => (
                                        <Menu.Item>
                                            <Link to={`${notif.route}`} key={i}>
                                                <div
                                                    className={`p-4 ${
                                                        notif.read ? 'bg-[#EFEFF9]' : 'bg-white'
                                                    } cursor-pointer  flex items-center gap-4 border-b border-[#DADCE0]`}
                                                >
                                                    <div className=''>
                                                        {notif.metadata ? (
                                                            <img
                                                                src={notif.metadata.photo}
                                                                alt=''
                                                                className='w-[40px] h-[40px] rounded-full'
                                                            />
                                                        ) : (
                                                            <p className='w-[40px] h-[40px] bg-[#D9D9D9] text-[#838383] flex justify-center items-center rounded-full tracking-wider'>
                                                                {user?.first_name?.[0]?.toUpperCase()}{' '}
                                                                {user?.last_name?.[0]?.toUpperCase()}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className='w-full'>
                                                        <h2 className='text-[15px] font-semibold'>{notif.message}</h2>
                                                        <p className='text-[#838383] text-[13px]'>
                                                            {moment(notif.createdAt).fromNow()}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Menu.Item>
                                    ))
                                ) : (
                                    <div className='flex justify-center items-center h-[300px]'>
                                        No notifications yet
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Menu.Items>
            </Menu>
        </section>
    )
}

// const nots = ['e', 'e']
