import React from 'react'
import { DocumentCategory } from '@/types/vault'

interface DocumentCategoryBadgeProps {
    status: DocumentCategory
    allowBg?: boolean
}

const DocumentCategoryBadge: React.FC<DocumentCategoryBadgeProps> = ({ status, allowBg = false }) => {
    let badgeColor: string

    switch (status) {
        case DocumentCategory.contract:
            badgeColor = 'bg-[#EFF7F9] text-[#45B0DE] border-[#45B0DE]'
            break
        case DocumentCategory.invoice:
            badgeColor = 'text-[#8F37B6] border-[#8F37B6] bg-[#FAF1FE]'
            break
        case DocumentCategory.others:
            badgeColor = 'bg-[#FFEFE7] text-[#FF9152] border-[#FF9152]'
            break
        case DocumentCategory.receipt:
            badgeColor = 'bg-[#EFFFED] text-[#19C37D] border-[#19C37D]'
            break
        case DocumentCategory.bill:
            badgeColor = 'bg-[#FDFCDB] text-[#DEB145] border-[#DEB145]'
            break
        case DocumentCategory.sla:
            badgeColor = 'bg-[#EBF5FF] text-[#63A8F9] border-[#63A8F9]'
            break
        default:
            badgeColor = 'bg-[#EFEFF9] text-[#202020]/70'
            break
    }

    return (
        <div
            className={`px-2 py-1 rounded text-[10px] font-semibold border max-w-fit inline-flex items-center justify-center ${allowBg && badgeColor}`}
        >
            {status}
        </div>
    )
}

export default DocumentCategoryBadge
