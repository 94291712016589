import { Button } from '@/components'
import { ArrowLeft } from 'lucide-react'
import { useEffect, useState } from 'react'
// import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import NewBenefitStep1 from './NewBenefit1'
import NewBenefitStep2, { plans } from './NewBenefit2'
import NewBenefitStep3 from './NewBenefit3'
import NewBenefitStep4 from './NewBenefit4'
import { ProfileIcon } from '@/assets/assets.export'
import { ReactComponent as MoneyIcon } from '@/assets/money.svg'
import { formatAmount } from '@/utils/money'
import NewBenefitStep5 from './NewBenefit5'
import { useForm } from 'react-hook-form'
import { BenefitForm, convertToPercentage } from './helper'
import { useCreateBenefit } from '@/services/benefits/mutations'
import { useAuthContext } from '@/context'
import BenefitAddedSuccessModal from './BenefitSuccessModal'
import { OrgBenefitsVendors } from '@/types/benefits'

const NewBenefitPages = () => {
    const navigate = useNavigate()
    const [step, setStep] = useState(1)
    const { selectedAccount } = useAuthContext()

    const [fullSelectedPlan, setFullSelectedPlan] = useState<{ name: string; price: number; list: string[] }[]>()
    const [selectedMembers, setSelectedMembers] = useState<{ label: string; value: string }[]>([])
    const [selectedCategoryVendors, setSelectedCategoryVendors] = useState<OrgBenefitsVendors[]>([])
    const [addBenefitSuccess, setAddBenefitSuccess] = useState(false)

    const [createBtn, setCreateBtn] = useState(false)
    const { mutate: createBenefitFn, isLoading } = useCreateBenefit({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
        onSuccess: () => {
            // toastHandler({ message: 'Benefit created successfully', state: 'success' })
            // navigate('/admin/dashboard/payroll/benefits')
            setAddBenefitSuccess(true)
        },
    })

    const { handleSubmit, getValues, control, watch } = useForm<BenefitForm>()
    useEffect(() => {
        setFullSelectedPlan(
            plans.filter(plan => {
                return plan?.id === getValues()?.selectedPlan
            })
        )
    }, [getValues, selectedMembers])

    const handleCreateBenefits = (values: BenefitForm) => {
        if (step === 5 && createBtn) {
            createBenefitFn({
                vendor: values?.vendor,
                category: values?.category,
                beneficiaries: selectedMembers?.map(member => member?.value),
                manager: [selectedAccount?.id],
                plan: {
                    name: fullSelectedPlan?.[0]?.name,
                    amount: fullSelectedPlan?.[0]?.price,
                    frequency: 'monthly',
                },
                cost_sharing: {
                    company: convertToPercentage(values?.split_payment_company),
                    employee: 100 - Number(convertToPercentage(values?.split_payment_company)),
                },
            })
        }
    }

    const selectedPlan = getValues()?.selectedPlan
    const watchCategory = watch('category')
    const watchVendor = watch('vendor')
    const watchPlan = watch('selectedPlan')
    const watchSharePaymentCompany = watch('split_payment_company')
    const agreeTerms = watch('agree_terms')

    // const watchSharePaymentUser = watch('split_payment_user')

    const disableBtn =
        (step === 1 && !!watchCategory && !!watchVendor) ||
        (step === 2 && !!watchPlan) ||
        (step == 3 && selectedMembers.length > 0) ||
        (step === 4 && !!watchSharePaymentCompany)

    // console.log({ getValues: getValues() })
    return (
        <div className='h-screen overflow-scroll'>
            {' '}
            <section className='mx-3 md:mx-8 lg:mx-[2.7rem] py-3 pt-2 mb-6'>
                <div
                    className='flex gap-1 my-8 cursor-pointer'
                    onClick={() => {
                        if (step === 1) {
                            navigate(-1)
                            return
                        }
                        setStep(step => step - 1)
                    }}
                >
                    <ArrowLeft />
                    back
                </div>
                <section className=' rounded-xl py-6 xl:mx-28 mb-24 '>
                    <div className='flex justify-between mb-10'>
                        <div className=''>
                            <h1 className='text-xl font-semibold text-[#202020]'>Create new benefit</h1>
                            <p className='text-base font-medium text-[#5E5E5E]'>Create a new benefit for your users </p>
                        </div>
                        <span className='hidden sm:flex py-2 px-4 bg-[#EFEFF9] rounded-2xl  items-center gap-2'>
                            {step} / 5 <span className='font-semibold'> steps</span>
                        </span>
                    </div>
                    <div className='flex flex-col md:flex-row justify-between gap-4 lg:gap-8'>
                        <div className='flex flex-col gap-6 p-4 lg:p-7 border border-[rgb(218,220,224)] rounded-xl bg-white w-full md:w-max h-fit'>
                            <p className={`whitespace-nowrap ${step > 0 ? 'text-brand' : ''}`}>
                                Select category & vendor
                            </p>
                            <p className={`whitespace-nowrap ${step > 1 ? 'text-brand' : ''}`}>Choose plan</p>
                            <p className={`whitespace-nowrap ${step > 2 ? 'text-brand' : ''}`}>Select beneficiaries</p>
                            <p className={`whitespace-nowrap ${step > 3 ? 'text-brand' : ''}`}>Set cost-sharing</p>
                            <p className={`whitespace-nowrap ${step > 4 ? 'text-brand' : ''}`}>Make payment</p>
                        </div>
                        <form
                            className=' border border-[#DADCE0] rounded-xl bg-white w-full lg:w-[70%]'
                            onSubmit={handleSubmit(handleCreateBenefits)}
                        >
                            {step === 1 && (
                                <NewBenefitStep1
                                    control={control}
                                    watch={watch}
                                    setSelectedCategoryVendors={setSelectedCategoryVendors}
                                />
                            )}
                            {step === 2 && <NewBenefitStep2 control={control} />}
                            {step === 3 && (
                                <NewBenefitStep3
                                    selectedMembers={selectedMembers}
                                    selectedPlan={selectedPlan}
                                    setSelectedMembers={setSelectedMembers}
                                />
                            )}
                            {step === 4 && (
                                <NewBenefitStep4
                                    control={control}
                                    selectedPlan={selectedPlan}
                                    selectedMembers={selectedMembers}
                                    watch={watch}
                                />
                            )}
                            {step === 5 && (
                                <NewBenefitStep5
                                    selectedPlan={selectedPlan}
                                    selectedMembers={selectedMembers}
                                    control={control}
                                />
                            )}

                            <div className='border-t border-[#DADCE0] pl-[52px] py-4 pr-4 md:pr-12 flex flex-col md:flex-row justify-between items-center'>
                                {step === 3 && fullSelectedPlan && (
                                    <div className='mb-4 md:mb-0 flex items-center text-lg font-semibold  text-[#5E5E5E]'>
                                        <ProfileIcon className=' scale-90' />{' '}
                                        <p className='mr-8'>{selectedMembers.length}</p>
                                        <MoneyIcon className='mr-2' />{' '}
                                        <p>
                                            {formatAmount(selectedMembers.length * fullSelectedPlan[0]?.price * 100)} /
                                            month
                                        </p>
                                    </div>
                                )}
                                {step !== 5 ? (
                                    <Button
                                        className='ml-auto'
                                        type='button'
                                        onClick={() => setStep(step + 1)}
                                        disabled={!disableBtn}
                                    >
                                        {step < 4 && 'Next'}
                                        {step === 4 && 'Proceed to Payment'}
                                    </Button>
                                ) : (
                                    <Button
                                        className='ml-auto'
                                        type={'submit'}
                                        loading={isLoading}
                                        onClick={() => setCreateBtn(true)}
                                        disabled={!agreeTerms}
                                    >
                                        Make Payment
                                    </Button>
                                )}
                            </div>
                        </form>
                    </div>
                </section>
            </section>
            {addBenefitSuccess && (
                <BenefitAddedSuccessModal
                    benefit_plan={watchPlan}
                    benefit_name={selectedCategoryVendors?.find(el => el?.id === watchVendor)?.name ?? ''}
                    closeModal={setAddBenefitSuccess}
                />
            )}
        </div>
    )
}

export default NewBenefitPages
