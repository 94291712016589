import { FlagIcon } from '@/assets/assets.export'
// import CheckBox from '@/components/Table/CheckBox'
import { useTransactionContext } from '@/context'
import { TransactionType } from '@/types/transactions'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import TransactionStatusBadge from '../../Transactions/components/TransactionStatusBadge'
import filter from '@/assets/filter.svg'
import { isAdminRoute } from '@/utils/permissions'
import TableActions from '@/components/Table/TableActions'
// import downArrow from '@/assets/down-arrow.svg'
import Table from '@/components/Table'

type Props = {
    transactions: TransactionType[]
}
export default function LinkedBudgetTransactionsTable({ transactions }: Props) {
    const { setSelectedTransactions } = useTransactionContext()

    const columnHelper = createColumnHelper<TransactionType>()
    const [rowSelection, setRowSelection] = React.useState({})

    const navigate = useNavigate()

    const columns: ColumnDef<TransactionType, any>[] = [
        // columnHelper.display({
        //     id: 'select',
        //     header: ({ table }) => (
        //         <CheckBox
        //             {...{
        //                 checked: table?.getIsAllRowsSelected(),
        //                 indeterminate: table?.getIsSomeRowsSelected(),
        //                 onChange: table?.getToggleAllRowsSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     cell: ({ row }) => (
        //         <CheckBox
        //             {...{
        //                 checked: row.getIsSelected(),
        //                 disabled: !row.getCanSelect(),
        //                 indeterminate: row.getIsSomeSelected(),
        //                 onChange: row.getToggleSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     enableSorting: false,
        // }),
        columnHelper.accessor('transaction_id', {
            header: () => {
                return <p className='whitespace-nowrap text-[95%] '>Transaction ID</p>
            },
            cell: cell => (
                <div>
                    <p className='font-normal mb-1 text-sm'>{cell.renderValue()}</p>

                    {cell.row.original?.flagged && <FlagIcon className='h-4 w-4' />}
                </div>
            ),
            enableSorting: false,
        }),
        columnHelper.accessor('createdAt', {
            header: () => <p className='whitespace-nowrap text-[95%]'>Date & time</p>,
            cell: cell => (
                <div>
                    <p className='font-normal mb-1 text-sm'>{format(new Date(cell.renderValue()), 'dd/MM/yyyy')}</p>
                    <p className='font-normal mb-1 text-sm'>{format(new Date(cell.renderValue()), 'hh:mm a')}</p>
                </div>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('debit.amount', {
            header: () => <p>Amount</p>,
            cell: cell => (
                <span className='font-normal whitespace-nowrap'>
                    {cell.row.original.ownerTransactionType === 'DEBIT'
                        ? `- ${formatAmount(cell.renderValue())}`
                        : `+ ${formatAmount(cell.row?.original?.credit?.amount)}`}
                </span>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('initiated_by', {
            header: () => <p>Initiated by</p>,
            cell: cell => {
                const owner = cell.row.original.initiated_by

                return (
                    <span className='font-normal '>
                        {owner?.first_name &&
                            `${capitalizeText(owner?.first_name)} ${capitalizeText(owner?.last_name?.[0])}`}
                    </span>
                )
            },
            enableSorting: false,
        }),
        columnHelper.accessor('credit.account.user.first_name', {
            header: () => <p>Destination</p>,
            cell: cell => {
                const owner = cell.row.original?.credit.account

                return (
                    <span className='font-normal '>
                        {' '}
                        {capitalizeText(owner?.org_name) ??
                            `${capitalizeText(owner?.user?.first_name)} ${capitalizeText(
                                owner?.user?.last_name?.[0]
                            )}` ??
                            capitalizeText(cell.row.original?.owner?.org_name)}
                    </span>
                )
            },
            enableSorting: false,
        }),
        columnHelper.accessor('type', {
            header: () => <p>Type</p>,
            cell: cell => <span className='font-normal'>{cell.renderValue()}</span>,
            enableSorting: false,
        }),
        columnHelper.accessor('status', {
            header: () => <p>Status</p>,
            cell: cell => {
                return (
                    <>
                        <TransactionStatusBadge status={cell.renderValue()?.toLowerCase()} />
                    </>
                )
            },
            enableSorting: false,
        }),
        columnHelper.display({
            id: 'actions',
            header: () => (
                <div className='flex items-center gap-2'>
                    <div className='h-[40px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-2 cursor-pointer'>
                        <img src={filter} alt='button' />
                    </div>
                    {/* <div
                        className='h-[40px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-2 cursor-pointer'
                        // onClick={() => {
                        //     downloadTableFileFn({ expenses })
                        // }}
                    >
                        <img src={downArrow} alt='button' />
                    </div> */}
                </div>
            ),

            cell: cell => {
                return (
                    <TableActions
                        actions={[
                            {
                                label: 'More details',
                                method: () => {
                                    setSelectedTransactions(cell?.row?.original)
                                    isAdminRoute()
                                        ? navigate(
                                              `/admin/dashboard/transactions/transactions_history/${
                                                  cell.row.original?.id ?? cell.row.original?._id
                                              }`
                                          )
                                        : navigate(
                                              `/dashboard/transactions/transactions_history/${
                                                  cell.row.original?.id ?? cell.row.original?._id
                                              }`
                                          )
                                },
                            },
                        ]}
                    />
                )
            },
        }),
    ]
    const handleRowClick = (trans: TransactionType) => {
        setSelectedTransactions(trans)
        isAdminRoute()
            ? navigate(`/admin/dashboard/transactions/transactions_history/${trans._id ?? trans.id}`)
            : navigate(`/dashboard/transactions/transactions_history/${trans._id ?? trans.id}`)
    }

    return (
        <div>
            <Table
                data={transactions}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleRowClicked={handleRowClick}
                rowClickable={true}
            />
        </div>
    )
}
