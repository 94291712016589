import ExpenseList from './partials/ExpenseList'
import PageContent from '@/layout/PageContent'
import { Tabs, TabsContent, TabsList, TabTriggersContainer, TabsTrigger } from '@/components/Tabs/Tabs'
import ExpenseAnalytics from './partials/ExpenseAnalytics'
import { useParams, useNavigate } from 'react-router-dom'
import { isAdminRoute, IsOwner } from '@/utils/permissions'
import { motion } from 'framer-motion'
import { SwitchOrgInViewMode } from '@/components/SwitchOrgInView'
import ExpenseApprovalPolicy from './partials/ExpenseApprovalpolicy'

const expense_routes_const = {
    list: 'expense_list',
    analytics: 'expense_analytics',
    settings: 'settings',
    quick: 'quick_actions',
}

const Expenses = () => {
    const { expense_subRoute } = useParams()
    const navigate = useNavigate()
    const adminRoute = isAdminRoute()

    const orgViewSubroute = location.pathname.includes('company') || location.pathname.includes('expenses/settings')

    return (
        <div id='expenses'>
            {isAdminRoute() && (
                <section className='border-b border-gray4'>
                    <SwitchOrgInViewMode />
                </section>
            )}
            <section className=' mt-6'>
                <Tabs defaultValue={expense_subRoute} value={expense_subRoute}>
                    <TabsList className='border-none'>
                        <TabTriggersContainer>
                            <TabsTrigger
                                value={expense_routes_const.list}
                                id={expense_routes_const.list}
                                onClick={() =>
                                    navigate(
                                        `/${adminRoute ? 'admin/' : ''}dashboard/expenses/${expense_routes_const.list}${adminRoute ? (orgViewSubroute ? '/company' : '/personal') : ''}`
                                    )
                                }
                                className={`relative border-none ${
                                    expense_routes_const.list === expense_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {expense_routes_const.list === expense_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles '} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                    {!adminRoute && 'My'} Expenses
                                </motion.span>
                            </TabsTrigger>
                            <TabsTrigger
                                value={expense_routes_const.analytics}
                                id={expense_routes_const.analytics}
                                onClick={() =>
                                    navigate(
                                        `/${adminRoute ? 'admin/' : ''}dashboard/expenses/${expense_routes_const.analytics}${adminRoute ? (orgViewSubroute ? '/company' : '/personal') : ''}`
                                    )
                                }
                                className={`relative border-none ${
                                    expense_routes_const.analytics === expense_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {expense_routes_const.analytics === expense_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                    Analytics
                                </motion.span>
                            </TabsTrigger>
                            {orgViewSubroute && (
                                <TabsTrigger
                                    hidden={!isAdminRoute()}
                                    value={expense_routes_const.settings}
                                    onClick={() =>
                                        navigate(
                                            `/${adminRoute ? 'admin/' : ''}dashboard/expenses/${
                                                expense_routes_const.settings
                                            }`
                                        )
                                    }
                                    className={`relative border-none ${
                                        expense_routes_const.settings === expense_subRoute ? '!text-white' : ''
                                    } text-sm`}
                                >
                                    {expense_routes_const.settings === expense_subRoute && (
                                        <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                    )}
                                    <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                        Settings
                                    </motion.span>
                                </TabsTrigger>
                            )}
                        </TabTriggersContainer>
                    </TabsList>
                    <TabsContent value={expense_routes_const.list}>
                        <PageContent>
                            <ExpenseList />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={expense_routes_const.quick}>
                        <PageContent>
                            <ExpenseList />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={expense_routes_const.analytics}>
                        <PageContent>
                            <ExpenseAnalytics />
                        </PageContent>
                    </TabsContent>
                    {IsOwner() && (
                        <TabsContent value={expense_routes_const.settings}>
                            <PageContent>
                                <ExpenseApprovalPolicy />
                            </PageContent>
                        </TabsContent>
                    )}
                </Tabs>
            </section>
        </div>
    )
}

export default Expenses
